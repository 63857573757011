import { md } from 'constants/styles/sizes';
import styled from 'styled-components';

export const WizardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  padding: 80px 0;

  @media screen and (max-width: ${md}) {
    width: 95%;
    max-width: 700px;
    margin: 0 auto;
  }
`;
