import { TextArea, TextAreaProps } from 'components/common/inputs/TextArea';
import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';

interface FormTextAreaProps extends TextAreaProps {
  control: Control<FieldValues>;
}

export const FormTextArea = ({
  name = '',
  defaultValue,
  control,
  ...textAreaPros
}: FormTextAreaProps) => {
  const {
    /* exclude Ref from variable */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const errorText = error?.message;

  return (
    <TextArea
      {...field}
      defaultValue={defaultValue}
      errorText={errorText}
      {...textAreaPros}
    />
  );
};
