import {
  InformationBadgeColorTypeInterface,
  InformationBadgeTypeInterface,
} from 'components/common/dividers/InformationBadge/types';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { darkBlue, paleBlue, white } from 'constants/styles/colors';
import styled, { css } from 'styled-components';
import { Disabled } from 'types/form';
import { MinSizes } from 'types/styles';

export const TitleSpan = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;

export const TotalTitleSpan = styled(TitleSpan)`
  font-weight: 500;
  font-size: 18px;
`;

export const ValueWrapper = styled.div`
  min-width: 55px;
  padding: 7px 13px;
  background: #ffffff;
  border-radius: 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: ${darkBlue};
`;

export const BadgeContentWrapper = styled(Section)`
  height: 100%;
  padding: 10px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(2, 30, 115, 0.24);

  :last-child {
    border-color: transparent;
  }
`;

interface BadgeProps
  extends InformationBadgeTypeInterface,
    InformationBadgeColorTypeInterface,
    Disabled,
    Pick<MinSizes, 'minWidth'> {}

export const Badge = styled.div<BadgeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ type }) =>
    type === 'information'
      ? 'space-between'
      : type === 'mobile'
      ? 'flex-start'
      : 'center'};

  ${({ minWidth }) => (minWidth ? `min-width: ${minWidth}` : 'width: 100%')};
  min-height: 48px;
  background-color: ${paleBlue};
  border-radius: 10px;
  padding: 5px 10px;

  ${({ colorType }) =>
    colorType === 'white' &&
    css`
      background-color: #f1f3f8;

      ${TitleSpan} {
        color: black;
      }

      ${ValueWrapper} {
        color: white;
      }
    `};

  ${ValueWrapper} {
    background-color: ${({ colorType, disabled }) =>
      colorType === 'white'
        ? disabled
          ? 'rgba(144, 168, 228, 0.4)'
          : paleBlue
        : white};
  }
`;
