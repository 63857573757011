import { socialIcons } from 'components/common/buttons/SocialButton/constants';
import { SocialButtonType } from 'components/common/buttons/SocialButton/types';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { Row } from 'components/wrappers/grid/FlexWrapper/styles';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import React, { ButtonHTMLAttributes } from 'react';
import { SpanButton, StyledButton } from './styles';

export interface SocialButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: SocialButtonType;
}

export const SocialButton = ({
  buttonType,
  disabled,
  ...buttonProps
}: SocialButtonProps) => {
  const { src, alt, nameIcon, disabledSrc } = socialIcons[buttonType];

  return (
    <StyledButton disabled={disabled} {...buttonProps}>
      <Row>
        <MarginWrapper marginRight="10px">
          <CustomImg
            alt={alt}
            height="22px"
            src={disabled ? disabledSrc : src}
            width="22px"
          />
        </MarginWrapper>
        <SpanButton>{nameIcon}</SpanButton>
      </Row>
    </StyledButton>
  );
};
