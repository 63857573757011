import { createEvent, createStore } from 'effector';
import { API } from 'api';
import { defaultCalculatorState } from 'constants/defaults/store';

interface CalculatorProps
  extends Pick<
    API.CreateOrderDto,
    | 'country'
    | 'subcategory'
    | 'method'
    | 'dimensions'
    | 'weight'
    | 'volume'
    | 'tariff'
  > {
  tariffCost: number;
}

const setCalculatorStoreValue = createEvent<Partial<CalculatorProps>>();
const clearCalculatorStore = createEvent();

const calculatorStore = createStore<Partial<CalculatorProps>>({})
  .on(setCalculatorStoreValue, (values, newValues) => ({
    ...values,
    ...newValues,
  }))
  .on(clearCalculatorStore, () => defaultCalculatorState);

export const calculatorEffects = {};
export const calculatorEvents = {
  setCalculatorStoreValue,
  clearCalculatorStore,
};
export const calculatorStores = { calculatorStore };
