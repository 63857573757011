import { noop } from 'constants/functions';
import { languages } from 'constants/languages';
import { combine, createEvent, createStore } from 'effector';
import { initializeToggleStore } from 'stores/initialize/initialize.toggle.store';
import { AsyncModal } from 'types/modals';
import { Noop } from 'types/types';

//const initialLoading = initializeLoadingStore([]);

// const loading = initializeLoadingStore([]);

const [asyncModalLoading, updateAsyncModalLoading] = initializeToggleStore();

const initialAsyncModal: AsyncModal = {
  visible: false,
  title: '',
  closeText: 'Нет',
  okText: 'Да',
};

const openAsyncModal = createEvent<AsyncModal>();
const closeAsyncModal = createEvent();

const asyncModal = createStore<AsyncModal>(initialAsyncModal)
  .on(openAsyncModal, (state, newState) => ({
    ...state,
    ...newState,
    visible: true,
  }))
  .on(closeAsyncModal, () => initialAsyncModal);

const asyncModalStore = combine(asyncModalLoading, asyncModal);

//**************** ErrorModal ****************//

interface ErrorModalProps {
  visible?: boolean;
  title?: string;
  subtitle?: string;
  callToActionText?: string;
  userId?: string;
  entityId?: string;
  path?: string;
  status?: number;
  message?: string;
  error?: string;
  supplementaryText?: string;
  copyButtonText?: string;
  successButtonText?: string;
  onSuccess?: Noop;
}

interface InitialErrorModalProps {
  visible: boolean;
  title: string;
  subtitle: string;
  callToActionText: string;
  userId: string;
  entityId: string;
  path: string;
  status?: number;
  message: string;
  error: string;
  supplementaryText: string;
  copyButtonText: string;
  successButtonText: string;
  onSuccess?: Noop;
}

const {
  title,
  defaultSubtitle,
  callToActionText,
  supplementaryText,
  copyButtonText,
  successButtonText,
} = languages.modals.errorModal;

const initialErrorModal: InitialErrorModalProps = {
  visible: false,
  title,
  subtitle: defaultSubtitle,
  callToActionText,
  supplementaryText,
  userId: '',
  entityId: '',
  path: '',
  status: undefined,
  message: '',
  error: '',
  copyButtonText,
  successButtonText,
  onSuccess: noop,
};

const openErrorModal = createEvent<ErrorModalProps>();
const closeErrorModal = createEvent();

const errorModalStore = createStore<InitialErrorModalProps>(initialErrorModal)
  .on(openErrorModal, (state, newState) => ({
    ...state,
    ...newState,
    visible: true,
  }))
  .on(closeErrorModal, () => initialErrorModal);

//***********************************************/

export const modalEvents = {
  openAsyncModal,
  closeAsyncModal,
  updateAsyncModalLoading,
  openErrorModal,
  closeErrorModal,
};

export const modalStores = {
  asyncModalStore,
  errorModalStore,
  // loading,
  asyncModalLoading,
  //initialLoading,
};

//export const modalEffects = {};
