import { Button } from 'components/common/buttons/Button';
import {
  contentPadding,
  contentWrapperProps,
} from 'components/modals/InformationModal/constants';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import { Column, Section } from 'components/wrappers/grid/FlexWrapper';
import { useStore } from 'effector-react';
import React from 'react';
import { informationModalStore } from 'stores/initialize/initialize.modal.store';
import { InformationText } from './styles';

export const InformationModal = () => {
  const [visible, { message, buttonText, actionAfterCloseClick, width }] =
    useStore(informationModalStore.modal);

  const onCloseClick = () => {
    informationModalStore.closeModal();
    actionAfterCloseClick && actionAfterCloseClick();
  };

  return (
    <ModalWrapper
      contentPadding={contentPadding}
      visible={visible}
      width={width || '500px'}
      onCloseClick={onCloseClick}
      {...contentWrapperProps}
    >
      <Column alignCenter justifyCenter noWrap height="100%">
        <Section justifyCenter marginBottom="30px" marginTop="20px">
          <InformationText>{message}</InformationText>
        </Section>

        {!!buttonText ? (
          <Button
            padding="10px 50px"
            width="fit-content"
            onClick={onCloseClick}
          >
            {buttonText}
          </Button>
        ) : (
          <Button buttonType="form" width="101px" onClick={onCloseClick}>
            Ок
          </Button>
        )}
      </Column>
    </ModalWrapper>
  );
};
