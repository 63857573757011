import {
  TextCheckbox,
  TextCheckboxProps,
} from 'components/common/inputs/Checkbox';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { Sizes } from 'types/styles';

export interface FormStrictFields {
  control: Control<FieldValues>;
}
export interface FormInputProps
  extends TextCheckboxProps,
    FormStrictFields,
    Pick<Sizes, 'width'> {
  defaultValue?: boolean;
}

export const FormConfirmCheckbox = ({
  name = '',
  control,
  defaultValue,
  width,
  ...decorationInputProps
}: FormInputProps) => {
  const {
    /* exclude Ref from variable */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { ref, ...field },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <Section alignCenter width={width}>
      <TextCheckbox {...field} {...decorationInputProps} />
    </Section>
  );
};
