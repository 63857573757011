import { lightgrey, white } from 'constants/styles/colors';
import styled from 'styled-components';
import { Color, TextProperties } from 'types/styles';

export interface StyledLinkProps extends Color, TextProperties {
  hoverColor?: string;
  activeColor?: string;
}

export const StyledLink = styled.a<StyledLinkProps>`
  font-size: ${({ fontSize }) => fontSize || '12px'};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  color: ${({ color }) => color || lightgrey};
  text-decoration-line: underline;
  transition: color 0.3s;

  :hover {
    color: ${({ hoverColor }) => hoverColor || white};
  }
`;
