import {
  Steps,
  StepsList,
} from 'components/grid/Wizards/ApproximateCostCalculationWizard/types';
import { DimensionsStep } from 'pages/ApproximateCostCalculator/Steps/DimensionsStep';
import { InitialStep } from 'pages/ApproximateCostCalculator/Steps/InitialStep';

export const steps: StepsList = {
  [Steps.initialStep]: {
    activeStep: 0,
    index: 0,
    Component: InitialStep,
  },
  [Steps.dimensionsStep]: {
    activeStep: 1,
    index: 1,
    Component: DimensionsStep,
  },
};
