import React from 'react';
import { useStore } from 'effector-react';
import { termsOfShipmentModal } from 'stores/initialize/initialize.modal.store';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import {
  contentPadding,
  contentWrapperProps,
} from 'components/modals/TermsOfShipmentModal/constants';

import { languages } from 'constants/languages';
import { ListContentWrapper } from '../InsuranceModal/styles';

const { content } = languages.modals.termsOfShipmentModal;

export const TermsOfShipmentModal = () => {
  const { visible } = useStore(termsOfShipmentModal.modal);

  const onCloseClick = () => {
    termsOfShipmentModal.closeModal();
  };

  return (
    <ModalWrapper
      contentPadding={contentPadding}
      visible={visible}
      onCloseClick={onCloseClick}
      {...contentWrapperProps}
    >
      <ListContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
    </ModalWrapper>
  );
};
