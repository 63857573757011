import logoImage from 'assets/img/logo-text.svg';
import { Button } from 'components/common/buttons/Button';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import {
  logoHeight,
  logoWidth,
  mobileLogoHeight,
  mobileLogoWidth,
} from 'components/grid/Headers/MainHeader/constants';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { languages } from 'constants/languages';
import { landingLink } from 'constants/routes';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { userEffects, userEvents, userStores } from 'stores/user';
import { IsAuthorized } from 'types/global';
import {
  ContactNumberText,
  HeaderContent,
  HeaderWrapper,
  StyledLink,
} from './styles';

export const Logo = () => {
  const isMobile = useMediaQuery(MediaSizes.Mobile);

  return (
    <Row>
      <CustomImg
        alt="logo image"
        height={isMobile ? mobileLogoHeight : logoHeight}
        src={logoImage}
        width={isMobile ? mobileLogoWidth : logoWidth}
      />
    </Row>
  );
};

const { contactNumber, goMain, logoutButton } =
  languages.layouts.headers.mainHeader;

const { logout, clearUserProfile } = userEffects;
const { setToken } = userEvents;
const { user } = userStores;

export interface MainHeaderProps extends IsAuthorized {}

export const MainHeader = ({ isAuthorized }: MainHeaderProps) => {
  const { accessToken } = useStore(user);
  const isTablet = useMediaQuery(MediaSizes.Tablet);
  const isTabletSmall = useMediaQuery(MediaSizes.TabletSmall);

  const buttonWidth = isTablet ? '126px' : '239px';
  return (
    <HeaderWrapper isAuthorized={isAuthorized}>
      <HeaderContent>
        <Row alignCenter>
          <a href={landingLink} rel="noopener noreferrer" target="_blank">
            <Logo />
          </a>
        </Row>
        <Row alignCenter>
          {isAuthorized ? (
            <Button
              buttonType="accent"
              width="110px"
              onClick={() => {
                accessToken && logout({ accessToken });
                setToken({ accessToken: '', refreshToken: '' });
                clearUserProfile();
              }}
            >
              {logoutButton}
            </Button>
          ) : (
            <>
              {!isTabletSmall && (
                <MarginWrapper marginRight="48px">
                  <ContactNumberText>{contactNumber}</ContactNumberText>
                </MarginWrapper>
              )}

              <StyledLink
                href={landingLink}
                rel="noopener noreferrer"
                target="_blank"
                width={buttonWidth}
              >
                {goMain}
              </StyledLink>

              {/* <BurgerButton /> */}
            </>
          )}
        </Row>
      </HeaderContent>
    </HeaderWrapper>
  );
};
