import { Button } from 'components/common/buttons/Button';
import { InformationBadge } from 'components/common/dividers/InformationBadge';
import { AdditionalInputText } from 'components/common/typography/AdditionalInputText';
import { TitleText } from 'components/common/typography/TitleText';
import { mobileMarginBottom } from 'components/forms/AddressForm/constants';
import { FormTextInput } from 'components/forms/inputs/FormTextInput';
import {
  contentMarginBottom,
  getDimensionsValue,
  getVolumeValueInMeters,
  yupSchema,
} from 'components/forms/OrdersFroms/DimensionsStepForm/constants';
import { DimensionsStepData } from 'components/forms/OrdersFroms/DimensionsStepForm/types';
import { Form } from 'components/forms/styles';
import {
  Column,
  FlexGrow,
  Row,
  Section,
} from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { toRegisterLinkClick } from 'constants/routes';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
import { usePrevious } from 'hooks/previous';
import React, { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { calculatorEvents } from 'stores/calculator';
import { informationModalStore } from 'stores/initialize/initialize.modal.store';

import { tariffsEffects, tariffsEvents, tariffsStores } from 'stores/tariffs';

const {
  dimensionsStepForm,
  text: {
    submitButton,
    requiredField,
    approximateCost,
    dimensionsStepFormTitle,
    volumeError,
    calculateCostButton,
  },
} = languages.pages.shipments;

const { message, buttonText } = languages.modals.approximateCostCalculator;

const { getTariffCost } = tariffsEffects;
const { setDefaultTariffCost } = tariffsEvents;
const { setCalculatorStoreValue } = calculatorEvents;

interface DimensionsStepFormProps
  extends Pick<
    Partial<API.CreateOrderDto>,
    'dimensions' | 'volume' | 'weight'
  > {}

export const DimensionsStepForm = ({
  dimensions = '',
  volume,
  weight,
}: DimensionsStepFormProps) => {
  const tariff = useStore(tariffsStores.tariff)?._id;
  const { cost: tariffCost } = useStore(tariffsStores.tariffCost);
  const loadingTariffCost = useStore(getTariffCost.pending);
  const isMobile = useMediaQuery(MediaSizes.Mobile);

  const [height, width, length] = dimensions?.split('x');

  const {
    handleSubmit,
    formState: { isValid },
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
  } = useFormSchema(yupSchema);

  const heightValue = parseFloat(watch('height'));
  const widthValue = parseFloat(watch('width'));
  const lengthValue = parseFloat(watch('length'));
  const volumeValue = parseFloat(watch('volume'));
  const weightValue = parseFloat(watch('weight'));

  const roundedVolumeValue = volumeValue ? Number(volumeValue.toFixed(2)) : 0;
  const roundedWeightValue = weightValue ? Number(weightValue.toFixed(2)) : 0;

  const isValidForm = isValid && !!volumeValue && !!tariffCost;
  const isValidToCalculateCost = isValid && !!volumeValue && tariff;

  const onWeightBlur = () => {
    setValue('weight', roundedWeightValue);
  };

  const onVolumeBlur = () => {
    setValue('volume', roundedVolumeValue);
  };

  const volumeMemo = useMemo(
    () =>
      getVolumeValueInMeters({
        height: heightValue,
        width: widthValue,
        length: lengthValue,
      }),
    [heightValue, widthValue, lengthValue],
  );

  const prevVolumeMemoValue = usePrevious(volumeMemo);

  const onCalculateButtonClick = () => {
    isValid &&
      !!roundedVolumeValue &&
      tariff &&
      getTariffCost({
        tariff,
        weight: weightValue,
        volume: roundedVolumeValue,
      });
  };

  const onSubmit = async ({
    height,
    length,
    volume,
    weight,
    width,
  }: DimensionsStepData) => {
    setCalculatorStoreValue({
      tariffCost,
      tariff,
      volume,
      weight,
      dimensions: getDimensionsValue({ height, length, width }),
    });

    informationModalStore.openModal({
      message,
      buttonText,
      width: '545px',
      actionAfterCloseClick: toRegisterLinkClick,
    });
  };

  useEffect(() => {
    if (!volume || prevVolumeMemoValue !== undefined) {
      setValue('volume', volumeMemo);
      trigger('volume');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heightValue, widthValue, lengthValue]);

  useEffect(() => {
    if (heightValue && widthValue && lengthValue) {
      if (volumeMemo !== roundedVolumeValue) {
        setError('volume', {
          type: 'manual',
          message: volumeError,
        });
      } else {
        clearErrors('volume');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeValue]);

  useEffect(() => {
    setDefaultTariffCost();
  }, [volumeValue, weightValue]);

  return !isMobile ? (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section marginBottom="25px">
        <TitleText>{dimensionsStepFormTitle}</TitleText>
      </Section>
      <Column height="140px" marginBottom={contentMarginBottom} width="100%">
        <Section marginBottom="5px">
          <FormTextInput
            control={control}
            defaultValue={weight}
            id="weight"
            name="weight"
            type="number"
            onBlur={onWeightBlur}
            {...dimensionsStepForm['weight']}
            autoFocus
          />
        </Section>

        <Row>
          <AdditionalInputText>{requiredField}</AdditionalInputText>
        </Row>
      </Column>
      <Section justifyBetween marginBottom="40px">
        <FlexGrow flexBasis="23%" flexGrow="0">
          <FormTextInput
            control={control}
            defaultValue={height}
            id="height"
            name="height"
            type="number"
            {...dimensionsStepForm['height']}
          />
        </FlexGrow>
        <FlexGrow flexBasis="23%" flexGrow="0">
          <FormTextInput
            control={control}
            defaultValue={width}
            id="width"
            name="width"
            type="number"
            {...dimensionsStepForm['width']}
          />
        </FlexGrow>
        <FlexGrow flexBasis="23%" flexGrow="0">
          <FormTextInput
            control={control}
            defaultValue={length}
            id="length"
            name="length"
            type="number"
            {...dimensionsStepForm['length']}
          />
        </FlexGrow>
        <FlexGrow flexBasis="23%" flexGrow="0">
          <FormTextInput
            control={control}
            defaultValue={volume}
            id="volume"
            name="volume"
            type="number"
            onBlur={onVolumeBlur}
            {...dimensionsStepForm['volume']}
          />
        </FlexGrow>
      </Section>
      <Section alignCenter justifyBetween>
        <Button
          buttonType="supplementary"
          disabled={!isValidToCalculateCost}
          type="button"
          onClick={onCalculateButtonClick}
        >
          {calculateCostButton}
        </Button>
        <Column marginBottom="40px">
          <Row marginBottom="10px">
            <TitleText>{approximateCost}</TitleText>
          </Row>
          <Row width="180px">
            <InformationBadge
              content={{ value: `${tariffCost} $` }}
              isLoading={loadingTariffCost}
              type="total"
            />
          </Row>
        </Column>
      </Section>
      <Section alignCenter justifyCenter>
        <Button disabled={!isValidForm} type="submit">
          {submitButton}
        </Button>
      </Section>
    </Form>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section marginBottom={mobileMarginBottom}>
        <TitleText>{dimensionsStepFormTitle}</TitleText>
      </Section>
      <Section marginBottom="3px">
        <FormTextInput
          control={control}
          defaultValue={weight}
          id="weight"
          name="weight"
          type="number"
          onBlur={onWeightBlur}
          {...dimensionsStepForm['weight']}
          autoFocus
        />
      </Section>

      <Row marginBottom={mobileMarginBottom}>
        <AdditionalInputText>{requiredField}</AdditionalInputText>
      </Row>

      <Section justifyBetween noWrap marginBottom={mobileMarginBottom}>
        <Section marginRight="15px">
          <FormTextInput
            control={control}
            defaultValue={height}
            id="height"
            name="height"
            type="number"
            {...dimensionsStepForm['height']}
          />
        </Section>
        <Section>
          <FormTextInput
            control={control}
            defaultValue={width}
            id="width"
            name="width"
            type="number"
            {...dimensionsStepForm['width']}
          />
        </Section>
      </Section>
      <Section justifyBetween noWrap marginBottom={mobileMarginBottom}>
        <Section marginRight="15px">
          <FormTextInput
            control={control}
            defaultValue={length}
            id="length"
            name="length"
            type="number"
            {...dimensionsStepForm['length']}
          />
        </Section>
        <Section>
          <FormTextInput
            control={control}
            defaultValue={volume}
            id="volume"
            name="volume"
            type="number"
            onBlur={onVolumeBlur}
            {...dimensionsStepForm['volume']}
          />
        </Section>
      </Section>
      <Section alignCenter justifyCenter marginBottom={mobileMarginBottom}>
        <Button
          buttonType="supplementary"
          disabled={!isValidToCalculateCost}
          type="button"
          width="100%"
          onClick={onCalculateButtonClick}
        >
          {calculateCostButton}
        </Button>
      </Section>

      <Row marginBottom="10px">
        <TitleText>{approximateCost}</TitleText>
      </Row>
      <Section marginBottom="24px">
        <InformationBadge
          content={{ value: `${tariffCost} $` }}
          isLoading={loadingTariffCost}
          type="mobile"
        />
      </Section>

      <Button disabled={!isValidForm} type="submit" width="100%">
        {submitButton}
      </Button>
    </Form>
  );
};
