import styled, { css } from 'styled-components';
import { errorColor, white } from 'constants/styles/colors';
import {
  horizontalInputPadding,
  inputHeight,
  placeholderInputColor,
} from 'components/common/inputs/Input/constants';
import { StyledInputProps } from 'components/common/inputs/Input/index';
import { Section } from 'components/wrappers/grid/FlexWrapper';

export const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: ${white};
  border: 0;
  padding: 0 ${horizontalInputPadding};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;

  :focus {
    border: 0;
  }

  ${({ type }) =>
    type === 'password' &&
    css`
      letter-spacing: 5px;
    `};

  &::placeholder {
    color: ${placeholderInputColor};
    ${({ type }) =>
      type === 'password' &&
      css`
        letter-spacing: 0;
      `};
  }

  :disabled {
    cursor: not-allowed;
    color: black;
  }
`;

export const PrefixStyleMixin = css`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #b4bac0;
`;

export const InputWrapper = styled(Section)<StyledInputProps>`
  height: ${({ height }) => height || inputHeight};
  background-color: ${white};
  border-radius: 10px;
  border: 2px solid white;

  ${({ postfix }) =>
    postfix &&
    css`
      padding: 0 ${horizontalInputPadding} 0 0;
    `};

  ${({ prefix }) =>
    prefix &&
    css`
      padding: 0 0 0 ${horizontalInputPadding};

      ${StyledInput} {
        padding: 0 0 0 3px;
      }
    `};

  span {
    ${PrefixStyleMixin}
  }

  ${({ hasError }) =>
    hasError &&
    css`
      color: ${errorColor};
      border: 2px solid ${errorColor};

      :focus {
        border: 2px solid ${errorColor};
      }
    `};

  transition: color 0.3s linear;
`;

export const InformationInputWrapper = styled.div`
  position: absolute;
  right: ${horizontalInputPadding};
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${inputHeight};
`;
