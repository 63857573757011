import React from 'react';

export const MobileSupportIcon = () => (
  <svg
    fill="none"
    height="21"
    viewBox="0 0 16 21"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.66687 0.0910359C9.60418 0.032225 9.51996 -0.000477124 9.43241 5.26187e-06H2.0673C0.927379 5.26187e-06 0 0.925108 0 2.06554V18.9493C0 20.0897 0.927379 21 2.0673 21H13.9092C15.0491 21 16 20.0897 16 18.9493V6.19914C15.9956 6.11178 15.9555 6.02964 15.8884 5.97058L9.66687 0.0910359ZM9.6 0.950335L15.0717 6.1116H10.4433C9.99059 6.1116 9.6 5.73231 9.6 5.26765V0.950335ZM15.3313 18.9497C15.3313 19.7355 14.6804 20.357 13.9092 20.357H2.0673C1.2961 20.3567 0.668657 19.735 0.668657 18.9493V2.06554C0.668657 1.27986 1.2961 0.643331 2.0673 0.643331H8.93134V2.95549V5.26765C8.93134 6.08706 9.62188 6.75493 10.4433 6.75493H15.3313V18.9497Z"
      fill="#90A8E4"
    />
  </svg>
);
