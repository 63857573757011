import { darkBlue, darkBlue2, grey4 } from 'constants/styles/colors';
import { lg, md, xl } from 'constants/styles/sizes';
import styled from 'styled-components';
import { Active } from 'types/global';

export const Wrapper = styled.div`
  position: relative;
  max-width: 1200px;
  width: 100%;
  padding-top: 80px;

  display: flex;
  justify-content: space-between;

  &::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: ${grey4};
  }

  @media screen and (max-width: ${xl}) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media screen and (max-width: 1110px) {
    max-width: 910px;
    margin: 0;
  }

  @media screen and (max-width: ${lg}) {
    margin-left: 70px;
    margin-right: 70px;
  }

  @media screen and (max-width: 910px) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media screen and (max-width: ${md}) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const SidebarWrapper = styled.div`
  /* min-width: 318px;
  max-width: 318px; */
  flex-shrink: 0;
  width: 318px;
  height: fit-content;

  position: sticky;
  left: 0;
  top: 80px;

  padding: 25px 20px;
  background: white;

  margin-right: 62px;
`;

export const SidebarItem = styled.a<Active>`
  display: block;
  width: 100%;
  padding: 9px 24px;
  cursor: pointer;

  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: ${({ active }) => (active ? 'white' : darkBlue2)};

  background: ${({ active }) => (active ? darkBlue : 'white')};
  border-radius: 6px;
`;

export const MainTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: ${darkBlue2};

  margin-bottom: 30px;
`;

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${darkBlue2};
`;

interface AccentTextProps {
  isLink?: boolean;
}

export const AccentText = styled.span<AccentTextProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${darkBlue};

  ${({ isLink }) => isLink && `border-bottom: 2px solid ${darkBlue};`}
`;

export const AccentLink = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${darkBlue};
  border-bottom: 2px solid ${darkBlue};
  cursor: pointer;
`;
