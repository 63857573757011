import {
  DateAndTimeRangePicker,
  DateAndTimeRangePickerProps,
} from 'components/common/inputs/DateInput';
import { Row, Section } from 'components/wrappers/grid/FlexWrapper';
import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { Title } from 'types/data';
import { InputLabel } from '../FormTextInput/styles';

interface DateAndTimeRangeInputProps
  extends Pick<Title, 'title'>,
    DateAndTimeRangePickerProps {
  isSecondaryTitle?: boolean;
}

export const DateAndTimeRangeInput = ({
  title,
  isSecondaryTitle,
  ...datePicker
}: DateAndTimeRangeInputProps) => (
  <Section>
    <Row marginBottom="5px">
      <InputLabel isSecondary={isSecondaryTitle}>{title}</InputLabel>
    </Row>
    <DateAndTimeRangePicker {...datePicker} />
  </Section>
);

interface FormDateAndTimeRangeInputProps extends DateAndTimeRangeInputProps {
  control: Control<FieldValues>;
  defaultValue?: string;
  name: string;
}

export const FormDateAndTimeRangeInput = ({
  defaultValue,
  name,
  control,
  ...inputProps
}: FormDateAndTimeRangeInputProps) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <DateAndTimeRangeInput value={value} {...inputProps} onChange={onChange} />
  );
};
