import selectIcon from 'assets/icons/select_icon.svg';
import { ArrowImg } from 'components/common/imgComponents/ArrowImg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import {
  borderLineShift,
  selectorHeight,
  selectorLeftPadding,
  selectorRightPadding,
  selectorVerticalPadding,
} from 'components/common/inputs/Select/constants';
import {
  BorderedItem,
  InformationBlock,
  ItemRelativeWrapper,
  ItemsAbsoluteWrapper,
  ItemSpan,
  ItemWrapper,
  PlaceholderSpan,
  SelectWrapper,
  SelectWrapperProps,
} from 'components/common/inputs/Select/styles';
import { OpacityActiveEffect } from 'components/dynamic/effects';
import { Loader } from 'components/dynamic/Loader';
import { ContentWrapperProps } from 'components/wrappers/ContentWrapper';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import { Column, Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { noop } from 'constants/functions';
import { useCloseClick } from 'hooks/closeClick';
import { useModal } from 'hooks/modal';
import React, { useEffect, useRef, useState } from 'react';
import { Loading } from 'types/data';
import { Disabled } from 'types/form';

export interface SelectorInterface {
  value: string;
  label: string;
  information?: string;
}

export interface SelectProps
  extends Omit<ContentWrapperProps, 'padding'>,
    SelectWrapperProps,
    Disabled,
    Loading {
  selector: SelectorInterface[];
  defaultValue?: SelectorInterface;
  onChange?: (select: SelectorInterface) => void;
  onMultipleChange?: (index: SelectorInterface[]) => void;
  isMultiple?: boolean;
  values?: SelectorInterface[];
  placeholder?: string;
}

/* TODO: Improve logic for Multiple selector */

export const Select = ({
  selector,
  // title,
  paddingBottom = selectorVerticalPadding,
  paddingLeft = selectorLeftPadding,
  paddingRight = selectorRightPadding,
  paddingTop = selectorVerticalPadding,
  backgroundColor,
  isMultiple,
  onMultipleChange = noop,
  values = [],
  defaultValue,
  onChange = noop,
  disabled,
  isLoading,
  height,
  placeholder,
  ...wrapperStyles
}: SelectProps) => {
  const { visible, close, open } = useModal();
  const componentRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  const isDisabled = disabled || selector.length === 0 || isLoading;

  const [activeValue, setActiveValue] = useState<SelectorInterface | undefined>(
    isMultiple ? values?.[0] : defaultValue,
  );

  const openClick = () => !isDisabled && open();
  const selectClick = (item: SelectorInterface) => {
    const returnedValues = values?.some(({ value }) => item.value === value)
      ? values?.filter(({ value }) => item.value !== value)
      : [...(values as SelectorInterface[]), item];

    isMultiple ? onMultipleChange(returnedValues) : onChange(item);
    isMultiple ? setActiveValue(returnedValues[0]) : setActiveValue(item);
    close();
  };

  useCloseClick(componentRef, close);

  useEffect(() => setActiveValue(defaultValue), [defaultValue]);
  useEffect(() => {
    isMultiple && setActiveValue(values?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <SelectWrapper
      ref={componentRef}
      backgroundColor={backgroundColor}
      disabled={isDisabled}
      height={height}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingTop={paddingTop}
      visible={visible}
      onClick={visible ? close : openClick}
      {...wrapperStyles}
    >
      <Column ref={selectRef} justifyCenter height="100%" width="100%">
        <Section alignCenter justifyBetween noWrap height={height}>
          {activeValue !== undefined ? (
            <ItemSpan>{activeValue.label}</ItemSpan>
          ) : placeholder ? (
            <PlaceholderSpan>{placeholder}</PlaceholderSpan>
          ) : (
            <ItemSpan>{''}</ItemSpan>
          )}
          <Row alignCenter noWrap>
            {activeValue?.information && (
              <MarginWrapper marginRight="8px">
                <InformationBlock>{activeValue?.information}</InformationBlock>
              </MarginWrapper>
            )}
            {!isLoading && (
              <ArrowImg disabled={isDisabled} rotate={visible ? 180 : 0} />
            )}
          </Row>
          {isLoading && (
            <Row>
              <Loader />
            </Row>
          )}
        </Section>
      </Column>
      <ItemsAbsoluteWrapper top={height || selectorHeight} visible={visible}>
        <Column>
          {selector.map(({ value, information, label }, index) => {
            const isActive = isMultiple
              ? values?.some(
                  ({ value: multipleValue }) => multipleValue === value,
                )
              : value === activeValue?.value;
            return (
              <ItemRelativeWrapper key={index.toString()}>
                <AbsoluteWrapper
                  left={borderLineShift}
                  top="-1px"
                  width="100%"
                  zIndex="1000"
                >
                  <BorderedItem />
                </AbsoluteWrapper>
                <ItemWrapper
                  active={isActive}
                  onClick={() => selectClick({ value, information, label })}
                >
                  <ItemSpan>{label}</ItemSpan>
                  <Row alignCenter noWrap>
                    {information && (
                      <MarginWrapper marginRight="8px">
                        <InformationBlock>{information}</InformationBlock>
                      </MarginWrapper>
                    )}
                    <OpacityActiveEffect active={isActive} opacity={0}>
                      <CustomImg
                        alt="Select Icon"
                        height="12px"
                        src={selectIcon}
                        width="13px"
                      />
                    </OpacityActiveEffect>
                  </Row>
                </ItemWrapper>
              </ItemRelativeWrapper>
            );
          })}
        </Column>
      </ItemsAbsoluteWrapper>
    </SelectWrapper>
  );
};
