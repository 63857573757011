export const blue = '#3360ff';
export const paleBlue = '#90A8E4';

export const grey = '#d9d9d9';
export const grey2 = '#d3d3d3';
export const grey3 = '#B4BAC0';
export const grey4 = '#F1F3F8';
export const grey5 = '#F6F7F9';
export const grey6 = '#C4C4C4';
export const grey7 = '#7b7f8b';
export const grey8 = '#d0d3d9';

export const backgroundColor = '#FFFFFF';

export const errorColor = '#d80027';
export const inactiveTextColor = 'rgba(196, 196, 196, 0.58)';

//////////////////
/* UI KIT Color */
//////////////////

export const black = '#000000';
export const darkBlue = '#021E73';
export const darkBlue2 = '#242B3E';
export const raspberry = '#E30B5E';
export const darkRaspberry = '#be064d';
export const gray = '#858B9B';
export const lightgrey = '#cad0dd';
export const lightBlue = '#7D828E';
export const lightBlue2 = '#2E458B';
export const white = '#FFFFFF';
export const whiteHover = 'rgba(255, 255, 255, 0.25)';
export const whiteHover2 = 'rgba(255, 255, 255, 0.35)';

export const linkColor = '#3393EC';
export const hColor = '#21242b';
