import { Span } from 'components/common/typography/Span';
import { mobileNavItems } from 'components/grid/MobileNavBar/constants';
import { landingLink } from 'constants/routes';
import { MediaSizes } from 'constants/styles/sizes';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { IsAuthorized } from 'types/global';
import { NavItem, Wrapper } from './styles';

interface MobileNavBarProps extends IsAuthorized {}

export const MobileNavBar = ({ isAuthorized }: MobileNavBarProps) => {
  const { pathname } = useLocation();
  const isMobileBig = useMediaQuery(MediaSizes.MobileBig);

  if (!isMobileBig || !isAuthorized) return null;

  return (
    <Wrapper>
      {mobileNavItems.map(({ title, path, Icon }) => {
        const isActive = !!matchPath(pathname, {
          path,
        });

        return path === landingLink ? (
          <a key={title} href={path} rel="noopener noreferrer" target="_blank">
            <NavItem active={isActive}>
              <Icon />
              <Span>{title}</Span>
            </NavItem>
          </a>
        ) : (
          <Link key={title} to={path}>
            <NavItem active={isActive}>
              <Icon />
              <Span>{title}</Span>
            </NavItem>
          </Link>
        );
      })}
    </Wrapper>
  );
};
