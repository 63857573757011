import { Loader } from 'components/dynamic/Loader';
import { AddressForm } from 'components/forms/AddressForm';
import { AddressFormInterface } from 'components/forms/AddressForm/types';
import { AccountLayout } from 'components/layouts/AccountLayout';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { toAddressesLinkClick } from 'constants/routes';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import {
  addressesEffects,
  addressesEvents,
  addressesStores,
} from 'stores/addresses';
import { getDeliveryPost } from 'utils/data';

interface AddressParamsInterface extends Pick<API.GetAddressDto, 'id'> {}

const { loadItem, formUpdateAddressById, formCreateAddress } = addressesEffects;
const { clearAddress } = addressesEvents;

export const Address = () => {
  const { id } = useParams<AddressParamsInterface>();
  const {
    lastName,
    firstName,
    country,
    city,
    postCode,
    phone,
    region,
    apartmentNumber,
    buildingNumber,
    frameNumber,
    street,
    title,
    deliveryPoint,
    middleName,
  } = useStore(addressesStores.address);
  const loading = useStore(loadItem.pending);

  useEffect(
    () => () => {
      clearAddress();
    },
    [],
  );

  useEffect(() => {
    if (id !== 'new') {
      loadItem({ id });
    }
  }, [id]);

  const onSubmit = async ({
    firstName,
    lastName,
    country,
    city,
    postCode,
    addressName,
    secondName,
    delivery,
    apartmentNumber,
    area,
    buildingHouse,
    house,
    phoneNumber,
    street,
  }: AddressFormInterface) => {
    const data = {
      firstName,
      lastName,
      country,
      city,
      postCode: postCode === null ? undefined : postCode,
      apartmentNumber,
      region: area,
      frameNumber: buildingHouse,
      buildingNumber: house,
      phone: phoneNumber,
      street,
      title: addressName,
      deliveryPoint: getDeliveryPost(delivery),
      middleName: secondName,
    };
    if (id !== 'new') {
      await formUpdateAddressById({ ...data, id });
    } else {
      await formCreateAddress({
        ...data,
        actionAfterCloseClick: toAddressesLinkClick,
      });
    }
  };

  return (
    <AccountLayout>
      {loading ? (
        <Section alignCenter justifyCenter height="600px">
          <Loader />
        </Section>
      ) : (
        <AddressForm
          addressName={title}
          apartmentNumber={apartmentNumber}
          area={region}
          buildingHouse={frameNumber}
          city={city}
          country={country}
          delivery={deliveryPoint}
          firstName={firstName}
          house={buildingNumber}
          lastName={lastName}
          phoneNumber={phone}
          postCode={postCode}
          secondName={middleName}
          street={street}
          onClose={toAddressesLinkClick}
          onSubmit={onSubmit}
        />
      )}
    </AccountLayout>
  );
};
