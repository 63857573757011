import { contentWrapperBorderRadius } from 'components/wrappers/ContentWrapper/constants';
import { black, white } from 'constants/styles/colors';

export const selectorRightPadding = '12px';
export const selectorLeftPadding = '16px';
export const selectorVerticalPadding = '0px';
export const selectorHeight = '48px';

export const itemHeight = '27px';

export const lastItemBorderRadius = contentWrapperBorderRadius;

const beforeBorderRadiusHeight = '5px';
export const lastItemBorderHeight = `${
  parseInt(lastItemBorderRadius) + parseInt(beforeBorderRadiusHeight)
}px`;

export const selectedColor = black;
export const hoveredColor = '#F1F3F8';
export const selectedTextColor = white;

export const expandButtonHeight = '20px';

export const borderItemColor = 'rgba(0, 0, 0, 0.2)';

export const titleMarginBottom = '0px';
export const absoluteWrapperTop = '50px';
export const absoluteWrapperWithTitleTop = `${
  parseInt(absoluteWrapperTop) + parseInt(titleMarginBottom)
}px`;

export const borderLineShift = '8px';
export const borderLineWidth = `calc(100% - ${
  parseInt(borderLineShift) * 2 + 'px'
})`;
