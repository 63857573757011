import {
  yupDefault,
  yupFirstName,
  yupLastName,
  yupPostCode,
  yupRequiredPhoneNumber,
} from 'constants/yupFields';
import * as Yup from 'yup';

export const yupSchema = Yup.object().shape({
  addressName: Yup.string(),
  firstName: yupFirstName,
  lastName: yupLastName,
  secondName: yupDefault,
  country: yupDefault,
  area: yupDefault,
  city: yupDefault,
  street: yupDefault,
  house: yupDefault,
  buildingHouse: Yup.string(),
  apartmentNumber: Yup.string(),
  postCode: yupPostCode,
  phoneNumber: yupRequiredPhoneNumber,
  delivery: yupDefault,
});

export const contentMarginBottom = '10px';
export const fieldsMarginBottom = '20px';
export const titleMarginBottom = '20px';
export const marginBetween = '10px';
export const delayRegionAPI = 1000;
export const mobileMarginBottom = '16px';
export const mobileMarginBottomLarge = '24px';
