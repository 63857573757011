import { DimensionsStepForm } from 'components/forms/ApproximateCostCalculatorForms/DimensionsStepForm';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import {
  mobileStepContentWrapperPadding,
  stepContentWrapperPadding,
} from 'pages/Shipments/constants';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ordersStores } from 'stores/orders';

export const DimensionsStep = () => {
  const { weight, dimensions, volume } = useStore(ordersStores.orderFormData);
  const isMobileLarge = useMediaQuery(MediaSizes.MobileLarge);

  return (
    <ContentWrapper
      padding={
        isMobileLarge
          ? mobileStepContentWrapperPadding
          : stepContentWrapperPadding
      }
      width="100%"
    >
      <DimensionsStepForm
        dimensions={dimensions}
        volume={volume}
        weight={weight}
      />
    </ContentWrapper>
  );
};
