import {
  yupDefaultBoolean,
  yupEmail,
  yupFirstName,
  yupLastName,
  yupPassword,
  yupRequiredPhoneNumber,
} from 'constants/yupFields';
import * as Yup from 'yup';

export const yupSchema = Yup.object().shape({
  firstName: yupFirstName,
  lastName: yupLastName,
  phoneNumber: yupRequiredPhoneNumber,
  email: yupEmail,
  password: yupPassword,
  political: yupDefaultBoolean,
});

export const marginBetween = '10px';
export const formInputMarginBottom = '15px';
