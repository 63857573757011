import { darkBlue } from 'constants/styles/colors';
import { xs } from 'constants/styles/sizes';
import styled from 'styled-components';

export const InformationText = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  color: ${darkBlue};

  @media screen and (max-width: ${xs}) {
    font-size: 18px;
  }
`;
