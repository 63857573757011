import { baseURL } from 'constants/services';
import queryString from 'query-string';
import { SocialMediaEnum } from 'types/types';

export const triggerCopy: (text: string) => void = (text) =>
  navigator.clipboard.writeText(text);

export const getQueryParams = <T extends {}>(query: string) => {
  const params = queryString.parse(query);
  return params as T;
};

export const authenticateWithSocialMedia = (name: SocialMediaEnum) => {
  window.location.href = `${baseURL}/auth/${name}`;
};
