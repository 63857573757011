import React from 'react';

export const MobileMainIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.204 5.70959L10.579 0.534591C10.4042 0.429684 10.2041 0.374268 10.0002 0.374268C9.79629 0.374268 9.59622 0.429684 9.42138 0.534591L0.796375 5.70959C0.63013 5.80989 0.492525 5.95132 0.396826 6.12026C0.301127 6.28919 0.25056 6.47993 0.25 6.67409V17.8746C0.250595 18.3717 0.448331 18.8483 0.799832 19.1998C1.15133 19.5513 1.6279 19.749 2.125 19.7496H6.25C6.54837 19.7496 6.83452 19.6311 7.0455 19.4201C7.25647 19.2091 7.375 18.923 7.375 18.6246V13.7496C7.375 13.6501 7.41451 13.5548 7.48483 13.4844C7.55516 13.4141 7.65054 13.3746 7.75 13.3746H12.25C12.3495 13.3746 12.4448 13.4141 12.5152 13.4844C12.5855 13.5548 12.625 13.6501 12.625 13.7496V18.6246C12.625 18.923 12.7435 19.2091 12.9545 19.4201C13.1655 19.6311 13.4516 19.7496 13.75 19.7496H17.875C18.3721 19.749 18.8487 19.5513 19.2002 19.1998C19.5517 18.8483 19.7494 18.3717 19.75 17.8746V6.67409C19.7495 6.47997 19.699 6.28925 19.6033 6.12032C19.5077 5.95139 19.3702 5.80994 19.204 5.70959V5.70959ZM19 17.8746C19 18.173 18.8815 18.4591 18.6705 18.6701C18.4595 18.8811 18.1734 18.9996 17.875 18.9996H13.75C13.6505 18.9996 13.5552 18.9601 13.4848 18.8898C13.4145 18.8194 13.375 18.724 13.375 18.6246V13.7496C13.375 13.4512 13.2565 13.1651 13.0455 12.9541C12.8345 12.7431 12.5484 12.6246 12.25 12.6246H7.75C7.45163 12.6246 7.16548 12.7431 6.9545 12.9541C6.74353 13.1651 6.625 13.4512 6.625 13.7496V18.6246C6.625 18.724 6.58549 18.8194 6.51517 18.8898C6.44484 18.9601 6.34946 18.9996 6.25 18.9996H2.125C1.82663 18.9996 1.54048 18.8811 1.3295 18.6701C1.11853 18.4591 1 18.173 1 17.8746V6.67409C1.00001 6.60938 1.01677 6.54578 1.04864 6.48946C1.08051 6.43315 1.12641 6.38604 1.18187 6.35272L9.80688 1.17772C9.86524 1.1426 9.93207 1.12405 10.0002 1.12405C10.0683 1.12405 10.1351 1.1426 10.1935 1.17772L18.8185 6.35272C18.874 6.38604 18.9199 6.43315 18.9517 6.48946C18.9836 6.54578 19.0004 6.60938 19.0004 6.67409L19 17.8746Z"
      fill="#90A8E4"
    />
  </svg>
);
