import { Button } from 'components/common/buttons/Button';
import { InternalLink } from 'components/common/links/InternalLink';
import { FormTextInput } from 'components/forms/inputs/FormTextInput';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import { Column, Section } from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { registerLink } from 'constants/routes';
import { useStore } from 'effector-react';
import { useHistory } from 'react-router';
import { MediaSizes } from 'constants/styles/sizes';
import { useMediaQuery } from 'react-responsive';
import React from 'react';
import { inputEmailModal } from 'stores/initialize/initialize.modal.store';
import { userEffects } from 'stores/user';
import { Title } from './styles';
import { useFormSchema } from 'hooks/form';
import { contentPadding, contentWrapperProps, yupSchema } from './constants';
import { Form } from 'components/forms/styles';

const { signUpWithSocialMedia } = userEffects;

const { submitButton, title, cancelLink } = languages.modals.inputEmailModal;
const { personalForm } = languages.pages.personal;
const { closeModal } = inputEmailModal;

export const InputEmailModal = () => {
  const {
    handleSubmit,
    formState: { isValid },
    watch,
    control,
  } = useFormSchema(yupSchema);
  const history = useHistory();

  const emailValue = watch('email');

  const [visible, { socialId, firstName, lastName, provider }] = useStore(
    inputEmailModal.modal,
  );
  const isMobileBig = useMediaQuery(MediaSizes.MobileBig);

  const loading = useStore(signUpWithSocialMedia.pending);
  const modalContentPadding = isMobileBig ? '0px' : contentPadding;
  const marginTop = isMobileBig ? '20px' : '45px';

  const onSubmit = async () => {
    closeModal();

    const user = await signUpWithSocialMedia({
      email: emailValue,
      socialId,
      firstName,
      lastName,
      provider,
    });

    history.push(
      `/auth/social?refreshToken=${user.refreshToken}&accessToken=${user.accessToken}`,
    );
  };

  return (
    <ModalWrapper
      contentPadding={modalContentPadding}
      visible={visible}
      {...contentWrapperProps}
    >
      <Column alignCenter width="100%">
        <Title>{title}</Title>
        <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
          <Section marginBottom="40px" marginTop={marginTop}>
            <FormTextInput
              autoComplete="off"
              control={control}
              defaultValue={emailValue}
              id="email"
              name="email"
              {...personalForm['email']}
            />
          </Section>
          <Section alignCenter justifyCenter marginBottom="15px">
            <Button disabled={!isValid} isLoading={loading} type="submit">
              {submitButton}
            </Button>
          </Section>
        </Form>
        <InternalLink
          to={registerLink}
          onClick={() => {
            closeModal();
          }}
        >
          {cancelLink}
        </InternalLink>
      </Column>
    </ModalWrapper>
  );
};
