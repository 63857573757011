export const isoLanguageCodeRegExp = /^((([A-Za-z]+)-*)*)$/i;
export const mongoDbObjectIdRegExp = /^[0-9a-fA-F]{24}$/;

export const commaInserterRegExp = new RegExp(/\d{1,3}(?=(\d{3})*$)/g);
export const spaceInserterRegExp = new RegExp(/\d{1,4}(?=(\d{4})*$)/g);
export const slashInserterRegExp = new RegExp(/\d{1,2}(?=(\d{2})*$)/g);
export const removeRightSlashRegExp = new RegExp(/[/]+$/g);

export const oneCapitalCharRequiredRegExp = new RegExp(/[A-Z]/);
export const atLeastOneNumberRequiredRegExp = new RegExp(/[0-9]/);
export const noLettersAndSomeSpecialCharactersRegExp = /^[^A-Za-z*!$%&*@`~]+$/;

export const onlySimpleCharactersAllowedRegExp = new RegExp(/^[0-9A-Za-z ]+$/);
export const onlySymbolAndNumbersRegExp = new RegExp(/^[0-9A-Za-z]+$/);
export const onlyNumbersOrEmptyFieldRegExp = new RegExp(/^[0-9]+|\s*$/);

export const phoneRegExp =
  // eslint-disable-next-line no-useless-escape
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
