import { InsuranceStepForm } from 'components/forms/OrdersFroms/InsuranceStepForm';
import { StepInterface } from 'components/grid/Wizards/OrderWizard/types';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import {
  mobileStepContentWrapperPadding,
  stepContentWrapperPadding,
  tabletStepContentWrapperPadding,
} from 'pages/Shipments/constants';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { insurancesStores } from 'stores/insurances';
import { ordersStores } from 'stores/orders';

interface InsuranceStepProps extends StepInterface {}

export const InsuranceStep = ({ onNext }: InsuranceStepProps) => {
  const { weight, productPrice } = useStore(ordersStores.orderFormData);
  const insurance = useStore(insurancesStores.relevantInsurance);
  const isMobileLarge = useMediaQuery(MediaSizes.MobileLarge);
  const isTabletSmall = useMediaQuery(MediaSizes.TabletSmall);

  const contentWrapperPadding = isMobileLarge
    ? mobileStepContentWrapperPadding
    : isTabletSmall
    ? tabletStepContentWrapperPadding
    : stepContentWrapperPadding;

  return (
    <ContentWrapper padding={contentWrapperPadding} width="100%">
      <InsuranceStepForm
        insurance={insurance}
        productPrice={productPrice}
        weight={weight}
        onNext={onNext}
      />
    </ContentWrapper>
  );
};
