import { Loader } from 'components/dynamic/Loader';
import { AccountLayout } from 'components/layouts/AccountLayout';
import { AddressesLayout } from 'components/layouts/AddressesLayout';
import { AddressCard } from 'components/layouts/cards/AddressCard';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { useStore } from 'effector-react';
import React from 'react';
import { addressesEffects, addressesStores } from 'stores/addresses';

export const Addresses = () => {
  const { result, totalPages } = useStore(addressesStores.addresses);

  const loading = useStore(addressesEffects.loadItems.pending);

  return (
    <AccountLayout>
      {loading ? (
        <Section alignCenter justifyCenter height="600px">
          <Loader />
        </Section>
      ) : (
        <AddressesLayout totalPages={totalPages}>
          {result.map(
            ({
              firstName,
              lastName,
              country,
              postCode,
              city,
              id,
              street,
              buildingNumber,
              apartmentNumber,
              frameNumber,
              phone,
              region,
              middleName,
              title,
            }) => (
              <Section key={id} marginBottom="10px">
                <AddressCard
                  apartmentNumber={apartmentNumber}
                  area={region}
                  city={city}
                  country={country}
                  fullName={`${firstName} ${lastName} ${middleName}`}
                  house={buildingNumber}
                  houseBuilding={frameNumber}
                  id={id}
                  phone={phone}
                  postCode={postCode}
                  street={street}
                  title={title}
                />
              </Section>
            ),
          )}
        </AddressesLayout>
      )}
    </AccountLayout>
  );
};
