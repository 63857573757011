import { yupEmail } from 'constants/yupFields';
import * as Yup from 'yup';
import { ForgotPasswordInterface } from 'pages/Auth/ForgotPassword/types';
import { userEffects } from 'stores/user';

export const yupSchema = Yup.object().shape({
  email: yupEmail,
});

export const onSubmit = async (data: ForgotPasswordInterface) => {
  await userEffects.formRecoverPassword(data);
};
