import {
  ItemList,
  Steps,
  StepsList,
} from 'components/grid/Wizards/OrderWizard/types';
import { InitialStep } from 'pages/Shipments/Steps/InitialStep';
import { DimensionsStep } from 'pages/Shipments/Steps/DimensionsStep';
import { PackageStep } from 'pages/Shipments/Steps/PackageStep';
import { ConfirmationStep } from 'pages/Shipments/Steps/ConfirmationStep';
import { NewAddressStep } from 'pages/Shipments/Steps/NewAddressStep';
import { InsuranceStep } from 'pages/Shipments/Steps/InsuranceStep';

export const steps: StepsList = {
  [Steps.initialStep]: {
    activeStep: 0,
    index: 0,
    Component: InitialStep,
  },
  [Steps.dimensionsStep]: {
    activeStep: 1,
    index: 1,
    Component: DimensionsStep,
  },
  [Steps.insuranceStep]: {
    activeStep: 2,
    index: 2,
    Component: InsuranceStep,
  },
  [Steps.packageStep]: {
    activeStep: 3,
    index: 3,
    Component: PackageStep,
  },
  [Steps.confirmationStep]: {
    activeStep: 4,
    index: 4,
    Component: ConfirmationStep,
  },
  [Steps.newAddressStep]: {
    activeStep: 4,
    Component: NewAddressStep,
  },
};

export const getTotalSteps = () =>
  new Set(Object.values(steps).map(({ activeStep }) => activeStep)).size;

export const getStepByIndex = (index: number): ItemList =>
  steps[
    Object.keys(steps).find(
      (key) => (steps[key as Steps] as ItemList)?.index === index,
    ) as Steps
  ];
