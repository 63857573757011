import { Section } from 'components/wrappers/grid/FlexWrapper';
import { darkBlue, darkBlue2, grey4, raspberry } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import { lg, md, xl } from 'constants/styles/sizes';
import { MarginSizes } from 'pages/Support/constants';
import styled, { css } from 'styled-components';
import { Active } from 'types/global';
import { MarginBottom } from 'types/styles';

interface LastItem {
  lastItem?: boolean;
}

export const StyledSection = styled(Section)`
  justify-content: center;
  min-height: 100vh;
  background: ${grey4};
`;

export const Wrapper = styled.div`
  position: relative;
  max-width: 1200px;
  padding-top: 80px;
  min-height: 100vh;

  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${xl}) {
    margin-left: 100px;
    margin-right: 100px;
  }
  @media screen and (max-width: 1110px) {
    max-width: 910px;
    margin: 0;
  }

  @media screen and (max-width: ${lg}) {
    margin-left: 70px;
    margin-right: 70px;
  }

  @media screen and (max-width: 910px) {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media screen and (max-width: ${md}) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;

export const SidebarWrapper = styled.div`
  min-width: 318px;
  height: fit-content;

  position: sticky;
  left: 0;
  top: 80px;

  padding: 25px 20px;
  background: white;

  margin-right: 62px;
`;

export const SidebarItem = styled.a<Active>`
  display: block;
  width: 100%;
  padding: 9px 24px;
  cursor: pointer;

  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: ${({ active }) => (active ? 'white' : darkBlue2)};

  background: ${({ active }) => (active ? darkBlue : 'white')};
  border-radius: 6px;
`;

export const SecondaryTitle = styled.h3<MarginBottom>`
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;

  color: ${darkBlue};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? marginBottom : '20px'};
`;

interface ParagraphProps {
  mb?: 'small' | 'medium' | 'large';
  number?: number;
  numbered?: boolean;
  dotted?: boolean;
  ml?: boolean;
}

export const Paragraph = styled.p<ParagraphProps>`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${darkBlue2};

  margin-bottom: ${({ mb }) => (mb ? MarginSizes[mb] : '30px')};

  ${({ numbered, number }) =>
    numbered &&
    css`
      position: relative;
      margin-left: 41px;

      &::before {
        content: '${number}';

        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: white;

        position: absolute;
        left: -41px;
        top: 0;

        ${flexCenter}

        width: 29px;
        height: 29px;

        background: ${raspberry};
        border-radius: 7px;
      }
    `}

  ${({ dotted }) =>
    dotted &&
    css`
      position: relative;
      margin-left: 18px;

      &::before {
        content: '';

        position: absolute;
        left: -18px;
        top: 9px;

        width: 6px;
        height: 6px;

        background: ${raspberry};
        border-radius: 50%;
      }
    `}

    ${({ ml }) => ml && 'margin-left: 41px'}
`;

export const PackingCostBlock = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: white;

  background: ${raspberry};
  border-radius: 7px;
  width: fit-content;

  margin-bottom: 20px;
  padding: 2px 12px;
`;

interface AccentTextProps {
  isLink?: boolean;
}

export const AccentText = styled.span<AccentTextProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${darkBlue};

  ${({ isLink }) => isLink && `border-bottom: 2px solid ${darkBlue};`}
`;

export const AccentLink = styled.a`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${darkBlue};
  border-bottom: 2px solid ${darkBlue};
  cursor: pointer;
`;

export const RedSpan = styled.span`
  color: ${raspberry};
`;
