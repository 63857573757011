import { ContentWrapperInterface } from '../ModalWrapper';

export const contentWrapperProps: ContentWrapperInterface = {
  height: '800px',
  width: '750px',
  backgroundColor: 'white',
  borderRadius: '10px',
  padding: '20px',
};

export const contentPadding = '20px 30px';
