import { createEffect, createStore } from 'effector';
import { API } from 'api';
import { defaultGetAllResponse } from 'constants/defaults/store';
import { getDataAndOpenErrorModal } from 'utils/store';

const getAllServices = createEffect({
  handler: async () => {
    try {
      return await API.services.getAllServices();
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const services = createStore<API.GetAllServicesResponseDto>(
  defaultGetAllResponse,
).on(getAllServices.doneData, (_, newState) => newState);

export const servicesEffects = { getAllServices };
export const servicesEvents = {};
export const servicesStores = { services };
