import React, { useState } from 'react';
import { MarginWrapper } from '../MarginWrapper';
import {
  ContentItemWrapper,
  ContentMobileWrapper,
  Title,
  TitleWrapper,
} from './styles';

interface ParagraphWrapperProps {
  children: React.ReactNode;
  title: string;
  id: string;
  lastItem?: boolean;
  titleFontSize?: string;
}

export const ParagraphWrapper = ({
  children,
  title,
  id,
  lastItem,
  titleFontSize,
}: ParagraphWrapperProps) => {
  const [active, setActive] = useState(false);

  return (
    <ContentItemWrapper>
      <TitleWrapper
        active={active}
        lastItem={lastItem}
        onClick={() => {
          setActive(!active);
        }}
      >
        <MarginWrapper marginRight="15px">
          <Title fontSize={titleFontSize} id={id}>
            {title}
          </Title>
        </MarginWrapper>
      </TitleWrapper>
      <ContentMobileWrapper active={active} lastItem={lastItem}>
        {children}
      </ContentMobileWrapper>
    </ContentItemWrapper>
  );
};
