import styled, { css } from 'styled-components';
import { Active } from 'types/global';
import { paleBlue, white } from 'constants/styles/colors';
import { Disabled } from 'types/form';
import { flexCenter } from 'constants/styles/mixins';

export const badgeStyleMixin = (
  backgroundColor: string,
  textColor: string,
) => css`
  background-color: ${backgroundColor};
  color: ${textColor};
`;

interface BadgeProps extends Active, Disabled {}

export const Badge = styled.div<BadgeProps>`
  border-radius: 6px;
  min-height: 28px;
  min-width: 54px;
  padding: 6px 10px;
  white-space: nowrap;
  ${flexCenter}

  ${({ active, disabled }) =>
    /* //TODO update styles for disabled if necessary */
    disabled
      ? badgeStyleMixin('transparent', 'rgba(144, 168, 228, 0.2)')
      : active
      ? badgeStyleMixin(paleBlue, white)
      : badgeStyleMixin('rgba(144, 168, 228, 0.5)', 'rgba(255, 255, 255, 0.6)')}
`;
