import {
  borderLineWidth,
  hoveredColor,
  lastItemBorderHeight,
  lastItemBorderRadius,
  selectorHeight,
} from 'components/common/inputs/Select/constants';
import { Span } from 'components/common/typography/Span';
import { OriginContentWrapper } from 'components/wrappers/ContentWrapper';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import { white } from 'constants/styles/colors';
import styled, { css } from 'styled-components';
import { Active } from 'types/global';
import { PaddingLeft, TextProperties, Visibility } from 'types/styles';
import { RelativeWrapper } from 'components/wrappers/grid/RelativeWrapper';
import { Disabled } from 'types/form';
import { placeholderInputColor } from 'components/common/inputs/Input/constants';

export interface SelectWrapperProps extends Visibility, Disabled {
  border?: string;
}

export const SelectWrapper = styled(OriginContentWrapper)<SelectWrapperProps>`
  position: relative;
  ${({ border }) => border && `border: ${border}`};
  height: ${({ height }) => height || selectorHeight};
  background-color: white;
  padding: ${({ paddingBottom, paddingRight, paddingLeft, paddingTop }) =>
    `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`};
  min-width: 140px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ visible }) =>
    visible &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `};
`;

export const ItemsAbsoluteWrapper = styled(AbsoluteWrapper)<Visibility>`
  width: 100%;
  left: 0;
  background-color: ${white};
  max-height: calc(${selectorHeight} * 5);
  border-radius: 0 0 ${lastItemBorderRadius} ${lastItemBorderRadius};
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.24);
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 200;
  opacity: 0;
  transform: scaleY(0);
  transition: opacity 0.3s, transform 0.3s;
  transform-origin: 100% 0;

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      transform: scaleY(1);
    `};
`;

export const InformationBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #90a8e4;
  border-radius: 6px;
  width: 60px;
  height: 28px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;

export const LastBorderRadiusBlock = styled.div`
  width: 100%;
  height: ${lastItemBorderHeight};
  border-radius: 0 0 ${lastItemBorderRadius} ${lastItemBorderRadius};
`;

interface ItemWrapperProps extends PaddingLeft, Active, TextProperties {}

export const TitleSpan = styled(Span)`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 13px;
  color: black;
  opacity: 0.4;
`;

export const ItemSpan = styled.span<ItemWrapperProps>`
  font-style: ${({ fontStyle }) => fontStyle || 'normal'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  font-size: 16px;
  color: black;
  line-height: 16px;
`;

export const PlaceholderSpan = styled(ItemSpan)<ItemWrapperProps>`
  color: ${placeholderInputColor};
`;

export const BorderedItem = styled.div`
  height: 1px;
  width: ${borderLineWidth};
  background-color: #f1f3f8;
`;

export const ItemRelativeWrapper = styled(RelativeWrapper)`
  :last-child {
    border-bottom: 0;
    border-radius: 0 0 ${lastItemBorderRadius} ${lastItemBorderRadius};
  }

  :hover {
    ${BorderedItem} {
      height: 0;
    }
  }
`;

export const ItemWrapper = styled.div<ItemWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: ${selectorHeight};
  width: 100%;
  padding: 0 12px;
  z-index: 100;

  ${InformationBlock} {
    background: rgba(144, 168, 228, 0.4);
  }

  :hover {
    background-color: ${hoveredColor};
    cursor: pointer;
    ${ItemSpan} {
      color: black;
    }
    ${InformationBlock} {
      background: #90a8e4;
    }
  }
`;
