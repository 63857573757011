import axios from 'api/axios';
import { CancelToken } from 'axios';

export const createOrder = (data: API.CreateOrderDto) =>
  axios<API.CreateOrderResponseDto>({
    url: '/orders',
    data,
  });

export const getMyOrders = (
  { limit, page, dateFrom, dateTo, orderId, status }: API.GetMyOrdersDto,
  cancelToken?: CancelToken,
) =>
  axios<API.MyOrdersResponseDto>({
    url: `/orders/my-orders?limit=${limit}&page=${page}${
      orderId ? `&orderId=${orderId}` : ''
    }${status ? `&status=${status}` : ''}${
      dateFrom ? `&dateFrom=${dateFrom}` : ''
    }${dateTo ? `&dateTo=${dateTo}` : ''}`,
    cancelToken,
    method: 'GET',
  });

export const deleteOrderById = ({ id }: API.DeleteOrderDto) =>
  axios<API.DeleteOrderResponseDto>({
    url: `/orders/${id}`,
    method: 'DELETE',
  });
