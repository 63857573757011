import { createEffect, createStore } from 'effector';
import { API } from 'api';
import { defaultGetAllResponse } from 'constants/defaults/store';
import { getDataAndOpenErrorModal } from 'utils/store';

const getAllCountries = createEffect({
  handler: async () => {
    try {
      return await API.countries.getAllCountries();
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const countries = createStore<API.GetAllCountriesResponseDto>(
  defaultGetAllResponse,
).on(getAllCountries.doneData, (_, newState) => newState);

export const countriesEffects = { getAllCountries };
export const countriesEvents = {};
export const countriesStores = { countries };
