import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { inputModal } from 'stores/initialize/initialize.modal.store';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import {
  contentPadding,
  contentWrapperProps,
} from 'components/modals/InputModal/constants';

export const InputModal: FC = ({ children }) => {
  const { visible } = useStore(inputModal.modal);

  const onCloseClick = () => {
    inputModal.closeModal();
  };

  return (
    <ModalWrapper
      contentPadding={contentPadding}
      visible={visible}
      onCloseClick={onCloseClick}
      {...contentWrapperProps}
    >
      {children}
    </ModalWrapper>
  );
};
