import styled, { css } from 'styled-components';
import { ButtonProps } from 'components/common/buttons/Button/index';
import {
  darkBlue,
  raspberry,
  white,
  paleBlue,
  darkRaspberry,
  whiteHover,
} from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';

export const buttonStyleMixin = (
  color?: string,
  borderColor?: string,
  backgroundColor?: string,
) => css`
  color: ${color || white};
  border: ${borderColor ? `2px solid ${borderColor}` : 'none'};
  background-color: ${backgroundColor || raspberry};
`;

export const StyledButton = styled.button<ButtonProps>`
  width: ${({ width }) => width || '240px'};
  ${({ padding }) => padding && `padding: ${padding}`};
  height: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${white};
  transition: 0.3s ease-in-out;
  ${flexCenter};

  border-radius: ${({ buttonType }) => {
    switch (buttonType) {
      case 'header':
      case 'basic':
      case 'accent':
      case 'card':
      case 'supplementary':
        return '40px';
      case 'body':
      case 'form':
        return '10px';
    }
  }};

  background-color: ${({ buttonType }) => {
    switch (buttonType) {
      case 'header':
      case 'body':
        return darkBlue;
      case 'card':
        return paleBlue;
      case 'basic':
      case 'form':
        return raspberry;
      case 'accent':
      case 'supplementary':
        return 'transparent';
    }
  }};

  ${({ isLoading, buttonType }) =>
    isLoading
      ? 'cursor: not-allowed; pointer-events:none'
      : buttonType === 'card'
      ? css`
          :hover {
            ${buttonStyleMixin(darkBlue, '', white)};
          }
          :active {
            ${buttonStyleMixin()};
          }
        `
      : buttonType === 'accent'
      ? css`
          ${buttonStyleMixin(raspberry, raspberry, 'transparent')};

          :hover {
            ${buttonStyleMixin('', raspberry)};
          }

          :active {
            ${buttonStyleMixin('', darkRaspberry, darkRaspberry)};
          }
        `
      : buttonType === 'supplementary'
      ? css`
          ${buttonStyleMixin('', white, 'transparent')};

          :hover {
            ${buttonStyleMixin(darkBlue, white, white)};
          }

          :active {
            ${buttonStyleMixin(whiteHover, '', whiteHover)};
          }
        `
      : css`
          :hover {
            background-color: ${paleBlue};
          }
          :active {
            background-color: ${raspberry};
          }
        `}

  :disabled {
    background-color: #d0d3d9;
    color: ${white};
    cursor: not-allowed;
  }
`;
