import React from 'react';
import { Title } from 'types/data';
import {
  SimpleDatePickerProps,
  SimpleDatePicker,
} from 'components/common/inputs/DateInput';
import { Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { Control, FieldValues, useController } from 'react-hook-form';

import { InputLabel } from '../FormTextInput/styles';

interface DateInputProps extends Pick<Title, 'title'>, SimpleDatePickerProps {}

export const DateInput = ({ title, ...datePicker }: DateInputProps) => (
  <Section>
    <Row marginBottom="10px">
      <InputLabel>{title}</InputLabel>
    </Row>
    <SimpleDatePicker {...datePicker} />
  </Section>
);

interface FormDateInputProps extends DateInputProps {
  control: Control<FieldValues>;
  defaultValue?: string;
  name: string;
}

export const FormDateInput = ({
  defaultValue,
  name,
  control,
  ...inputProps
}: FormDateInputProps) => {
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
    defaultValue,
  });

  return (
    <DateInput
      value={value ? new Date(value) : undefined}
      {...inputProps}
      onChange={onChange}
    />
  );
};
