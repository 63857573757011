import { darkBlue } from 'constants/styles/colors';
import { xs } from 'constants/styles/sizes';
import styled from 'styled-components';

export const ListContentWrapper = styled.div`
  h2 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${darkBlue};

    @media screen and (max-width: ${xs}) {
      width: 90%;
    }
  }

  ol {
    width: 100%;
    margin: 0;
    color: ${darkBlue};
    padding-inline-start: 20px;
  }

  li {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${darkBlue};
  }
`;
