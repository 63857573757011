import { OriginContentWrapper } from 'components/wrappers/ContentWrapper';
import { ParagraphWrapper } from 'components/wrappers/ParagraphWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { useFindTopYPositionItem } from 'hooks/findTopYPositionItem';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { sidebarItems } from './constants';
import { SidebarItem, SidebarWrapper, StyledSection, Wrapper } from './styles';

export const Support = () => {
  const history = useHistory();

  const [activeRoute, setActiveRoute] = useState(sidebarItems[0].id);

  const titleIds = sidebarItems.map((it) => it.id);
  const activeTopIndex = useFindTopYPositionItem(titleIds);

  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const isTablet = useMediaQuery(MediaSizes.Tablet);

  useEffect(() => {
    if (isTablet) return;
    history.push(`#${sidebarItems[activeTopIndex].id}`);
    setActiveRoute(sidebarItems[activeTopIndex].id);
  }, [activeTopIndex, history, isTablet]);

  return (
    <StyledSection>
      <Wrapper>
        {!isTablet && (
          <SidebarWrapper>
            {sidebarItems.map(({ title, id }) => (
              <SidebarItem
                key={title}
                active={activeRoute === id}
                href={`#${id}`}
                onClick={() => {
                  setActiveRoute(id);
                }}
              >
                {title}
              </SidebarItem>
            ))}
          </SidebarWrapper>
        )}

        <OriginContentWrapper
          backgroundColor="white"
          borderRadius="10px"
          height="fit-content"
          maxWidth="889px"
          padding={isMobile ? '12px' : '42px'}
          width="100%"
        >
          {sidebarItems.map(({ title, id, content, desktopTitle }, i) => (
            <ParagraphWrapper
              key={id}
              id={id}
              lastItem={sidebarItems.length - 1 === i}
              title={!isTablet && desktopTitle ? desktopTitle : title}
            >
              {content}
            </ParagraphWrapper>
          ))}
        </OriginContentWrapper>
      </Wrapper>
    </StyledSection>
  );
};
