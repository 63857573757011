import {
  atLeastOneNumberRequiredRegExp,
  oneCapitalCharRequiredRegExp,
  onlyNumbersOrEmptyFieldRegExp,
  onlySimpleCharactersAllowedRegExp,
  onlySymbolAndNumbersRegExp,
} from 'constants/regularExpressions';
import urlRegex from 'url-regex';
import { Rule } from 'effector-forms';
import * as Yup from 'yup';
import { languages } from './languages';
import {
  // isValidNumberForRegion,
  // parseNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
//import { CountryCode } from 'libphonenumber-js/types';
import {
  maxProductHeightInCentimeters,
  maxProductLengthInCentimeters,
  maxProductVolumeInMeters,
  maxProductWidthInCentimeters,
} from './defaults/values';

// * any because type can be any
export function createRule<V, T = any>({
  schema,
  name,
}: {
  schema: Yup.SchemaOf<T>;
  name: string;
}): Rule<V> {
  return {
    name,
    validator: (v: V) => {
      try {
        schema.validateSync(v);
        return {
          isValid: true,
          value: v,
        };
      } catch (err) {
        return {
          isValid: false,
          value: v,
          errorText: err.message,
        };
      }
    },
  };
}

export const passwordMinimum = 8;
export const firstNameAndLastNameMinimum = 2;

const {
  requiredField: requiredFieldMessage,
  firstNameLength: firstNameLengthMessage,
  lastNameLength: lastNameLengthMessage,
  atLeastOneNumberRequired: atLeastOneNumberRequiredMessage,
  confirmCondition: confirmConditionMessage,
  maximumValue: maximumValueMessage,
  wholeNumberValue: wholeNumberValueMessage,
  incorrectId: incorrectIdMessage,
  invalidEmail: invalidEmailMessage,
  matchPassword: matchPasswordMessage,
  oneCapitalCharRequired: oneCapitalCharRequiredMessage,
  onlySimpleCharactersAllowed: onlySimpleCharactersAllowedMessage,
  passwordLength: passwordLengthMessage,
  requiredSet: requiredSetMessage,
  urlMatch: urlMatchMessage,
  incorrectPhoneNumber: incorrectPhoneNumberMessage,
  incorrectPostCode: incorrectPostCodeMessage,
  shouldBePositive: shouldBePositiveMessage,
  shouldBeMoreThanZero: shouldBeMoreThanZeroMessage,
} = languages.validation.messages;

export const yupDefault = Yup.string().required(requiredFieldMessage);
export const yupDefaultArray = Yup.array()
  .of(yupDefault)
  .required(requiredSetMessage);
export const yupDefaultConfirmed = Yup.boolean().required(
  confirmConditionMessage,
); // .oneOf([true], 'You must confirm the conditions')

export const yupDefaultBoolean = Yup.boolean()
  .required()
  .oneOf([true], confirmConditionMessage);

// const supportedRegions: CountryCode[] = [
//   'RU',
//   'UA',
//   'KZ',
//   'BY',
//   'MD',
//   'KG',
//   'TJ',
//   'TM',
//   'CN',
// ];

// const isValidNumber = (value: string) =>
//   supportedRegions
//     .map((region) => isValidNumberForRegion(value, region))
//     .some((value) => value);

// Adding new method for Yup validation: check phone number
Yup.addMethod(Yup.string, 'phoneNumber', function (errorMessage) {
  return this.test(`test-phone-type`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      //isValidNumber(value || '') || createError({ path, message: errorMessage })
      isValidPhoneNumber(value || '') ||
      createError({ path, message: errorMessage })
    );
  });
});

export const yupPhoneNumber = Yup.string()
  // @ts-ignore
  .phoneNumber(incorrectPhoneNumberMessage);

export const yupRequiredPhoneNumber = yupPhoneNumber.required();

// Adding new method for Yup validation: check phone number if field was filled
Yup.addMethod(Yup.string, 'optionalPhoneNumber', function (errorMessage) {
  return this.test(`test-phone-type`, errorMessage, function (value) {
    const { path, createError } = this;

    if (!value) return true;

    return (
      isValidPhoneNumber(value) || createError({ path, message: errorMessage })
    );
  });
});

export const yupOptionalPhoneNumber = Yup.string()
  // @ts-ignore
  .optionalPhoneNumber(incorrectPhoneNumberMessage);

export const yupCompanyName = yupDefault.matches(
  onlySimpleCharactersAllowedRegExp,
  onlySimpleCharactersAllowedMessage,
);
export const yupId = yupDefault
  .min(4)
  .matches(onlySymbolAndNumbersRegExp, incorrectIdMessage);

export const yupFirstName = Yup.string()
  .required(requiredFieldMessage)
  .min(
    firstNameAndLastNameMinimum,
    firstNameLengthMessage(firstNameAndLastNameMinimum),
  );

export const yupLastName = Yup.string()
  .required(requiredFieldMessage)
  .min(
    firstNameAndLastNameMinimum,
    lastNameLengthMessage(firstNameAndLastNameMinimum),
  );

export const yupEmail = Yup.string()
  .email(invalidEmailMessage)
  .required(requiredFieldMessage);
export const yupEmailNoHint = yupDefault;
export const yupPassword = Yup.string()
  .required(requiredFieldMessage)
  .min(passwordMinimum, passwordLengthMessage(passwordMinimum))
  .matches(oneCapitalCharRequiredRegExp, oneCapitalCharRequiredMessage)
  .matches(atLeastOneNumberRequiredRegExp, atLeastOneNumberRequiredMessage);
export const yupRepeatPassword = (passwordName = 'password') =>
  Yup.string()
    .oneOf([Yup.ref(passwordName)], matchPasswordMessage)
    .required(requiredFieldMessage);
export const yupPasswordNoHint = yupDefault;
export const yupSecurityCode = yupDefault;
export const yupUrl = Yup.string().matches(urlRegex(), urlMatchMessage);
export const yupPostCode = Yup.string().matches(
  onlyNumbersOrEmptyFieldRegExp,
  incorrectPostCodeMessage,
);

export const yupDefaultNumber = Yup.number()
  .transform((parsedValue, originalValue) =>
    originalValue === '' ? null : parsedValue,
  )
  .typeError(requiredFieldMessage);
export const yupPositiveNumber = yupDefaultNumber.positive(
  shouldBePositiveMessage,
);
export const yupProductPrice = yupPositiveNumber;
export const yupDefaultNullableNumber = yupDefaultNumber
  .nullable()
  .positive(shouldBePositiveMessage);

export const yupProductHeight = yupDefaultNullableNumber
  .integer(wholeNumberValueMessage)
  .max(
    maxProductHeightInCentimeters,
    maximumValueMessage(maxProductHeightInCentimeters),
  );
export const yupProductWidth = yupDefaultNullableNumber
  .integer(wholeNumberValueMessage)
  .max(
    maxProductWidthInCentimeters,
    maximumValueMessage(maxProductWidthInCentimeters),
  );
export const yupProductLength = yupDefaultNullableNumber
  .integer(wholeNumberValueMessage)
  .max(
    maxProductLengthInCentimeters,
    maximumValueMessage(maxProductLengthInCentimeters),
  );

export const yupMoreThanZeroVolume = yupDefaultNumber.positive(
  shouldBeMoreThanZeroMessage,
);

export const yupProductVolume = yupMoreThanZeroVolume.max(
  maxProductVolumeInMeters,
  maximumValueMessage(maxProductVolumeInMeters),
);
