import {
  DeleteAddressButton,
  EditAddressButton,
} from 'components/common/buttons/ImageButtons';
import {
  MobileTable,
  MobileTableData,
} from 'components/common/tables/MobileTable';
import { Table } from 'components/common/tables/Table';
import { TitleText } from 'components/common/typography/TitleText';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import { Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { languages } from 'constants/languages';
import { toAddressLinkClick } from 'constants/routes';
import { MediaSizes } from 'constants/styles/sizes';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { addressesEffects } from 'stores/addresses';
import { modalEvents } from 'stores/modals/asyncModal';
import { DataTable } from 'types/data';
import { AddressWrapper, CardWrapper, FullNameText } from './styles';

const { formDeleteAddressById } = addressesEffects;

const { cityTableColumns, houseTableColumns, phoneTableColumns } =
  languages.layouts.cards.addressCard;

const { titleAddress, okText, closeText } = languages.modals.confirmationModal;

interface AddressCardProps {
  fullName?: string;
  country?: string;
  area?: string;
  city?: string;
  street?: string;
  postCode?: string;
  house?: string;
  houseBuilding?: string;
  apartmentNumber?: string;
  title?: string;
  phone?: string;
  id: string;
}

export const AddressCard = ({
  fullName,
  apartmentNumber,
  area,
  city,
  country,
  house,
  houseBuilding,
  postCode,
  street,
  title,
  phone,
  id,
}: AddressCardProps) => {
  const isMobile = useMediaQuery(MediaSizes.Mobile);

  const mobileTableData: MobileTableData[] | undefined = [
    {
      title: cityTableColumns[0],
      value: country,
    },
    {
      title: cityTableColumns[1],
      value: area,
    },
    {
      title: cityTableColumns[2],
      value: city,
    },
    {
      title: cityTableColumns[3],
      value: street,
    },
    {
      title: houseTableColumns[0],
      value: postCode || '-',
    },
    {
      title: houseTableColumns[1],
      value: house,
    },
    {
      title: houseTableColumns[2],
      value: houseBuilding || '-',
    },
    {
      title: houseTableColumns[3],
      value: apartmentNumber || '-',
    },
    {
      title: phoneTableColumns[0],
      value: phone,
    },
  ];

  const cityTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={country} alignCenter noWrap>
          {country}
        </Row>,
        <Row key={area}>{area}</Row>,
        <Row key={city}>{city}</Row>,
        <Row key={street}>{street}</Row>,
      ],
      alignment: [...new Array(4).fill('start')],
    },
  ];

  const houseTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={postCode} alignCenter noWrap>
          {postCode || '-'}
        </Row>,
        <Row key={house}>{house}</Row>,
        <Row key={houseBuilding}>{houseBuilding || '-'}</Row>,
        <Row key={apartmentNumber}>{apartmentNumber || '-'}</Row>,
      ],
      alignment: [...new Array(4).fill('start')],
    },
  ];

  const phoneTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={phone} alignCenter noWrap>
          {phone}
        </Row>,
      ],
      alignment: [...new Array(1).fill('start')],
    },
  ];

  const onEditClick = () => toAddressLinkClick(id);

  const onDeleteAddress = () => {
    formDeleteAddressById({ id });
  };

  const onDeleteClick = () => {
    modalEvents.openAsyncModal({
      title: titleAddress,
      closeText,
      okText,
      onOk: onDeleteAddress,
    });
  };

  return !isMobile ? (
    <CardWrapper>
      <AbsoluteWrapper right="20px" top="23px">
        <Row alignCenter>
          <MarginWrapper marginRight="10px">
            <EditAddressButton onClick={onEditClick} />
          </MarginWrapper>
          <DeleteAddressButton onClick={onDeleteClick} />
        </Row>
      </AbsoluteWrapper>

      <Section justifyBetween marginBottom="20px">
        <TitleText>{title}</TitleText>
      </Section>
      <AddressWrapper>
        <Section justifyCenter marginBottom="30px" marginTop="15px">
          <FullNameText>{fullName}</FullNameText>
        </Section>
        <Section>
          <Table columns={cityTableColumns} data={cityTableData} />
        </Section>
        <Section>
          <Table columns={houseTableColumns} data={houseTableData} />
        </Section>
        <Section>
          <Table columns={phoneTableColumns} data={phoneTableData} />
        </Section>
      </AddressWrapper>
    </CardWrapper>
  ) : (
    <CardWrapper>
      <AbsoluteWrapper right="20px" top="23px">
        <Row alignCenter>
          <MarginWrapper marginRight="10px">
            <EditAddressButton onClick={onEditClick} />
          </MarginWrapper>
          <DeleteAddressButton onClick={onDeleteClick} />
        </Row>
      </AbsoluteWrapper>

      <Section justifyBetween marginBottom="20px">
        <TitleText>{title}</TitleText>
      </Section>
      <AddressWrapper>
        <Section justifyCenter marginBottom="30px" marginTop="15px">
          <FullNameText>{fullName}</FullNameText>
        </Section>
        <MobileTable data={mobileTableData} />
      </AddressWrapper>
    </CardWrapper>
  );
};
