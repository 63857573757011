import axios from 'api/axios';

export const getMethods = ({
  country,
  subcategory,
}: API.GetMethodsRequestDto) =>
  axios<API.GetAllMethodsResponseDto>({
    url: `/methods?country=${country}&subcategory=${subcategory}`,
    method: 'GET',
  });
