import { initializeIsFirstStore } from 'stores/initialize/initialize.isFirst.store';
import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'api';
import {
  defaultOrders,
  defaultOrdersPageLimit,
  defaultQueryOrders,
  emptyOrderForm,
} from 'constants/defaults/store';
import axios, { CancelTokenSource } from 'axios';
import {
  informationModalStore,
  invoiceModalStore,
} from 'stores/initialize/initialize.modal.store';
import { toOrdersLinkClick } from 'constants/routes';
import { languages } from 'constants/languages';
import { modalEvents } from './modals/asyncModal';
import { deleteOrderById } from 'api/orders';
import { OrderStatuses } from 'types/types';
import { getDataAndOpenErrorModal } from 'utils/store';

const { successOrderDelete } = languages.modals.informationModal;
const { updateAsyncModalLoading, closeAsyncModal } = modalEvents;

let cancelToken: CancelTokenSource | undefined;

const loadItems = createEffect({
  handler: async (data: API.GetMyOrdersDto) => {
    try {
      cancelToken && cancelToken.cancel();
      cancelToken = axios.CancelToken.source();

      return await API.orders.getMyOrders(data, cancelToken.token);
    } catch (error) {
      getDataAndOpenErrorModal(error);
      return defaultOrders;
    }
  },
});

const { isFirst, setIsFirstToFalse, setIsFirstToTrue } =
  initializeIsFirstStore();

const updateValues = createEvent<Partial<Omit<API.GetMyOrdersDto, 'limit'>>>();
const invokeGetItems = createEvent();
const setDefaultValues = createEvent();

const values = createStore<API.GetMyOrdersDto>(defaultQueryOrders)
  .on(updateValues, (state, values) => ({
    ...state,
    limit: defaultOrdersPageLimit,
    ...values,
  }))
  .on(invokeGetItems, (state) => state)
  .on(setDefaultValues, () => defaultQueryOrders);

forward({
  from: [values],
  to: [loadItems],
});

values.watch(invokeGetItems, (state) => loadItems(state));

const orders = createStore<API.MyOrdersResponseDto>(defaultOrders).on(
  loadItems.doneData,
  (_, state) => state,
);

const setOrderFormValue = createEvent<Partial<API.CreateOrderDto>>();
const clearOrderFormData = createEvent();

const orderFormData = createStore<Partial<API.CreateOrderDto>>({})
  .on(setOrderFormValue, (values, newValues) => ({ ...values, ...newValues }))
  .on(clearOrderFormData, () => emptyOrderForm);

// TODO  to remove after logic of sending order data to back end will be done
//orderFormData.watch((state) => console.log('order state', state));

const { successInvoiceConfirmed } = languages.modals.invoiceModal;

const createOrder = createEffect({
  handler: async (data: API.CreateOrderDto) => {
    try {
      const { status } = await API.orders.createOrder(data);

      if (status === OrderStatuses.Issued) {
        invokeGetItems();
        invoiceModalStore.closeModal();
        clearOrderFormData();

        informationModalStore.openModal({
          message: successInvoiceConfirmed,
          actionAfterCloseClick: toOrdersLinkClick,
        });
      }
    } catch (error) {
      invoiceModalStore.closeModal();
      getDataAndOpenErrorModal(error);
    }
  },
});

const formDeleteOrderById = createEffect({
  handler: async (data: API.DeleteOrderDto) => {
    try {
      updateAsyncModalLoading();
      await deleteOrderById(data);
      closeAsyncModal();

      informationModalStore.openModal({
        message: successOrderDelete,
        actionAfterCloseClick: toOrdersLinkClick,
      });

      invokeGetItems();
    } catch (error) {
      modalEvents.closeAsyncModal();
      getDataAndOpenErrorModal(error);
    } finally {
      updateAsyncModalLoading();
    }
  },
});

export const ordersEffects = { loadItems, formDeleteOrderById, createOrder };
export const ordersEvents = {
  updateValues,
  invokeGetItems,
  setDefaultValues,
  setIsFirstToFalse,
  setIsFirstToTrue,
  setOrderFormValue,
  clearOrderFormData,
};
export const ordersStores = { values, isFirst, orders, orderFormData };
