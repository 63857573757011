import * as Yup from 'yup';
import { yupPasswordNoHint } from 'constants/yupFields';

export const yupSchema = Yup.object().shape({
  password: yupPasswordNoHint,
  newPassword: yupPasswordNoHint,
});

export const contentMarginBottom = '10px';
export const fieldsMarginBottom = '20px';
export const titleMarginBottom = '20px';
export const marginBetween = '10px';
