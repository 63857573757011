import {
  buttonBorderRadius,
  buttonInnerTextFontWeight,
  buttonPadding,
  disabledCardButtonBackgroundColor,
} from 'components/common/buttons/SimpleButton/constants';

import { darkBlue, paleBlue, raspberry, white } from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import { Disabled } from 'types/form';
import { MinSizes } from 'types/styles';

interface Props extends Disabled, Pick<MinSizes, 'minWidth'> {
  buttonType: 'primary' | 'secondary' | 'tertiary';
}

export const StyledButton = styled.button<Props>`
  ${disableDefaultButtonStyleMixin};
  min-width: ${({ minWidth }) => minWidth || '105px'};
  height: 48px;
  font-size: 14px;
  font-weight: ${buttonInnerTextFontWeight};
  border-radius: ${buttonBorderRadius};
  padding: ${buttonPadding};
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  ${({ buttonType }) =>
    buttonType === 'primary'
      ? css`
          color: ${white};
          background-color: ${raspberry};
        `
      : buttonType === 'tertiary'
      ? css`
          color: ${white};
          background-color: ${darkBlue};
        `
      : css`
          color: ${darkBlue};
          background-color: ${white};
        `}

  ${({ disabled }) =>
    disabled
      ? css`
          background: ${disabledCardButtonBackgroundColor};
          color: ${white};
          cursor: not-allowed;
        `
      : css`
          :hover {
            background-color: ${paleBlue};
            color: ${white};
          }
          :active {
            background-color: ${raspberry};
          }
        `};
`;
