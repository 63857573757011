import { Button } from 'components/common/buttons/Button';
import {
  contentPadding,
  contentWrapperProps,
  mobileContentWrapperProps,
  pairedItemWidth,
} from 'components/modals/InvoiceModal/constants';
import {
  InvoiceContentWrapper,
  InvoiceHeader,
  InvoiceSubHeader,
  InvoiceTitle,
  StyledSection,
  TitleText,
  ValueSpan,
} from 'components/modals/InvoiceModal/styles';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import { Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { addressesStores } from 'stores/addresses';
import { invoiceModalStore } from 'stores/initialize/initialize.modal.store';
import { insurancesStores } from 'stores/insurances';
import { ordersEffects } from 'stores/orders';
import { packagesStores } from 'stores/packages';
import { servicesStores } from 'stores/services';
import { tariffsStores } from 'stores/tariffs';

const { createOrder } = ordersEffects;

const {
  titleCargoPickUp,
  cargoPickUpAddress,
  cargoPickUpDateAndTime,
  cargoPickUpPhone,
  title,
  submitButton,
  titleAddress,
  recipient,
  phoneNumber,
  country,
  region,
  city,
  street,
  building,
  frame,
  flat,
  postcode,
  titleCost,
  tariff,
  insurance,
  extraServices,
  orderCurrency,
  titleAmount,
} = languages.modals.invoiceModal;

export const InvoiceModal = () => {
  const { cost: tariffCost } = useStore(tariffsStores.tariffCost);
  const { cost: insuranceCost } = useStore(insurancesStores.relevantInsurance);
  const [visible, orderData] = useStore(invoiceModalStore.modal);
  const loading = useStore(createOrder.pending);
  const isMobile = useMediaQuery(MediaSizes.Mobile);

  const addressValue = useStore(addressesStores.allMyAddresses).result?.find(
    ({ id }) => id === orderData.address,
  );

  const packageValue = useStore(packagesStores.packages).result?.find(
    ({ _id }) => _id === orderData.packageType,
  );
  const servicesValues = useStore(servicesStores.services).result?.filter(
    ({ _id }) => orderData.services?.some((id) => id === _id),
  );

  const onCloseClick = () => {
    invoiceModalStore.closeModal();
  };

  const onConfirm = () => {
    createOrder(orderData as API.CreateOrderDto);
  };

  return !isMobile ? (
    <ModalWrapper
      contentPadding={contentPadding}
      visible={visible}
      onCloseClick={onCloseClick}
      {...contentWrapperProps}
    >
      <InvoiceContentWrapper>
        <Section justifyCenter>
          <TitleText>{title}</TitleText>
        </Section>

        {!!orderData.pickUpOrder && (
          <>
            <InvoiceHeader>{titleCargoPickUp}</InvoiceHeader>

            <StyledSection justifyBetween>
              <InvoiceTitle>{cargoPickUpAddress}</InvoiceTitle>
              <ValueSpan>{orderData.pickUpOrder?.address}</ValueSpan>
            </StyledSection>

            <StyledSection justifyBetween>
              <InvoiceTitle>{cargoPickUpDateAndTime}</InvoiceTitle>
              <ValueSpan>
                {orderData.pickUpOrder?.date}&nbsp;&nbsp;
                {orderData.pickUpOrder?.time.from}-
                {orderData.pickUpOrder?.time.to}
              </ValueSpan>
            </StyledSection>

            <StyledSection justifyBetween>
              <InvoiceTitle>{cargoPickUpPhone}</InvoiceTitle>
              <ValueSpan>{orderData.pickUpOrder?.phone} </ValueSpan>
            </StyledSection>
          </>
        )}

        <InvoiceHeader>{titleAddress}</InvoiceHeader>

        <StyledSection justifyBetween>
          <InvoiceTitle>{recipient}</InvoiceTitle>
          <ValueSpan>
            {addressValue?.firstName}&nbsp;
            {addressValue?.middleName}&nbsp;
            {addressValue?.lastName}
          </ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween>
          <InvoiceTitle>{phoneNumber}</InvoiceTitle>
          <ValueSpan>{addressValue?.phone}</ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween>
          <InvoiceTitle>{country}</InvoiceTitle>
          <ValueSpan>{addressValue?.country}</ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween noWrap>
          <Row justifyBetween width={pairedItemWidth}>
            <InvoiceTitle>{region} </InvoiceTitle>
            <ValueSpan>{addressValue?.region}</ValueSpan>
          </Row>

          <Row justifyBetween width={pairedItemWidth}>
            <InvoiceTitle>{city}</InvoiceTitle>
            <ValueSpan>{addressValue?.city}</ValueSpan>
          </Row>
        </StyledSection>

        <StyledSection justifyBetween noWrap>
          <Row justifyBetween width={pairedItemWidth}>
            <InvoiceTitle>{street} </InvoiceTitle>
            <ValueSpan>{addressValue?.street}</ValueSpan>
          </Row>

          <Row justifyBetween width={pairedItemWidth}>
            <InvoiceTitle>{building} </InvoiceTitle>
            <ValueSpan>
              {addressValue?.buildingNumber}&nbsp;
              {!!addressValue?.frameNumber &&
                `${frame} ${addressValue?.frameNumber}`}
            </ValueSpan>
          </Row>
        </StyledSection>

        <StyledSection justifyBetween noWrap>
          <Row justifyBetween width={pairedItemWidth}>
            <InvoiceTitle>{flat}</InvoiceTitle>
            <ValueSpan>{addressValue?.apartmentNumber}</ValueSpan>
          </Row>

          <Row justifyBetween width={pairedItemWidth}>
            <InvoiceTitle>{postcode}</InvoiceTitle>
            <ValueSpan>{addressValue?.postCode}</ValueSpan>
          </Row>
        </StyledSection>

        <InvoiceHeader>{titleCost}</InvoiceHeader>

        <StyledSection justifyBetween noWrap>
          <Row justifyBetween width={pairedItemWidth}>
            <InvoiceTitle>{tariff}</InvoiceTitle>
            <ValueSpan>{tariffCost}$</ValueSpan>
          </Row>

          <Row justifyBetween width={pairedItemWidth}>
            <InvoiceTitle>{insurance}</InvoiceTitle>
            <ValueSpan>{insuranceCost}$</ValueSpan>
          </Row>
        </StyledSection>

        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{packageValue?.title}</InvoiceTitle>
          <ValueSpan>{packageValue?.price}$</ValueSpan>
        </StyledSection>

        <InvoiceSubHeader>{extraServices}</InvoiceSubHeader>

        {servicesValues.map(({ title, price }) => (
          <StyledSection key={title} justifyBetween noWrap>
            <InvoiceTitle>{title}</InvoiceTitle>
            <ValueSpan>{price}$</ValueSpan>
          </StyledSection>
        ))}

        <InvoiceHeader justifyBetween>
          {titleAmount} <ValueSpan>{orderData.totalPrice}$</ValueSpan>
        </InvoiceHeader>

        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{orderCurrency}</InvoiceTitle>
          <ValueSpan>{orderData.currency}</ValueSpan>
        </StyledSection>

        <MarginWrapper marginTop="10px">
          <Button isLoading={loading} onClick={onConfirm}>
            {submitButton}
          </Button>
        </MarginWrapper>
      </InvoiceContentWrapper>
    </ModalWrapper>
  ) : (
    <ModalWrapper
      contentPadding={contentPadding}
      visible={visible}
      onCloseClick={onCloseClick}
      {...mobileContentWrapperProps}
    >
      <InvoiceContentWrapper>
        <Section justifyCenter>
          <TitleText>{title}</TitleText>
        </Section>

        {!!orderData.pickUpOrder && (
          <>
            <InvoiceHeader>{titleCargoPickUp}</InvoiceHeader>

            <StyledSection justifyBetween>
              <InvoiceTitle>{cargoPickUpAddress}</InvoiceTitle>
              <ValueSpan>{orderData.pickUpOrder?.address}</ValueSpan>
            </StyledSection>

            <StyledSection justifyBetween>
              <InvoiceTitle>{cargoPickUpDateAndTime}</InvoiceTitle>
              <ValueSpan>
                {orderData.pickUpOrder?.date}&nbsp;&nbsp;
                {orderData.pickUpOrder?.time.from}-
                {orderData.pickUpOrder?.time.to}
              </ValueSpan>
            </StyledSection>

            <StyledSection justifyBetween>
              <InvoiceTitle>{cargoPickUpPhone}</InvoiceTitle>
              <ValueSpan>{orderData.pickUpOrder?.phone} </ValueSpan>
            </StyledSection>
          </>
        )}

        <InvoiceHeader>{titleAddress}</InvoiceHeader>

        <StyledSection justifyBetween>
          <InvoiceTitle>{recipient}</InvoiceTitle>
          <ValueSpan>
            {addressValue?.firstName}&nbsp;
            {addressValue?.middleName}&nbsp;
            {addressValue?.lastName}
          </ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween>
          <InvoiceTitle>{phoneNumber}</InvoiceTitle>
          <ValueSpan>{addressValue?.phone}</ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween>
          <InvoiceTitle>{country}</InvoiceTitle>
          <ValueSpan>{addressValue?.country}</ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{region} </InvoiceTitle>
          <ValueSpan>{addressValue?.region}</ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{city}</InvoiceTitle>
          <ValueSpan>{addressValue?.city}</ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{street} </InvoiceTitle>
          <ValueSpan>{addressValue?.street}</ValueSpan>
        </StyledSection>
        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{building} </InvoiceTitle>
          <ValueSpan>
            {addressValue?.buildingNumber}&nbsp;
            {!!addressValue?.frameNumber &&
              `${frame} ${addressValue?.frameNumber}`}
          </ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{flat}</InvoiceTitle>
          <ValueSpan>{addressValue?.apartmentNumber}</ValueSpan>
        </StyledSection>
        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{postcode}</InvoiceTitle>
          <ValueSpan>{addressValue?.postCode}</ValueSpan>
        </StyledSection>

        <InvoiceHeader>{titleCost}</InvoiceHeader>

        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{tariff}</InvoiceTitle>
          <ValueSpan>{tariffCost}$</ValueSpan>
        </StyledSection>
        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{insurance}</InvoiceTitle>
          <ValueSpan>{insuranceCost}$</ValueSpan>
        </StyledSection>

        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{packageValue?.title}</InvoiceTitle>
          <ValueSpan>{packageValue?.price}$</ValueSpan>
        </StyledSection>

        <InvoiceSubHeader>{extraServices}</InvoiceSubHeader>

        {servicesValues.map(({ title, price }) => (
          <StyledSection key={title} justifyBetween noWrap>
            <InvoiceTitle>{title}</InvoiceTitle>
            <ValueSpan>{price}$</ValueSpan>
          </StyledSection>
        ))}

        <InvoiceHeader justifyBetween>
          {titleAmount} <ValueSpan>{orderData.totalPrice}$</ValueSpan>
        </InvoiceHeader>

        <StyledSection justifyBetween noWrap>
          <InvoiceTitle>{orderCurrency}</InvoiceTitle>
          <ValueSpan>{orderData.currency}</ValueSpan>
        </StyledSection>

        <MarginWrapper marginTop="10px">
          <Button isLoading={loading} onClick={onConfirm}>
            {submitButton}
          </Button>
        </MarginWrapper>
      </InvoiceContentWrapper>
    </ModalWrapper>
  );
};
