import { yupDefault, yupEmail } from 'constants/yupFields';
import * as Yup from 'yup';

export const yupSchema = Yup.object().shape({
  firstName: yupDefault,
  lastName: yupDefault,
  email: yupEmail,
  dateOfBirth: Yup.string().nullable(),
  gender: Yup.string(),
  telegram: Yup.string(),
  phoneNumber: Yup.string(),
  weChat: Yup.string(),
});

export const contentMarginBottom = '10px';
export const fieldsMarginBottom = '20px';
export const titleMarginBottom = '20px';
export const marginBetween = '10px';
export const personalFormPadding = '25px 90px';
export const mobilePersonalFormPadding = '20px 16px';
export const mobileMarginBottom = '16px';
