import { Button } from 'components/common/buttons/Button';
import { TitleText } from 'components/common/typography/TitleText';
import { FormRadio } from 'components/forms/inputs/FormRadio';
import { FormTextInput } from 'components/forms/inputs/FormTextInput';
import { UpdatePersonalInterface } from 'components/forms/PersonalForm/types';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { FlexGrow, Section } from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { subYears } from 'date-fns';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { userEffects } from 'stores/user';
import { getGender, getGenderValue } from 'utils/data';
import { FormDateInput } from '../inputs/FormDateInput';
import { Form } from '../styles';
import {
  contentMarginBottom,
  fieldsMarginBottom,
  mobileMarginBottom,
  mobilePersonalFormPadding,
  personalFormPadding,
  titleMarginBottom,
  yupSchema,
} from './constants';

const { formSendVerifyEmail, formUpdateUser, updateUser } = userEffects;

const {
  personalForm,
  text: { submitButton, feedback, personalData, confirmButton },
} = languages.pages.personal;

interface PersonalFormProps {
  lastName?: string;
  firstName?: string;
  email?: string;
  weChatId?: string;
  phone?: string;
  telegram?: string;
  gender?: API.GenderType;
  dateOfBirth?: string;
  isVerified?: boolean;
}

export const PersonalForm = ({
  lastName,
  email,
  firstName,
  phone,
  weChatId,
  isVerified,
  dateOfBirth,
  gender = 'female',
  telegram,
}: PersonalFormProps) => {
  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useFormSchema(yupSchema);
  const isDisabled = !isValid || (!phone && !telegram && !weChatId);
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const isMobileLarge = useMediaQuery(MediaSizes.MobileLarge);

  const loading = useStore(updateUser.pending);
  const loadingVerifyButton = useStore(formSendVerifyEmail.pending);
  const padding = isMobileLarge ? '25px 30px' : personalFormPadding;

  const onConfirmClick = async () => {
    email && (await formSendVerifyEmail({ email }));
  };

  const onSubmit = async ({
    lastName,
    dateOfBirth,
    firstName,
    gender,
    phoneNumber,
    telegram,
    weChat,
  }: UpdatePersonalInterface) => {
    await formUpdateUser({
      lastName: lastName || undefined,
      dateOfBirth:
        dateOfBirth !== undefined
          ? new Date(dateOfBirth)?.toISOString()
          : undefined,
      firstName: firstName || undefined,
      gender: getGender(gender),
      phone: phoneNumber || undefined,
      telegramNumber: telegram || undefined,
      weChatId: weChat || undefined,
    });
  };

  return !isMobile ? (
    <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
      <ContentWrapper
        marginBottom={contentMarginBottom}
        padding={padding}
        width="100%"
      >
        <Section marginBottom={titleMarginBottom}>
          <TitleText>{personalData}</TitleText>
        </Section>
        <Section marginBottom={fieldsMarginBottom}>
          <FormTextInput
            disabled
            autoComplete="off"
            control={control}
            defaultValue={email}
            id="email"
            name="email"
            {...personalForm['email']}
          />
        </Section>
        <Section justifyBetween marginBottom={fieldsMarginBottom}>
          <FlexGrow flexBasis="48%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={lastName}
              id="lastName"
              name="lastName"
              type="text"
              {...personalForm['lastName']}
            />
          </FlexGrow>
          <FlexGrow flexBasis="48%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={firstName}
              id="firstName"
              name="firstName"
              type="text"
              {...personalForm['firstName']}
            />
          </FlexGrow>
        </Section>
        <Section marginBottom={fieldsMarginBottom}>
          <FormDateInput
            control={control}
            defaultValue={dateOfBirth}
            id="dateOfBirth"
            maxDate={subYears(new Date(), 13)}
            name="dateOfBirth"
            {...personalForm['dateOfBirth']}
          />
        </Section>
        <Section marginBottom={fieldsMarginBottom}>
          <FormRadio
            control={control}
            defaultSelectedValue={getGenderValue(gender)}
            id="gender"
            name="gender"
            {...personalForm['gender']}
          />
        </Section>
        {!isVerified && (
          <Section alignCenter justifyCenter>
            <Button
              isLoading={loadingVerifyButton}
              type="button"
              onClick={onConfirmClick}
            >
              {confirmButton}
            </Button>
          </Section>
        )}
      </ContentWrapper>
      <ContentWrapper padding={padding} width="100%">
        <Section marginBottom={titleMarginBottom}>
          <TitleText>{feedback}</TitleText>
        </Section>
        <Section justifyBetween marginBottom={fieldsMarginBottom}>
          <FlexGrow flexBasis="48%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={telegram}
              id="telegram"
              name="telegram"
              {...personalForm['telegram']}
            />
          </FlexGrow>
          <FlexGrow flexBasis="48%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={weChatId}
              id="weChat"
              name="weChat"
              {...personalForm['weChat']}
            />
          </FlexGrow>
        </Section>
        <Section marginBottom={titleMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={phone}
            id="phoneNumber"
            name="phoneNumber"
            {...personalForm['phoneNumber']}
          />
        </Section>
        <Section alignCenter justifyCenter>
          <Button disabled={isDisabled} isLoading={loading} type="submit">
            {submitButton}
          </Button>
        </Section>
      </ContentWrapper>
    </Form>
  ) : (
    <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
      <ContentWrapper
        marginBottom={contentMarginBottom}
        padding={mobilePersonalFormPadding}
        width="100%"
      >
        <Section marginBottom={titleMarginBottom}>
          <TitleText>{personalData}</TitleText>
        </Section>

        <Section justifyBetween marginBottom={mobileMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={firstName}
            id="firstName"
            name="firstName"
            type="text"
            {...personalForm['firstName']}
          />
        </Section>
        <Section justifyBetween marginBottom={mobileMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={lastName}
            id="lastName"
            name="lastName"
            type="text"
            {...personalForm['lastName']}
          />
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            disabled
            autoComplete="off"
            control={control}
            defaultValue={email}
            id="email"
            name="email"
            {...personalForm['email']}
          />
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormDateInput
            control={control}
            defaultValue={dateOfBirth}
            id="dateOfBirth"
            maxDate={subYears(new Date(), 13)}
            name="dateOfBirth"
            {...personalForm['dateOfBirth']}
          />
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormRadio
            control={control}
            defaultSelectedValue={getGenderValue(gender)}
            id="gender"
            name="gender"
            {...personalForm['gender']}
          />
        </Section>
        {!isVerified && (
          <Section alignCenter justifyCenter>
            <Button
              isLoading={loadingVerifyButton}
              type="button"
              onClick={onConfirmClick}
            >
              {confirmButton}
            </Button>
          </Section>
        )}
      </ContentWrapper>
      <ContentWrapper padding={mobilePersonalFormPadding} width="100%">
        <Section marginBottom={titleMarginBottom}>
          <TitleText>{feedback}</TitleText>
        </Section>
        <Section justifyBetween marginBottom={fieldsMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={telegram}
            id="telegram"
            name="telegram"
            {...personalForm['telegram']}
          />
        </Section>
        <Section justifyBetween marginBottom={fieldsMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={weChatId}
            id="weChat"
            name="weChat"
            {...personalForm['weChat']}
          />
        </Section>
        <Section marginBottom={titleMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={phone}
            id="phoneNumber"
            name="phoneNumber"
            {...personalForm['phoneNumber']}
          />
        </Section>
        <Section alignCenter justifyCenter>
          <Button disabled={isDisabled} isLoading={loading} type="submit">
            {submitButton}
          </Button>
        </Section>
      </ContentWrapper>
    </Form>
  );
};
