import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { InformationInputWrapper, InputWrapper, StyledInput } from './styles';
import { useToggle } from 'hooks/toggle';
import { Section, FlexGrow } from 'components/wrappers/grid/FlexWrapper';
import { RelativeWrapper } from 'components/wrappers/grid/RelativeWrapper';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import showPasswordIcon from 'assets/icons/show-password-icon.svg';
import hidePasswordIcon from 'assets/icons/hide-password-icon.svg';
import { Sizes } from 'types/styles';

export interface StyledInputProps extends Pick<Sizes, 'height'> {
  hasError?: boolean;
  prefix?: string;
  postfix?: string;
}

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'height'>,
    StyledInputProps {}

export const Input = ({
  type,
  prefix,
  postfix,
  hasError,
  height,
  value = '',
  ...inputProps
}: InputProps) => {
  const [inputType, setInputType] = useState(type);
  const [isShowPassword, setShowPassword] = useToggle();
  const isPasswordType = type === 'password';

  const onChangePasswordView = () => {
    setInputType(isShowPassword ? 'password' : 'text');
    setShowPassword();
  };

  useEffect(() => setInputType(type), [type]);

  return (
    <InputWrapper
      hasError={hasError}
      height={height}
      postfix={postfix}
      prefix={prefix}
    >
      <RelativeWrapper>
        <Section alignCenter noWrap height="100%">
          {isPasswordType && (
            <InformationInputWrapper onClick={onChangePasswordView}>
              <CustomImg
                src={isShowPassword ? hidePasswordIcon : showPasswordIcon}
                width="18px"
              />
            </InformationInputWrapper>
          )}
          {prefix && (
            <FlexGrow flexGrow="0">
              <span>{prefix}</span>
            </FlexGrow>
          )}
          <FlexGrow flexGrow="1">
            <StyledInput type={inputType} value={value} {...inputProps} />
          </FlexGrow>
          {postfix && (
            <FlexGrow flexGrow="0">
              <span>{postfix}</span>
            </FlexGrow>
          )}
        </Section>
      </RelativeWrapper>
    </InputWrapper>
  );
};
