import { useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';
import { Mode } from 'react-hook-form/dist/types/form';

// TODO: any
export const useFormSchema = (
  yupSchema: AnyObjectSchema,
  mode: Mode = 'onChange',
) => {
  const schema = useMemo(() => yupSchema, [yupSchema]);

  return useForm({
    resolver: yupResolver(schema),
    mode,
  });
};
