import React, { useEffect } from 'react';
import { AuthLayout } from 'components/layouts/AuthLayout';
import { useLocation } from 'react-router';
import { getQueryParams } from 'utils/browser.api';
import { ConfirmationEmailQueryParams } from 'pages/Auth/Confirmation/types';
import { userEffects } from 'stores/user';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { Loader } from 'components/dynamic/Loader';
import { TitleText } from 'components/common/typography/TitleText';

const { formVerifyAccount } = userEffects;

const {
  text: { title },
} = languages.pages.confirmation;

export const Confirmation = () => {
  const { search } = useLocation();
  const { info } = getQueryParams<ConfirmationEmailQueryParams>(search);
  // const loading = useStore(userEffects.verifyAccount.pending);

  useEffect(() => {
    info && formVerifyAccount({ token: info });
  }, [info]);

  return (
    <AuthLayout withoutBar>
      <Section width="400px">
        <Section alignCenter justifyCenter marginBottom="40px">
          <TitleText>{title}</TitleText>
        </Section>
        <Section alignCenter justifyCenter>
          <Loader />
        </Section>
      </Section>
    </AuthLayout>
  );
};
