import { stepBackButtonDiameter } from 'components/common/buttons/ImageButtons/constants';
import {
  activeColor,
  inactiveColor,
} from 'components/dynamic/WizardStatus/constants';
import { FlexGrow } from 'components/wrappers/grid/FlexWrapper';
import styled from 'styled-components';
import { Disabled } from 'types/form';

export const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #ffffff;
  border-radius: 10px;
  padding: 0 12px;
`;

export const StepsWrapper = styled(FlexGrow)`
  position: relative;
`;

interface StepProps extends Disabled {
  wasSaved?: boolean;
}

export const StepButton = styled.button`
  width: 30px;
  height: 30px;
  background: ${activeColor};
  border-radius: 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  :disabled {
    background: ${inactiveColor};
    cursor: not-allowed;
  }
`;

export const Line = styled.div<StepProps>`
  position: relative;
  height: 5px;
  width: 100%;
  background: ${({ disabled, wasSaved }) =>
    disabled ? inactiveColor : wasSaved ? activeColor : 'none'};
`;

export const Step = styled.div<StepProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${stepBackButtonDiameter};
  height: ${stepBackButtonDiameter};
`;
