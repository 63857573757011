import { Row, Section } from 'components/wrappers/grid/FlexWrapper';
import React, { useEffect, useState } from 'react';
import { HiddenRadioButton, Label, VisibleRadioButton } from './styles';

export interface RadioProps {
  values: string[];
  defaultSelectedValue?: string;
  onChange?: (value: string) => void;
  name: string;
}

export const Radio = ({
  values,
  name,
  defaultSelectedValue,
  onChange,
}: RadioProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    onChange?.(value);
    setSelectedValue(value);
  };

  useEffect(() => {
    setSelectedValue(defaultSelectedValue);
  }, [defaultSelectedValue]);

  return (
    <Section alignCenter>
      {values.map((value) => (
        <Row key={value} marginBottom="10px" marginRight="50px">
          <Label>
            <HiddenRadioButton
              checked={value === selectedValue}
              name={name}
              value={value}
              onChange={onRadioChange}
            />

            <VisibleRadioButton checked={value === selectedValue} />
            {value}
          </Label>
        </Row>
      ))}
    </Section>
  );
};
