import { white } from 'constants/styles/colors';
import { lg, xs } from 'constants/styles/sizes';
import styled from 'styled-components';
import { IsAuthorized } from 'types/global';
import { Background, BackgroundColor } from 'types/styles';

interface LayoutWrapperProps
  extends Background,
    IsAuthorized,
    BackgroundColor {}

export const LayoutWrapper = styled.div<LayoutWrapperProps>`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ isAuthorized }) => (isAuthorized ? '#F1F3F8' : white)};
  ${({ background }) => background && `background-image: url(${background})`};
  background-position: 50% 320px;
  background-repeat: no-repeat;
  padding-bottom: 20px;

  @media screen and (max-width: ${lg}) {
    padding-bottom: 0;
  }

  @media screen and (max-width: ${xs}) {
    padding-bottom: ${({ isAuthorized }) => (isAuthorized ? '100px' : 0)};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-around;
  flex-grow: 1;
`;
