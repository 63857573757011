export interface StepInterface {
  onNext: (step: Steps) => void;
}
//TODO rename enum from 'Steps' to 'Step'
export enum Steps {
  initialStep = 'initialStep',
  dimensionsStep = 'dimensionsStep',
}

export interface ItemList {
  activeStep: number;
  index?: number;
  Component: (props: StepInterface) => JSX.Element;
}

export type StepsList = {
  [key in Steps]: ItemList;
};
