import { MainHeaderProps } from 'components/grid/Headers/MainHeader/index';
import { darkBlue, paleBlue, raspberry, white } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import { contentWidth, lg, md, xl } from 'constants/styles/sizes';
import styled from 'styled-components';
import { Sizes } from 'types/styles';

export const HeaderWrapper = styled.header<MainHeaderProps>`
  position: relative;
  z-index: 10;
  width: 100%;
  padding: ${({ isAuthorized }) => (isAuthorized ? '18px' : '42px')} 0;
  background-color: white;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: ${contentWidth};

  @media screen and (max-width: ${xl}) {
    margin: 0 100px;
  }

  @media screen and (max-width: 1110px) {
    max-width: 910px;
    margin: 0 auto;
  }

  @media screen and (max-width: ${lg}) {
    margin: 0 70px;
  }

  @media screen and (max-width: 910px) {
    margin: 0 50px;
  }

  @media screen and (max-width: ${md}) {
    margin: 0 20px;
  }
`;

export const ContactNumberText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #242b3e;
`;

export const StyledLink = styled.a<Pick<Sizes, 'width'>>`
  width: ${({ width }) => width || '240px'};
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: ${white};
  background-color: ${darkBlue};
  border-radius: 40px;
  transition: 0.3s ease-in-out;
  ${flexCenter};

  :hover {
    background-color: ${paleBlue};
  }
  :active {
    background-color: ${raspberry};
  }

  @media screen and (max-width: 370px) {
    height: 38px;
  }
`;
