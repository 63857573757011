import { Tab } from 'components/grid/Bars/TabBar/types';
import { languages } from 'constants/languages';
import { addressesLink, ordersLink, personalLink } from 'constants/routes';

const { addresses, orders, personal } = languages.layouts.tabs.accountTab;

export const tabs: Tab[] = [
  {
    path: personalLink,
    description: personal,
  },
  {
    path: addressesLink,
    description: addresses,
  },
  {
    path: ordersLink,
    description: orders,
  },
];

export const tabButtonWidth = '295px';
export const contentTabWidth = parseInt(tabButtonWidth) * tabs.length + 'px';
export const tabWidth = '808px';
export const accountTabWidth = '910px';
