import { darkBlue, grey3, raspberry } from 'constants/styles/colors';
import { ellipsisMixin } from 'constants/styles/mixins';
import styled, { css } from 'styled-components';

export const FileNameSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  padding: 5px;
  color: ${grey3};
  ${ellipsisMixin};
`;

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: ${grey3};
  transition: color 0.3s;

  ${({ disabled }) =>
    disabled
      ? 'cursor: not-allowed;'
      : css`
          :hover {
            color: ${darkBlue};

            ${FileNameSpan} {
              color: ${darkBlue};
            }
          }

          :active {
            color: ${raspberry};

            ${FileNameSpan} {
              color: ${raspberry};
            }
          }
        `}
`;
