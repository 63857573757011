import React from 'react';
import { AuthLayout } from 'components/layouts/AuthLayout';
import { ResetPasswordForm } from 'components/forms/ResetPasswordForm';
import { useLocation } from 'react-router';
import { getQueryParams } from 'utils/browser.api';
import { ResetPasswordQueryParams } from 'pages/Auth/ResetPassword/types';

export const ResetPassword = () => {
  const { search } = useLocation();
  const { uniqId } = getQueryParams<ResetPasswordQueryParams>(search);

  return (
    <AuthLayout withoutBar>
      <ResetPasswordForm token={uniqId} />
    </AuthLayout>
  );
};
