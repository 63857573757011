import { useCallback, useEffect, useState } from 'react';

const PART_SCREEN = 6;

const options = {
  root: document,
  rootMargin: '0px 0px 75% 0px',
  threshold: 1.0,
};
const documentCenterY = document.documentElement.clientWidth / PART_SCREEN;

// searches for the element at the top of the document and returns its index

export const useFindTopYPositionItem = (ids: string[]) => {
  const [activeItem, setActiveItem] = useState(0);

  const memoizedOnScroll = useCallback(() => {
    ids.forEach((id, i) => {
      const item = document.getElementById(id);
      const callback = function (entries: IntersectionObserverEntry[]) {
        entries.forEach((entry) => {
          if (
            documentCenterY > entry.boundingClientRect.y &&
            entry.boundingClientRect.y > 0
          ) {
            setActiveItem(i);
          }
        });
      };
      const observer = new IntersectionObserver(callback, options);
      if (item) observer.observe(item);
    });
  }, [ids]);

  useEffect(() => {
    document.addEventListener('scroll', memoizedOnScroll);
    return () => {
      document.removeEventListener('scroll', memoizedOnScroll);
    };
  }, [memoizedOnScroll]);

  return activeItem;
};
