import { ContentWrapperInterface } from '../ModalWrapper';

export const contentWrapperProps: ContentWrapperInterface = {
  height: 'fit-content' /*'650px'*/,
  width: '450px',
  backgroundColor: 'white',
  borderRadius: '10px',
  padding: '20px',
};

export const mobileContentWrapperProps: ContentWrapperInterface = {
  height: 'fit-content' /*'650px'*/,
  width: '100%',
  backgroundColor: 'white',
  borderRadius: '10px',
};

export const contentPadding = '15px 0px 10px';
export const pairedItemWidth = '48%';
