import { Button } from 'components/common/buttons/Button';
import { TextButton } from 'components/common/buttons/TextButton';
import { InformationBadge } from 'components/common/dividers/InformationBadge';
import { TitleText } from 'components/common/typography/TitleText';
import { mobileMarginBottom } from 'components/forms/AddressForm/constants';
import { FormConfirmCheckbox } from 'components/forms/inputs/FormConfirmCheckbox';
import { FormTextInput } from 'components/forms/inputs/FormTextInput';
import { yupSchema } from 'components/forms/OrdersFroms/InsuranceStepForm/constants';
import { InsuranceStepData } from 'components/forms/OrdersFroms/InsuranceStepForm/types';
import { Form } from 'components/forms/styles';
import {
  StepInterface,
  Steps,
} from 'components/grid/Wizards/OrderWizard/types';
import { InsuranceModal } from 'components/modals/InsuranceModal';
import {
  Column,
  FlexGrow,
  Section,
} from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  insurancesEffects,
  insurancesEvents,
  insurancesStores,
  RelevantInsuranceProps,
} from 'stores/insurances';
import { ordersEvents } from 'stores/orders';

const { getAllInsurances } = insurancesEffects;
const { setRelevantInsurance, clearRelevantInsurance } = insurancesEvents;
const { setOrderFormValue } = ordersEvents;

const {
  insuranceStepForm,
  text: {
    insuranceStepFormTitle,
    insurancePrice,
    insuranceOrderingButton,
    withoutInsuranceButton,
    InsuranceDescriptionJSX,
    insurancePerWeightError,
    // insurancePerPriceError,
  },
} = languages.pages.shipments;

interface InsuranceStepFormProps
  extends Pick<StepInterface, 'onNext'>,
    Pick<Partial<API.CreateOrderDto>, 'weight' | 'productPrice'> {
  insurance: RelevantInsuranceProps;
}

export const InsuranceStepForm = ({
  onNext,
  weight,
  insurance,
  productPrice,
}: InsuranceStepFormProps) => {
  const insurances = useStore(insurancesStores.insurances).result;
  const insurancesLoading = useStore(getAllInsurances.pending);
  const { cost: insuranceCost, id: insuranceId } = insurance;
  const isMobileBig = useMediaQuery(MediaSizes.MobileBig);
  const {
    handleSubmit,
    formState: { isValid },
    control,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useFormSchema(yupSchema);

  const isValidForm = isValid && insuranceCost;

  const priceValue = parseFloat(Number(watch('productPrice')).toFixed(2));

  const onProductPriceBlur = () => {
    setValue('productPrice', priceValue);
  };

  const onSubmit = async ({ productPrice }: InsuranceStepData) => {
    setOrderFormValue({
      productPrice: parseFloat(productPrice),
      insurance: insuranceId,
    });
    onNext(Steps.packageStep);
  };

  const onWithoutInsuranceClick = () => {
    setOrderFormValue({ productPrice: undefined, insurance: undefined });
    clearRelevantInsurance();
    onNext(Steps.packageStep);
  };

  useEffect(() => {
    if (insurances.length > 0 && weight && priceValue) {
      const weightAsNumber = parseFloat(weight);
      const insuranceCoefficient = Math.round(priceValue / weightAsNumber);

      const relevantInsurance = insurances?.find(
        ({ priceFromTo: { from, to } }) =>
          insuranceCoefficient >= from && insuranceCoefficient <= to,
      );

      const percent = relevantInsurance?.percent;
      const insuranceId = relevantInsurance?._id;

      if (percent === undefined) {
        setError('productPrice', {
          type: 'manual',
          message: insurancePerWeightError,
        });

        clearRelevantInsurance();
      } else {
        const insuranceCost = Number(((priceValue * percent) / 100).toFixed(2));
        clearErrors('productPrice');

        insuranceId &&
          setRelevantInsurance({
            cost: insuranceCost,
            id: insuranceId,
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceValue]);

  useEffect(() => {
    insurances.length === 0 && getAllInsurances();
    if (productPrice) {
      setValue('productPrice', productPrice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isMobileBig ? (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section marginBottom="20px">
        <TitleText>{insuranceStepFormTitle}</TitleText>
      </Section>
      <Section justifyBetween height="150px" marginBottom="10px">
        <FlexGrow flexBasis="58%" flexGrow="0">
          <FormTextInput
            control={control}
            defaultValue={productPrice}
            id="productPrice"
            name="productPrice"
            type="number"
            onBlur={onProductPriceBlur}
            {...insuranceStepForm['productPrice']}
            autoFocus
          />
        </FlexGrow>
        <FlexGrow flexBasis="38%" flexGrow="0">
          <Column width="100%">
            <Section marginBottom="5px">
              <TitleText>{insurancePrice}</TitleText>
            </Section>
            <Section>
              <InformationBadge
                content={{
                  value: `${insuranceCost || 0} $`,
                }}
                type="total"
              />
            </Section>
          </Column>
        </FlexGrow>
      </Section>
      <Section marginBottom="30px">
        <InsuranceDescriptionJSX />
      </Section>
      <Section marginBottom="45px">
        <FormConfirmCheckbox
          control={control}
          id="confirmation"
          name="confirmation"
          {...insuranceStepForm['confirmation']}
        />
      </Section>
      <Section alignCenter justifyCenter marginBottom="10px">
        <Button
          disabled={!isValidForm}
          isLoading={insurancesLoading}
          type="submit"
        >
          {insuranceOrderingButton}
        </Button>
      </Section>
      <Section alignCenter justifyCenter>
        <TextButton onClick={onWithoutInsuranceClick}>
          {withoutInsuranceButton}
        </TextButton>
      </Section>
      <InsuranceModal />
    </Form>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section marginBottom={mobileMarginBottom}>
        <TitleText>{insuranceStepFormTitle}</TitleText>
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <FormTextInput
          control={control}
          defaultValue={productPrice}
          id="productPrice"
          name="productPrice"
          type="number"
          onBlur={onProductPriceBlur}
          {...insuranceStepForm['productPrice']}
          autoFocus
        />
      </Section>
      <Section marginBottom="10px">
        <TitleText>{insurancePrice}</TitleText>
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <InformationBadge
          content={{
            value: `${insuranceCost || 0} $`,
          }}
          type="total"
        />
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <InsuranceDescriptionJSX />
      </Section>
      <Section marginBottom="24px">
        <FormConfirmCheckbox
          control={control}
          id="confirmation"
          name="confirmation"
          {...insuranceStepForm['confirmation']}
        />
      </Section>
      <Section alignCenter justifyCenter marginBottom="10px">
        <Button
          disabled={!isValidForm}
          isLoading={insurancesLoading}
          type="submit"
          width="100%"
        >
          {insuranceOrderingButton}
        </Button>
      </Section>
      <Section alignCenter justifyCenter>
        <TextButton onClick={onWithoutInsuranceClick}>
          {withoutInsuranceButton}
        </TextButton>
      </Section>
      <InsuranceModal />
    </Form>
  );
};
