export const checkboxDiameter = '18px';
export const largeCheckboxDiameter = '29px';
export const checkboxBorderRadius = '2px';

export const checkMarkIconHeight = '8px';
export const largeCheckMarkIconHeight = '12px';
export const checkMarkIconWidth = '10px';
export const largeCheckMarkIconWidth = '15px';

export const checkPosition = (isLarge: boolean) => ({
  bottom: isLarge ? '8px' : '4px',
  left: isLarge ? '7px' : '3px',
});
