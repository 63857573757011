import { Tab } from 'components/grid/Bars/TabBar/types';
import { accountTabWidth } from 'components/layouts/AccountLayout/constants';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { matchPath, useLocation } from 'react-router';
import { ButtonWrapper, StyledLink } from './styles';

export interface TabBarProps {
  tabs: Tab[];
  withoutShift?: boolean;
  buttonWidth?: string;
  theme?: 'white' | 'grey';
}

export const TabBar = ({ tabs, withoutShift, theme = 'grey' }: TabBarProps) => {
  const { pathname } = useLocation();
  const isTablet = useMediaQuery(MediaSizes.Tablet);

  return (
    <Section justifyBetween noWrap width={isTablet ? '100%' : accountTabWidth}>
      {tabs.map(({ path, description }, i) => {
        const isActive = !!matchPath(pathname, {
          path,
        });
        const isFirst = i === 0;
        const shift = withoutShift ? '0' : !isFirst ? '8px' : '0';

        return (
          <ButtonWrapper
            key={path}
            marginLeft={shift}
            width="100%"
            zIndex={isActive ? '10' : '0'}
          >
            <StyledLink isActive={isActive} theme={theme} to={path}>
              {description}
            </StyledLink>
          </ButtonWrapper>
        );
      })}
    </Section>
  );
};
