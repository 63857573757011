import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'api';
import {
  InformationModalPayload,
  informationModalStore,
  inputModal,
} from 'stores/initialize/initialize.modal.store';
import { toAddressesLinkClick } from 'constants/routes';
import { languages } from 'constants/languages';
import axios, { CancelTokenSource } from 'axios';
import {
  defaultAddresses,
  defaultAddressesPageLimit,
  defaultGetAllResponse,
  defaultLocationState,
  defaultQueryAddresses,
} from 'constants/defaults/store';
import { initializeIsFirstStore } from 'stores/initialize/initialize.isFirst.store';
import { modalEvents } from 'stores/modals/asyncModal';
import { getDataAndOpenErrorModal } from 'utils/store';

let cancelToken: CancelTokenSource | undefined;

const { successAddressSave, successDeleteAddress } =
  languages.modals.informationModal;

const setDefaultCountriesList = createEvent();

const getCountriesList = createEffect({
  handler: async (data: API.CountriesListByNameDto) => {
    try {
      cancelToken && cancelToken.cancel();
      cancelToken = axios.CancelToken.source();

      return await API.addresses.getCountriesList(data, cancelToken.token);
    } catch (error) {
      getDataAndOpenErrorModal(error);
      setDefaultCountriesList();
    }
  },
});

const countriesList = createStore<API.CountriesListResponseDto>(
  defaultLocationState,
)
  .on(getCountriesList.doneData, (_, newState) => newState)
  .on(setDefaultCountriesList, () => defaultLocationState);

const setDefaultRegionsList = createEvent();

const getRegionsList = createEffect({
  handler: async (data: API.RegionsListByCountryDto) => {
    try {
      cancelToken && cancelToken.cancel();
      cancelToken = axios.CancelToken.source();

      return await API.addresses.getRegionsListByCountry(
        data,
        cancelToken.token,
      );
    } catch (error) {
      getDataAndOpenErrorModal(error);
      setDefaultRegionsList();
    }
  },
});

const regionsList = createStore<API.RegionsListResponseDto>(
  defaultLocationState,
)
  .on(getRegionsList.doneData, (_, newState) => newState)
  .on(setDefaultRegionsList, () => defaultLocationState);

const setDefaultCitiesList = createEvent();

const getCitiesList = createEffect({
  handler: async (data: API.CitiesListDto) => {
    try {
      cancelToken && cancelToken.cancel();
      cancelToken = axios.CancelToken.source();

      return await API.addresses.getCitiesListByCountry(
        data,
        cancelToken.token,
      );
    } catch (error) {
      getDataAndOpenErrorModal(error);
      setDefaultCitiesList();
    }
  },
});

const citiesList = createStore<API.CitiesListResponseDto>(defaultLocationState)
  .on(getCitiesList.doneData, (_, newState) => newState)
  .on(setDefaultCitiesList, () => defaultLocationState);

const loadItems = createEffect({
  handler: async (data: API.GetMyAddressDto) => {
    try {
      cancelToken && cancelToken.cancel();
      cancelToken = axios.CancelToken.source();

      return await API.addresses.getMyAddresses(data, cancelToken.token);
    } catch (error) {
      getDataAndOpenErrorModal(error);
      return defaultAddresses;
    }
  },
});

const addresses = createStore<API.MyAddressesResponseDto>(defaultAddresses).on(
  loadItems.doneData,
  (_, state) => state,
);

const { isFirst, setIsFirstToFalse, setIsFirstToTrue } =
  initializeIsFirstStore();

const updateValues = createEvent<Pick<API.GetMyAddressDto, 'page'>>();
const invokeGetItems = createEvent();
const setDefaultValues = createEvent();

const values = createStore<API.GetMyAddressDto>(defaultQueryAddresses)
  .on(updateValues, (state, values) => ({
    ...state,
    limit: defaultAddressesPageLimit,
    ...values,
  }))
  .on(invokeGetItems, (state) => state)
  .on(setDefaultValues, () => defaultQueryAddresses);

forward({
  from: [values],
  to: [loadItems],
});

values.watch(invokeGetItems, (state) => loadItems(state));

const loadItem = createEffect({
  handler: async (data: API.GetAddressDto) => {
    try {
      cancelToken && cancelToken.cancel();
      cancelToken = axios.CancelToken.source();

      return await API.addresses.getAddressById(data, cancelToken.token);
    } catch (error) {
      getDataAndOpenErrorModal(error);
      return {};
    }
  },
});

const formDeleteAddressById = createEffect({
  handler: async (data: API.DeleteAddressDto) => {
    try {
      modalEvents.updateAsyncModalLoading();
      await API.addresses.deleteAddressById(data);
      modalEvents.closeAsyncModal();

      informationModalStore.openModal({
        message: successDeleteAddress,
        actionAfterCloseClick: toAddressesLinkClick,
      });

      invokeGetItems();
    } catch (error) {
      modalEvents.closeAsyncModal();
      getDataAndOpenErrorModal(error);
    } finally {
      modalEvents.updateAsyncModalLoading();
    }
  },
});

interface FormCreateAddressInterface
  extends API.CreateAddressDto,
    Pick<InformationModalPayload, 'actionAfterCloseClick'> {}

const formCreateAddress = createEffect({
  handler: async ({
    actionAfterCloseClick,
    ...data
  }: FormCreateAddressInterface) => {
    try {
      await API.addresses.createAddress({ ...data });
      invokeGetItems();
      inputModal.closeModal();
      informationModalStore.openModal({
        message: successAddressSave,
        actionAfterCloseClick,
      });
    } catch (error) {
      inputModal.closeModal();
      getDataAndOpenErrorModal(error);
      toAddressesLinkClick();
    }
  },
});

const formUpdateAddressById = createEffect({
  handler: async (data: API.UpdateAddressDto) => {
    try {
      await API.addresses.updateAddressById(data);
      inputModal.closeModal();
      informationModalStore.openModal({
        message: successAddressSave,
        actionAfterCloseClick: toAddressesLinkClick,
      });
      invokeGetItems();
    } catch (error) {
      inputModal.closeModal();
      getDataAndOpenErrorModal(error);
      toAddressesLinkClick();
    }
  },
});

const clearAddress = createEvent();

const address = createStore<Partial<API.AddressItemResponseDto>>({})
  .on(loadItem.doneData, (_, state) => state)
  .on(clearAddress, () => ({}));

const getAllMyAddresses = createEffect({
  handler: async () => {
    try {
      return await API.addresses.getAllMyAddresses();
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const allMyAddresses = createStore<API.MyAddressesResponseDto>(
  defaultGetAllResponse,
).on(getAllMyAddresses.doneData, (_, newState) => newState);

export const addressesEvents = {
  clearAddress,
  setDefaultRegionsList,
  setDefaultCitiesList,
  setIsFirstToFalse,
  setIsFirstToTrue,
  updateValues,
  invokeGetItems,
  setDefaultCountriesList,
};
export const addressesEffects = {
  getCountriesList,
  getRegionsList,
  getCitiesList,
  loadItems,
  loadItem,
  formCreateAddress,
  formUpdateAddressById,
  formDeleteAddressById,
  getAllMyAddresses,
};
export const addressesStores = {
  countriesList,
  regionsList,
  citiesList,
  addresses,
  address,
  isFirst,
  values,
  allMyAddresses,
};
