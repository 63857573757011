import { contentWrapperBorderRadius } from 'components/wrappers/ContentWrapper/constants';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { darkBlue } from 'constants/styles/colors';
import { lg, md, sm, sm_2 } from 'constants/styles/sizes';
import styled from 'styled-components';
import { authLayoutWidth } from './constants';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  width: 100%;
  max-width: ${authLayoutWidth};
  height: 100%;

  @media screen and (max-width: ${lg}) {
    padding-left: 90px;
    padding-right: 90px;
  }

  @media screen and (max-width: ${sm_2}) {
    padding: 60px 16px;
  }

  @media screen and (max-width: ${sm}) {
    padding: 40px 16px;
  }
`;

export const StyledContentWrapper = styled(Column)`
  width: 100%;
  align-items: center;
  padding: 40px 65px;
  border-radius: ${contentWrapperBorderRadius};
  background-color: ${darkBlue};

  @media screen and (max-width: ${md}) {
    padding: 20px 20px;
  }

  @media screen and (max-width: ${sm_2}) {
    padding: 20px 16px;
  }
`;
