import { createStore } from 'effector';
import { API } from 'api';
import { defaultGetAllResponse } from 'constants/defaults/store';
import { createNotifyingErrorEffect } from 'utils/store';

const getMethods = createNotifyingErrorEffect({
  handler: async (data: API.GetMethodsRequestDto) =>
    await API.methods.getMethods(data),
});

const methods = createStore<API.GetAllMethodsResponseDto>(
  defaultGetAllResponse,
).on(getMethods.doneData, (_, newState) => newState);

export const methodsEffects = { getMethods };
export const methodsEvents = {};
export const methodsStores = { methods };
