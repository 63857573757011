import { Button } from 'components/common/buttons/Button';
import { CloseButton } from 'components/common/buttons/ImageButtons';
import { TitleText } from 'components/common/typography/TitleText';
import {
  //! DON't remove:
  // delayRegionAPI,
  fieldsMarginBottom,
  mobileMarginBottom,
  mobileMarginBottomLarge,
  titleMarginBottom,
  yupSchema,
} from 'components/forms/AddressForm/constants';
import { AddressFormInterface } from 'components/forms/AddressForm/types';
//! DON't remove:
//import { FormAsyncTextInput } from 'components/forms/inputs/FormAsyncInput';
import { FormRadio } from 'components/forms/inputs/FormRadio';
import { FormTextInput } from 'components/forms/inputs/FormTextInput';
import {
  accountTabWidth,
  tabWidth,
} from 'components/layouts/AccountLayout/constants';
import { InputModal } from 'components/modals/InputModal';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import {
  Column,
  FlexGrow,
  Row,
  Section,
} from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
//! DON't remove:
//import { useDebounce } from 'hooks/debounce';
//import { usePrevious } from 'hooks/previous';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import {
  addressesEffects,
  //! DON't remove:
  // addressesEvents,
  //addressesStores,
} from 'stores/addresses';
import { inputModal } from 'stores/initialize/initialize.modal.store';
import { Noop } from 'types/types';
import { getDeliveryPostValue } from 'utils/data';
import { Form } from '../styles';
import { FormWrapper } from './styles';

const { formCreateAddress } = addressesEffects;

//! DON't remove:
//* Logic of fetching countries, regions and cities from BE
//const { regionsList, countriesList, citiesList } = addressesStores;
//const { getCitiesList, getRegionsList, getCountriesList } = addressesEffects;
// const { setDefaultCitiesList, setDefaultRegionsList, setDefaultCountriesList } =
//   addressesEvents;

// const setRegionValues = async (country: string, area: string) => {
//   const countries = await getCountriesList({ namePrefix: country });

//   //waiting one second between requests to API
//   await (() =>
//     new Promise<void>((resolve) =>
//       setTimeout(() => {
//         resolve();
//       }, delayRegionAPI),
//     ))();

//   await getRegionsList({
//     namePrefix: area,
//     countryId: countries?.data[0]?.code as string,
//   });
// };

const {
  addressForm,
  text: { deliveryOfCargo, submitButton, recipient, continueButton },
} = languages.pages.address;

interface AddressFormProps {
  addressName?: string;
  lastName?: string;
  firstName?: string;
  secondName?: string;
  country?: string;
  area?: string;
  city?: string;
  street?: string;
  house?: string;
  buildingHouse?: string;
  apartmentNumber?: string;
  postCode?: string;
  phoneNumber?: string;
  delivery?: string;
  onClose: Noop;
  onSubmit: (addressData: AddressFormInterface) => Promise<void>;
}

export const AddressForm = ({
  addressName,
  lastName,
  apartmentNumber,
  area,
  buildingHouse,
  city,
  country,
  delivery = 'cargo',
  firstName,
  house,
  phoneNumber,
  postCode,
  secondName,
  street,
  onClose,
  onSubmit,
}: AddressFormProps) => {
  const {
    handleSubmit,
    formState: { isValid },
    control,
    watch,
    setValue,
    getValues,
  } = useFormSchema(yupSchema);
  const isTablet = useMediaQuery(MediaSizes.Tablet);

  const isValidModalForm =
    !!addressName || !!watch('addressName') || !!getValues('addressName');

  const isMobile = useMediaQuery(MediaSizes.Mobile);

  //! DON't remove:
  //* Logic of fetching countries, regions and cities from BE
  // const countryValue = watch('country');
  // const areaValue = watch('area');
  // const cityValue = watch('city');

  // const debouncedCountryValue = useDebounce(countryValue, delayRegionAPI);
  // const debouncedAreaValue = useDebounce(areaValue, delayRegionAPI);
  // const debouncedCityValue = useDebounce(cityValue, delayRegionAPI);

  // const prevCountry = usePrevious<string | undefined>(debouncedCountryValue);
  // const prevArea = usePrevious<string | undefined>(debouncedAreaValue);

  // const { data: countriesData } = useStore(countriesList);
  // const countries = countriesData.map(({ name }) => name);
  // const activeCountry = countriesData.find(({ name }) => name === countryValue);

  // const { data: regionsData } = useStore(regionsList);
  // const regions = regionsData.map(({ name }) => name);
  // const activeRegions = regionsData.find(({ name }) => name === areaValue);

  // const { data: citiesData } = useStore(citiesList);
  // const cities = citiesData.map(({ name }) => name);

  // const loadingCountries = useStore(getCountriesList.pending);
  // const loadingRegions = useStore(getRegionsList.pending);
  // const loadingCities = useStore(getCitiesList.pending);
  const loading = useStore(formCreateAddress.pending);

  const onContinueClick = () => {
    inputModal.openModal();
  };

  const onSubmitForm = async (addressData: AddressFormInterface) => {
    await onSubmit(addressData);
  };

  //! DON't remove:
  //* Logic of fetching countries, regions and cities from BE
  // useEffect(() => {
  //   debouncedCityValue && activeCountry?.code && activeRegions?.isoCode
  //     ? getCitiesList({
  //         countryId: activeCountry?.code,
  //         stateId: activeRegions?.isoCode,
  //         namePrefix: debouncedCityValue,
  //       })
  //     : setDefaultCitiesList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedCityValue]);

  // useEffect(() => {
  //   if (prevArea !== debouncedAreaValue && prevArea !== undefined) {
  //     setValue('city', '');
  //     setDefaultCitiesList();
  //     debouncedAreaValue && activeCountry?.code
  //       ? getRegionsList({
  //           countryId: activeCountry?.code,
  //           namePrefix: debouncedAreaValue,
  //         })
  //       : setDefaultRegionsList();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedAreaValue]);

  // useEffect(() => {
  //   if (
  //     prevCountry !== debouncedCountryValue &&
  //     (!country || prevCountry !== undefined) &&
  //     debouncedCountryValue !== ''
  //   ) {
  //     setValue('area', '');
  //     setValue('city', '');
  //     setDefaultRegionsList();
  //     setDefaultCitiesList();
  //     debouncedCountryValue
  //       ? getCountriesList({ namePrefix: debouncedCountryValue })
  //       : setDefaultCountriesList();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedCountryValue]);

  useEffect(() => {
    if (country && area && city) {
      //! DON't remove:
      //setRegionValues(country, area);

      setValue('country', country);
      setValue('area', area);
      setValue('city', city);
    }

    //! DON't remove:
    // return () => {
    //   setDefaultCitiesList();
    //   setDefaultRegionsList();
    //   setDefaultCountriesList();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isMobile ? (
    <Form
      width={isTablet ? '100%' : accountTabWidth}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FormWrapper>
        <AbsoluteWrapper right="10px" top="23px">
          <CloseButton onClick={onClose} />
        </AbsoluteWrapper>
        <Section marginBottom={titleMarginBottom}>
          <TitleText>{recipient}</TitleText>
        </Section>
        <Section justifyBetween marginBottom="60px">
          <FlexGrow flexBasis="32%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={lastName}
              id="lastName"
              name="lastName"
              type="text"
              {...addressForm['lastName']}
            />
          </FlexGrow>
          <FlexGrow flexBasis="32%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={firstName}
              id="firstName"
              name="firstName"
              type="text"
              {...addressForm['firstName']}
            />
          </FlexGrow>
          <FlexGrow flexBasis="32%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={secondName}
              id="secondName"
              name="secondName"
              type="text"
              {...addressForm['secondName']}
            />
          </FlexGrow>
        </Section>
        <Section marginBottom={titleMarginBottom}>
          <TitleText>{deliveryOfCargo}</TitleText>
        </Section>
        <Section justifyBetween marginBottom={fieldsMarginBottom}>
          <FlexGrow flexBasis="32%" flexGrow="0">
            <FormTextInput
              autoComplete="off"
              control={control}
              defaultValue={country}
              id="country"
              name="country"
              type="text"
              {...addressForm['country']}
            />
            {/*  //! DON't remove:
            <FormAsyncTextInput
              autoComplete="off"
              control={control}
              defaultValue={country}
              id="country"
              isLoading={loadingCountries}
              name="country"
              suggestions={countries}
              {...addressForm['country']}
            /> */}
          </FlexGrow>
          <FlexGrow flexBasis="32%" flexGrow="0">
            <FormTextInput
              autoComplete="off"
              control={control}
              defaultValue={area}
              id="area"
              name="area"
              type="text"
              {...addressForm['area']}
            />
            {/* //! DON't remove: 
            <FormAsyncTextInput
              autoComplete="off"
              control={control}
              defaultValue={area}
              disabled={!activeCountry}
              id="area"
              isLoading={loadingRegions}
              name="area"
              suggestions={regions}
              {...addressForm['area']}
            /> */}
          </FlexGrow>
          <FlexGrow flexBasis="32%" flexGrow="0">
            <FormTextInput
              autoComplete="off"
              control={control}
              defaultValue={city}
              id="city"
              name="city"
              type="text"
              {...addressForm['city']}
            />

            {/* //! DON't remove: 
            <FormAsyncTextInput
              autoComplete="off"
              control={control}
              defaultValue={city}
              disabled={!activeRegions}
              id="city"
              isLoading={loadingCities}
              name="city"
              suggestions={cities}
              {...addressForm['city']}
            /> */}
          </FlexGrow>
        </Section>
        <Section justifyBetween marginBottom={fieldsMarginBottom}>
          <FlexGrow flexBasis="32%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={street}
              id="street"
              name="street"
              {...addressForm['street']}
            />
          </FlexGrow>
          <FlexGrow flexBasis="21%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={house}
              id="house"
              name="house"
              {...addressForm['house']}
            />
          </FlexGrow>
          <FlexGrow flexBasis="21%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={buildingHouse}
              id="buildingHouse"
              name="buildingHouse"
              {...addressForm['buildingHouse']}
            />
          </FlexGrow>
          <FlexGrow flexBasis="21%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={apartmentNumber}
              id="apartmentNumber"
              name="apartmentNumber"
              {...addressForm['apartmentNumber']}
            />
          </FlexGrow>
        </Section>
        <Section marginBottom="40px">
          <FlexGrow flexBasis="32%" flexGrow="0" marginRight="15px">
            <FormTextInput
              control={control}
              defaultValue={postCode}
              id="postCode"
              name="postCode"
              type="number"
              {...addressForm['postCode']}
            />
          </FlexGrow>
          <FlexGrow flexBasis="45%" flexGrow="0">
            <FormTextInput
              control={control}
              defaultValue={phoneNumber}
              id="phoneNumber"
              name="phoneNumber"
              {...addressForm['phoneNumber']}
            />
          </FlexGrow>
        </Section>
        <Row marginBottom="45px" maxWidth="600px">
          <FormRadio
            justifyBetween
            control={control}
            defaultSelectedValue={getDeliveryPostValue(delivery)}
            id="delivery"
            name="delivery"
            textType="default"
            widthRadioButton="220px"
            {...addressForm['delivery']}
          />
        </Row>
        <Section alignCenter justifyCenter>
          <Button disabled={!isValid} type="button" onClick={onContinueClick}>
            {continueButton}
          </Button>
        </Section>
        <InputModal>
          <Column width="100%">
            <Section marginBottom={fieldsMarginBottom}>
              <FormTextInput
                control={control}
                defaultValue={addressName}
                id="addressName"
                name="addressName"
                type="text"
                {...addressForm['addressName']}
              />
            </Section>
            <Section alignCenter justifyCenter>
              <Button
                disabled={!isValidModalForm}
                isLoading={loading}
                type="submit"
              >
                {submitButton}
              </Button>
            </Section>
          </Column>
        </InputModal>
      </FormWrapper>
    </Form>
  ) : (
    <Form
      width={isTablet ? '100%' : tabWidth}
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FormWrapper>
        <AbsoluteWrapper right="10px" top="23px">
          <CloseButton onClick={onClose} />
        </AbsoluteWrapper>
        <Section marginBottom={mobileMarginBottomLarge}>
          <TitleText>{recipient}</TitleText>
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={lastName}
            id="lastName"
            name="lastName"
            type="text"
            {...addressForm['lastName']}
          />
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={firstName}
            id="firstName"
            name="firstName"
            type="text"
            {...addressForm['firstName']}
          />
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={secondName}
            id="secondName"
            name="secondName"
            type="text"
            {...addressForm['secondName']}
          />
        </Section>

        <Section marginBottom={mobileMarginBottomLarge}>
          <TitleText>{deliveryOfCargo}</TitleText>
        </Section>

        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            autoComplete="off"
            control={control}
            defaultValue={country}
            id="country"
            name="country"
            type="text"
            {...addressForm['country']}
          />

          {/* //! DON't remove: 
           <FormAsyncTextInput
            autoComplete="off"
            control={control}
            defaultValue={country}
            id="country"
            isLoading={loadingCountries}
            name="country"
            suggestions={countries}
            {...addressForm['country']}
          /> */}
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            autoComplete="off"
            control={control}
            defaultValue={area}
            id="area"
            name="area"
            type="text"
            {...addressForm['area']}
          />
          {/* //! DON't remove: 
           <FormAsyncTextInput
            autoComplete="off"
            control={control}
            defaultValue={area}
            disabled={!activeCountry}
            id="area"
            isLoading={loadingRegions}
            name="area"
            suggestions={regions}
            {...addressForm['area']}
          /> */}
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            autoComplete="off"
            control={control}
            defaultValue={city}
            id="city"
            name="city"
            type="text"
            {...addressForm['city']}
          />

          {/* //! DON't remove: 
            <FormAsyncTextInput
            autoComplete="off"
            control={control}
            defaultValue={city}
            disabled={!activeRegions}
            id="city"
            isLoading={loadingCities}
            name="city"
            suggestions={cities}
            {...addressForm['city']}
          /> */}
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={street}
            id="street"
            name="street"
            {...addressForm['street']}
          />
        </Section>
        <Section noWrap marginBottom={mobileMarginBottom}>
          <Section marginRight="5px">
            <FormTextInput
              control={control}
              defaultValue={house}
              id="house"
              name="house"
              {...addressForm['house']}
            />
          </Section>
          <Section marginRight="5px">
            <FormTextInput
              control={control}
              defaultValue={buildingHouse}
              id="buildingHouse"
              name="buildingHouse"
              {...addressForm['buildingHouse']}
            />
          </Section>
          <Section>
            <FormTextInput
              control={control}
              defaultValue={apartmentNumber}
              id="apartmentNumber"
              name="apartmentNumber"
              {...addressForm['apartmentNumber']}
            />
          </Section>
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={postCode}
            id="postCode"
            name="postCode"
            type="number"
            {...addressForm['postCode']}
          />
        </Section>
        <Section marginBottom={mobileMarginBottom}>
          <FormTextInput
            control={control}
            defaultValue={phoneNumber}
            id="phoneNumber"
            name="phoneNumber"
            {...addressForm['phoneNumber']}
          />
        </Section>
        <FormRadio
          justifyBetween
          control={control}
          defaultSelectedValue={getDeliveryPostValue(delivery)}
          id="delivery"
          name="delivery"
          textType="default"
          widthRadioButton="220px"
          {...addressForm['delivery']}
        />
        <Section alignCenter justifyCenter>
          <Button disabled={!isValid} type="button" onClick={onContinueClick}>
            {continueButton}
          </Button>
        </Section>
        <InputModal>
          <Column width="100%">
            <Section marginBottom={fieldsMarginBottom}>
              <FormTextInput
                control={control}
                defaultValue={addressName}
                id="addressName"
                name="addressName"
                type="text"
                {...addressForm['addressName']}
              />
            </Section>
            <Section alignCenter justifyCenter>
              <Button
                disabled={!isValidModalForm}
                isLoading={loading}
                type="submit"
              >
                {submitButton}
              </Button>
            </Section>
          </Column>
        </InputModal>
      </FormWrapper>
    </Form>
  );
};
