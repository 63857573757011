import {
  buttonBorderRadius,
  buttonInnerTextFontWeight,
  buttonPadding,
} from 'components/common/buttons/SimpleButton/constants';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { white, darkBlue } from 'constants/styles/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Margin, Sizes, ZIndex } from 'types/styles';

interface ButtonWrapper extends ZIndex, Pick<Margin, 'marginLeft'>, Sizes {}

export const ButtonWrapper = styled(Row)<ButtonWrapper>`
  ${({ zIndex }) => (zIndex !== undefined ? `z-index: ${zIndex};` : '')};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`};
  ${({ width }) => width && `width: ${width}`}
`;

interface StyledLInkProps {
  isActive?: boolean;
  theme?: 'white' | 'grey';
}

export const StyledLink = styled(Link)<StyledLInkProps>`
  font-size: 14px;
  font-weight: ${buttonInnerTextFontWeight};
  height: 48px;
  width: 100%;
  padding: ${buttonPadding};
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  border-radius: ${buttonBorderRadius};
  color: ${({ isActive }) => (isActive ? white : darkBlue)};

  background-color: ${({ isActive, theme }) =>
    isActive ? darkBlue : theme === 'grey' ? '#F1F3F8' : white};
`;
