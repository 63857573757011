import { TitleText } from 'components/common/typography/TitleText';
import { accountTabWidth } from 'components/layouts/AccountLayout/constants';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { lightBlue2, whiteHover2 } from 'constants/styles/colors';
import { borderRadius, sm, tableBorder, xs } from 'constants/styles/sizes';
import styled from 'styled-components';
import { Sizes } from 'types/styles';

export const MobileTitle = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${whiteHover2};
`;

export const MobileDescription = styled.span`
  font-size: 14px;
  line-height: 17px;

  color: #ffffff;
`;

export const CardWrapper = styled(ContentWrapper)`
  width: 100%;
  max-width: ${accountTabWidth};
  padding: 22px 27px;

  @media screen and (max-width: ${sm}) {
    position: relative;
    padding: 16px 22px;
  } ;
`;

export const TableWrapper = styled(Row)<Pick<Sizes, 'width'>>`
  width: ${({ width }) => width || '100%'};
  border-radius: ${borderRadius};
  border: ${tableBorder};
  margin-bottom: 27px;
`;

export const AdaptiveTextTitle = styled(TitleText)`
  @media screen and (max-width: ${xs}) {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
  }
`;

export const AdaptiveDateTitle = styled(TitleText)`
  @media screen and (max-width: ${xs}) {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const MobileHistoryWrapper = styled.div`
  padding: 12px 14px;
  width: 100%;
  border-bottom: 1px solid ${lightBlue2};
`;
