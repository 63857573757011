import { Button } from 'components/common/buttons/Button';
import { TitleText } from 'components/common/typography/TitleText';
import { FormTextInput } from 'components/forms/inputs/FormTextInput';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { loginLink } from 'constants/routes';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
import { onSubmit, yupSchema } from 'pages/Auth/ForgotPassword/constants';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { userEffects } from 'stores/user';
import { Form } from '../styles';
import { GoBack } from './styles';

const { formRecoverPassword } = userEffects;

const {
  forgotPasswordForm,
  text: { title, submitButton, goBack },
} = languages.pages.forgotPassword;

export const ForgotPasswordForm = () => {
  const isTabletSmall = useMediaQuery(MediaSizes.TabletSmall);
  const isMobile = useMediaQuery(MediaSizes.Mobile);

  const formWidth = isMobile ? '100%' : isTabletSmall ? '80%' : '100%';

  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useFormSchema(yupSchema);

  const loading = useStore(formRecoverPassword.pending);

  return (
    <Form width={formWidth} onSubmit={handleSubmit(onSubmit)}>
      <Section alignCenter justifyCenter marginBottom="40px">
        <TitleText>{title}</TitleText>
      </Section>
      <Section marginBottom="45px">
        <FormTextInput
          control={control}
          id="email"
          name="email"
          type="email"
          {...forgotPasswordForm['email']}
        />
      </Section>
      <Section alignCenter justifyCenter marginBottom="15px">
        <Button disabled={!isValid} isLoading={loading} type="submit">
          {submitButton}
        </Button>
      </Section>
      <Section alignCenter justifyCenter>
        <GoBack>
          <Link to={loginLink}>{goBack}</Link>
        </GoBack>
      </Section>
    </Form>
  );
};
