import { Button } from 'components/common/buttons/Button';
import { SocialButton } from 'components/common/buttons/SocialButton';
import { TitleText } from 'components/common/typography/TitleText';
import { FormConfirmCheckbox } from 'components/forms/inputs/FormConfirmCheckbox';
import {
  formInputMarginBottom,
  yupSchema,
} from 'components/forms/RegisterForm/constants';
import {
  Column,
  FlexGrow,
  Row,
  Section,
} from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
import { SignUp } from 'pages/Auth/Register/types';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { userEffects } from 'stores/user';
import { SocialMediaEnum } from 'types/types';
import { authenticateWithSocialMedia } from 'utils/browser.api';
import { getRegistrationData } from 'utils/data';
import { FormTextInput } from '../inputs/FormTextInput';
import { Form } from '../styles';

declare global {
  interface Window {
    ym: any;
  }
}

const {
  registerForm,
  text: { or, submitButton },
} = languages.pages.register;

const { formCreateUser } = userEffects;

export const RegisterForm = () => {
  const {
    handleSubmit,
    formState: { isValid },
    control,
  } = useFormSchema(yupSchema);
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const marginBetween = isMobile ? '5px' : '10px';
  const loading = useStore(formCreateUser.pending);

  const onSubmit = async ({
    email,
    firstName,
    password,
    phoneNumber,
    lastName,
  }: SignUp) => {
    await formCreateUser({
      email,
      firstName,
      password,
      phone: phoneNumber,
      lastName,
    });
  };

  useEffect(() => {
    getRegistrationData();
  }, []);

  return (
    <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
      <Section justifyBetween marginBottom={formInputMarginBottom}>
        <FlexGrow flexBasis="48%" flexGrow="0">
          <FormTextInput
            control={control}
            id="lastName"
            name="lastName"
            type="text"
            {...registerForm['lastName']}
          />
        </FlexGrow>
        <FlexGrow flexBasis="48%" flexGrow="0">
          <FormTextInput
            control={control}
            id="firstName"
            name="firstName"
            type="text"
            {...registerForm['firstName']}
          />
        </FlexGrow>
      </Section>
      <Section marginBottom={formInputMarginBottom}>
        <FormTextInput
          control={control}
          id="phoneNumber"
          name="phoneNumber"
          {...registerForm['phoneNumber']}
        />
      </Section>
      <Section marginBottom={formInputMarginBottom}>
        <FormTextInput
          autoComplete="off"
          control={control}
          id="email"
          name="email"
          type="email"
          {...registerForm['email']}
        />
      </Section>
      <Section marginBottom="10px">
        <FormTextInput
          autoComplete="new-password"
          control={control}
          id="password"
          name="password"
          type="password"
          {...registerForm['password']}
        />
      </Section>
      <Section marginBottom="20px">
        <Column alignCenter width="100%">
          <Row marginBottom="10px">
            <TitleText>{or}</TitleText>
          </Row>
          <Section>
            <FlexGrow alignCenter marginRight={marginBetween}>
              <SocialButton
                buttonType="vk"
                type="button"
                onClick={() => {
                  window.ym(88733081, 'reachGoal', 'reg1');
                  authenticateWithSocialMedia(SocialMediaEnum.Vkontakte);
                  return true;
                }}
              />
            </FlexGrow>
            <FlexGrow alignCenter marginLeft={marginBetween}>
              <SocialButton
                buttonType="fb"
                type="button"
                onClick={() => {
                  window.ym(88733081, 'reachGoal', 'reg1');
                  authenticateWithSocialMedia(SocialMediaEnum.Facebook);
                  return true;
                }}
              />
            </FlexGrow>
          </Section>
        </Column>
      </Section>
      <Section alignCenter marginBottom="20px">
        <FormConfirmCheckbox
          control={control}
          id="political"
          name="political"
          width="fit-content"
          {...registerForm['political']}
        />
      </Section>
      <Section alignCenter justifyCenter>
        <Button
          disabled={!isValid}
          isLoading={loading}
          type="submit"
          onClick={() => {
            window.ym(88733081, 'reachGoal', 'reg1');
            return true;
          }}
        >
          {submitButton}
        </Button>
      </Section>
    </Form>
  );
};
