import { CloseModalButton } from 'components/common/buttons/ImageButtons';
import { defaultContentWrapperPadding } from 'components/modals/ModalWrapper/constants';
import { ContentWrapperProps } from 'components/wrappers/ContentWrapper';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import React, { FC } from 'react';
import { Visibility, ZIndex } from 'types/styles';
import { Noop } from 'types/types';
import { Modal, ModalContent, ModalContentWrapper } from './styles';

export interface ContentWrapperInterface
  extends Pick<
    ContentWrapperProps,
    | 'width'
    | 'height'
    | 'backgroundColor'
    | 'borderRadius'
    | 'minHeight'
    | 'padding'
  > {}

export interface ModalWrapperProps
  extends Visibility,
    ContentWrapperInterface,
    ZIndex {
  contentPadding?: string;
  onCloseClick?: Noop;
  modalBackgroundColor?: string;
}

export const ModalWrapper: FC<ModalWrapperProps> = ({
  children,
  visible,
  contentPadding,
  onCloseClick,
  padding = defaultContentWrapperPadding,
  modalBackgroundColor,
  zIndex,
  ...contentWrapperProps
}) => {
  const backgroundColor = contentWrapperProps.backgroundColor;

  if (!visible) return null;

  return (
    <Modal modalBackgroundColor={modalBackgroundColor} zIndex={zIndex}>
      <ModalContentWrapper padding={padding} {...contentWrapperProps}>
        <AbsoluteWrapper right="50px" top="10px" zIndex="2">
          {onCloseClick ? (
            <CloseModalButton
              backgroundColor={backgroundColor}
              onClick={onCloseClick}
            />
          ) : null}
        </AbsoluteWrapper>

        <ModalContent padding={contentPadding}>{children}</ModalContent>
      </ModalContentWrapper>
    </Modal>
  );
};
