import { Span } from 'components/common/typography/Span';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import { darkBlue, grey2, white } from 'constants/styles/colors';
import { defaultTextColor } from 'constants/styles/default';
import {
  disableDefaultButtonStyleMixin,
  disableDefaultInputStyleMixin,
  flexCenter,
  flexStart,
} from 'constants/styles/mixins';
import styled, { css } from 'styled-components';
import { Active } from 'types/global';
import { PaddingLeft } from 'types/styles';
import {
  borderItemColor,
  hoveredColor,
  itemHeight,
  lastItemBorderRadius,
  paginationCellActiveBackground,
  paginationCellBackground,
  paginationCellBorderRadius,
  paginationCellColor,
  PaginationCellFontSize,
  PaginationCellFontWeight,
  paginationCellHeight,
  PaginationCellLineHeight,
  PaginationCellMarginRight,
  paginationCellWidth,
  paginationInputHeight,
  paginationInputPadding,
  paginationInputWidth,
  paginationWrapperMarginRight,
  selectedColor,
  selectedTextColor,
} from './constants';
import { ArrowProps, PaginationCellProps } from './types';

export const PaginationCell = styled.button<PaginationCellProps>`
  ${disableDefaultButtonStyleMixin};
  ${flexCenter};
  border-radius: ${paginationCellBorderRadius};
  min-width: ${paginationCellWidth};
  height: ${paginationCellHeight};
  padding: 8px 8px;
  background-color: ${({ active }) =>
    active ? paginationCellActiveBackground : paginationCellBackground};
  font-weight: 500;
  font-size: ${PaginationCellFontSize};
  line-height: ${PaginationCellLineHeight};
  color: ${({ active }) => (active ? white : paginationCellColor)};
  margin-right: ${PaginationCellMarginRight};
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `};
`;

export const Arrow = styled(PaginationCell)<ArrowProps>`
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
`;

export const PaginationWrapper = styled.div`
  ${flexStart};
  margin-right: ${paginationWrapperMarginRight};
  border-radius: ${paginationCellBorderRadius};
`;

export const PaginationInput = styled.input`
  ${disableDefaultInputStyleMixin};
  height: ${paginationInputHeight};
  width: ${paginationInputWidth};
  padding: 2px ${paginationInputPadding};
  box-sizing: border-box;
  border-radius: ${paginationCellBorderRadius};
  font-weight: ${PaginationCellFontWeight};
  font-size: ${PaginationCellFontSize};
  line-height: ${PaginationCellLineHeight};
  background-color: ${paginationCellBackground};
  border: 1px solid rgba(0, 0, 0, 0);

  :focus-within {
    border: 1px solid ${darkBlue};
  }
`;

export const ItemsAbsoluteWrapper = styled(AbsoluteWrapper)`
  background-color: ${white};
  //border: 1px solid ${grey2};
  border-radius: ${lastItemBorderRadius} ${lastItemBorderRadius} 0 0;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.24);
`;

interface ItemWrapperProps extends PaddingLeft, Active {}

export const ItemSpan = styled(Span)`
  font-weight: ${PaginationCellFontWeight};
  font-size: ${PaginationCellFontSize};
  line-height: ${PaginationCellLineHeight};
`;

export const ItemWrapper = styled.div<ItemWrapperProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${itemHeight};
  width: 100%;
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
  background-color: ${({ active }) => (active ? selectedColor : 'transparent')};
  border-bottom: 1px solid ${borderItemColor};

  ${ItemSpan} {
    color: ${({ active }) => (active ? selectedTextColor : defaultTextColor)};
  }

  :hover {
    background-color: ${hoveredColor};
    cursor: pointer;
    ${ItemSpan} {
      color: ${selectedTextColor};
    }
  }

  :first-child {
    border-bottom: 0;
    border-radius: ${lastItemBorderRadius} ${lastItemBorderRadius} 0 0;
  }
`;
