import { AddNewOrderButton } from 'components/common/buttons/AddNewOrderButton';
import { Loader } from 'components/dynamic/Loader';
import { AccountLayout } from 'components/layouts/AccountLayout';
import { OrderCard } from 'components/layouts/cards/OrderCard';
import { OrdersLayout } from 'components/layouts/OrdersLayout';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ordersEffects, ordersStores } from 'stores/orders';

const { loadItems } = ordersEffects;

export const Orders = () => {
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const loading = useStore(loadItems.pending);
  const { result, totalPages } = useStore(ordersStores.orders);
  return (
    <AccountLayout>
      <OrdersLayout totalPages={totalPages}>
        {loading ? (
          <Section alignCenter justifyCenter height="600px">
            <Loader />
          </Section>
        ) : !result.length ? (
          <ContentWrapper height={isMobile ? '88px' : '220px'} width="100%">
            <Section alignCenter justifyCenter height="100%">
              <AddNewOrderButton />
            </Section>
          </ContentWrapper>
        ) : (
          result.map(
            ({
              insurance,
              _id,
              orderId,
              status,
              createdAt,
              totalPrice,
              method,
              category,
              packageType,
              dimensions,
              weight,
              volume,
              orderHistory,
              services,
              currency,
              attachments,

              address: {
                country,
                postCode,
                buildingNumber,
                apartmentNumber,
                region,
                city,
                street,
                frameNumber,
                firstName,
                middleName,
                lastName,
              },
              pickUpOrder,
            }) => (
              <Section key={_id} marginBottom="10px">
                <OrderCard
                  amount={totalPrice}
                  apartmentNumber={apartmentNumber}
                  area={region}
                  attachments={attachments}
                  category={category.title}
                  city={city}
                  country={country}
                  currency={currency}
                  date={createdAt}
                  dimensions={dimensions}
                  extraService={services
                    .map((service) => service.title)
                    .join(', ')}
                  firstName={firstName}
                  history={orderHistory[orderHistory.length - 1]}
                  house={buildingNumber}
                  houseBuilding={frameNumber}
                  insurance={!!insurance?.percent}
                  lastName={lastName}
                  method={method?.title || ''}
                  middleName={middleName}
                  orderId={orderId || ' - '}
                  originalId={_id}
                  packaging={packageType?.title}
                  pickUpOrder={pickUpOrder}
                  postCode={postCode}
                  status={status}
                  street={street}
                  volume={volume}
                  weight={weight}
                />
              </Section>
            ),
          )
        )}
      </OrdersLayout>
    </AccountLayout>
  );
};
