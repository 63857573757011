import { darkBlue } from 'constants/styles/colors';
import { ContentWrapperInterface } from 'components/modals/ModalWrapper';

export const contentWrapperProps: ContentWrapperInterface = {
  minHeight: '190px',
  width: '500px',
  backgroundColor: darkBlue,
  borderRadius: '10px',
  padding: '30px 35px 35px',
};
