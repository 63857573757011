import { OriginContentWrapper } from 'components/wrappers/ContentWrapper';
import { RelativeWrapper } from 'components/wrappers/grid/RelativeWrapper';
import { ParagraphWrapper } from 'components/wrappers/ParagraphWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { useFindTopYPositionItem } from 'hooks/findTopYPositionItem';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory } from 'react-router';
import { sidebarItems } from './constants';
import {
  MainTitle,
  Paragraph,
  SidebarItem,
  SidebarWrapper,
  Wrapper,
} from './styles';

export const PrivacyPolicy = () => {
  const history = useHistory();

  const [activeRoute, setActiveRoute] = useState(sidebarItems[0].id);
  const titleIds = sidebarItems.map((it) => it.id);
  const activeTopIndex = useFindTopYPositionItem(titleIds);

  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const isTablet = useMediaQuery(MediaSizes.Tablet);

  useEffect(() => {
    if (isTablet) return;

    history.push(`#${sidebarItems[activeTopIndex].id}`);
    setActiveRoute(sidebarItems[activeTopIndex].id);
  }, [activeTopIndex, history, isTablet]);

  return (
    <Wrapper>
      {!isTablet && (
        <SidebarWrapper>
          {sidebarItems.map(({ title, id }) => (
            <SidebarItem
              key={title}
              active={activeRoute === id}
              href={`#${id}`}
              onClick={() => {
                setActiveRoute(id);
              }}
            >
              {title}
            </SidebarItem>
          ))}
        </SidebarWrapper>
      )}
      <RelativeWrapper>
        <OriginContentWrapper
          backgroundColor="white"
          borderRadius="10px"
          maxWidth="889px"
          padding={isMobile ? '22px 14px' : '42px'}
          width="100%"
        >
          <MainTitle>
            Политика в отношении обработки персональных данных
          </MainTitle>
          {sidebarItems.map(({ title, id, text }, i) => (
            <ParagraphWrapper
              key={id}
              id={id}
              lastItem={sidebarItems.length - 1 === i}
              title={title}
              titleFontSize="16px"
            >
              <Paragraph>{text}</Paragraph>
            </ParagraphWrapper>
          ))}
        </OriginContentWrapper>
      </RelativeWrapper>
    </Wrapper>
  );
};
