import * as Yup from 'yup';
import { yupDefault } from 'constants/yupFields';

export const contentMarginBottom = '20px';

export const yupSchema = Yup.object().shape({
  country: yupDefault,
  subcategory: yupDefault,
  method: yupDefault,
});
