import styled from 'styled-components';
import { black, raspberry, grey7 } from 'constants/styles/colors';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import { Visibility } from 'types/styles';
import { Span } from 'components/common/typography/Span';

export const TitleText = styled.span`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${black};

  @media screen and (max-width: 400px) {
    font-size: 20px;
  }
`;

export const TextWrapper = styled(Row)`
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: ${black};

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`;

export const ToggleWrapper = styled(Row)`
  margin: 20px 0 16px;
  align-items: center;
  opacity: 0.5;
`;

export const SupplementaryText = styled(TextWrapper)`
  color: ${grey7};
  margin-right: 10px;
`;

export const ErrorMessage = styled(Column)<Visibility>`
  max-width: 404px;
  padding: 12px;
  overflow: auto;
  background-color: rgba(250, 226, 226, 0.52);
  border-radius: 3px;

  ${({ visible }) => !visible && 'display: none;'};

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: rgba(250, 226, 226, 0.52);
  }

  ::-webkit-scrollbar-thumb {
    background: #fdd0d0;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #f9bfbf;
  }
`;

export const StyledSpan = styled(Span)`
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.7px;
  color: ${raspberry};

  @media screen and (max-width: 400px) {
    font-size: 14px;
    line-height: 20px;
  }
}`;
