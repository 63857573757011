import { AccountLayout } from 'components/layouts/AccountLayout';
import React, { useEffect } from 'react';
import { PersonalForm } from 'components/forms/PersonalForm';
import { userEffects, userStores } from 'stores/user';
import { useStore } from 'effector-react';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { Loader } from 'components/dynamic/Loader';
import { ChangePasswordForm } from 'components/forms/ChangePasswordForm';

const { getUserProfile } = userEffects;

export const Personal = () => {
  const { email, phone, weChatId, userProfile, isVerified, telegramNumber } =
    useStore(userStores.userProfile);

  const firstName = userProfile?.firstName;
  const lastName = userProfile?.lastName;
  const dateOfBirth = userProfile?.dateOfBirth;
  const gender = userProfile?.gender || undefined;

  const loading = useStore(getUserProfile.pending);

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AccountLayout>
      {loading ? (
        <Section alignCenter justifyCenter height="600px">
          <Loader />
        </Section>
      ) : (
        <>
          <Section marginBottom="10px">
            <PersonalForm
              dateOfBirth={dateOfBirth}
              email={email}
              firstName={firstName}
              gender={gender}
              isVerified={isVerified}
              lastName={lastName}
              phone={phone}
              telegram={telegramNumber}
              weChatId={weChatId}
            />
          </Section>
          <ChangePasswordForm />
        </>
      )}
    </AccountLayout>
  );
};
