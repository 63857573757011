import stepStatusIcon from 'assets/icons/step-status-icon.svg';
import { StepBackButton } from 'components/common/buttons/ImageButtons';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { noop } from 'constants/functions';
import { MediaSizes } from 'constants/styles/sizes';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { iconDiameter } from './constants';
import { StatusWrapper, Step, StepButton, StepsWrapper } from './styles';

interface StepStatusProps {
  wasSaved?: boolean;
}

export const StepStatus: FC<StepStatusProps> = ({
  children,
  wasSaved = false,
}) => {
  const isTabletSmall = useMediaQuery(MediaSizes.TabletSmall);

  return !isTabletSmall ? (
    <>
      {wasSaved && (
        <AbsoluteWrapper bottom="20px" right="147px" zIndex="10">
          <CustomImage
            height={iconDiameter}
            src={stepStatusIcon}
            width={iconDiameter}
          />
        </AbsoluteWrapper>
      )}
      {children}
    </>
  ) : (
    <>
      {wasSaved && (
        <AbsoluteWrapper left="-8px" top="-20px" zIndex="10">
          <CustomImage
            height={iconDiameter}
            src={stepStatusIcon}
            width={iconDiameter}
          />
        </AbsoluteWrapper>
      )}
      {children}
    </>
  );
};

interface WizardStatusProps {
  totalSteps: number;
  activeStep: number;
  onStepClick?: (index: number) => void;
  // onNext: (index: number) => void;
  onBack: (index: number) => void;
}

export const WizardStatus = ({
  totalSteps,
  activeStep,
  onStepClick,
  onBack,
}: WizardStatusProps) => {
  const onStepButtonClick = (index: number) => () =>
    onStepClick ? onStepClick(index) : noop();

  return (
    <StatusWrapper>
      <Row marginRight="10px">
        <StepBackButton
          disabled={!activeStep}
          onClick={() => onBack(activeStep - 1)}
        />
      </Row>
      <StepsWrapper justifyBetween flexDirection="row">
        {new Array(totalSteps).fill('').map((_, index) => {
          const disabled = activeStep < index;
          const stepWasSaved = !disabled && index > 0 && index < totalSteps;
          return (
            <Step
              key={index.toString()}
              disabled={disabled}
              zIndex={(totalSteps - index).toString()}
            >
              <StepStatus wasSaved={stepWasSaved}>
                <StepButton
                  disabled={disabled}
                  wasSaved={stepWasSaved}
                  onClick={onStepButtonClick(index)}
                >
                  {index + 1}
                </StepButton>
              </StepStatus>
            </Step>
          );
        })}
      </StepsWrapper>
    </StatusWrapper>
  );
};
