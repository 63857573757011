import React from 'react';
import { LoaderWrapper } from './styles';

export const Loader = () => (
  <LoaderWrapper>
    <div className="circleG" id="circleG_1" />
    <div className="circleG" id="circleG_2" />
    <div className="circleG" id="circleG_3" />
  </LoaderWrapper>
);
