import React, { useEffect } from 'react';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { FormTextInput } from 'components/forms/inputs/FormTextInput';
import { Button } from 'components/common/buttons/Button';
import { useFormSchema } from 'hooks/form';
import { onSubmit, yupSchema } from 'pages/Auth/ResetPassword/constants';
import { languages } from 'constants/languages';
import { useStore } from 'effector-react';
import { userEffects } from 'stores/user';
import { TitleText } from 'components/common/typography/TitleText';
import { Form } from '../styles';
import { useMediaQuery } from 'react-responsive';
import { MediaSizes } from 'constants/styles/sizes';

const {
  resetPasswordForm,
  text: { title, submitButton },
} = languages.pages.resetPassword;

const { formRestorePassword } = userEffects;

interface ResetPasswordFormProps {
  token?: string;
}

export const ResetPasswordForm = ({ token }: ResetPasswordFormProps) => {
  const isTabletSmall = useMediaQuery(MediaSizes.TabletSmall);
  const isMobile = useMediaQuery(MediaSizes.Mobile);

  const formWidth = isMobile ? '100%' : isTabletSmall ? '80%' : '100%';

  const {
    handleSubmit,
    formState: { isValid },
    control,
    setValue,
  } = useFormSchema(yupSchema);

  const loading = useStore(formRestorePassword.pending);

  useEffect(() => {
    setValue('confirmationToken', token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Form width={formWidth} onSubmit={handleSubmit(onSubmit)}>
      <Section alignCenter justifyCenter marginBottom="40px">
        <TitleText>{title}</TitleText>
      </Section>
      <Section marginBottom="45px">
        <FormTextInput
          autoComplete="new-password"
          control={control}
          id="password"
          name="password"
          type="password"
          {...resetPasswordForm['password']}
        />
      </Section>
      <Section marginBottom="45px">
        <FormTextInput
          autoComplete="new-password"
          control={control}
          id="repeatPassword"
          name="repeatPassword"
          type="password"
          {...resetPasswordForm['repeatPassword']}
        />
      </Section>
      <Section alignCenter justifyCenter marginBottom="15px">
        <Button disabled={!isValid} isLoading={loading} type="submit">
          {submitButton}
        </Button>
      </Section>
    </Form>
  );
};
