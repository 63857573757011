import { raspberry, white } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';
import { checkMarkDiameter, radioButtonDiameter } from './constants';

export const HiddenRadioButton = styled.input.attrs({ type: 'radio' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Label = styled.label`
  ${flexCenter};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
`;

interface RadioButtonProps {
  checked?: boolean;
}

export const VisibleRadioButton = styled.div<RadioButtonProps>`
  width: ${radioButtonDiameter};
  height: ${radioButtonDiameter};
  background-color: ${white};
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;
  ${flexCenter};
  min-width: ${radioButtonDiameter};

  &:after {
    content: '';
    width: ${checkMarkDiameter};
    height: ${checkMarkDiameter};
    background-color: ${raspberry};
    border-radius: 50%;
    cursor: pointer;
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
`;
