import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import {
  checkboxBorderRadius,
  checkboxDiameter,
  largeCheckboxDiameter,
} from 'components/common/inputs/Checkbox/constants';
import {
  CheckboxProps,
  CheckboxTypeInterface,
} from 'components/common/inputs/Checkbox/types';
import { backgroundColor, grey2, white } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import { transitionTime } from 'constants/styles/others';
import { borderWidth } from 'constants/styles/sizes';
import styled, { css } from 'styled-components';
import { CheckboxTextMixin } from 'components/common/typography/CheckboxText';

export const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const Label = styled.label`
  ${flexCenter};
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const VisibleCheckbox = styled.div<CheckboxProps>`
  position: relative;
  width: ${checkboxDiameter};
  height: ${checkboxDiameter};
  background: transparent;
  border-radius: ${checkboxBorderRadius};
  border: ${borderWidth} solid ${white};
  transition: ${transitionTime};
  cursor: pointer;
  ${({ disabled }) =>
    disabled
      ? `background: '${backgroundColor}'; border-color: ${grey2}; cursor: not-allowed`
      : ''};
  ${CustomImage} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }
  ${({ type }) =>
    type === 'large' &&
    css`
      width: ${largeCheckboxDiameter};
      height: ${largeCheckboxDiameter};
      background-color: white;
    `}
`;

export const LabelText = styled.label<CheckboxTypeInterface>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  ${CheckboxTextMixin}
  ${({ textType }) =>
    textType === 'large' &&
    css`
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    `}
`;
