import { InputWrapper as InputOriginWrapper } from 'components/common/inputs/Input/styles';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { white } from 'constants/styles/colors';
import styled from 'styled-components';

interface InputLabelProps {
  isSecondary?: boolean;
}

export const InputLabel = styled.label<InputLabelProps>`
  font-size: 16px;
  line-height: 24px;
  color: ${({ isSecondary }) =>
    isSecondary ? 'rgba(255, 255, 255, 0.35)' : white};
`;

export const ErrorText = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: white;
`;

export const InputWrapper = styled(Column)`
  width: 100%;

  label {
    margin-bottom: 5px;
  }

  ${InputOriginWrapper} {
    margin-bottom: 9px;
  }

  div:last-child {
    margin-bottom: 0;
  }
`;
