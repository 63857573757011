import { lightgrey, white } from 'constants/styles/colors';
import styled from 'styled-components';

export const TextButton = styled.button`
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${lightgrey};
  transition: color 0.3s;

  :not(:disabled):hover {
    color: ${white};
  }

  :disabled {
    cursor: not-allowed;
  }
`;
