import { Section } from 'components/wrappers/grid/FlexWrapper';
import { darkBlue2, grey4, grey8 } from 'constants/styles/colors';
import { lg } from 'constants/styles/sizes';
import styled from 'styled-components';
import { Active } from 'types/global';
import { TextProperties } from 'types/styles';

export const ContentItemWrapper = styled.div`
  margin-bottom: 40px;
  @media screen and (max-width: ${lg}) {
    margin-bottom: 0;
    }
  }
`;

interface LastItem {
  lastItem?: boolean;
}

interface ContentMobileWrapperProps extends Active, LastItem {}

export const ContentMobileWrapper = styled.div<ContentMobileWrapperProps>`
  @media screen and (max-width: ${lg}) {
    max-height: ${({ active }) => (active ? ' 10000px' : '0')};
    ${({ active }) => (active ? 'padding-top: 12px' : 'padding-top: 0')};
    ${({ lastItem, active }) =>
      !lastItem && active && ` border-bottom: 2px solid ${grey4}`};
    overflow: hidden;
    transition: max-height, padding-top 0.4s;
  }
`;

// TODO need new realized before

export const TitleWrapper = styled(Section)<ContentMobileWrapperProps>`
  @media screen and (max-width: ${lg}) {
    position: relative;
    padding: 12px 0;
    border-bottom: 2px solid ${grey4};

    ${({ lastItem, active }) => lastItem && !active && 'border-bottom:none'};

    &:before {
      content: '';
      position: absolute;
      right: 2px;
      top: ${({ active }) => (active ? 'calc(50% - 4px)' : 'calc(50% - 8px)')};

      width: 8px;
      height: 8px;

      border-top: 2px solid ${grey8};
      border-right: 2px solid ${grey8};

      transform: ${({ active }) =>
        active ? 'rotate(-45deg)' : 'rotate(135deg)'};
    }
  }
`;

export const Title = styled.h2<Pick<TextProperties, 'fontSize'>>`
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: ${({ fontSize }) => fontSize || '22px'};
  line-height: 24px;
  color: ${darkBlue2};

  margin-bottom: 30px;

  @media screen and (max-width: ${lg}) {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    padding-right: 15px;
  }
`;
