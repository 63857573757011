import {
  defaultFontFamily,
  defaultFontSize,
  defaultFontStyle,
  defaultFontWeight,
  defaultLineHeight,
  defaultTextColor,
} from 'constants/styles/default';
import { ellipsisMixin } from 'constants/styles/mixins';
import styled from 'styled-components';
import { Color, Opacity, Padding, TextProperties } from 'types/styles';

export interface SpanProps extends Color, TextProperties, Opacity, Padding {}

export const Span = styled.span<SpanProps>`
  font-family: ${({ fontFamily }) => fontFamily || defaultFontFamily};
  font-style: ${({ fontStyle }) => fontStyle || defaultFontStyle};
  font-weight: ${({ fontWeight }) => fontWeight || defaultFontWeight};
  font-size: ${({ fontSize }) => fontSize || defaultFontSize};
  line-height: ${({ lineHeight }) => lineHeight || defaultLineHeight};
  color: ${({ color }) => color || defaultTextColor};
  ${({ noWrap }) => (noWrap ? 'white-space: nowrap' : '')};
  ${({ opacity }) => (opacity ? `opacity: ${opacity};` : '')};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ alignCenter }) => (alignCenter ? 'text-align: center' : '')}
  ${ellipsisMixin}
`;
