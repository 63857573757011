import styled from 'styled-components';
import { Section } from 'components/wrappers/grid/FlexWrapper';

export const LayoutWrapper = styled(Section)`
  flex-direction: column;
`;

export const PaginationWrapper = styled.div`
  //position: fixed;
  width: 100%;
  //bottom: 30px;
  //left: 0;
  margin-top: 30px;
`;
