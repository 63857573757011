import styled from 'styled-components';
import { raspberry, white, paleBlue } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  width: 280px;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${white};
  border-radius: 40px;
  background-color: ${raspberry};
  transition: 0.3s ease-in-out;
  cursor: pointer;
  ${flexCenter};

  :hover {
    background-color: ${paleBlue};
  }
  :active {
    background-color: ${raspberry};
  }
`;
