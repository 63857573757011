import { darkBlue } from 'constants/styles/colors';
import { lg, md } from 'constants/styles/sizes';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  width: 100%;
  background-color: ${darkBlue};
  padding: 29px 0;

  @media screen and (max-width: ${lg}) {
    padding: 0;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
  }
`;

export const HeaderContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 910px;
  margin: 0 auto;

  @media screen and (max-width: ${lg}) {
    width: 100%;
    padding: 0 50px;
  }

  @media screen and (max-width: ${md}) {
    padding: 0 20px;
  }
`;
