import {
  contentPadding,
  contentWrapperProps,
} from 'components/modals/InsuranceModal/constants';
import { ListContentWrapper } from 'components/modals/InsuranceModal/styles';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { insuranceModal } from 'stores/initialize/initialize.modal.store';

const { content } = languages.modals.insuranceModal;

export const InsuranceModal = () => {
  const { visible } = useStore(insuranceModal.modal);
  const isMobile = useMediaQuery(MediaSizes.Mobile);

  const onCloseClick = () => {
    insuranceModal.closeModal();
  };

  return (
    <ModalWrapper
      contentPadding={isMobile ? '0' : contentPadding}
      visible={visible}
      onCloseClick={onCloseClick}
      {...contentWrapperProps}
    >
      <ListContentWrapper dangerouslySetInnerHTML={{ __html: content }} />
    </ModalWrapper>
  );
};
