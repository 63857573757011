import history from 'browserHistory';
import { MainLayout } from 'components/layouts/MainLayout';
import { ConfirmationModal } from 'components/modals/ConfirmationModal';
import { ConfirmEmailModal } from 'components/modals/ConfirmEmailModal';
import { ErrorModal } from 'components/modals/ErrorModal';
import { InformationModal } from 'components/modals/InformationModal';
import { InputEmailModal } from 'components/modals/InputEmailModal';
import {
  accountLink,
  addressesLink,
  addressLink,
  approximateCostCalculatorLink,
  confirmationLink,
  error401Link,
  error403Link,
  forgotPasswordLink,
  homeLink,
  loginLink,
  newAddressLink,
  ordersLink,
  personalLink,
  privacyPolicyLink,
  registerLink,
  resetPasswordLink,
  shipmentsLink,
  socialLink,
  supportLink,
} from 'constants/routes';
import { GlobalStyle } from 'constants/styles/global';
import { Error401 } from 'pages/401';
import { Error403 } from 'pages/403';
import { Addresses } from 'pages/Account/Addresses';
import { Address } from 'pages/Account/Addresses/Address';
import { Orders } from 'pages/Account/Orders';
import { Personal } from 'pages/Account/Personal';
import { ApproximateCostCalculator } from 'pages/ApproximateCostCalculator';
import { Confirmation } from 'pages/Auth/Confirmation';
import { ForgotPassword } from 'pages/Auth/ForgotPassword';
import { Login } from 'pages/Auth/Login';
import { Register } from 'pages/Auth/Register';
import { ResetPassword } from 'pages/Auth/ResetPassword';
import { SocialAuth } from 'pages/Auth/SocialAuth';
import { PrivacyPolicy } from 'pages/PrivacyPolicy';
import { Shipments } from 'pages/Shipments';
import { Support } from 'pages/Support';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import { AuthRoute } from 'routes/AuthRoute';
import { TokenProtectedRoute } from 'routes/TokenProtectedRoute';

const App = () => (
  // useSaveScrollPositionAfterFullscreenMode();

  <>
    <GlobalStyle />
    <ErrorModal />
    <ConfirmationModal />
    <InformationModal />
    <ConfirmEmailModal />
    {/* <AdaptiveNotificationModal /> */}
    {/*<AppLayout>*/}
    <Router history={history}>
      <InputEmailModal />
      <MainLayout>
        <Switch>
          {/* Public route */}
          <Route exact component={Confirmation} path={confirmationLink} />
          <Route exact component={PrivacyPolicy} path={privacyPolicyLink} />
          {/* * Auth */}
          <AuthRoute exact component={Register} path={registerLink} />
          <AuthRoute exact component={Login} path={loginLink} />
          <AuthRoute
            exact
            component={ForgotPassword}
            path={forgotPasswordLink}
          />
          <AuthRoute exact component={ResetPassword} path={resetPasswordLink} />
          <AuthRoute exact component={SocialAuth} path={socialLink} />

          {/* * Account */}
          <TokenProtectedRoute
            exact
            notProtect
            component={Personal}
            path={personalLink}
          />
          <TokenProtectedRoute
            exact
            component={Addresses}
            path={addressesLink}
          />
          <TokenProtectedRoute
            exact
            component={Address}
            path={[addressLink, newAddressLink]}
          />
          <TokenProtectedRoute exact component={Orders} path={ordersLink} />

          <TokenProtectedRoute
            exact
            component={Shipments}
            path={shipmentsLink}
          />
          <TokenProtectedRoute exact component={Support} path={supportLink} />

          {/* * calculator */}
          <Route
            exact
            component={ApproximateCostCalculator}
            path={approximateCostCalculatorLink}
          />

          {/* * error status */}
          <Route exact component={Error403} path={error403Link} />
          <Route exact component={Error401} path={error401Link} />

          <Redirect from={homeLink} to={accountLink} />
          <Redirect from={accountLink} to={personalLink} />
          <Redirect to={homeLink} />
        </Switch>
      </MainLayout>
    </Router>
    {/*</AppLayout>*/}
  </>
);

export default App;
