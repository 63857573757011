import { Button } from 'components/common/buttons/Button';
import { FileDownloadButton } from 'components/common/buttons/FileDownloadButton';
import {
  AttachedFilesButton,
  ToggleArrowButton,
} from 'components/common/buttons/ImageButtons';
import { MobileTable } from 'components/common/tables/MobileTable';
import { Table } from 'components/common/tables/Table';
import { TitleText } from 'components/common/typography/TitleText';
import { mobileMarginBottom } from 'components/forms/AddressForm/constants';
import { TitleModal } from 'components/modals/TitleModal';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import { Column, Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { format } from 'date-fns';
import { useToggle } from 'hooks/toggle';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { titleModal } from 'stores/initialize/initialize.modal.store';
import { modalEvents } from 'stores/modals/asyncModal';
import { ordersEffects } from 'stores/orders';
import { DataTable, Expanded } from 'types/data';
import { OrderCurrency, OrderStatuses } from 'types/types';
import { getOrderStatus } from 'utils/data';
import { orderStatusesAllowedDeletion } from './constants';
import {
  AdaptiveDateTitle,
  AdaptiveTextTitle,
  CardWrapper,
  MobileDescription,
  MobileHistoryWrapper,
  MobileTitle,
  TableWrapper,
} from './styles';

const { titleOrder, okText, closeText } = languages.modals.confirmationModal;
const { formDeleteOrderById } = ordersEffects;

const {
  historyTableColumns,
  amountTableColumns,
  cityTableColumns,
  houseTableColumns,
  termsTableColumns,
  packagingTableColumns,
  extraServiceTableColumns,
  cargoPickUpAddressColumns,
  cargoPickUpDateAndPhoneColumns,
  insuranceStatus,
} = languages.layouts.cards.orderCard;

// export interface OrderCardProps extends Expanded {
//   orderId: string;
//   originalId: string;
//   status: OrderStatuses;
//   date: string;
//   history: string;
//   amount: number;
//   method: string;
//   category: string;
//   insurance: boolean;
//   packaging: string;
//   dimensions: string;
//   weight: string;
//   volume: string;
//   extraService: string;
//   country: string;
//   area: string;
//   city: string;
//   street: string;
//   postCode: string;
//   house: string;
//   houseBuilding: string;
//   apartmentNumber: string;
//   currency: OrderCurrency;
//   firstName: string;
//   middleName: string;
//   lastName: string;
// }

export interface OrderCardProps extends Expanded {
  orderId: string;
  originalId: string;
  status: OrderStatuses;
  date: string;
  history: string;
  attachments: API.AttachmentItemResponseDto[];
  amount: number;
  method: string;
  category: string;
  insurance: boolean;
  packaging: string;
  dimensions: string;
  weight: string;
  volume: string;
  extraService: string;
  country: string;
  area: string;
  city: string;
  street: string;
  postCode: string;
  house: string;
  houseBuilding: string;
  apartmentNumber: string;
  currency: OrderCurrency;
  pickUpOrder?: {
    address: string;
    date: string;
    time: API.TimeRange;
    phone: string;
  };
  firstName?: string;
  middleName?: string;
  lastName?: string;
}

export const OrderCard = ({
  isExpanded = false,
  orderId,
  originalId,
  status,
  date,
  history,
  attachments,
  amount,
  method,
  category,
  insurance,
  packaging,
  dimensions,
  weight,
  volume,
  extraService,
  country,
  area,
  city,
  street,
  postCode,
  house,
  houseBuilding,
  apartmentNumber,
  currency,
  pickUpOrder,
  firstName,
  middleName,
  lastName,
}: OrderCardProps) => {
  const [isOrderExpanded, setIsOrderExpanded] = useToggle(isExpanded);
  const isButtonDisabled = !orderStatusesAllowedDeletion.includes(status);
  const isMobileBig = useMediaQuery(MediaSizes.MobileBig);
  const onToggleArrowButtonClick = () => setIsOrderExpanded();
  const hasAttachments = !!attachments.length;

  const mobileAddressTableData = [
    { title: cityTableColumns[0], value: country },
    { title: cityTableColumns[1], value: area },
    { title: cityTableColumns[2], value: city },
    { title: cityTableColumns[3], value: street },
    { title: houseTableColumns[0], value: postCode },
    { title: houseTableColumns[1], value: house },
    { title: houseTableColumns[2], value: houseBuilding || '-' },
    { title: houseTableColumns[3], value: apartmentNumber || '-' },
  ];

  const mobileTermsTableData = [
    { title: termsTableColumns[0], value: method },
    { title: termsTableColumns[1], value: category },
    { title: termsTableColumns[2], value: insuranceStatus[Number(insurance)] },
    { title: packagingTableColumns[0], value: packaging },
    { title: packagingTableColumns[1], value: dimensions || '-' },
    { title: packagingTableColumns[2], value: weight },
    { title: packagingTableColumns[3], value: volume },
    { title: extraServiceTableColumns[0], value: extraService },
    { title: extraServiceTableColumns[1], value: currency },
  ];

  const historyTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={history} alignCenter noWrap>
          {history}
        </Row>,
      ],
      alignment: [...new Array(1).fill('start')],
    },
  ];

  const amountTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={amount} alignCenter noWrap>
          {`${amount || 0} $`}
        </Row>,
      ],
      alignment: [...new Array(1).fill('start')],
    },
  ];

  const cityTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={country} alignCenter noWrap>
          {country}
        </Row>,
        <Row key={area}>{area}</Row>,
        <Row key={city}>{city}</Row>,
        <Row key={street}>{street}</Row>,
      ],
      alignment: [...new Array(4).fill('start')],
    },
  ];

  const houseTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={postCode} alignCenter noWrap>
          {postCode}
        </Row>,
        <Row key={house}>{house}</Row>,
        <Row key={houseBuilding}>{houseBuilding || '-'}</Row>,
        <Row key={apartmentNumber}>{apartmentNumber || '-'}</Row>,
      ],
      alignment: [...new Array(4).fill('start')],
    },
  ];

  const termsTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={method} alignCenter noWrap>
          {method}
        </Row>,
        <Row key={category}>{category}</Row>,
        <Row key={'insurance'}>{insuranceStatus[Number(insurance)]}</Row>,
      ],
      alignment: [...new Array(3).fill('start')],
    },
  ];

  const packagingTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={packaging} alignCenter noWrap>
          {packaging}
        </Row>,
        <Row key={dimensions}>{dimensions || '-'}</Row>,
        <Row key={weight}>{weight}</Row>,
        <Row key={volume}>{volume}</Row>,
      ],
      alignment: [...new Array(4).fill('start')],
    },
  ];

  const extraServiceTableData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={extraService} alignCenter noWrap>
          {extraService}
        </Row>,
        <Row key={currency}>{currency}</Row>,
      ],
      alignment: [...new Array(2).fill('start')],
    },
  ];

  const cargoPickUpAddressData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={pickUpOrder?.address} alignCenter noWrap>
          {pickUpOrder?.address}
        </Row>,
      ],
      alignment: [...new Array(1).fill('start')],
    },
  ];

  const cargoPickUpDateAndPhoneData: DataTable[] | undefined = [
    {
      cells: [
        <Row key={pickUpOrder?.date} alignCenter noWrap>
          {pickUpOrder?.date} &nbsp;{pickUpOrder?.time.from}-
          {pickUpOrder?.time.to}
        </Row>,
        <Row key={pickUpOrder?.phone}>{pickUpOrder?.phone}</Row>,
      ],
      alignment: [...new Array(2).fill('start')],
    },
  ];

  const onOpenFilesClick = () => {
    titleModal.openModal({ title: orderId });
  };

  const onDeleteOrder = () => {
    formDeleteOrderById({ id: originalId });
  };

  const onDeleteClick = () => {
    modalEvents.openAsyncModal({
      title: titleOrder,
      closeText,
      okText,
      onOk: onDeleteOrder,
    });
  };

  return !isMobileBig ? (
    <CardWrapper>
      <Section justifyBetween>
        <Row alignCenter>
          <MarginWrapper marginRight="15px">
            <TitleText>№{orderId}</TitleText>
          </MarginWrapper>
          {isOrderExpanded && hasAttachments && (
            <AttachedFilesButton onClick={onOpenFilesClick} />
          )}
        </Row>

        <Row alignCenter>
          <MarginWrapper marginRight="30px">
            <TitleText>{getOrderStatus(status)}</TitleText>
          </MarginWrapper>
          <MarginWrapper marginRight="37px">
            <TitleText>{format(new Date(date), 'yyyy.MM.dd')}</TitleText>
          </MarginWrapper>
          <ToggleArrowButton
            isExpanded={isOrderExpanded}
            onClick={onToggleArrowButtonClick}
          />
        </Row>
      </Section>

      {isOrderExpanded && (
        <>
          <Section justifyBetween marginTop="22px">
            <Row width="75%">
              <TableWrapper>
                <Section>
                  <Table
                    columns={historyTableColumns}
                    data={historyTableData}
                  />
                </Section>
              </TableWrapper>
            </Row>
            <Row width="21%">
              <TableWrapper>
                <Section>
                  <Table columns={amountTableColumns} data={amountTableData} />
                </Section>
              </TableWrapper>
            </Row>
          </Section>

          <TableWrapper>
            <Section>
              <Table columns={cityTableColumns} data={cityTableData} />
            </Section>
            <Section>
              <Table columns={houseTableColumns} data={houseTableData} />
            </Section>
          </TableWrapper>

          <TableWrapper>
            <Section>
              <Table columns={termsTableColumns} data={termsTableData} />
            </Section>
          </TableWrapper>

          <TableWrapper>
            <Section>
              <Table
                columns={packagingTableColumns}
                data={packagingTableData}
              />
            </Section>
          </TableWrapper>

          <TableWrapper>
            <Section>
              <Table
                columns={extraServiceTableColumns}
                data={extraServiceTableData}
              />
            </Section>
          </TableWrapper>

          {!!pickUpOrder && (
            <TableWrapper>
              <Section>
                <Table
                  columns={cargoPickUpAddressColumns}
                  data={cargoPickUpAddressData}
                />
              </Section>
              <Section>
                <Table
                  columns={cargoPickUpDateAndPhoneColumns}
                  data={cargoPickUpDateAndPhoneData}
                />
              </Section>
            </TableWrapper>
          )}

          <Section justifyCenter>
            <Button
              buttonType="card"
              disabled={isButtonDisabled}
              onClick={onDeleteClick}
            >
              Удалить
            </Button>
          </Section>
        </>
      )}

      <TitleModal title={orderId}>
        <Section>
          {attachments.map(({ name, url }) => (
            <Row key={name} marginRight="20px">
              <FileDownloadButton fileName={name} url={url} />
            </Row>
          ))}
        </Section>
      </TitleModal>
    </CardWrapper>
  ) : (
    <CardWrapper>
      <Section alignCenter>
        <MarginWrapper marginRight="15px">
          <AdaptiveTextTitle>№{orderId}</AdaptiveTextTitle>
        </MarginWrapper>
        {isOrderExpanded && <AttachedFilesButton onClick={onOpenFilesClick} />}
      </Section>

      <Section marginBottom={isOrderExpanded ? '20px' : '0'}>
        <MarginWrapper marginRight="8px">
          <AdaptiveTextTitle>{getOrderStatus(status)}</AdaptiveTextTitle>
        </MarginWrapper>
        <MarginWrapper marginRight="8px">
          <AdaptiveDateTitle>
            {format(new Date(date), 'yyyy.MM.dd')}
          </AdaptiveDateTitle>
        </MarginWrapper>
        <AbsoluteWrapper right="14px" top="31px">
          <ToggleArrowButton
            isExpanded={isOrderExpanded}
            onClick={onToggleArrowButtonClick}
          />
        </AbsoluteWrapper>
      </Section>

      {isOrderExpanded && (
        <>
          <Section>
            <TableWrapper>
              <Column width="100%">
                <MobileHistoryWrapper>
                  <MobileTitle>{historyTableColumns}</MobileTitle>
                </MobileHistoryWrapper>
                <ContentWrapper
                  backgroundColor="transparent"
                  padding="12px 14px"
                >
                  <MobileDescription>{history}</MobileDescription>
                </ContentWrapper>
              </Column>
            </TableWrapper>
          </Section>
          <Section>
            <TableWrapper>
              <ContentWrapper
                backgroundColor="transparent"
                padding="16px 14px"
                width="100%"
              >
                <Section justifyBetween>
                  <MobileTitle>{amountTableColumns}</MobileTitle>
                  <MobileDescription>{`${amount || 0} $`}</MobileDescription>
                </Section>
              </ContentWrapper>
            </TableWrapper>
          </Section>

          <TableWrapper>
            <Section justifyCenter margin={mobileMarginBottom}>
              <TitleText
                alignCenter
              >{`${lastName} ${firstName} ${middleName}`}</TitleText>
            </Section>
            <MobileTable data={mobileAddressTableData} />
          </TableWrapper>

          <TableWrapper>
            <MobileTable data={mobileTermsTableData} />
          </TableWrapper>

          <Button
            buttonType="card"
            disabled={isButtonDisabled}
            width="100%"
            onClick={onDeleteClick}
          >
            Удалить
          </Button>
        </>
      )}

      <TitleModal title={orderId}>
        <Section>
          {attachments.map(({ name, url }) => (
            <Row key={name} marginRight="20px">
              <FileDownloadButton fileName={name} url={url} />
            </Row>
          ))}
        </Section>
      </TitleModal>
    </CardWrapper>
  );
};
