import { Section } from 'components/wrappers/grid/FlexWrapper';
import { lg, md, sm } from 'constants/styles/sizes';
import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  width: 100%;
  max-width: 910px;
  height: 100%;

  @media screen and (max-width: ${sm}) {
    padding-top: 0;
    padding-bottom: 100px;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${lg}) {
    padding: 0 50px;
  }

  @media screen and (max-width: ${md}) {
    padding: 0 16px;
  }
`;

export const TabsWrapper = styled(Section)`
  justify-content: center;
  margin-bottom: 30px;
  overflow-x: auto;

  @media screen and (max-width: ${lg}) {
    padding: 0 50px;
  }

  @media screen and (max-width: ${md}) {
    padding: 0 16px;
  }

  @media screen and (max-width: ${sm}) {
    padding: 0;
  }
`;
