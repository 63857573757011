import {
  contentWrapperBorderRadius,
  contentWrapperMinWidth,
} from 'components/wrappers/ContentWrapper/constants';
import { darkBlue, white } from 'constants/styles/colors';
import styled from 'styled-components';
import {
  BackgroundColor,
  BorderRadius,
  MarginRightBottom,
  MaxSizes,
  MinSizes,
  Padding,
  Sizes,
} from 'types/styles';

export interface ContentWrapperProps
  extends Sizes,
    Padding,
    MinSizes,
    MaxSizes,
    BackgroundColor,
    BorderRadius,
    MarginRightBottom {}

export const OriginContentWrapper = styled.div<ContentWrapperProps>`
  ${({ height }) => height && `height: ${height}`};
  ${({ width }) => width && `width: ${width}`};
  min-width: ${({ minWidth }) => minWidth || contentWrapperMinWidth};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
  ${({ padding }) => padding && `padding: ${padding}`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`};

  border-radius: ${({ borderRadius }) =>
    borderRadius || contentWrapperBorderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor || white};
`;

export const ContentWrapper = styled(OriginContentWrapper)`
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor }) => backgroundColor || darkBlue};
`;
