import { createEffect, createEvent, createStore, forward } from 'effector';
import { API } from 'api';
import {
  defaultGetAllResponse,
  relevantInsuranceState,
} from 'constants/defaults/store';
import { ordersEvents } from './orders';
import { getDataAndOpenErrorModal } from 'utils/store';

const { clearOrderFormData } = ordersEvents;

const getAllInsurances = createEffect({
  handler: async () => {
    try {
      return await API.insurances.getAllInsurance();
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const insurances = createStore<API.GetAllInsurancesResponseDto>(
  defaultGetAllResponse,
).on(getAllInsurances.doneData, (_, newState) => newState);

export interface RelevantInsuranceProps {
  cost: number;
  id: string;
}
const clearRelevantInsurance = createEvent();
const setRelevantInsurance = createEvent<RelevantInsuranceProps>();

const relevantInsurance = createStore<RelevantInsuranceProps>(
  relevantInsuranceState,
)
  .on(setRelevantInsurance, (_, newState) => newState)
  .on(clearRelevantInsurance, () => relevantInsuranceState);

forward({
  from: [clearOrderFormData],
  to: [clearRelevantInsurance],
});

export const insurancesEffects = { getAllInsurances };
export const insurancesEvents = {
  clearRelevantInsurance,
  setRelevantInsurance,
};
export const insurancesStores = { insurances, relevantInsurance };
