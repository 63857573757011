import { Span } from 'components/common/typography/Span';
import { menuItems } from 'components/grid/Menu/constants';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { MenuItem, MenuWrapper } from './styles';

export const Menu = () => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(MediaSizes.Mobile);

  return (
    <MenuWrapper>
      {menuItems.map(({ title, path, Icon }) => {
        const isActive = !!matchPath(pathname, {
          path,
        });

        return (
          <MenuItem key={title} active={isActive}>
            <Link to={path}>
              <Row alignCenter noWrap>
                {!isMobile && (
                  <Row marginRight="12px">
                    <Icon />
                  </Row>
                )}
                <Span>{title}</Span>
              </Row>
            </Link>
          </MenuItem>
        );
      })}
    </MenuWrapper>
  );
};
