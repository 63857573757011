import hardPackingImg from 'assets/img/hard-packing.png';
import regularPackingImd from 'assets/img/regular-packing.png';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import {
  AccentLink,
  AccentText,
  PackingCostBlock,
  Paragraph,
  RedSpan,
  SecondaryTitle,
} from 'pages/Support/styles';
import React from 'react';
import { Link } from 'react-router-dom';

export const MarginSizes = {
  small: '10px',
  medium: '15px',
  large: '20px',
};

export const doorFenceServiceNumberListText = [
  'Оформите международную отправку через форму заявки от MALEAN',
  'На последнем шаге нажать «Услуга забор груза от двери».',
  'Достоверно заполнить все требуемые поля и подтвердить.',
  'Готово.',
];

export const doorFenceServiceDottedListText = [
  'Оплата услуги производится в юанях не позже, чем на следующий день после забора груза по чеку от китайской транспортной компании. (Wechat, Alipay, Банковская карта)',
  'Если клиент не может оплатить в юанях, в таком случае следует написать в чат поддержки менеджеру MALEAN. Наши работники с радостью помогут вам организовать оплату в другой валюте (по внутреннему курсу MALEAN).',
  'Стоимость доставки за кг в среднем от 0.9 $ до 1.9 $ объемного веса, без учета упаковки груза, если таковая потребуется. Зависит от удаленности вашей локации до нашего склада в Китае.',
  'Услуга «Забор груза от двери» оплачивается отдельно и не входит в тариф международной доставки.',
  `Объемный вес рассчитывается по формуле ДЛИНА(см) х ШИРИНА(см) х ВЫСОТА(см) \\ 6000.`,
  'При надобности выдается накладная с общей суммой и трек номером по Китаю.',
  'Услуга оформляется не раньше, чем за 24 часа до желаемой даты забора груза.',
  'Изменение в дате и времени забора груза, не раньше чем за 3 часа до забора груза.',
  'Время работы курьеров с 10ч утра до 18-20ч вечера по пекинскому времени.',
];

export const costCalculationFactorsNumberListText = [
  'Вид доставки (Авто, Авиа, ЖД, Море)',
  'Тип перевозимого товара (Электроника, Одежда, Текстиль, Обувь бренд, Аксессуары и другие)',
  'Вес (кг), объем груза (м3) и количество мест (коробок/упаковок)',
];

export const costDeliveryDottedListText = [
  'Доставка из Китая(наш склад) – (наш склад) в стране получателя. Наши склады находятся в г.Киев(Украина), г.Москва(Россия), г.Алматы(Казахстан), г.Ашхабад(Туркменистан), г.Бангкок(Таиланд), г.Пном-Пень(Камбоджа)',
  'Страховка, если таковая присутствует',
  'Упаковка груза',
  'Таможенные сборы, плата за оформление деклараций и т.д',
  'Складской сбор',
];

export const importantPointsDottedListText = [
  'Убедитесь в том что целостность посылки не нарушена, посылка не имеет внешних видимых повреждений.',
  'Проверьте соответствие веса, кубатуры и вашего кода на посылке. Код должен соответствовать накладной.',
  'Перед уведомлением клиентов о прибытии грузов на наш склад в стране получателя, все посылки в обязательном порядке проходят контроль на нарушение и повреждения упаковки. В случае если таковые будут обнаружены, посылка не перегружается дальше и клиент уведомляется до дальнейших выяснительных мероприятий.',
  'При получении груза на отделении местной транспортной компании или доставки до двери, если вы обнаружили какие-либо внешние повреждения или нарушения целостности упаковки, сделайте фото видео фиксацию и обратитесь к сотруднику данной компании на месте получения груза для выяснения обстоятельств. Также пришлите фото-видео материалы менеджеру MALEAN.',
  'Обратите внимание, что проверка вами соответствия веса и кубатуры груза может производиться только при условии сохранения целостности упаковки груза (не вскрывать,не открывать), а также фото и видео фиксации. Иногда данные от местных транспортных компаний могут не совпадать с фактическими, т.к некоторые компании вписывают объемный вес, в то время когда мы вписываем вес фактический.',
];

export const insuranceText = [
  'Международные перевозки из Китая - это очень многогранный и сложный процесс, в котором задействованы несколько логистических цепочек и сотни людей. Как и в любом деле все же остаются риски, что груз может быть конфискован или утерян из-за влияния третьих сил, форс-мажорных обстоятельств или же человеческого фактора.',
  'MALEAN дорожит каждым клиентом и для этого мы создали по-настоящему рабочую страховку, которая покрывает полную стоимость груза в страховых случаях, указанных выше и поможет избежать непредвиденных финансовых потерь.',
];

export const sidebarItems = [
  {
    title: 'Как мы работаем?',
    id: 'how_we_are_working?',
    content: (
      <>
        <Paragraph>
          Вы заполняете заявку на отправку груза и получаете финальный чек-лист
          с указанием нашего адреса и вашим личным кодом, которой нужно будет
          написать на грузе или указать в имени получателя нашего склада. Склады
          находятся в г. Иу, г. Пекин, г. Гуанчжоу.
        </Paragraph>
        <Paragraph>
          После того как вы или ваш поставщик отправили груз на один из наших
          складов укажите номера трекингов по Китаю. Если вы заказываете с
          торговых площадок TaoBao, 1688, PingDuoDuo, Made-in-China и другие, не
          всегда поставщик четко выполняет условия по маркировке груза.&nbsp;
          <AccentText>Настоятельно рекомендуем</AccentText> дополнять ваш личный
          код номерами трекингов.
        </Paragraph>
        <Paragraph>
          Если же вы находитесь на территории Китая и отправляете из своего
          офисадома, все еще проще. Вы можете заказать услугу от MALEAN «Забор
          от двери». В таком случае мы сами организуем нашего курьера за
          отдельную плату и вам не нужно будет заботиться о пункте 2. Подробнее
          смотрите{' '}
          <AccentLink href="#door_fence_service">
            услуга забор от двери
          </AccentLink>
        </Paragraph>
        <Paragraph>
          Груз поступил на наш склад. Мы распознали его согласно маркировке,
          далее проверка содержимого на соответствие, упаковка и оформление
          согласно созданной вами заявки.
        </Paragraph>
        <Paragraph>
          Посылка выехала. В{' '}
          <Link to="account/orders">
            <AccentText isLink>личном кабинете</AccentText>
          </Link>{' '}
          вам выдается накладная, фотоотчет до и после упаковки, трек код
          посылки.
        </Paragraph>
        <Paragraph>
          В процессе перевозки товара вы сможете его отслеживать используя
          специальный трекинг от MALEAN.
        </Paragraph>

        <Paragraph>
          По прибытию груза на наш склад в стране получателя, от менеджера на
          складе поступит извещение на WhatssApp или звонок о прибытии груза с
          суммой к оплате. В конечную сумму включены все таможенные сборы,
          упаковка, логистика, а также страховка, если таковая присутствует.
        </Paragraph>
        <Paragraph>
          После оплаты вы можете забрать груз самовывозом или же оформить
          пересылку груза в регион или область, оставив данные получателя нашему
          менеджеру.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Услуга забор от двери',
    id: 'door_fence_service',
    content: (
      <>
        {doorFenceServiceNumberListText.map((text, i) => (
          <Paragraph
            key={text}
            numbered
            mb={
              i === doorFenceServiceNumberListText.length - 1
                ? undefined
                : 'medium'
            }
            number={i + 1}
          >
            {text}
          </Paragraph>
        ))}
        {doorFenceServiceDottedListText.map((text, i) => (
          <Paragraph
            key={text}
            dotted
            mb={
              i === doorFenceServiceDottedListText.length - 1
                ? undefined
                : 'small'
            }
          >
            {text}
          </Paragraph>
        ))}
      </>
    ),
  },
  {
    title: 'Расчет стоимости онлайн',
    id: 'calculating_the_cost_online',
    content: (
      <>
        <Paragraph>
          Зачастую при грузоперевозках из Китая многие клиенты испытывают
          сложность при расчете стоимости доставки вследствие чего вынуждены
          обращаться к менеджерам транспортных компаний для индивидуального и
          личного просчета, что не всегда быстро и удобно. MALEAN попробует Вам
          помочь с этим, и разобраться во всех тонкостях и особенностях этого
          нехитрого процесса, а также предоставит возможность самостоятельно
          делать просчеты с помощью нашего онлайн-калькулятора.
        </Paragraph>
        <SecondaryTitle>
          Цена доставки груза из Китая зависит от нескольких факторов:
        </SecondaryTitle>
        {costCalculationFactorsNumberListText.map((text, i) => (
          <Paragraph
            key={text}
            numbered
            mb={
              i === costCalculationFactorsNumberListText.length - 1
                ? undefined
                : 'medium'
            }
            number={i + 1}
          >
            {text}
          </Paragraph>
        ))}
        <Paragraph mb="large">
          При условии, что <AccentText>все данные известны</AccentText>: клиент
          заполняет все необходимые данные в онлайн-калькуляторе от MALEAN и
          получает конечную стоимость доставки.
        </Paragraph>
        <Paragraph mb="large">
          Если клиент <AccentText>затрудняется/не знает</AccentText> точных
          данных: он может обратиться за консультацией к менеджеру через онлайн
          поддержу или по указанным контактам на сайте MALEAN, для расчета
          предварительной стоимости перевозки груза.
        </Paragraph>
        <Paragraph mb="large">
          Для просчета тарифа на доставку того или иного груза используется
          такая аббревиатура как плотность груза. Если плотность груза
          составляет от 100 (минимальная стандартная) и выше, то тариф будет
          считаться по фактическому весу, если плотность меньше 100 (минимальная
          стандартная), в таком случае будет использоваться расчет по кубам.
        </Paragraph>

        <SecondaryTitle>
          Что такое плотность груза и как ее вычислить?
        </SecondaryTitle>
        <Paragraph numbered mb="medium" number={1}>
          У вас 1 чемодан личных вещей, который нужно доставить из Гуанчжоу в
          Москву. Общий вес до упаковки составляет 30 кг и общая кубатура
          составляет 0.2 м3 (0.6м Х 0.6м Х 0.55м =0.198 м3).
        </Paragraph>
        <Paragraph ml mb="medium">
          <AccentText>30 кг 0.2 м3= 150. Это и есть наша плотность.</AccentText>
        </Paragraph>
        <Paragraph ml mb="medium">
          При выборе категории товара личные вещи + точный вес + точная кубатура
          онлайн-калькулятор от MALEAN посчитает вам стоимость доставки товара
          исходя из фактического веса и тарифа на вашу плотность груза.
        </Paragraph>
        <Paragraph numbered mb="medium" number={2}>
          У вас 1 ящик детских игрушек, который нужно доставить из Гуанчжоу в
          Алматы. Общий вес до упаковки составляет 45 кг и общая кубатура
          составляет 0.7 м3 (0.9м Х 0.9м Х 0.86м =0.69 м3)
        </Paragraph>
        <Paragraph ml mb="medium">
          <AccentText>
            45 кг 0.7 м3= 64. Это и есть наша плотность, ниже минимальной
            стандартной!
          </AccentText>
        </Paragraph>
        <Paragraph ml mb="medium">
          При выборе категории товара детские игрушки + точный вес + точная
          кубатура онлайн-калькулятор от MALEAN посчитает вам стоимость доставки
          товара исходя из объема вашего груза и цену за 1 куб.
        </Paragraph>
        <Paragraph ml mb="medium">
          <AccentText>
            Другими словами чем меньше размер и тяжелее груз, тем дешевле тариф
            на перевозку, чем больше размер и легче груз,тем дороже.
          </AccentText>
        </Paragraph>
        <Paragraph ml mb="medium">
          <RedSpan>*</RedSpan>
          &nbsp;&nbsp; Для более точного и корректного просчета, максимально
          правдиво указывайте категории перевозимых товаров.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Упаковка',
    id: 'package',
    desktopTitle: 'Упаковка: обычная\\обрешетка, в чем разница?',
    content: (
      <>
        {' '}
        <Paragraph>
          Стоимость упаковки рассчитывается исходя из количества мест. По
          стандарту размер 1-ого места 50см*60см*80см или же 0.24 м3. Размеры
          коробок могут меняться, но кубатура 1-ого места зачастую неизменна.
        </Paragraph>
        <SecondaryTitle marginBottom="7px">Обычная упаковка</SecondaryTitle>
        <CustomImage
          alt="regular packing"
          src={regularPackingImd}
          width="250px"
        />
        <PackingCostBlock>Стоимость: 5$-10$ за 1 место</PackingCostBlock>
        <Paragraph>
          Товар упаковывается в картонные коробки или коробку, сверху
          оборачивается скотчем и добавляется водонепроницаемый мешок для
          дополнительной защиты груза. В большинстве случаев несколько маленьких
          коробок группируются в одно место для удешевления и удобства
          перевозки.
          <br />
          Такая упаковка подходит для <AccentText>нехрупких товаров</AccentText>
          : текстиль, одежда, металлические запчасти, рюкзаки, шапки...
        </Paragraph>
        <SecondaryTitle marginBottom="7px">
          Обрешетчатая упаковка
        </SecondaryTitle>
        <CustomImage alt="hard packing" src={hardPackingImg} width="250px" />
        <PackingCostBlock>Стоимость: 10$-15$ за 1 место</PackingCostBlock>
        <Paragraph mb="medium">
          Товар упаковывается в картонные коробки или коробку, сверху
          добавляется водонепроницаемый мешок для дополнительной защиты груза,
          делается деревянный каркас, оборачивается скотчем.
          <br />
          Такая упаковка подходит для <AccentText>хрупких товаров</AccentText>:
          электроника, стекло, хрупкий пластик, электротранспорт, брендовая
          продукция в коробках. Особенно подходит для товара, который
          предназначен для продажи и очень важен внешний вид упаковки!
        </Paragraph>
        <Paragraph>
          <RedSpan>*</RedSpan>&nbsp;&nbsp; Обратите внимание, что обрешетка
          значительно может прибавить к конечному весу посылки.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Что входит в стоимость доставки?',
    id: 'what_is_included_in_the_shipping_cost?',
    content: (
      <>
        <SecondaryTitle>
          В стоимость доставки, которая указывается в накладной после отправки
          груза входит следующе:
        </SecondaryTitle>
        {costDeliveryDottedListText.map((text) => (
          <Paragraph key={text} dotted mb="small">
            {text}
          </Paragraph>
        ))}
      </>
    ),
  },
  {
    title: 'Перегруз товара в регионы или область',
    id: 'transhipment_of_goods_to_regions_or_region',
    desktopTitle:
      'Перегруз товара в регионы или область. Что значит и как происходит?',
    content: (
      <>
        <Paragraph>
          Перегруз товара означает, что по прибытию товара на наш склад в стране
          получателя, после оплаты клиентом стоимости доставки по накладной, мы
          передаем груз в руки местных транспортных компаний, которые и
          занимаются доставкой в регионы и области.
          <br />
          Стоимость этой доставки{' '}
          <AccentText>оплачивается при получении груза</AccentText> по тарифам
          местной ТК в валюте страны в которую прибыл груз.
        </Paragraph>
      </>
    ),
  },
  {
    title: 'Важные моменты при получении груза',
    id: 'important_points_when_receiving_cargo',
    content: (
      <>
        {' '}
        {importantPointsDottedListText.map((text, i) =>
          i === 3 ? (
            <Paragraph key={text} dotted mb="small">
              При получении груза на{' '}
              <AccentText>отделении местной транспортной компании</AccentText>{' '}
              или <AccentText>доставки до двери</AccentText>, если вы обнаружили
              какие-либо внешние повреждения или нарушения целостности упаковки,
              сделайте фото видео фиксацию и обратитесь к сотруднику данной
              компании на месте получения груза для выяснения обстоятельств.
              Также пришлите фото-видео материалы менеджеру MALEAN.
            </Paragraph>
          ) : (
            <Paragraph key={text} dotted mb="small">
              {text}
            </Paragraph>
          ),
        )}
      </>
    ),
  },
  {
    title: 'Страховка',
    id: 'insurance',
    content: (
      <>
        {insuranceText.map((text, i) => (
          <Paragraph
            key={text}
            mb={insuranceText.length - 1 === i ? undefined : 'large'}
          >
            {text}
          </Paragraph>
        ))}
        <SecondaryTitle>Как же это работает?</SecondaryTitle>
        <Paragraph numbered mb="small" number={1}>
          Чтобы просчитать сколько будет стоить страховка вашего груза,
          необходимо зайти в онлайн-калькулятор от{' '}
          <Link to="/shipments">
            <AccentText isLink>MALEAN</AccentText>
          </Link>
          , дойти до шага «страховка», заполнить все необходимые данные и
          система автоматически сделает вам расчет стоимости.
        </Paragraph>
        <Paragraph numbered mb="small" number={2}>
          Страховка работает на протяжении всего маршрута «наш склад в Китае» -
          «наш склад в стране получателя»
        </Paragraph>
        <Paragraph numbered mb="small" number={3}>
          Если в течение <AccentText>90 дней</AccentText> после выхода накладной
          для способа доставки <AccentText>авто\авиа</AccentText>, груз не
          поступил на наш склад в стране получателя, но была оформлена
          страховка, клиент имеет право потребовать компенсацию в полном размере
          от заявленной стоимости груза.
        </Paragraph>
        <Paragraph numbered mb="small" number={4}>
          Если в течение <AccentText>120 дней</AccentText> после выхода
          накладной для способа доставки <AccentText>ЖД</AccentText>, груз не
          поступил на наш склад в стране получателя, но была оформлена
          страховка, клиент имеет право потребовать компенсацию в полном размере
          от заявленной стоимости груза.
        </Paragraph>
        <Paragraph numbered number={5}>
          В случае если товар не был конфискован и не был утерян, но срок
          доставки превысил выше описанные и клиенту была выплачена страховка,
          то клиент имеет право выкупить товар обратно, вернув выплаченные ему
          деньги по страховке плюс сумма по накладной за доставку самого товара.
        </Paragraph>
        <SecondaryTitle marginBottom="5px">
          Что не является страховым случаем?
        </SecondaryTitle>
        <Paragraph mb="small">
          Все, что не входит в перечень страховых случаев.
        </Paragraph>
      </>
    ),
  },
];
