import history from 'browserHistory';

export const initialLink = '/';

// * auth
export const authLink = '/auth';
export const authLinkIndex = `${authLink}/`;
export const registerLink = `${authLinkIndex}register`;
export const loginLink = `${authLinkIndex}login`;
export const socialLink = `${authLinkIndex}social`;
export const forgotPasswordLink = `${authLinkIndex}forgot-password`;
export const resetPasswordLink = `${authLinkIndex}reset-password`;
export const confirmationLink = `${authLinkIndex}confirmation-email`;

// * app
export const homeLink = '/main';
export const privateOfficeLink = '/private-office';

// * Account
export const accountLink = '/account';
export const personalLink = `${accountLink}/personal`;
export const addressesLink = `${accountLink}/addresses`;
export const newAddressLink = `${addressesLink}/new`;
export const addressLink = `${addressesLink}/:id`;
export const ordersLink = `${accountLink}/orders`;

// * Shipments
export const shipmentsLink = '/shipments';

// * Calculator
export const approximateCostCalculatorLink = '/cost-calculator';

// * Support
export const supportLink = '/support';

// PrivacyPolicy
export const privacyPolicyLink = '/privacy_policy';

export const templatesLink = '/templates';
export const templatesLinkIndex = `${templatesLink}/`;
export const templateLink = `${templatesLinkIndex}:templateId`;

export const error403Link = '/403';
export const error401Link = '/401';

// * Landing page
export const landingLink =
  process.env.NODE_ENV === 'production'
    ? 'https://landing.malean.online/'
    : 'https://unmd.landing.dev.incodewetrust.online/';

export const toNewAddressLinkClick = () => history.push(newAddressLink);
export const toLoginLinkClick = () => history.push(loginLink);
export const toPersonalLinkClick = () => history.push(personalLink);
export const toAddressesLinkClick = () => history.push(addressesLink);
export const toAddressLinkClick = (id: string) =>
  history.push(`${addressesLink}/${id}`);

export const toOrdersLinkClick = () => history.push(ordersLink);
export const toSupportLinkClick = () => history.push(supportLink);
export const toRegisterLinkClick = () => history.push(registerLink);
export const toShipmentsLinkClick = () => history.push(shipmentsLink);
