import { Button } from 'components/common/buttons/Button';
import { TitleText } from 'components/common/typography/TitleText';
import {
  contentMarginBottom,
  fieldsMarginBottom,
  titleMarginBottom,
  yupSchema,
} from 'components/forms/ChangePasswordForm/constants';
import { ChangePasswordInterface } from 'components/forms/ChangePasswordForm/type';
import { FormTextInput } from 'components/forms/inputs/FormTextInput';
import {
  mobileMarginBottom,
  personalFormPadding,
} from 'components/forms/PersonalForm/constants';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { FlexGrow, Section } from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { userEffects } from 'stores/user';
import { Form } from '../styles';

const { formUpdateUserPassword } = userEffects;

const {
  changePasswordForm,
  text: { submitButton, changePassword },
} = languages.pages.personal;

export const ChangePasswordForm = () => {
  const {
    handleSubmit,
    formState: { isDirty },
    control,
    reset,
  } = useFormSchema(yupSchema, 'onSubmit');
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const isMobileLarge = useMediaQuery(MediaSizes.MobileLarge);
  const padding = isMobileLarge ? '25px 30px' : personalFormPadding;

  const loading = useStore(formUpdateUserPassword.pending);

  const onSubmit = async ({
    password,
    newPassword,
  }: ChangePasswordInterface) => {
    await formUpdateUserPassword({ oldPassword: password, newPassword });
    reset({ password: '', newPassword: '' });
  };

  return !isMobile ? (
    <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
      <ContentWrapper
        marginBottom={contentMarginBottom}
        padding={padding}
        width="100%"
      >
        <Section marginBottom={titleMarginBottom}>
          <TitleText>{changePassword}</TitleText>
        </Section>
        <Section justifyBetween marginBottom={fieldsMarginBottom}>
          <FlexGrow flexBasis="48%" flexGrow="0">
            <FormTextInput
              autoComplete="current-password"
              control={control}
              id="password"
              name="password"
              type="password"
              {...changePasswordForm['password']}
            />
          </FlexGrow>
          <FlexGrow flexBasis="48%" flexGrow="0">
            <FormTextInput
              autoComplete="new-password"
              control={control}
              id="newPassword"
              name="newPassword"
              type="password"
              {...changePasswordForm['newPassword']}
            />
          </FlexGrow>
        </Section>
        <Section alignCenter justifyCenter>
          <Button disabled={!isDirty} isLoading={loading} type="submit">
            {submitButton}
          </Button>
        </Section>
      </ContentWrapper>
    </Form>
  ) : (
    <Form width="100%" onSubmit={handleSubmit(onSubmit)}>
      <ContentWrapper
        marginBottom={contentMarginBottom}
        padding="20px 16px"
        width="100%"
      >
        <Section marginBottom={titleMarginBottom}>
          <TitleText>{changePassword}</TitleText>
        </Section>
        <Section justifyBetween marginBottom={mobileMarginBottom}>
          <FormTextInput
            autoComplete="current-password"
            control={control}
            id="password"
            name="password"
            type="password"
            {...changePasswordForm['password']}
          />
        </Section>
        <Section justifyBetween marginBottom={mobileMarginBottom}>
          <FormTextInput
            autoComplete="new-password"
            control={control}
            id="newPassword"
            name="newPassword"
            type="password"
            {...changePasswordForm['newPassword']}
          />
        </Section>
        <Section alignCenter justifyCenter>
          <Button disabled={!isDirty} isLoading={loading} type="submit">
            {submitButton}
          </Button>
        </Section>
      </ContentWrapper>
    </Form>
  );
};
