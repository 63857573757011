import { Span } from 'components/common/typography/Span';
import { inactiveTextColor, white } from 'constants/styles/colors';
import { lg } from 'constants/styles/sizes';
import styled, { css } from 'styled-components';
import { Active } from 'types/global';

export const MenuItem = styled.li<Active>`
  a {
    ${Span} {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: ${inactiveTextColor};
      transition: color 0.3s;

      @media screen and (max-width: ${lg}) {
        font-size: 14px;
      }
    }
    svg {
      color: ${inactiveTextColor};
      transition: color 0.3s;
      width: 23px;
    }
  }

  a:hover {
    ${Span} {
      color: ${white};
    }

    svg {
      color: ${white};
    }
  }

  ${({ active }) =>
    active &&
    css`
      a {
        ${Span} {
          color: ${white};
        }

        svg {
          color: ${white};
        }
      }
    `}

  @media screen and (max-width: ${lg}) {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const MenuWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
`;
