import { Button } from 'components/common/buttons/Button';
import { SocialButton } from 'components/common/buttons/SocialButton';
import { TitleText } from 'components/common/typography/TitleText';
import { FormTextInput } from 'components/forms/inputs/FormTextInput';
import { onSubmit, yupSchema } from 'components/forms/LoginForm/constants';
import { formInputMarginBottom } from 'components/forms/RegisterForm/constants';
import {
  Column,
  FlexGrow,
  Row,
  Section,
} from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { forgotPasswordLink } from 'constants/routes';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
import { SignIn } from 'pages/Auth/Login/types';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { userEffects } from 'stores/user';
import { SocialMediaEnum } from 'types/types';
import { authenticateWithSocialMedia } from 'utils/browser.api';
import { getRegistrationData } from 'utils/data';
import { Form } from '../styles';
import { ForgotPassword } from './styles';

const { loadToken } = userEffects;

export const LoginForm = () => {
  const {
    handleSubmit,
    formState: { isValid },
    setError,
    control,
  } = useFormSchema(yupSchema);

  const loading = useStore(loadToken.pending);
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const marginBetween = isMobile ? '5px' : '10px';

  const {
    loginForm,
    text: { or, submitButton, forgotPassword },
  } = languages.pages.login;

  const onSubmitForm = (data: SignIn) => onSubmit(data, setError);

  useEffect(() => {
    getRegistrationData();
  }, []);

  return (
    <Form width="100%" onSubmit={handleSubmit(onSubmitForm)}>
      <Section marginBottom={formInputMarginBottom}>
        <FormTextInput
          control={control}
          id="email"
          name="email"
          type="email"
          {...loginForm['email']}
        />
      </Section>
      <Section marginBottom="10px">
        <FormTextInput
          control={control}
          id="password"
          name="password"
          type="password"
          {...loginForm['password']}
        />
      </Section>
      <Section marginBottom="45px">
        <Column alignCenter width="100%">
          <Row marginBottom="10px">
            <TitleText>{or}</TitleText>
          </Row>
          <Section>
            <FlexGrow alignCenter marginRight={marginBetween}>
              <SocialButton
                buttonType="vk"
                type="button"
                onClick={() =>
                  authenticateWithSocialMedia(SocialMediaEnum.Vkontakte)
                }
              />
            </FlexGrow>
            <FlexGrow
              alignCenter
              marginLeft={marginBetween}
              onClick={() =>
                authenticateWithSocialMedia(SocialMediaEnum.Facebook)
              }
            >
              <SocialButton buttonType="fb" type="button" />
            </FlexGrow>
          </Section>
        </Column>
      </Section>
      <Section alignCenter justifyCenter marginBottom="15px">
        <Button disabled={!isValid} isLoading={loading} type="submit">
          {submitButton}
        </Button>
      </Section>
      <Section alignCenter justifyCenter>
        <ForgotPassword>
          <Link to={forgotPasswordLink}>{forgotPassword}</Link>
        </ForgotPassword>
      </Section>
    </Form>
  );
};
