import { SocialMediaEnum } from 'types/types';
import axios from './axios';

export const createUser = (data: API.SignUpDto) =>
  axios<API.SecuredUser>(
    {
      url: '/auth/signup',
      data,
    },
    false,
  );

export const authenticateUser = (data: API.LoginDto) =>
  axios<API.AuthResponseDto>(
    {
      url: '/auth/login',
      data,
    },
    false,
  );

export const logOut = (data: API.LogOutDto) =>
  axios<API.BaseResponse>({
    url: '/auth/logout',
    data,
  });

export const authenticateUserWithSocialMedia = (name: SocialMediaEnum) =>
  axios<API.AuthResponseDto>(
    {
      url: `/auth/${name}`,
      method: 'GET',
    },
    false,
  );

export const signUpWithSocialMedia = (data: API.SignUpWithSocialMediaDto) =>
  axios<API.AuthResponseDto>(
    {
      url: `/auth/signup-social`,
      method: 'POST',
      data,
    },
    false,
  );

export const recoverPassword = (data: API.ForgotPasswordDto) =>
  axios<API.BaseResponse>(
    {
      url: '/auth/forget-password',
      data,
    },
    false,
  );

export const restorePassword = (data: API.RestorePasswordDto) =>
  axios<API.BaseResponse>(
    {
      url: '/auth/restore-password',
      data,
    },
    false,
  );

export const verifyAccount = (data: API.VerifyAccountDto) =>
  axios<API.BaseResponse>(
    {
      url: '/auth/verify-account',
      data,
    },
    false,
  );

export const sendVerifyEmail = (data: API.VerifyEmailDto) =>
  axios<API.BaseResponse>(
    {
      url: '/auth/send-verify-code',
      data,
    },
    false,
  );

export const getUserProfile = () =>
  axios<API.UserProfileResponse>({
    url: '/users/get-me',
    method: 'GET',
  });

export const updateUser = (data: API.UpdateUserDto) =>
  axios<API.SecuredUser>({
    url: '/users/update-me',
    method: 'PATCH',
    data,
  });

export const updateUserPassword = (data: API.UpdatePasswordDto) =>
  axios<API.SuccessAnswer>({
    url: '/users/change-password',
    method: 'PATCH',
    data,
  });
