import React, { FC } from 'react';
import { useStore } from 'effector-react';
import { Title } from 'types/data';
import { titleModal } from 'stores/initialize/initialize.modal.store';
import { Row } from 'components/wrappers/grid/FlexWrapper';
import { black } from 'constants/styles/colors';
import { Span } from 'components/common/typography/Span/styles';
import {
  contentPadding,
  contentWrapperProps,
} from 'components/modals/TitleModal/constants';
import { ModalWrapper } from 'components/modals/ModalWrapper';

export interface TitleModalProps extends Required<Pick<Title, 'title'>> {}

export const TitleModal: FC<TitleModalProps> = ({ children, title }) => {
  const [visible, { title: payloadTitle }] = useStore(titleModal.modal);

  const onCloseClick = () => {
    titleModal.closeModal();
  };

  return (
    <ModalWrapper
      contentPadding={contentPadding}
      visible={visible && payloadTitle === title}
      onCloseClick={onCloseClick}
      {...contentWrapperProps}
    >
      <Row alignCenter marginBottom="40px" width="560px">
        <Span color={black} fontSize="18px">
          {title}
        </Span>
      </Row>
      {children}
    </ModalWrapper>
  );
};
