import { defaultModalContentPadding } from 'components/modals/ModalWrapper/constants';
import { ModalWrapperProps } from 'components/modals/ModalWrapper/index';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { grey } from 'constants/styles/colors';
import { md } from 'constants/styles/sizes';
import styled from 'styled-components';
import { Padding } from 'types/styles';

interface ModalProps
  extends Pick<ModalWrapperProps, 'modalBackgroundColor'>,
    Pick<ModalWrapperProps, 'zIndex'> {}

export const Modal = styled.div<ModalProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ modalBackgroundColor }) =>
    modalBackgroundColor || 'rgba(0, 0, 0, 0.6)'};
  z-index: ${({ zIndex }) => zIndex || '1000'};

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${md}) {
    padding: 0 16px;
  }
`;

export const ModalContentWrapper = styled(ContentWrapper)`
  position: relative;
  max-height: 95vh;
  overflow: auto;
  z-index: 1;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${grey};
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c5c5c5;
  }
`;

export const ModalContent = styled.div<Pick<Padding, 'padding'>>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => padding || defaultModalContentPadding};
`;
