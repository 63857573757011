import { Row } from 'components/wrappers/grid/FlexWrapper';
import React, { ButtonHTMLAttributes } from 'react';
import { FileNameSpan, StyledButton } from './styles';
import { saveAs } from 'file-saver';
import { BigFileIcon } from 'components/common/icons/SVGIcons';

export interface FileDownloadButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  fileName: string;
  url: string;
}

export const FileDownloadButton = ({
  fileName,
  url,
  ...buttonProps
}: FileDownloadButtonProps) => {
  const downloadFile = () => {
    saveAs(`${url}`, `${fileName}`);
  };

  return (
    <StyledButton {...buttonProps} onClick={downloadFile}>
      <BigFileIcon />
      <Row justifyCenter marginTop="5px" width="80px">
        <FileNameSpan>{fileName}</FileNameSpan>
      </Row>
    </StyledButton>
  );
};
