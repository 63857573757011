import React, { ButtonHTMLAttributes } from 'react';
import {
  StyledButton,
  IconButton,
  ModalButton,
  BackButton,
  FileButton,
} from './styles';
import {
  CustomImageProps,
  CustomImg,
} from 'components/common/imgComponents/CustomImg';
import { arrowButtonHeight, arrowButtonWidth } from './constants';
import arrowIcon from 'assets/icons/arrow-down-white-icon.svg';
import { Expanded } from 'types/data';
import {
  EditIcon,
  CloseIcon,
  StepBackIcon,
  FileIcon,
  TrashIcon,
} from 'components/common/icons/SVGIcons';
import { Disabled } from 'types/form';
import { BackgroundColor } from 'types/styles';
import { languages } from 'constants/languages';
import { Span } from 'components/common/typography/Span';
import { Row } from 'components/wrappers/grid/FlexWrapper';

interface ImageButtonProps
  extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>,
    Omit<CustomImageProps, 'onClick'> {}

export const ImageButton = ({ onClick, ...imageProps }: ImageButtonProps) => (
  <StyledButton type="button" onClick={onClick}>
    <CustomImg {...imageProps} />
  </StyledButton>
);

type OnClickType = Pick<ImageButtonProps, 'onClick'>;

interface ToggleArrowButtonProps extends OnClickType, Expanded {}

export const ToggleArrowButton = ({
  onClick,
  isExpanded,
}: ToggleArrowButtonProps) => (
  <ImageButton
    alt="arrow icon"
    height={arrowButtonHeight}
    rotate={isExpanded ? 180 : 0}
    src={arrowIcon}
    width={arrowButtonWidth}
    onClick={onClick}
  />
);

interface ButtonProps extends OnClickType, BackgroundColor {}

export const CloseModalButton = ({ onClick, backgroundColor }: ButtonProps) => (
  <ModalButton
    backgroundColor={backgroundColor}
    type="button"
    onClick={onClick}
  >
    <CloseIcon />
  </ModalButton>
);

export const DeleteAddressButton = ({ onClick }: ButtonProps) => (
  <IconButton type="button" onClick={onClick}>
    <TrashIcon />
  </IconButton>
);

export const EditAddressButton = ({ onClick }: ButtonProps) => (
  <IconButton type="button" onClick={onClick}>
    <EditIcon />
  </IconButton>
);

export const CloseButton = ({ onClick }: ButtonProps) => (
  <IconButton type="button" onClick={onClick}>
    <CloseIcon />
  </IconButton>
);

interface StepBackButtonProps extends ButtonProps, Disabled {}

export const StepBackButton = ({ onClick, disabled }: StepBackButtonProps) => (
  <BackButton disabled={disabled} type="button" onClick={onClick}>
    <StepBackIcon />
  </BackButton>
);

const { fileButton } = languages.layouts.ordersLayout;

export const AttachedFilesButton = ({ onClick }: ButtonProps) => (
  <FileButton type="button" onClick={onClick}>
    <FileIcon />
    <Row alignCenter marginLeft="7px">
      <Span color="currentColor" fontSize="12px" lineHeight="14px">
        {fileButton}
      </Span>
    </Row>
  </FileButton>
);
