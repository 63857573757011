import React from 'react';

import arrowImg from 'assets/icons/arrow-icon.svg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import { Rotation } from 'types/styles';
import { Disabled } from 'types/form';

interface Props extends Rotation, Disabled {}

export const ArrowImg = (props: Props) => (
  <CustomImg {...props} pointer height="8px" src={arrowImg} width="16px" />
);
