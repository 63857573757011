import { paleBlue, grey2 } from 'constants/styles/colors';
import { ellipsisMixin } from 'constants/styles/mixins';
import styled from 'styled-components';

export const ButtonText = styled.span`
  padding-left: 12px;
  font-size: 16px;
  line-height: 19px;
  color: ${grey2};
  ${ellipsisMixin};
`;

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  height: 48px;
  color: ${grey2};
  border: 2px solid ${grey2};
  border-radius: 8px;
  transition: color 0.3s, border-color 0.3s;

  &:hover {
    color: ${paleBlue};
    border-color: ${paleBlue};
  }

  ${ButtonText} {
    color: inherit;
  }
`;
