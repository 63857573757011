import { Button } from 'components/common/buttons/Button';
import {
  DateAndTimeRange,
  tomorrowDate,
} from 'components/common/inputs/DateInput/constants';
import { AdditionalInputText } from 'components/common/typography/AdditionalInputText';
import { TitleText } from 'components/common/typography/TitleText';
import {
  fieldsMarginBottom,
  mobileMarginBottom,
} from 'components/forms/AddressForm/constants';
import { FormConfirmCheckbox } from 'components/forms/inputs/FormConfirmCheckbox';
import { FormDateAndTimeRangeInput } from 'components/forms/inputs/FormDateAndTimeInput';
import { FormInformationBadge } from 'components/forms/inputs/FormInformationBadge';
import { FormRadio } from 'components/forms/inputs/FormRadio';
import { FormSelect } from 'components/forms/inputs/FormSelect';
import { FormTextArea } from 'components/forms/inputs/FormTextArea';
import {
  FormTextInput,
  TextInput,
} from 'components/forms/inputs/FormTextInput';
import {
  contentMarginBottom,
  defaultPickUpDateAndTimeRangeValues,
  getTotalPrice,
  yupSchema,
} from 'components/forms/OrdersFroms/ConfirmationStepForm/constants';
import { Form } from 'components/forms/styles';
import {
  StepInterface,
  Steps,
} from 'components/grid/Wizards/OrderWizard/types';
import { InvoiceModal } from 'components/modals/InvoiceModal';
import { TermsOfShipmentModal } from 'components/modals/TermsOfShipmentModal';
import { FlexGrow, Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import format from 'date-fns/format';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { addressesEffects, addressesStores } from 'stores/addresses';
import { categoriesStores } from 'stores/categories';
import { countriesStores } from 'stores/countries';
import { insurancesStores } from 'stores/insurances';
import { methodsStores } from 'stores/methods';
import { ordersEvents } from 'stores/orders';
import { packagesStores } from 'stores/packages';
import { servicesStores } from 'stores/services';
import { tariffsStores } from 'stores/tariffs';
import { OrderCurrency } from 'types/types';

const { setOrderFormValue } = ordersEvents;
const { getAllMyAddresses } = addressesEffects;

const {
  confirmationStepForm,
  text: {
    confirmationStepFormTitle,
    orderButton,
    orderPrice,
    orderPriceAdditionalInfo,
    requiredField,
    newAddressButton,
    itemStatus,
    priceComment,
  },
} = languages.pages.shipments;

interface ConfirmationStepFormProps
  extends Partial<API.CreateOrderDto>,
    StepInterface {}

export const ConfirmationStepForm = ({
  onNext,
  onFinish,
  country,
  subcategory,
  method,
  dimensions,
  weight,
  volume,
  packageType,
  services,
  address,
  comment,
}: ConfirmationStepFormProps) => {
  const {
    handleSubmit,
    formState: { isValid },
    control,
    watch,
  } = useFormSchema(yupSchema);
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const currencyValue: OrderCurrency = watch('currency');
  const addressValue = watch('address');
  const commentValue = watch('comment');

  const cargoPickUpSelected = watch('cargoPickUpCheckbox');
  const cargoPickUpAddressValue = watch('cargoPickUpAddress');
  const cargoPickUpPhoneValue = watch('cargoPickUpPhone');
  const cargoPickUpDateAndTimeRangeValue: DateAndTimeRange = watch(
    'cargoPickUpDateAndTimeRange',
  );

  const cargoPickUpFieldsFilled =
    !!cargoPickUpAddressValue &&
    !!cargoPickUpPhoneValue &&
    !!cargoPickUpDateAndTimeRangeValue;

  const formIsValid = cargoPickUpSelected
    ? isValid && cargoPickUpFieldsFilled
    : isValid;

  const { cost: tariffCost } = useStore(tariffsStores.tariffCost);
  const { cost: insuranceCost } = useStore(insurancesStores.relevantInsurance);

  const countryValue = useStore(countriesStores.countries).result?.find(
    ({ _id }) => _id === country,
  )?.title;
  const category = useStore(categoriesStores.subcategories).result?.find(
    ({ _id }) => _id === subcategory,
  )?.title;
  const methodValue = useStore(methodsStores.methods).result?.find(
    ({ _id }) => _id === method,
  )?.title;
  const packageValue = useStore(packagesStores.packages).result?.find(
    ({ _id }) => _id === packageType,
  );
  const servicesValues = useStore(servicesStores.services).result?.filter(
    ({ _id }) => services?.some((id) => id === _id),
  );

  const servicesInformationValues = servicesValues?.map(({ title, price }) => ({
    title,
    value: `${price} $`,
  }));
  const allMyAddresses = useStore(addressesStores.allMyAddresses).result.map(
    ({ title, id }) => ({ label: title, value: id }),
  );
  const totalPrice = getTotalPrice({
    tariffCost,
    insurance: insuranceCost,
    packagePrice: packageValue?.price,
    servicesPrice: servicesValues?.map(({ price }) => price),
  });

  const loadingAddresses = useStore(getAllMyAddresses.pending);

  const onSubmit = () => {
    onFinish && onFinish();
  };

  const onNewAddressClick = () => onNext(Steps.newAddressStep);

  useEffect(
    () => {
      addressValue && setOrderFormValue({ address: addressValue });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addressValue],
  );
  useEffect(
    () => {
      if (totalPrice) {
        // eslint-disable-next-line arrow-body-style
        const servicesData = servicesValues.map((item) => ({
          title: item.title,
          price: item.price,
        }));

        setOrderFormValue({
          totalPrice,
          detailsPrice: {
            tariffPrice: tariffCost,
            insurancePrice: insuranceCost,
            packagePrice: {
              title: packageValue?.title || '',
              price: packageValue?.price || 0,
            },
            servicesPrice: servicesData,
            totalPrice: totalPrice,
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [totalPrice],
  );

  useEffect(
    () => {
      commentValue && setOrderFormValue({ comment: commentValue });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commentValue],
  );

  useEffect(
    () => {
      currencyValue && setOrderFormValue({ currency: currencyValue });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currencyValue],
  );

  useEffect(
    () => {
      if (cargoPickUpSelected) {
        cargoPickUpAddressValue &&
          cargoPickUpPhoneValue &&
          cargoPickUpDateAndTimeRangeValue &&
          setOrderFormValue({
            pickUpOrder: {
              address: cargoPickUpAddressValue,
              date: format(cargoPickUpDateAndTimeRangeValue.date, 'dd.MM.yyy'),
              time: cargoPickUpDateAndTimeRangeValue.time,
              phone: cargoPickUpPhoneValue,
            },
          });
      } else {
        setOrderFormValue({
          pickUpOrder: undefined,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      cargoPickUpSelected,
      cargoPickUpAddressValue,
      cargoPickUpDateAndTimeRangeValue,
      cargoPickUpPhoneValue,
    ],
  );

  useEffect(
    () => {
      allMyAddresses.length === 0 && getAllMyAddresses();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return !isMobile ? (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section marginBottom={contentMarginBottom}>
        <TitleText>{confirmationStepFormTitle}</TitleText>
      </Section>
      <Section justifyBetween marginBottom={contentMarginBottom}>
        <FlexGrow flexBasis="48%" flexGrow="0">
          <TextInput
            disabled
            isSecondaryTitle
            name="country"
            value={countryValue}
            {...confirmationStepForm['country']}
          />
        </FlexGrow>
        <FlexGrow flexBasis="48%" flexGrow="0">
          <TextInput
            disabled
            isSecondaryTitle
            name="category"
            value={category}
            {...confirmationStepForm['category']}
          />
        </FlexGrow>
      </Section>
      <Section justifyBetween marginBottom={contentMarginBottom}>
        <FlexGrow flexBasis="48%" flexGrow="0">
          <TextInput
            disabled
            isSecondaryTitle
            name="method"
            value={methodValue}
            {...confirmationStepForm['method']}
          />
        </FlexGrow>
        <FlexGrow flexBasis="48%" flexGrow="0">
          <TextInput
            disabled
            isSecondaryTitle
            name="dimensions"
            value={dimensions}
            {...confirmationStepForm['dimensions']}
          />
        </FlexGrow>
      </Section>
      <Section justifyBetween marginBottom={contentMarginBottom}>
        <FlexGrow flexBasis="22%" flexGrow="0">
          <TextInput
            disabled
            isSecondaryTitle
            name="weight"
            value={weight}
            {...confirmationStepForm['weight']}
          />
        </FlexGrow>
        <FlexGrow flexBasis="22%" flexGrow="0">
          <TextInput
            disabled
            isSecondaryTitle
            name="volume"
            value={volume}
            {...confirmationStepForm['volume']}
          />
        </FlexGrow>
        <FlexGrow flexBasis="48%" flexGrow="0">
          <FormInformationBadge
            isSecondaryTitle
            colorType="white"
            content={{
              title: itemStatus[insuranceCost ? 1 : 0],
              value: `${insuranceCost || '0'} $`,
            }}
            disabled={!insuranceCost}
            name="insurance"
            {...confirmationStepForm['insurance']}
          />
        </FlexGrow>
      </Section>
      <Section marginBottom={contentMarginBottom}>
        <FormInformationBadge
          isSecondaryTitle
          colorType="white"
          content={{
            title: packageValue?.title || itemStatus[0],
            value: `${packageValue?.price || 0} $`,
          }}
          disabled={!packageValue?.title}
          name="package"
          {...confirmationStepForm['package']}
        />
      </Section>
      <Section marginBottom="15px">
        <FormInformationBadge
          isSecondaryTitle
          colorType="white"
          content={
            servicesInformationValues.length > 0
              ? servicesInformationValues
              : { title: itemStatus[0], value: `0 $` }
          }
          disabled={!servicesInformationValues.length}
          name="services"
          {...confirmationStepForm['services']}
        />
      </Section>
      <Section marginBottom="15px">
        <FormRadio
          control={control}
          defaultSelectedValue={OrderCurrency.Yuan}
          id="currency"
          name="currency"
          {...confirmationStepForm['currency']}
        />
      </Section>
      <Section alignEnd justifyBetween marginBottom="15px">
        <FlexGrow flexBasis="58%" flexGrow="0">
          <FormSelect
            control={control}
            defaultValue={allMyAddresses.find(({ value }) => value === address)}
            id="address"
            isLoading={loadingAddresses}
            name="address"
            selector={allMyAddresses}
            {...confirmationStepForm['address']}
          />
        </FlexGrow>
        <FlexGrow flexBasis="38%" flexGrow="0">
          <Button
            buttonType="form"
            type="button"
            width="100%"
            onClick={onNewAddressClick}
          >
            {newAddressButton}
          </Button>
        </FlexGrow>
      </Section>
      <Section marginBottom={contentMarginBottom}>
        <Section>
          <FormTextArea
            control={control}
            name="comment"
            {...confirmationStepForm['comment']}
            defaultValue={comment}
          />
        </Section>
        <Row>
          <AdditionalInputText>{requiredField}</AdditionalInputText>
        </Row>
      </Section>
      <Section marginBottom={contentMarginBottom}>
        <FormConfirmCheckbox
          control={control}
          id="confirmation"
          name="confirmation"
          {...confirmationStepForm['confirmation']}
        />
      </Section>
      <Section marginBottom={fieldsMarginBottom}>
        <FormConfirmCheckbox
          control={control}
          id="cargoPickUpCheckbox"
          name="cargoPickUpCheckbox"
          {...confirmationStepForm['cargoPickUpCheckbox']}
        />
      </Section>
      {cargoPickUpSelected && (
        <>
          <Section justifyBetween marginBottom={contentMarginBottom}>
            <FormTextInput
              isSecondaryTitle
              control={control}
              defaultValue={cargoPickUpAddressValue}
              id="cargoPickUpAddress"
              name="cargoPickUpAddress"
              type="text"
              {...confirmationStepForm['cargoPickUpAddress']}
            />
          </Section>
          <Section marginBottom={contentMarginBottom}>
            <FormDateAndTimeRangeInput
              isSecondaryTitle
              control={control}
              defaultValue={defaultPickUpDateAndTimeRangeValues}
              id="cargoPickUpDateAndTimeRange"
              minDate={tomorrowDate}
              name="cargoPickUpDateAndTimeRange"
              {...confirmationStepForm['cargoPickUpDateAndTimeRange']}
            />
          </Section>
          <Section justifyBetween marginBottom={fieldsMarginBottom}>
            <FormTextInput
              isSecondaryTitle
              control={control}
              defaultValue={cargoPickUpPhoneValue}
              id="cargoPickUpPhone"
              name="cargoPickUpPhone"
              type="text"
              {...confirmationStepForm['cargoPickUpPhone']}
            />
          </Section>
        </>
      )}
      <Section alignEnd justifyBetween marginBottom={contentMarginBottom}>
        <Row width="49%">
          <FormInformationBadge
            content={{ value: `${totalPrice} $` }}
            minWidth="60%"
            title={orderPrice}
            type="total"
          />
        </Row>
        <Row width="49%">
          <Button disabled={!formIsValid}>{orderButton}</Button>
        </Row>
      </Section>
      <Section>
        <Row width="180px">
          <AdditionalInputText isSecondary>
            {orderPriceAdditionalInfo}
          </AdditionalInputText>
        </Row>
      </Section>
      <TermsOfShipmentModal />
      <InvoiceModal />
    </Form>
  ) : (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section marginBottom={contentMarginBottom}>
        <TitleText>{confirmationStepFormTitle}</TitleText>
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <TextInput
          disabled
          name="country"
          value={countryValue}
          {...confirmationStepForm['country']}
        />
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <TextInput
          disabled
          name="category"
          value={category}
          {...confirmationStepForm['category']}
        />
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <TextInput
          disabled
          name="method"
          value={methodValue}
          {...confirmationStepForm['method']}
        />
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <TextInput
          disabled
          name="dimensions"
          value={dimensions}
          {...confirmationStepForm['dimensions']}
        />
      </Section>
      <Section justifyBetween noWrap marginBottom={mobileMarginBottom}>
        <Section marginRight="15px">
          <TextInput
            disabled
            name="weight"
            value={weight}
            {...confirmationStepForm['weight']}
          />
        </Section>
        <Section>
          <TextInput
            disabled
            name="volume"
            value={volume}
            {...confirmationStepForm['volume']}
          />
        </Section>
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <FormInformationBadge
          colorType="white"
          content={{
            title: itemStatus[insuranceCost ? 1 : 0],
            value: `${insuranceCost || '0'} $`,
          }}
          disabled={!insuranceCost}
          name="insurance"
          {...confirmationStepForm['insurance']}
        />
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <FormInformationBadge
          colorType="white"
          content={{
            title: packageValue?.title || itemStatus[0],
            value: `${packageValue?.price || 0} $`,
          }}
          disabled={!packageValue?.title}
          name="package"
          {...confirmationStepForm['package']}
        />
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <FormInformationBadge
          colorType="white"
          content={
            servicesInformationValues.length > 0
              ? servicesInformationValues
              : { title: itemStatus[0], value: `0 $` }
          }
          disabled={!servicesInformationValues.length}
          name="services"
          {...confirmationStepForm['services']}
        />
      </Section>
      <Section>
        <FormRadio
          control={control}
          defaultSelectedValue={OrderCurrency.Yuan}
          id="currency"
          name="currency"
          {...confirmationStepForm['currency']}
        />
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <FormSelect
          control={control}
          defaultValue={allMyAddresses.find(({ value }) => value === address)}
          id="address"
          isLoading={loadingAddresses}
          name="address"
          selector={allMyAddresses}
          {...confirmationStepForm['address']}
        />
      </Section>
      <Section marginBottom="24px">
        <Button
          buttonType="form"
          type="button"
          width="100%"
          onClick={onNewAddressClick}
        >
          {newAddressButton}
        </Button>
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <Section>
          <FormTextArea
            control={control}
            name="comment"
            {...confirmationStepForm['comment']}
            defaultValue={comment}
          />
        </Section>
        <Row>
          <AdditionalInputText>{requiredField}</AdditionalInputText>
        </Row>
      </Section>
      <Section marginBottom={mobileMarginBottom}>
        <FormConfirmCheckbox
          control={control}
          id="confirmation"
          name="confirmation"
          {...confirmationStepForm['confirmation']}
        />
      </Section>
      <Section marginBottom={fieldsMarginBottom}>
        <FormConfirmCheckbox
          control={control}
          id="cargoPickUpCheckbox"
          name="cargoPickUpCheckbox"
          {...confirmationStepForm['cargoPickUpCheckbox']}
        />
      </Section>
      {cargoPickUpSelected && (
        <>
          <Section justifyBetween marginBottom={contentMarginBottom}>
            <FormTextInput
              isSecondaryTitle
              control={control}
              defaultValue={cargoPickUpAddressValue}
              id="cargoPickUpAddress"
              name="cargoPickUpAddress"
              type="text"
              {...confirmationStepForm['cargoPickUpAddress']}
            />
          </Section>
          <Section marginBottom={contentMarginBottom}>
            <FormDateAndTimeRangeInput
              isSecondaryTitle
              control={control}
              defaultValue={defaultPickUpDateAndTimeRangeValues}
              id="cargoPickUpDateAndTimeRange"
              minDate={tomorrowDate}
              name="cargoPickUpDateAndTimeRange"
              {...confirmationStepForm['cargoPickUpDateAndTimeRange']}
            />
          </Section>
          <Section justifyBetween marginBottom={fieldsMarginBottom}>
            <FormTextInput
              isSecondaryTitle
              control={control}
              defaultValue={cargoPickUpPhoneValue}
              id="cargoPickUpPhone"
              name="cargoPickUpPhone"
              type="text"
              {...confirmationStepForm['cargoPickUpPhone']}
            />
          </Section>
        </>
      )}
      <Section marginBottom={mobileMarginBottom}>
        <FormInformationBadge
          content={{ value: `${totalPrice} $` }}
          title={orderPrice}
          type="total"
        />
        <Row marginTop="6px" width="177px">
          <AdditionalInputText>{priceComment}</AdditionalInputText>
        </Row>
      </Section>
      <Button disabled={!formIsValid} width="100%">
        {orderButton}
      </Button>
      <TermsOfShipmentModal />
      <InvoiceModal />
    </Form>
  );
};
