import styled, { css } from 'styled-components';

export const CheckboxTextMixin = css`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;
export const CheckboxText = styled.span`
  ${CheckboxTextMixin}
`;
