import { createStore } from 'effector';
import { API } from 'api';
import { defaultGetAllResponse } from 'constants/defaults/store';
import { createNotifyingErrorEffect } from 'utils/store';

const getSubcategoriesByCountry = createNotifyingErrorEffect({
  handler: async (data: API.GetSubcategoriesByCountryRequestDto) =>
    await API.categories.getAllSubCategoriesByCountry(data),
});

const subcategories = createStore<API.GetSubcategoriesByCountryResponseDto>(
  defaultGetAllResponse,
).on(getSubcategoriesByCountry.doneData, (_, newState) => newState);

export const categoriesEffects = {
  getSubcategoriesByCountry,
};
export const categoriesEvents = {};
export const categoriesStores = { subcategories };
