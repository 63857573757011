import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Description, TableRow, Title } from './styles';

export interface MobileTableData {
  title: string;
  value?: string;
}

interface Props {
  data: MobileTableData[];
}

export const MobileTable = ({ data }: Props) => {
  const isSmallMobile = useMediaQuery(MediaSizes.MobileSmall);

  return (
    <Column marginBottom="16px" width="100%">
      {data.map(({ title, value }) => (
        <TableRow key={title}>
          <Row marginLeft="14px" width={isSmallMobile ? '100px' : '55%'}>
            <Title>{title}</Title>
          </Row>
          <Row marginLeft="9px" marginRight="14px" width="50%">
            <Description>{value}</Description>
          </Row>
        </TableRow>
      ))}
    </Column>
  );
};
