import { Button } from 'components/common/buttons/Button';
import { Pagination } from 'components/layouts/Pagination';
import { Column, Section } from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { toNewAddressLinkClick } from 'constants/routes';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React, { FC, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { addressesEvents, addressesStores } from 'stores/addresses';
import { LayoutWrapper, PaginationWrapper } from './styles';

const { updateValues, setIsFirstToFalse, invokeGetItems } = addressesEvents;

const { addButton } = languages.layouts.addressesLayout;

interface AddressesLayoutProps {
  totalPages: number;
}

export const AddressesLayout: FC<AddressesLayoutProps> = ({
  children,
  totalPages,
}) => {
  const isFirst = useStore(addressesStores.isFirst);
  const { page } = useStore(addressesStores.values);
  // const [paginationIndex, setPaginationIndex] = useState(1);
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const onPaginationChange = (current: number) => {
    updateValues({ page: current + 1 });
  };

  useEffect(() => {
    if (isFirst) {
      invokeGetItems();
      setIsFirstToFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutWrapper>
      <Column width="100%">{children}</Column>
      <Section>
        <Button
          buttonType="body"
          width={isMobile ? '100%' : '240px'}
          onClick={toNewAddressLinkClick}
        >
          {addButton}
        </Button>
      </Section>
      <PaginationWrapper>
        <Pagination
          currentIndex={page}
          totalPages={totalPages}
          onSizeChange={onPaginationChange}
        />
      </PaginationWrapper>
    </LayoutWrapper>
  );
};
