import * as user from 'api/users';
import * as addresses from 'api/addresses';
import * as orders from 'api/orders';
import * as countries from 'api/countries';
import * as methods from 'api/methods';
import * as categories from 'api/categories';
import * as packages from 'api/packages';
import * as services from 'api/services';
import * as insurances from 'api/insurances';
import * as tariffs from 'api/tariffs';

export const API = {
  user,
  addresses,
  orders,
  countries,
  methods,
  categories,
  packages,
  services,
  insurances,
  tariffs,
};
