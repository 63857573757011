import { Button } from 'components/common/buttons/Button';
import { InformationBadge } from 'components/common/dividers/InformationBadge';
import { mobileMarginBottom } from 'components/forms/AddressForm/constants';
import { FormCheckboxes } from 'components/forms/inputs/FormCheckbox';
import { FormSelect } from 'components/forms/inputs/FormSelect';
import { getTotalPrice } from 'components/forms/OrdersFroms/ConfirmationStepForm/constants';
import {
  contentMarginBottom,
  yupSchema,
} from 'components/forms/OrdersFroms/PackageStepForm/constants';
import { PackageStepData } from 'components/forms/OrdersFroms/PackageStepForm/types';
import { Form } from 'components/forms/styles';
import {
  StepInterface,
  Steps,
} from 'components/grid/Wizards/OrderWizard/types';
import { Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { getInformationPriceString } from 'constants/functions';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import { useFormSchema } from 'hooks/form';
import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ordersEvents } from 'stores/orders';
import { packagesEffects, packagesStores } from 'stores/packages';
import { servicesEffects, servicesStores } from 'stores/services';
import { tariffsStores } from 'stores/tariffs';

const { getAllPackages } = packagesEffects;
const { getAllServices } = servicesEffects;

const {
  packageStepForm,
  text: {
    submitButton,
    PackageDescriptionJSX,
    weightTitle,
    weightMeasurement,
    price,
  },
} = languages.pages.shipments;

const { setOrderFormValue } = ordersEvents;

interface PackageStepFormProps
  extends Pick<StepInterface, 'onNext'>,
    Pick<Partial<API.CreateOrderDto>, 'services' | 'packageType' | 'weight'> {
  insurance: number;
}

export const PackageStepForm = ({
  onNext,
  insurance,
  packageType,
  services,
  weight,
}: PackageStepFormProps) => {
  const {
    handleSubmit,
    formState: { isValid },
    control,
    watch,
  } = useFormSchema(yupSchema);

  const { cost } = useStore(tariffsStores.tariffCost);
  const packageTypeID = watch('packageType');
  const servicesIDs = watch('services');
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const packagesResult = useStore(packagesStores.packages).result;
  const packages = packagesResult.map(({ title, _id, price }) => ({
    value: _id,
    label: title,
    information: getInformationPriceString(price),
  }));
  const servicesValues = useStore(servicesStores.services).result;
  const servicesData = servicesValues?.map(({ title, _id, price }) => ({
    value: _id,
    title,
    badgeInfo: getInformationPriceString(price),
  }));

  const loadingPackages = useStore(getAllPackages.pending);
  const loadingServices = useStore(getAllServices.pending);

  const totalPrice = getTotalPrice({
    tariffCost: cost,
    insurance,
    packagePrice: packagesResult.find(({ _id }) => _id === packageTypeID)
      ?.price,
    servicesPrice: servicesValues
      ?.filter(({ _id }) => (servicesIDs as string[])?.some((id) => id === _id))
      ?.map(({ price }) => price),
  });

  const onSubmit = async ({ services, packageType }: PackageStepData) => {
    setOrderFormValue({
      services,
      packageType,
    });

    onNext(Steps.confirmationStep);
  };

  useEffect(() => {
    packages.length === 0 && getAllPackages();
    servicesData.length === 0 && getAllServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section
        height={isMobile ? 'auto' : '130px'}
        marginBottom={isMobile ? '24px' : contentMarginBottom}
      >
        <FormSelect
          control={control}
          defaultValue={packages.find(({ value }) => value === packageType)}
          id="packageType"
          isLoading={loadingPackages}
          name="packageType"
          selector={packages}
          {...packageStepForm['packageType']}
        />
        {packageTypeID && (
          <Section marginTop="10px">
            <PackageDescriptionJSX />
          </Section>
        )}
      </Section>
      <Section marginBottom={isMobile ? '0' : '35px'}>
        <FormCheckboxes
          {...packageStepForm['services']}
          checkboxes={servicesData}
          control={control}
          defaultValue={services}
          isLoading={loadingServices}
          name="services"
        />
      </Section>
      {!isMobile ? (
        <>
          <Section justifyBetween marginBottom="35px">
            <Row width="49%">
              <InformationBadge
                content={{
                  value: `${weight || 0} ${weightMeasurement}`,
                  title: weightTitle,
                }}
                minWidth="70%"
                type="information"
              />
            </Row>
            <Row justifyEnd width="49%">
              <InformationBadge
                content={{ value: `${totalPrice} $`, title: price }}
                minWidth="70%"
                type="information"
              />
            </Row>
          </Section>
          <Section alignCenter justifyCenter>
            <Button disabled={!isValid} type="submit">
              {submitButton}
            </Button>
          </Section>
        </>
      ) : (
        <>
          <Section marginBottom={mobileMarginBottom}>
            <InformationBadge
              content={{
                value: `${weight || 0} ${weightMeasurement}`,
                title: weightTitle,
              }}
              type="information"
            />
          </Section>
          <Section marginBottom={mobileMarginBottom}>
            <InformationBadge
              content={{ value: `${totalPrice} $`, title: price }}
              type="information"
            />
          </Section>
          <Section alignCenter justifyCenter>
            <Button disabled={!isValid} type="submit" width="100%">
              {submitButton}
            </Button>
          </Section>
        </>
      )}
    </Form>
  );
};
