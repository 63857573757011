import { createEffect, createEvent, createStore } from 'effector';
import connectLocalStorage from 'effector-localstorage';
import { userStorageName, userTokenStorageName } from 'constants/storage';
import { API } from 'api';
import { toLoginLinkClick, toPersonalLinkClick } from 'constants/routes';
import { informationModalStore } from 'stores/initialize/initialize.modal.store';
import { languages } from 'constants/languages';
import { ordersEvents } from 'stores/orders';
import { addressesEvents } from 'stores/addresses';
import { getLocalTimeZone } from 'constants/functions';
import {
  createNotifyingErrorEffect,
  getDataAndOpenErrorModal,
} from 'utils/store';

const {
  sendingEmail,
  successPasswordChange,
  successRegistration,
  successVerify,
} = languages.modals.informationModal;

// * connect to local storage
const userTokenLocalStorage = connectLocalStorage(userTokenStorageName).onError(
  (err) => console.log(err),
);

const userLocalStorage = connectLocalStorage(userStorageName).onError((err) =>
  console.log(err),
);

const logout = createEffect({
  handler: async (data: API.LogOutDto) => {
    try {
      return await API.user.logOut(data);
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const signUpWithSocialMedia = createNotifyingErrorEffect({
  handler: async (data: API.SignUpWithSocialMediaDto) =>
    await API.user.signUpWithSocialMedia(data),
});

const loadToken = createNotifyingErrorEffect({
  handler: async (data: API.LoginDto) => await API.user.authenticateUser(data),
});

const setToken = createEvent<API.AuthResponseDto>();

const formCreateUser = createEffect({
  handler: async (data: Omit<API.SignUpDto, 'timeZone'>) => {
    try {
      const user = await API.user.createUser({
        ...data,
        timeZone: getLocalTimeZone(),
      });

      informationModalStore.openModal({
        message: successRegistration,
        actionAfterCloseClick: toLoginLinkClick,
      });

      return { user };
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const formRecoverPassword = createEffect({
  handler: async (data: API.ForgotPasswordDto) => {
    try {
      await API.user.recoverPassword(data);

      informationModalStore.openModal({
        message: sendingEmail,
      });
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const formRestorePassword = createEffect({
  handler: async (data: API.RestorePasswordDto) => {
    try {
      await API.user.restorePassword(data);

      informationModalStore.openModal({
        message: successPasswordChange,
        actionAfterCloseClick: toLoginLinkClick,
      });
    } catch (error) {
      getDataAndOpenErrorModal(error);
      toLoginLinkClick();
    }
  },
});

const formSendVerifyEmail = createEffect({
  handler: async (data: API.VerifyEmailDto) => {
    try {
      await API.user.sendVerifyEmail(data);

      informationModalStore.openModal({
        message: sendingEmail,
        actionAfterCloseClick: toPersonalLinkClick,
      });
    } catch (error) {
      getDataAndOpenErrorModal(error);
      toPersonalLinkClick();
    }
  },
});

const formVerifyAccount = createEffect({
  handler: async (data: API.VerifyAccountDto) => {
    try {
      await API.user.verifyAccount(data);

      informationModalStore.openModal({
        message: successVerify,
        actionAfterCloseClick: toLoginLinkClick,
      });
    } catch (error) {
      getDataAndOpenErrorModal(error);
      toLoginLinkClick();
    } finally {
      setTimeout(() => {
        informationModalStore.closeModal();
        toLoginLinkClick();
      }, 3000);
    }
  },
});

const clearUserProfile = createEvent();

const getUserProfile = createEffect({
  handler: async () => {
    try {
      return await API.user.getUserProfile();
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const updateUser = createNotifyingErrorEffect({
  handler: async (data: API.UpdateUserDto) => await API.user.updateUser(data),
});

const formUpdateUser = createEffect({
  handler: async (data: API.UpdateUserDto) => {
    await updateUser(data);
    await getUserProfile();
  },
});

const formUpdateUserPassword = createEffect({
  handler: async (data: API.UpdatePasswordDto) => {
    try {
      await API.user.updateUserPassword(data);
      informationModalStore.openModal({
        message: successPasswordChange,
      });
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const updateTimeZone = createEffect({
  handler: async () => {
    const response = await getUserProfile();
    const localTimeZone = getLocalTimeZone();

    if (response?.timeZone !== localTimeZone) {
      await updateUser({ timeZone: localTimeZone });
    }
  },
});

// * stores
const user = createStore<Partial<API.AuthResponseDto>>(
  userTokenLocalStorage.init({}),
)
  .on([loadToken.doneData, setToken], (_, newData) => newData)
  .on(logout.doneData, () => ({}));

user.watch(userTokenLocalStorage);
user.watch(({ accessToken }) => {
  accessToken && updateTimeZone();
});

logout.watch(() => {
  clearUserProfile();
  ordersEvents.setIsFirstToTrue();
  addressesEvents.setIsFirstToTrue();
});

const userProfile = createStore<Partial<API.UserProfileResponse>>(
  userLocalStorage.init({}),
)
  .on(getUserProfile.doneData, (_, newState) => newState)
  .on(clearUserProfile, () => ({}));

userProfile.watch(userLocalStorage);

// * exports
export const userEvents = { setToken };
export const userEffects = {
  logout,
  loadToken,
  updateUser,
  formCreateUser,
  formRecoverPassword,
  formRestorePassword,
  formVerifyAccount,
  getUserProfile,
  formSendVerifyEmail,
  formUpdateUser,
  formUpdateUserPassword,
  clearUserProfile,
  signUpWithSocialMedia,
};
export const userStores = { user, userProfile };
