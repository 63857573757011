import { HeaderTypeButton } from 'components/common/inputs/DateInput/index';
import { placeholderInputColor } from 'components/common/inputs/Input/constants';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import {
  black,
  darkBlue,
  grey,
  grey6,
  raspberry,
} from 'constants/styles/colors';
import { disableDefaultButtonStyleMixin } from 'constants/styles/mixins';
import { md, sm } from 'constants/styles/sizes';
import styled, { css } from 'styled-components';
import { Active } from 'types/global';
import {
  BackgroundColor,
  Rotation,
  Sizes,
  TextProperties,
  Visibility,
} from 'types/styles';

export const DataPickerStyles = css`
  .react-datepicker__input-container {
    input {
      width: 100%;
      height: 48px;
      border-radius: 10px;
      background-color: white;
      border: 0;
      padding: 0 12px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      :focus {
        border: 0;
      }

      ::placeholder {
        color: ${placeholderInputColor};
      }
    }
  }
  .date_picker.full-width {
    width: 100%;
  }
  div > .react-datepicker {
    border: 0;
    border-radius: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    box-shadow: 0 9px 12px rgb(0 0 0 / 24%);
    top: 6px;
  }
  .react-datepicker > .react-datepicker__triangle {
    visibility: hidden;
  }
  .react-datepicker__tab-loop > .react-datepicker-popper {
    margin: 0;
  }
  .react-datepicker-popper {
    z-index: 30;
  }
  .react-datepicker__month-container > .react-datepicker__header {
    background-color: white;
    border: 0;
  }
  .react-datepicker__header > .react-datepicker__current-month {
    padding: 10px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
  }
  .react-datepicker__navigation {
    top: 22px;
  }
  .react-datepicker__navigation--previous {
    left: 20px;
  }
  .react-datepicker__navigation--next {
    right: 20px;
  }

  .react-datepicker__week > .react-datepicker__day,
  .react-datepicker__day-names > .react-datepicker__day-name {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    height: 27px;
    align-items: center;
  }
  .react-datepicker__day-names {
    border-bottom: 2px solid #f6f7f9;
  }
  .react-datepicker__day-name {
    color: ${raspberry};
  }
  .react-datepicker__month-container > .react-datepicker__month {
    margin: 0;
  }
  .react-datepicker__month-container {
    padding: 10px 17px 14px 17px;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day:hover,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-range {
    border-radius: 6px;
    background-color: ${raspberry};
    //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.17);
    outline: none;
    color: white;
  }
  .react-datepicker__day--outside-month,
  .react-datepicker__day--disabled {
    color: #83869b;
  }
  .react-datepicker__day--disabled:hover {
    background-color: transparent;
    color: #83869b;
    cursor: not-allowed;
  }
  .react-datepicker__day--range-start {
    color: white;
  }
  .react-datepicker__day--in-range {
    color: white;
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__year-text--in-selecting-range {
    //background-color: rgba(0, 0, 0, 0.3);
    //border-radius: 50%;
  }
  .react-datepicker__day--selected + .react-datepicker__day--selected {
    color: white;
  }
  .react-datepicker__close-icon {
    right: 30px;
  }

  @media screen and (max-width: ${sm}) {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }
`;

export const HeaderSpan = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;

interface DatePickerHeaderButton extends HeaderTypeButton, Rotation {}

export const DatePickerHeaderButton = styled.button<DatePickerHeaderButton>`
  height: 16px;
  width: 16px;
  ${({ rotate }) => (rotate ? `transform: rotate(${rotate}deg);` : '')};

  svg {
    width: ${({ typeButton }) => (typeButton === 'year' ? '16px' : '8px')};
    height: 10px;
  }

  :hover {
    svg {
      fill: ${darkBlue};
    }
  }

  :active {
    svg {
      fill: ${raspberry};
    }
  }

  :disabled {
    cursor: not-allowed;
    svg {
      fill: #b4bac0;
    }
  }
`;

interface SelectorProps
  extends Visibility,
    BackgroundColor,
    Pick<Sizes, 'height'>,
    Pick<TextProperties, 'fontSize'> {}

export const Selector = styled(Row)<SelectorProps>`
  position: relative;
  ${({ height }) => height && `height: ${height}`};
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  justify-content: space-between;
  align-items: center;
  flex-wrap: noWrap;
  padding: 10px;
  border: 1px solid ${grey};
  border-radius: 6px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};

  cursor: pointer;

  ${({ visible, backgroundColor }) =>
    visible &&
    css`
      background-color: ${backgroundColor} || '#f8f8f8'};
      border-radius: 10px 10px 0 0;
    `};
`;

interface ItemSpanProps extends Pick<TextProperties, 'fontWeight'> {}

export const ItemSpan = styled.span<ItemSpanProps>`
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  font-family: Arial;
  color: ${black};
`;

export const ArrowImage = styled.img<Visibility>`
  width: 12px;
  height: 12px;
  transform: ${({ visible }) => (visible ? `rotate(180deg)` : '0')};
  transition: transform 0.3s;
`;

export const ListWrapper = styled(Column)<Visibility>`
  position: absolute;
  display: none;
  height: 200px;
  border-radius: 0 0 10px 10px;
  border: 1px solid ${grey};
  border-top: none;
  background-color: #f8f8f8;
  outline: none;
  overflow-y: scroll;
  z-index: 1;

  cursor: pointer;

  ${({ visible }) =>
    visible &&
    css`
      display: block;
    `};

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${grey};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #c5c5c5;
  }
`;

export const ListItemWrapper = styled.button.attrs({ type: 'button' })<Active>`
  ${disableDefaultButtonStyleMixin};
  width: 100%;
  padding: 5px 10px;
  text-align: left;
  transition: background-color 0.3s;

  :hover {
    background-color: #ededed;
  }

  ${({ active }) =>
    active &&
    css`
      ${ItemSpan} {
        color: ${raspberry};
      }
    `}
`;

export const IconWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 16px;
  right: 12px;
  top: 0;
  color: ${grey6};
  display: flex;
  align-items: center;
`;

export const RowFlexGrow = styled(Row)`
  width: 40%;

  @media screen and (max-width: ${md}) {
    width: 100%;
  }
`;

export const TimeRangeWrapper = styled(Row)`
  margin-left: 20px;

  @media screen and (max-width: ${md}) {
    margin-top: 16px;
  }

  @media screen and (max-width: 370px) {
    width: 100%;
    justify-content: space-between;
  }

  min-width: 280px;
  flex-wrap: nowrap;
`;
