import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import React from 'react';
import addIcon from 'assets/icons/add-icon.svg';
import { addIconDiameter } from './constants';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { languages } from 'constants/languages';
import { shipmentsLink } from 'constants/routes';
import { StyledLink } from './styles';

const { addButton } = languages.layouts.ordersLayout;

export const AddNewOrderButton = () => (
  <StyledLink to={shipmentsLink}>
    <MarginWrapper marginRight="10px">
      <CustomImage
        height={addIconDiameter}
        src={addIcon}
        width={addIconDiameter}
      />
    </MarginWrapper>
    {addButton}
  </StyledLink>
);
