import { Tab } from 'components/grid/Bars/TabBar/types';
import { languages } from 'constants/languages';
import { loginLink, registerLink } from 'constants/routes';

const { registration, login } = languages.layouts.tabs.authTab;

export const tabs: Tab[] = [
  {
    path: loginLink,
    description: login,
  },
  {
    path: registerLink,
    description: registration,
  },
];

export const authLayoutWidth = '808px';
