import { TextButton } from 'components/common/buttons/TextButton';
import {
  DateRangeType,
  OneFieldDateRangePicker,
} from 'components/common/inputs/DateInput';
import { Input } from 'components/common/inputs/Input';
import { Select } from 'components/common/inputs/Select';
import { mobileMarginBottom } from 'components/forms/AddressForm/constants';
import { accountTabWidth } from 'components/layouts/AccountLayout/constants';
import {
  filterInputBasis,
  filterInputHeight,
  orderStatusSelectorStyles,
} from 'components/layouts/OrdersLayout/constants';
import { Pagination } from 'components/layouts/Pagination';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { FlexGrow, Section } from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ordersEvents, ordersStores } from 'stores/orders';
import { SelectorType } from 'types/types';
import { LayoutWrapper, PaginationWrapper } from './styles';

const orderStatus: SelectorType[] = languages.inputs.orderSelector.orderStatus;

const { updateValues, invokeGetItems, setIsFirstToFalse, setDefaultValues } =
  ordersEvents;

const {
  inputs,
  text: { resetFilter },
} = languages.layouts.ordersLayout;

interface OrdersLayoutProps {
  totalPages?: number;
}

export const OrdersLayout: FC<OrdersLayoutProps> = ({
  children,
  totalPages = 0,
}) => {
  const isFirst = useStore(ordersStores.isFirst);
  const { page, orderId, status, dateTo, dateFrom } = useStore(
    ordersStores.values,
  );
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const [dateRange, setDateRange] = useState<DateRangeType>([dateFrom, dateTo]);

  const onPaginationChange = (current: number) => {
    updateValues({ page: current + 1 });
  };

  const onDateRangeChange = (dates: DateRangeType) => {
    const [start, end] = dates;
    setDateRange(dates);
    start &&
      end &&
      updateValues({
        dateFrom: start,
        dateTo: end,
      });

    !start &&
      !end &&
      updateValues({
        dateFrom: undefined,
        dateTo: undefined,
      });
  };

  const onOrderIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateValues({
      orderId: e.target.value ? e.target.value : undefined,
    });
  };

  const onStatusOrderChange = ({ value }: SelectorType) => {
    updateValues({
      status: status === value ? undefined : value,
    });
  };

  const onResetClick = () => setDefaultValues();

  useEffect(() => {
    setDateRange([dateFrom, dateTo]);
  }, [dateTo, dateFrom]);

  useEffect(() => {
    if (isFirst) {
      invokeGetItems();
      setIsFirstToFalse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutWrapper>
      <ContentWrapper
        marginBottom="10px"
        maxWidth={accountTabWidth}
        padding="17px 20px"
        width="100%"
      >
        <Section justifyEnd marginBottom="10px">
          <TextButton onClick={onResetClick}>{resetFilter}</TextButton>
        </Section>
        {!isMobile ? (
          <Section justifyBetween>
            <FlexGrow flexBasis={filterInputBasis} flexGrow="0">
              <Input
                height={filterInputHeight}
                // prefix="№"
                value={orderId}
                onChange={onOrderIdChange}
                {...inputs['orderNumber']}
              />
            </FlexGrow>
            <FlexGrow flexBasis={filterInputBasis} flexGrow="0">
              <Select
                defaultValue={orderStatus.find(({ value }) => value === status)}
                height={filterInputHeight}
                selector={orderStatus}
                onChange={onStatusOrderChange}
                {...inputs['orderStatus']}
                {...orderStatusSelectorStyles}
              />
            </FlexGrow>
            <FlexGrow flexBasis={filterInputBasis} flexGrow="0">
              <OneFieldDateRangePicker
                dateRange={dateRange}
                {...inputs['orderDateRange']}
                height={filterInputHeight}
                onChange={onDateRangeChange}
              />
            </FlexGrow>
          </Section>
        ) : (
          <>
            <FlexGrow
              flexBasis={filterInputBasis}
              flexGrow="0"
              marginBottom={mobileMarginBottom}
            >
              <Input
                height={filterInputHeight}
                // prefix="№"
                value={orderId}
                onChange={onOrderIdChange}
                {...inputs['orderNumber']}
              />
            </FlexGrow>
            <FlexGrow
              flexBasis={filterInputBasis}
              flexGrow="0"
              marginBottom={mobileMarginBottom}
            >
              <Select
                defaultValue={orderStatus.find(({ value }) => value === status)}
                height={filterInputHeight}
                selector={orderStatus}
                onChange={onStatusOrderChange}
                {...inputs['orderStatus']}
                {...orderStatusSelectorStyles}
              />
            </FlexGrow>
            <FlexGrow
              flexBasis={filterInputBasis}
              flexGrow="0"
              marginBottom={mobileMarginBottom}
            >
              <OneFieldDateRangePicker
                dateRange={dateRange}
                {...inputs['orderDateRange']}
                height={filterInputHeight}
                onChange={onDateRangeChange}
              />
            </FlexGrow>
          </>
        )}
      </ContentWrapper>
      {children}
      {totalPages > 1 && (
        <PaginationWrapper>
          <Pagination
            currentIndex={page}
            totalPages={totalPages}
            onSizeChange={onPaginationChange}
          />
        </PaginationWrapper>
      )}
    </LayoutWrapper>
  );
};
