import arrowIcon from 'assets/icons/arrow-icon.svg';
import {
  BackMonthIcon,
  BackYearIcon,
  CalendarIcon,
} from 'components/common/icons/SVGIcons';
import {
  currentYear,
  DateAndTimeRange,
  endTimeValues,
  getRangeValue,
  months,
  startTimeValues,
  tomorrowDate,
} from 'components/common/inputs/DateInput/constants';
import { inputHeight } from 'components/common/inputs/Input/constants';
import { InputLabel } from 'components/forms/inputs/FormTextInput/styles';
import { Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { RelativeWrapper } from 'components/wrappers/grid/RelativeWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { languages } from 'constants/languages';
import { white } from 'constants/styles/colors';
import { useCloseClick } from 'hooks/closeClick';
import { useModal } from 'hooks/modal';
import { useToggle } from 'hooks/toggle';
import React, {
  ButtonHTMLAttributes,
  FC,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Disabled } from 'types/form';
import { Rotation, Sizes, TextProperties } from 'types/styles';
import {
  ArrowImage,
  DatePickerHeaderButton,
  HeaderSpan,
  IconWrapper,
  ItemSpan,
  ListItemWrapper,
  ListWrapper,
  RowFlexGrow,
  Selector,
  TimeRangeWrapper,
} from './styles';

const {
  text: { timeRange },
} = languages.pages.shipments;

type WeakString = string | undefined;

export interface LineDatePickerProps {
  maxYear?: number;
  minYear?: number;
  maxDate?: Date;
  value?: Date;
  onChange?: (date: Date | undefined) => void;
}

export interface HeaderTypeButton {
  typeButton?: 'year' | 'month';
}

interface CustomHeaderButtonProps
  extends Rotation,
    Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'disabled' | 'onClick'>,
    HeaderTypeButton {}

const CustomHeaderButton = ({
  rotate,
  typeButton = 'year',
  ...buttonProps
}: CustomHeaderButtonProps) => (
  <DatePickerHeaderButton
    rotate={rotate}
    typeButton={typeButton}
    {...buttonProps}
  >
    {typeButton === 'year' ? <BackYearIcon /> : <BackMonthIcon />}
  </DatePickerHeaderButton>
);

interface CustomHeaderProps {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  customHeaderCount: number;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  decreaseYear(): void;
  increaseYear(): void;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
}

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  decreaseYear,
  increaseYear,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
}: CustomHeaderProps) => (
  <Section alignCenter justifyBetween height="50px">
    <Row>
      <MarginWrapper marginRight="10px">
        <CustomHeaderButton
          disabled={prevYearButtonDisabled}
          onClick={decreaseYear}
        />
      </MarginWrapper>
      <CustomHeaderButton
        disabled={prevMonthButtonDisabled}
        typeButton="month"
        onClick={decreaseMonth}
      />
    </Row>
    <Row>
      <HeaderSpan>{`${date.getFullYear()} ${
        months[date.getMonth()]
      }`}</HeaderSpan>
    </Row>
    <Row>
      <MarginWrapper marginRight="10px">
        <CustomHeaderButton
          disabled={nextMonthButtonDisabled}
          rotate={180}
          typeButton="month"
          onClick={increaseMonth}
        />
      </MarginWrapper>
      <CustomHeaderButton
        disabled={nextYearButtonDisabled}
        rotate={180}
        onClick={increaseYear}
      />
    </Row>
  </Section>
);

interface CustomSelectProps extends Pick<TextProperties, 'fontWeight'> {
  values: string[];
  selectedItem: string;
  width?: string;
  onChange: (value: string) => void;
}

export const CustomSelect: FC<CustomSelectProps> = ({
  values,
  selectedItem,
  fontWeight,
  width = 'fit-content',
  onChange,
}) => {
  const { visible, close, open } = useModal();
  const componentRef = useRef<HTMLDivElement>(null);

  const onSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = event.currentTarget;
    onChange(value);
    close();
  };

  useCloseClick(componentRef, close);

  return (
    <RelativeWrapper ref={componentRef} width="fit-content">
      <Selector
        backgroundColor={white}
        height={inputHeight}
        visible={visible}
        width={width}
        onClick={visible ? close : open}
      >
        <ItemSpan fontWeight={fontWeight}>{selectedItem}</ItemSpan>
        <ArrowImage src={arrowIcon} visible={visible} />
      </Selector>

      <ListWrapper visible={visible}>
        {values.map((item) => (
          <ListItemWrapper
            key={item}
            active={item === selectedItem}
            value={item}
            onClick={onSelect}
          >
            <ItemSpan fontWeight={fontWeight}>{item} </ItemSpan>
          </ListItemWrapper>
        ))}
      </ListWrapper>
    </RelativeWrapper>
  );
};

export interface SimpleDatePickerProps {
  maxYear?: number;
  minYear?: number;
  maxDate?: Date;
  value?: Date;
  onChange: (date: Date | undefined) => void;
}

export const SimpleDatePicker = ({
  maxDate = new Date(),
  minYear = 1960,
  maxYear = maxDate ? maxDate.getFullYear() : currentYear,
  value = new Date(),
  onChange,
}: SimpleDatePickerProps) => {
  const [selectedYear, setSelectedYear] = useState(value.getFullYear());
  const [selectedMonthIndex, setSelectedMonthIndex] = useState(
    value.getMonth(),
  );
  const [selectedDay, setSelectedDay] = useState(value.getDate());

  const yearRange = getRangeValue(minYear, maxYear);

  const onChangeDate = (date: Date) => {
    onChange(date);
    setSelectedDay(date.getDate());
  };

  useEffect(() => {
    const date = new Date(selectedYear, selectedMonthIndex, selectedDay);
    onChange(date);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, selectedMonthIndex]);

  const CustomInput = forwardRef<
    HTMLInputElement,
    Pick<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onClick'>
  >(({ value, onClick }, ref) => (
    <RelativeWrapper onClick={onClick}>
      <input
        ref={ref}
        className="react-datepicker__input-container"
        placeholder="ДД.ММ.ГГГГ"
        value={value}
      />
      <IconWrapper>
        <CalendarIcon />
      </IconWrapper>
    </RelativeWrapper>
  ));
  return (
    <ReactDatePicker
      scrollableYearDropdown
      customInput={<CustomInput />}
      dateFormat="dd.MM.yyyy"
      popperPlacement="bottom-start"
      renderCustomHeader={({ changeYear, changeMonth }) => (
        <Section alignCenter justifyEvenly height="50px">
          <CustomSelect
            selectedItem={selectedYear.toString()}
            values={yearRange}
            width="80px"
            onChange={(value) => {
              setSelectedYear(Number(value));
              changeYear(Number(value));
            }}
          />
          <CustomSelect
            selectedItem={months[selectedMonthIndex]}
            values={months}
            width="110px"
            onChange={(value) => {
              const monthIndex = months.indexOf(value);
              setSelectedMonthIndex(monthIndex);
              changeMonth(monthIndex);
            }}
          />
        </Section>
      )}
      selected={value}
      wrapperClassName="date_picker full-width"
      onChange={onChangeDate}
    />
  );
};

export interface DateAndTimeRangePickerProps {
  minDate?: Date;
  value?: DateAndTimeRange;
  onChange: (data: DateAndTimeRange) => void;
}

export const DateAndTimeRangePicker = ({
  value,
  minDate,
  onChange,
}: DateAndTimeRangePickerProps) => {
  const [isCalendarOpened, updateIsCalendarOpened] = useToggle();
  const [selectedDate, setSelectedDate] = useState(value?.date || tomorrowDate);
  const [startTime, setStartTime] = useState(
    value?.time.from || startTimeValues[0],
  );
  const [endTime, setEndTime] = useState(value?.time.to || endTimeValues[-1]);

  const onStartTimeChange = (value: string) => {
    const startTimeValueIndex = startTimeValues.indexOf(value);
    const endTimeValueIndex = endTimeValues.indexOf(endTime);
    const nextTimeValue = endTimeValues[endTimeValues.indexOf(value) + 1];

    setStartTime(value);

    if (startTimeValueIndex > endTimeValueIndex) {
      setEndTime(nextTimeValue);
    }
  };

  const onEndTimeChange = (value: string) => {
    const startTimeValueIndex = startTimeValues.indexOf(startTime);
    const endTimeValueIndex = endTimeValues.indexOf(value);
    const previousTimeValue =
      startTimeValues[startTimeValues.indexOf(value) - 1];
    setEndTime(value);

    if (startTimeValueIndex > endTimeValueIndex) {
      setStartTime(previousTimeValue);
    }
  };

  const onChangeDate = (date: Date) => {
    setSelectedDate(date);
    updateIsCalendarOpened();
  };

  const onInputClick = () => updateIsCalendarOpened();
  const onClickOutside = () => updateIsCalendarOpened();

  useEffect(() => {
    const data = { date: selectedDate, time: { from: startTime, to: endTime } };
    onChange(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, startTime, endTime]);

  const CustomInput = forwardRef<
    HTMLInputElement,
    Pick<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onClick'>
  >(({ value, onClick }, ref) => (
    <RelativeWrapper onClick={onClick}>
      <input
        ref={ref}
        className="react-datepicker__input-container"
        placeholder="ДД.ММ.ГГГГ"
        value={value}
      />
      <IconWrapper>
        <CalendarIcon />
      </IconWrapper>
    </RelativeWrapper>
  ));

  return (
    <Section justifyBetween>
      <RowFlexGrow>
        <ReactDatePicker
          customInput={<CustomInput />}
          dateFormat="dd.MM.yyyy"
          minDate={minDate}
          //maxDate={addMonths(new Date(), 2)}
          open={isCalendarOpened}
          popperPlacement="bottom-start"
          selected={selectedDate}
          wrapperClassName="date_picker full-width"
          onChange={onChangeDate}
          onClickOutside={onClickOutside}
          onInputClick={onInputClick}
        />
      </RowFlexGrow>

      <TimeRangeWrapper>
        <Row alignCenter noWrap>
          <MarginWrapper marginRight="8px">
            <InputLabel>{timeRange[0]}</InputLabel>
          </MarginWrapper>

          <CustomSelect
            selectedItem={startTime}
            values={startTimeValues}
            width="100px"
            onChange={onStartTimeChange}
          />
        </Row>
        <Row alignCenter noWrap marginLeft="20px">
          <MarginWrapper marginRight="8px">
            <InputLabel>{timeRange[1]}</InputLabel>
          </MarginWrapper>
          <CustomSelect
            selectedItem={endTime}
            values={endTimeValues}
            width="100px"
            onChange={onEndTimeChange}
          />
        </Row>
      </TimeRangeWrapper>
    </Section>
  );
};

export type DateRangeType = [string | undefined, string | undefined];

export interface OneFieldDateRangePickerProps
  extends Disabled,
    Pick<Sizes, 'height'> {
  dateRange: DateRangeType;
  onChange: (dates: DateRangeType) => void;
  placeholder?: string;
}

export const OneFieldDateRangePicker = ({
  dateRange,
  onChange,
  disabled,
  placeholder,
  height = inputHeight,
}: OneFieldDateRangePickerProps) => {
  const [isCalendarOpened, updateIsCalendarOpened] = useToggle();

  const fromPropsStartDate = useMemo(
    () => (dateRange[0] ? new Date(dateRange[0]) : null),
    [dateRange],
  );
  const fromPropsEndDate = useMemo(
    () => (dateRange[1] ? new Date(dateRange[1]) : null),
    [dateRange],
  );

  const [selectedDateRange, setSelectedDateRange] = useState([
    fromPropsStartDate,
    fromPropsEndDate,
  ]);
  const [startDateValue, endDateValue] = selectedDateRange;

  const onInputClick = () => updateIsCalendarOpened();
  const onClickOutside = () => updateIsCalendarOpened();

  const onChangeDate = (dates: [Date, Date]) => {
    setSelectedDateRange(dates);
    const [start, end] = dates;
    onChange([
      start ? start.toISOString() : undefined,
      end ? end.toISOString() : undefined,
    ]);
  };

  useEffect(() => {
    setSelectedDateRange([fromPropsStartDate, fromPropsEndDate]);
  }, [fromPropsStartDate, fromPropsEndDate]);

  const CustomInput = forwardRef<
    HTMLInputElement,
    Pick<
      InputHTMLAttributes<HTMLInputElement>,
      'value' | 'onClick' | 'placeholder'
    >
  >(({ value, onClick, placeholder }, ref) => (
    <RelativeWrapper height={height} onClick={onClick}>
      <input ref={ref} placeholder={placeholder} value={value} />
      <IconWrapper>
        <CalendarIcon />
      </IconWrapper>
    </RelativeWrapper>
  ));

  return (
    <ReactDatePicker
      isClearable
      selectsRange
      customInput={<CustomInput />}
      dateFormat="dd.MM.yyyy"
      disabled={disabled}
      endDate={endDateValue}
      open={!disabled && isCalendarOpened}
      placeholderText={placeholder}
      popperPlacement="bottom"
      renderCustomHeader={CustomHeader}
      startDate={startDateValue}
      wrapperClassName="date_picker full-width"
      onChange={onChangeDate}
      onClickOutside={onClickOutside}
      onInputClick={onInputClick}
    />
  );
};
