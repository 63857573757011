import { white } from 'constants/styles/colors';
import { xs } from 'constants/styles/sizes';
import styled from 'styled-components';
import { Sizes } from 'types/styles';

export const StyledTextArea = styled.textarea<Sizes>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '120px'};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  outline: none;
  border: none;
  border-radius: 10px;
  background-color: #f8f8f8;
  resize: none;
  margin: 10px 0 5px;
  padding: 10px;

  ::placeholder {
    color: #c4c4c4;
  }

  @media screen and (max-width: ${xs}) {
    height: 220px;
  }
`;

export const StyledLabel = styled.label`
  font-size: 16px;
  font-weight: 400;
  color: ${white};
  width: 100%;
`;

export const ErrorText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: white;
`;
