import { Radio, RadioProps } from 'components/common/inputs/Radio';
import { TitleInput } from 'components/common/typography/TitleInput';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { Title } from 'types/data';

interface RadioInputProps extends Pick<Title, 'title'>, RadioProps {}

export const RadioInput = ({ title, ...radioProps }: RadioInputProps) => (
  <Column width="100%">
    <Row marginBottom="10px">
      <TitleInput>{title}</TitleInput>
    </Row>
    <Radio {...radioProps} />
  </Column>
);

interface FormRadioProps extends RadioInputProps {
  control: Control<FieldValues>;
}

export const FormRadio = ({
  control,
  name = '',
  defaultSelectedValue,
  ...radioProps
}: FormRadioProps) => {
  const {
    field: { onChange, value, name: nameField },
  } = useController({
    name,
    control,
    defaultValue: defaultSelectedValue,
  });

  return (
    <RadioInput
      defaultSelectedValue={value}
      onChange={onChange}
      {...radioProps}
      name={nameField}
    />
  );
};
