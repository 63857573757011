import { TextCheckbox } from 'components/common/inputs/Checkbox';
import { TitleInput } from 'components/common/typography/TitleInput';
import { Loader } from 'components/dynamic/Loader';
import { mobileMarginBottom } from 'components/forms/AddressForm/constants';
import { FlexGrow, Section } from 'components/wrappers/grid/FlexWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { noop } from 'lodash';
import React, { useState } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';
import { Loading, Title } from 'types/data';
import { DefaultChecked, Disabled } from 'types/form';
import { Badge } from './styles';

export interface CheckboxInputWithBadgeProps
  extends Pick<Title, 'title'>,
    DefaultChecked,
    Disabled {
  id?: string;
  name?: string;
  badgeInfo?: string;
  checked?: boolean;
  onChange?: ((checked: boolean) => void) | undefined;
}

export const CheckboxInputWithBadge = ({
  title,
  name,
  id,
  disabled,
  badgeInfo,
  defaultChecked,
  onChange = noop,
}: CheckboxInputWithBadgeProps) => {
  const [checked, setChecked] = useState(defaultChecked);

  const onCheckboxChange = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <Section alignCenter justifyBetween noWrap>
      <FlexGrow marginRight="8px">
        <TextCheckbox
          defaultChecked={defaultChecked}
          disabled={disabled}
          id={id}
          name={name}
          textType="large"
          title={title}
          type="large"
          onChange={onCheckboxChange}
        />
      </FlexGrow>
      <Badge active={checked} disabled={disabled}>
        {badgeInfo}
      </Badge>
    </Section>
  );
};

interface FormCheckboxProps extends CheckboxInputWithBadgeProps {
  control: Control<FieldValues>;
}

export const FormCheckbox = ({
  control,
  name = '',
  defaultChecked,
  ...checkboxProps
}: FormCheckboxProps) => {
  const {
    field: { onChange, value, name: nameField },
  } = useController({
    name,
    control,
    defaultValue: defaultChecked,
  });

  return (
    <CheckboxInputWithBadge
      defaultChecked={value}
      onChange={onChange}
      {...checkboxProps}
      name={nameField}
    />
  );
};

interface CheckboxesInterface
  extends Pick<CheckboxInputWithBadgeProps, 'badgeInfo' | 'title'> {
  value: string;
}

interface FormCheckboxesProps extends Pick<Title, 'title'>, Loading {
  control: Control<FieldValues>;
  name: string;
  checkboxes: CheckboxesInterface[];
  defaultValue: string[];
}

export const FormCheckboxes = ({
  control,
  name,
  checkboxes,
  title,
  isLoading,
  defaultValue = [],
}: FormCheckboxesProps) => {
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const {
    field: { onChange, value: values },
  } = useController({
    name,
    control,
    defaultValue,
  });

  const onCheckboxesChange = (value: string, checked: boolean) => {
    onChange(
      checked
        ? [...values, value]
        : values.filter((checkedValue: string) => checkedValue !== value),
    );
  };

  return (
    <>
      <Section marginBottom="15px">
        <TitleInput>{title}</TitleInput>
      </Section>
      {isLoading ? (
        <Section alignCenter justifyCenter>
          <Loader />
        </Section>
      ) : (
        <Section justifyBetween>
          {checkboxes.map(({ badgeInfo, title, value }) =>
            !isMobile ? (
              <FlexGrow key={value} flexBasis="49%" flexGrow="0">
                <Section marginBottom="20px">
                  <CheckboxInputWithBadge
                    badgeInfo={badgeInfo}
                    defaultChecked={defaultValue.some(
                      (checkedValue) => checkedValue === value,
                    )}
                    title={title}
                    onChange={(checked) => onCheckboxesChange(value, checked)}
                  />
                </Section>
              </FlexGrow>
            ) : (
              <Section marginBottom={mobileMarginBottom}>
                <CheckboxInputWithBadge
                  badgeInfo={badgeInfo}
                  defaultChecked={defaultValue.some(
                    (checkedValue) => checkedValue === value,
                  )}
                  title={title}
                  onChange={(checked) => onCheckboxesChange(value, checked)}
                />
              </Section>
            ),
          )}
        </Section>
      )}
    </>
  );
};
