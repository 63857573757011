import { TabBar } from 'components/grid/Bars/TabBar';
import { tabs } from 'components/layouts/AuthLayout/constants';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import React, { FC } from 'react';
import { LayoutWrapper, StyledContentWrapper } from './styles';

interface AuthLayoutProps {
  withoutBar?: boolean;
  isRegister?: boolean;
}

export const AuthLayout: FC<AuthLayoutProps> = ({ withoutBar, children }) => (
  <LayoutWrapper>
    {!withoutBar && (
      <Section marginBottom="30px">
        <TabBar tabs={tabs} />
      </Section>
    )}

    <StyledContentWrapper>{children}</StyledContentWrapper>
  </LayoutWrapper>
);
