import React, { ButtonHTMLAttributes, FC } from 'react';
import { StyledButton } from './styles';
import { Loading } from 'types/data';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { Loader } from 'components/dynamic/Loader';
import { Padding, Sizes } from 'types/styles';

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Loading,
    Padding,
    Pick<Sizes, 'width'> {
  buttonType?:
    | 'header'
    | 'basic'
    | 'body'
    | 'card'
    | 'form'
    | 'accent'
    | 'supplementary';
}

export const Button: FC<ButtonProps> = ({
  children,
  buttonType = 'basic',
  isLoading,
  ...buttonProps
}) => (
  <StyledButton buttonType={buttonType} isLoading={isLoading} {...buttonProps}>
    {isLoading ? (
      <Section alignCenter justifyCenter>
        <Loader />
      </Section>
    ) : (
      children
    )}
  </StyledButton>
);
