import { CopyIcon } from 'components/common/icons/SVGIcons';
import React, { ButtonHTMLAttributes, FC, MouseEvent } from 'react';
import { triggerCopy } from 'utils/data';
import { ButtonText, StyledButton } from './styles';

export interface CopyButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  subject: string;
}

export const CopyButton: FC<CopyButtonProps> = ({
  children,
  subject,
  ...buttonProps
}) => {
  const triggerButtonCopy = (e: MouseEvent<HTMLButtonElement>) => {
    triggerCopy(subject);
    e.stopPropagation();
  };

  return (
    <StyledButton {...buttonProps} onClick={triggerButtonCopy}>
      <CopyIcon />
      <ButtonText>{children}</ButtonText>
    </StyledButton>
  );
};
