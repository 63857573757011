import { Link } from 'react-router-dom';
import { LinkProps } from 'components/common/links/InternalLink/types';
import styled from 'styled-components';
import { lightgrey, white } from 'constants/styles/colors';

export const InternalLink = styled(Link)<LinkProps>`
  font-size: ${({ fontSize }) => fontSize || '12px'};
  color: ${({ color }) => color || lightgrey};
  ${({ textDecoration }) => `text-decoration: ${textDecoration || 'none'}`};

  &:hover {
    color: ${({ color }) => color || white};
  }
`;
