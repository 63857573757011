// TODO: add test
export const findElementInChildrenList = (
  targetElement: Element,
  searchElement: EventTarget | null,
) => {
  let isInParentBlock = false;
  const checkChildrenRef = (el: Element) => {
    if (el.childElementCount === 0) return;
    else
      el.childNodes.forEach((el: any) => {
        if (searchElement === el) isInParentBlock = true;
        checkChildrenRef(el);
      });
  };

  checkChildrenRef(targetElement);
  return isInParentBlock;
};

export const changePositionOfJivoButton = () => {
  const element: HTMLElement | null = document.querySelector(
    '.__jivoMobileButton',
  );

  if (element) {
    element.style.bottom = '90px';
    element.style.right = '5px';
    element.style.zIndex = '900';
  }
};
