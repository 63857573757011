import { paleBlue } from './colors';

// * padding
export const padding = '10px';

// * others
export const borderWidth = '1px';
export const borderRadius = '10px';
export const tableBorder = `1px solid ${paleBlue}`;

// * layouts

export const contentWidth = '1200px';

// * adaptive

export const minimalAdaptiveSize = 1024;

export const xs = '480px';
export const sm = '576px';
export const md = '768px';
export const lg = '992px';
export const xl = '1280px';
export const xxl = '1600px';

export const xs_1 = '479px';
export const sm_1 = '575px';
export const sm_2 = '660px';
export const md_1 = '767px';
export const lg_1 = '991px';
export const xl_1 = '1279px';
export const xxl_1 = '1599px';

export const MediaSizes = {
  Tablet: { query: `(max-width: ${lg})` },
  TabletSmall: { query: `(max-width: ${md})` },
  TabletMini: { query: `(max-width: ${md_1})` },
  Mobile: { query: `(max-width: ${xs})` },
  MobileBig: { query: `(max-width: ${sm})` },
  MobileLarge: { query: `(max-width: ${sm_2})` },
  MobileMedium: { query: `(max-width: 400px)` },
  MobileSmall: { query: `(max-width: 370px)` },
};
