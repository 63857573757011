import styled from 'styled-components';

interface AdditionalInputTextProps {
  isSecondary?: boolean;
}

export const AdditionalInputText = styled.span<AdditionalInputTextProps>`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  color: ${({ isSecondary }) =>
    isSecondary ? 'rgba(255, 255, 255, 0.35)' : 'rgba(255, 255, 255, 0.6)'};
`;
