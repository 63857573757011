import { MobileTabBar } from 'components/grid/Bars/MobileTabBar';
import { TabBar } from 'components/grid/Bars/TabBar';
import {
  tabButtonWidth,
  tabs,
} from 'components/layouts/AccountLayout/constants';
import { MediaSizes } from 'constants/styles/sizes';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ContentWrapper, LayoutWrapper, TabsWrapper } from './styles';

export const AccountLayout: FC = ({ children }) => {
  const isTabletSmall = useMediaQuery(MediaSizes.TabletSmall);
  const isMobileBig = useMediaQuery(MediaSizes.MobileBig);

  const buttonWidth = isTabletSmall ? '216px' : tabButtonWidth;

  return (
    <LayoutWrapper>
      <TabsWrapper>
        {!isMobileBig ? (
          <TabBar buttonWidth={buttonWidth} tabs={tabs} theme="white" />
        ) : (
          <MobileTabBar tabs={tabs} />
        )}
      </TabsWrapper>
      <ContentWrapper>{children}</ContentWrapper>
    </LayoutWrapper>
  );
};
