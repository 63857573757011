import * as Yup from 'yup';
import { yupEmailNoHint, yupPasswordNoHint } from 'constants/yupFields';
import { SignIn } from 'pages/Auth/Login/types';
import { userEffects } from 'stores/user';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

const { loadToken } = userEffects;

export const yupSchema = Yup.object().shape({
  email: yupEmailNoHint,
  password: yupPasswordNoHint,
});

export const onSubmit = async (
  data: SignIn,
  setError: UseFormSetError<FieldValues>,
) => {
  try {
    await loadToken(data);
  } catch (error) {
    const message = error?.data?.message;

    setError('password', {
      type: 'manual',
      message,
    });
  }
};
