import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { baseURL } from 'constants/services';
import { userStores, userEffects, userEvents } from 'stores/user';

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = baseURL;
axiosInstance.defaults.method = 'POST';
axiosInstance.interceptors.response.use(
  (config) => config.data,
  (config) => {
    const status = config?.response?.status;

    if (status === 403 || status === 401) {
      userEffects.clearUserProfile();
      userEvents.setToken({ accessToken: '', refreshToken: '' });
    }

    return Promise.reject(config.response);
  },
);

export default <T = void>(config: AxiosRequestConfig, withToken = true) => {
  const request: AxiosPromise<T> = axiosInstance({
    ...config,
    headers: withToken
      ? {
          Authorization: `Bearer ${userStores.user.getState()?.accessToken}`,
        }
      : {},
  });

  return request as any as Promise<T>;
};
