import {
  InformationBadge,
  InformationBadgeProps,
} from 'components/common/dividers/InformationBadge';
import React from 'react';
import { Title } from 'types/data';
import { Column, Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { TitleInput } from 'components/common/typography/TitleInput';

interface FormInformationBadgeProps
  extends Pick<Title, 'title'>,
    InformationBadgeProps {
  isSecondaryTitle?: boolean;
}

export const FormInformationBadge = ({
  title,
  isSecondaryTitle,
  ...informationBadgeProps
}: FormInformationBadgeProps) => (
  <Column width="100%">
    <Row marginBottom="5px">
      <TitleInput isSecondary={isSecondaryTitle}>{title}</TitleInput>
    </Row>
    <Section>
      <InformationBadge {...informationBadgeProps} />
    </Section>
  </Column>
);
