import { darkBlue, inactiveTextColor, white } from 'constants/styles/colors';
import styled, { css } from 'styled-components';
import { Active } from 'types/global';

export const Wrapper = styled.nav`
  width: 100%;
  height: 68px;
  overflow-x: auto;
  background: ${darkBlue};
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 16px;
`;

export const MobileTab = styled.div<Active>`
  position: relative;
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }

  ${({ active }) =>
    active &&
    css`
      &::before {
        content: '';
        position: absolute;
        bottom: -22px;
        left: -10%;

        width: 120%;
        height: 4px;
        background: white;
      }
    `}

  span {
    font-size: 14px;
    color: ${({ active }) => (active ? white : inactiveTextColor)};
  }
`;
