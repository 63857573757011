import greyWorldMapImg from 'assets/img/greyWorldMap.svg';
import whiteWorldMapImg from 'assets/img/whiteWorldMap.svg';
import { AuthorizedHeader } from 'components/grid/Headers/AuthorizedHeader';
import { MainHeader } from 'components/grid/Headers/MainHeader';
import { MobileNavBar } from 'components/grid/MobileNavBar';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { useMediaQuery } from 'react-responsive';
import { userStores } from 'stores/user';
import { Content, LayoutWrapper } from './styles';

export const MainLayout: FC = ({ children }) => {
  const { accessToken } = useStore(userStores.user);
  const isAuthorized = !!accessToken;
  const isMobileBig = useMediaQuery(MediaSizes.MobileBig);

  const imageSrc = isAuthorized ? whiteWorldMapImg : greyWorldMapImg;

  return (
    <LayoutWrapper background={imageSrc} isAuthorized={isAuthorized}>
      <MainHeader isAuthorized={isAuthorized} />
      {isAuthorized && !isMobileBig && <AuthorizedHeader />}
      <Content>{children}</Content>
      <MobileNavBar isAuthorized={isAuthorized} />
    </LayoutWrapper>
  );
};
