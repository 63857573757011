import { RefObject, useEffect } from 'react';

import { Noop } from 'types/types';
import { findElementInChildrenList } from 'utils/dom';

const portal =
  document.querySelector('#portal-root') || document.createElement('div');

// * any because HTML Element can be any
export const useCloseClick = (
  ref: RefObject<any>,
  close: Noop,
  visible?: boolean,
) => {
  useEffect(() => {
    const closeClick = (e: MouseEvent) => {
      if (!ref.current) return;

      if (
        ref.current !== e.target &&
        !findElementInChildrenList(ref.current, e.target) &&
        !findElementInChildrenList(portal, e.target)
      ) {
        close();
      }
    };
    document.addEventListener('click', closeClick);

    return () => document.removeEventListener('click', closeClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);
};
