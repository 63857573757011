import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { getQueryParams } from 'utils/browser.api';
import { SocialAuthQueryParams } from 'pages/Auth/SocialAuth/types';
import { userEvents } from 'stores/user';

const { setToken } = userEvents;

export const SocialAuth = () => {
  const { search } = useLocation();
  const { refreshToken, accessToken } =
    getQueryParams<SocialAuthQueryParams>(search);

  useEffect(() => {
    setToken({ refreshToken, accessToken });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
