import { black, white } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';

export const SpanButton = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${black};

  @media screen and (max-width: 374px) {
    font-size: 12px;
  } ;
`;

export const StyledButton = styled.button`
  width: 210px;
  height: 48px;
  border-radius: 10px;
  padding: 13px 20px;
  background-color: ${white};

  :disabled {
    background-color: #d0d3d9;
    color: ${white};
    cursor: not-allowed;
  }

  @media screen and (max-width: 505px) {
    width: 150px;
    padding: 13px 14px;
  }
  @media screen and (max-width: 374px) {
    width: 123px;
    padding: 13px 2px;
    ${flexCenter}
  }
`;
