import { PackageStepForm } from 'components/forms/OrdersFroms/PackageStepForm';
import { StepInterface } from 'components/grid/Wizards/OrderWizard/types';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import {
  mobileStepContentWrapperPadding,
  stepContentWrapperPadding,
} from 'pages/Shipments/constants';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { insurancesStores } from 'stores/insurances';
import { ordersStores } from 'stores/orders';

interface PackageStepProps extends StepInterface {}

export const PackageStep = ({ onNext }: PackageStepProps) => {
  const { cost } = useStore(insurancesStores.relevantInsurance);
  const { packageType, services, weight } = useStore(
    ordersStores.orderFormData,
  );
  const isMobileLarge = useMediaQuery(MediaSizes.MobileLarge);

  return (
    <ContentWrapper
      padding={
        isMobileLarge
          ? mobileStepContentWrapperPadding
          : stepContentWrapperPadding
      }
      width="100%"
    >
      <PackageStepForm
        insurance={cost}
        packageType={packageType}
        services={services}
        weight={weight}
        onNext={onNext}
      />
    </ContentWrapper>
  );
};
