import { StepBackButton } from 'components/common/buttons/ImageButtons';
import { StepStatus } from 'components/dynamic/WizardStatus';
import { FlexGrow, Row } from 'components/wrappers/grid/FlexWrapper';
import { noop } from 'constants/functions';
import React from 'react';
import { Line, StatusWrapper, Step, StepButton, StepsWrapper } from './styles';

interface WizardStatusProps {
  totalSteps: number;
  activeStep: number;
  onStepClick?: (index: number) => void;
  // onNext: (index: number) => void;
  onBack: (index: number) => void;
}

export const WizardStatusAdaptive = ({
  totalSteps,
  activeStep,
  onStepClick,
  onBack,
}: WizardStatusProps) => {
  const onStepButtonClick = (index: number) => () =>
    onStepClick ? onStepClick(index) : noop();

  return (
    <StatusWrapper>
      <Row marginRight="10px">
        <StepBackButton
          disabled={!activeStep}
          onClick={() => onBack(activeStep - 1)}
        />
      </Row>
      <StepsWrapper justifyBetween flexDirection="row">
        {new Array(totalSteps).fill('').map((_, index) => {
          const disabled = activeStep < index;
          const stepWasSaved = !disabled && index > 0 && index < totalSteps;
          return index !== 0 ? (
            <FlexGrow
              key={index.toString()}
              alignCenter
              noWrap
              flexDirection="row"
            >
              <Line disabled={disabled} wasSaved={stepWasSaved}>
                <StepStatus wasSaved={stepWasSaved} />
              </Line>

              <Step disabled={disabled}>
                <StepButton
                  disabled={disabled}
                  onClick={onStepButtonClick(index)}
                >
                  {index + 1}
                </StepButton>
              </Step>
            </FlexGrow>
          ) : (
            <Row key={index.toString()} alignCenter>
              <Step disabled={disabled}>
                <StepStatus wasSaved={stepWasSaved}>
                  <StepButton
                    disabled={disabled}
                    onClick={onStepButtonClick(index)}
                  >
                    {index + 1}
                  </StepButton>
                </StepStatus>
              </Step>
            </Row>
          );
        })}
      </StepsWrapper>
    </StatusWrapper>
  );
};
