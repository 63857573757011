import React from 'react';

export const MobileShipmentsIcon = () => (
  <svg
    fill="none"
    height="23"
    viewBox="0 0 21 23"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8225 5.62613L10.6775 0.0453269C10.6236 0.0156327 10.5623 0 10.5 0C10.4377 0 10.3764 0.0156327 10.3225 0.0453269L0.177536 5.62613C0.12356 5.65582 0.078737 5.69853 0.0475729 5.74996C0.0164089 5.80139 1.58335e-06 5.85973 0 5.91912V17.0809C1.58335e-06 17.1403 0.0164089 17.1986 0.0475729 17.2501C0.078737 17.3015 0.12356 17.3442 0.177536 17.3739L10.3225 22.9547C10.3764 22.9844 10.4377 23 10.5 23C10.5623 23 10.6235 22.9844 10.6775 22.9547L20.8225 17.3738C20.8764 17.3441 20.9213 17.3014 20.9524 17.2499C20.9836 17.1985 21 17.1402 21 17.0808V5.91912C21 5.85973 20.9836 5.80139 20.9524 5.74996C20.9213 5.69853 20.8764 5.65582 20.8225 5.62613ZM16.4016 3.9754L6.96748 9.16529L5.09164 8.13335L14.5258 2.94351L16.4016 3.9754ZM6.5942 9.74126V15.2796L5.8762 14.5025C5.82532 14.4493 5.75861 14.4123 5.68502 14.3964C5.61143 14.3805 5.53449 14.3863 5.46451 14.4133L4.71739 14.7037V8.71938L6.5942 9.74126ZM10.5 0.728887L13.8156 2.55284L4.38149 7.74269L1.06593 5.91873L10.5 0.728887ZM10.1449 22.0758L0.710145 16.8856V6.50432L4.00725 8.32871V15.2095C4.00942 15.2657 4.02576 15.3206 4.05488 15.3696C4.084 15.4185 4.12505 15.4601 4.17457 15.4908C4.22408 15.5214 4.28061 15.5403 4.33939 15.5458C4.39816 15.5512 4.45745 15.5432 4.51226 15.5222L5.50834 15.1312L6.68155 16.3997C6.72931 16.4513 6.79243 16.4879 6.86252 16.5045C6.93261 16.5211 7.00635 16.5171 7.07395 16.4928C7.14155 16.4685 7.19979 16.4253 7.24095 16.3687C7.2821 16.3121 7.30421 16.245 7.30435 16.1761V10.1319L10.1449 11.6946V22.0758ZM10.5 11.1085L7.67763 9.55591L17.1117 4.36621L19.9341 5.91883L10.5 11.1085ZM20.2899 16.8856L10.8551 22.0758V11.6946L20.2899 6.50432V16.8856Z"
      fill="#90A8E4"
    />
  </svg>
);
