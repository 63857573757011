import { accountTabWidth } from 'components/layouts/AccountLayout/constants';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { borderRadius, tableBorder } from 'constants/styles/sizes';
import styled from 'styled-components';

export const CardWrapper = styled(ContentWrapper)`
  position: relative;
  width: 100%;
  max-width: ${accountTabWidth};
  padding: 30px 27px;
`;

export const FullNameText = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 14px;
`;

export const AddressWrapper = styled(Column)`
  width: 100%;
  border-radius: ${borderRadius};
  border: ${tableBorder};
`;
