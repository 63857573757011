import React from 'react';
import errorIcon from 'assets/icons/error-icon.svg';
import { useStore } from 'effector-react';

import {
  TitleText,
  ErrorMessage,
  SupplementaryText,
  ToggleWrapper,
  StyledSpan,
  TextWrapper,
} from './styles';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import {
  contentWrapperProps,
  iconDiameter,
  rowMaxWidth,
  smallIconDiameter,
} from 'components/modals/ErrorModal/constants';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { CustomImage } from 'components/common/imgComponents/CustomImg/styles';
import { ConfirmationButton } from 'components/common/buttons/ConfirmationButton';
import { modalEvents, modalStores } from 'stores/modals/asyncModal';
import { useModal } from 'hooks/modal';
import { ArrowImg } from 'components/common/imgComponents/ArrowImg';
import { languages } from 'constants/languages';
import { CopyButton } from 'components/common/buttons/CopyButton/Index';
import { userStores } from 'stores/user';
import { useMediaQuery } from 'react-responsive';
import { MediaSizes } from 'constants/styles/sizes';

const {
  pathTitle,
  statusTitle,
  errorTitle,
  messageTitle,
  userIdTitle,
  entityIdTitle,
} = languages.modals.errorModal;

export const ErrorModal = () => {
  const {
    visible,
    title,
    subtitle,
    callToActionText,
    supplementaryText,
    userId,
    entityId,
    path,
    status,
    error,
    message,
    copyButtonText,
    successButtonText,
    onSuccess,
  } = useStore(modalStores.errorModalStore);
  const isMediumMobile = useMediaQuery(MediaSizes.MobileMedium);
  const imageDiameter = isMediumMobile ? smallIconDiameter : iconDiameter;
  const modalPadding = isMediumMobile ? '20px' : '32px 24px';
  const confirmationButtonMinWidth = isMediumMobile ? '75px' : undefined;
  const sectionMargin = isMediumMobile ? '10px 0' : '20px 0';
  const sectionMarginTop = isMediumMobile ? '20px' : '32px';

  const { id } = useStore(userStores.userProfile);

  const { visible: isErrorMessageVisible, close, open } = useModal();
  const errorTextToCopy = `${userIdTitle}${userId || id}\n${entityIdTitle}${
    entityId || ''
  }\n${statusTitle}${status || ''}\n${pathTitle}${path}\n${messageTitle}${
    message || ''
  }\n${errorTitle}${error || ''}`;

  const onCloseClick = () => {
    modalEvents.closeErrorModal();
    onSuccess?.();
  };

  return (
    <ModalWrapper
      padding={modalPadding}
      visible={visible}
      onCloseClick={onCloseClick}
      {...contentWrapperProps}
    >
      <Section alignCenter margin={sectionMargin}>
        <MarginWrapper marginRight="12px">
          <CustomImage
            height={imageDiameter}
            src={errorIcon}
            width={imageDiameter}
          />
        </MarginWrapper>
        <TitleText>{title}</TitleText>
      </Section>

      <TextWrapper marginBottom="10px" maxWidth={rowMaxWidth}>
        {subtitle}
      </TextWrapper>

      <TextWrapper dangerouslySetInnerHTML={{ __html: callToActionText }} />

      <ToggleWrapper onClick={isErrorMessageVisible ? close : open}>
        <SupplementaryText>{supplementaryText} </SupplementaryText>
        <ArrowImg rotate={isErrorMessageVisible ? 180 : 0} />
      </ToggleWrapper>

      <ErrorMessage visible={isErrorMessageVisible}>
        <StyledSpan>
          {userIdTitle}
          {userId || id}
        </StyledSpan>

        <StyledSpan>
          {entityIdTitle}
          {entityId}
        </StyledSpan>

        <StyledSpan>
          {statusTitle}
          {status}
        </StyledSpan>

        <StyledSpan>
          {pathTitle}
          {path}
        </StyledSpan>
        <StyledSpan>
          {messageTitle}
          {message}
        </StyledSpan>
        <StyledSpan>
          {errorTitle}
          {error}
        </StyledSpan>
      </ErrorMessage>

      <Section justifyBetween marginTop={sectionMarginTop}>
        <MarginWrapper marginBottom="10px" marginRight="20px">
          <CopyButton subject={errorTextToCopy}>{copyButtonText}</CopyButton>
        </MarginWrapper>
        <MarginWrapper marginBottom="10px">
          <ConfirmationButton
            buttonType="tertiary"
            minWidth={confirmationButtonMinWidth}
            onClick={onCloseClick}
          >
            {successButtonText}
          </ConfirmationButton>
        </MarginWrapper>
      </Section>
    </ModalWrapper>
  );
};
