import { Column } from 'components/wrappers/grid/FlexWrapper';
import React, { ChangeEvent, useState } from 'react';
import { Label } from 'types/form';
import { Sizes } from 'types/styles';
import { ErrorText, StyledLabel, StyledTextArea } from './styles';
import { noop } from 'constants/functions';

export interface TextAreaProps
  extends Omit<HTMLTextAreaElement, 'children'>,
    Label,
    Sizes {
  onChange?: (value: string) => void;
  errorText?: string;
}

export const TextArea = ({
  id,
  name,
  title,
  placeholder,
  defaultValue = '',
  errorText,
  onChange = noop,
  width,
  height,
}: TextAreaProps) => {
  const [textAreaValue, setTextAreaValue] = useState(defaultValue);

  const onInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.currentTarget.value);
    setTextAreaValue(e.target.value);
  };

  return (
    <Column width="100%">
      <StyledLabel>
        {title}

        <StyledTextArea
          height={height}
          id={id}
          name={name}
          placeholder={placeholder}
          value={textAreaValue}
          width={width}
          onChange={onInputChange}
        />
      </StyledLabel>
      {!!errorText && <ErrorText>{errorText}</ErrorText>}
    </Column>
  );
};
