import { createEffect, Effect } from 'effector';
import { message } from 'stores/alerts';
import { modalEvents } from 'stores/modals/asyncModal';

const { openErrorModal } = modalEvents;

interface BadResponseErrors {
  data: API.SuccessAnswer;
}

export const getDataAndOpenErrorModal = (error: any) => {
  const { data, config, status: statusCode } = error;
  const { message, error: errorText, path, status, userId, entityId } = data;

  const { method, url } = config;
  const configMethod = (method as string).toUpperCase();
  const configUrl = (url as string).split('?')[0];

  openErrorModal({
    userId,
    entityId,
    path: path || `${configMethod} ${configUrl}`,
    status: status || statusCode,
    message,
    error: errorText,
  });
};

export function createNotifyingErrorEffect<Params, Done>(config: {
  name?: string;
  handler?: (params: Params) => Promise<Done> | Done;
  sid?: string;
}): Effect<Params, Done> {
  const effect = createEffect(config);

  effect.fail.watch(({ error }) => {
    getDataAndOpenErrorModal(error);
  });

  return effect;
}

export function createNotifyingSuccessEffect<Params, Done>(config: {
  name?: string;
  handler?: (params: Params) => Promise<API.SuccessAnswer>;
  sid?: string;
}): Effect<Params, API.SuccessAnswer> {
  const effect = createEffect(config);

  // TODO: get rid og copy pasta
  effect.fail.watch(
    ({
      error: {
        // TODO: ts-ginore
        // @ts-ignore
        data: { message: messageText },
      },
    }) => {
      message.error(messageText);
    },
  );
  effect.doneData.watch(({ message: messageText }) => {
    message.success(messageText);
  });

  return effect;
}
