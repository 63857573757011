import { ContentWrapperInterface } from '../ModalWrapper';
import { yupEmail } from 'constants/yupFields';
import * as Yup from 'yup';

export const contentWrapperProps: ContentWrapperInterface = {
  width: '570px',
};

export const contentPadding = '20px 65px 0px';

export const yupSchema = Yup.object().shape({
  email: yupEmail,
});
