export const defaultAddresses: API.MyAddressesResponseDto = {
  result: [],
  totalPages: 0,
  nextPage: 0,
};

export const defaultAddressesPageLimit = 2;

export const defaultQueryAddresses: API.GetMyAddressDto = {
  limit: defaultAddressesPageLimit,
  page: 1,
};

export const defaultOrders: API.MyOrdersResponseDto = {
  result: [],
  totalPages: 0,
  itemsCount: 0,
  limit: 5,
  currentPage: 1,
};

export const defaultOrdersPageLimit = 5;

export const defaultQueryOrders: API.GetMyAddressDto = {
  limit: defaultOrdersPageLimit,
  page: 1,
};

export const defaultGetAllResponse = {
  result: [],
  totalPages: 0,
  itemsCount: 0,
  limit: 10,
  currentPage: 1,
};

export const defaultLocationState = {
  data: [],
  metadata: {
    totalCount: 0,
    currentOffset: 0,
  },
};

export const defaultTariffCostResponse = {
  cost: 0,
};

export const relevantInsuranceState = {
  cost: 0,
  id: '',
};

export const emptyOrderForm = {};
export const defaultCalculatorState = {};
