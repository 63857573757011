import { WizardStatus } from 'components/dynamic/WizardStatus';
import { WizardStatusAdaptive } from 'components/dynamic/WizardStatusAdaptive';
import {
  getStepByIndex,
  getTotalSteps,
  steps,
} from 'components/grid/Wizards/OrderWizard/constants';
import { Steps } from 'components/grid/Wizards/OrderWizard/types';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { invoiceModalStore } from 'stores/initialize/initialize.modal.store';
import { ordersStores } from 'stores/orders';
import { WizardWrapper } from './styles';

export const OrderWizard = () => {
  const [{ activeStep, Component }, setActive] = useState(
    steps[Steps.initialStep],
  );
  const isTabletSmall = useMediaQuery(MediaSizes.TabletSmall);

  const orderData = useStore(ordersStores.orderFormData);

  const onSetStep = (index: number) => setActive(getStepByIndex(index));

  const setStep = (step: Steps) => setActive(steps[step]);

  const onFinish = () => {
    invoiceModalStore.openModal(orderData);
  };

  return (
    <WizardWrapper>
      <Section marginBottom="30px">
        {!isTabletSmall ? (
          <WizardStatus
            activeStep={activeStep}
            totalSteps={getTotalSteps()}
            onBack={onSetStep}
            onStepClick={onSetStep}
          />
        ) : (
          <WizardStatusAdaptive
            activeStep={activeStep}
            totalSteps={getTotalSteps()}
            onBack={onSetStep}
            onStepClick={onSetStep}
          />
        )}
      </Section>
      <Component onFinish={onFinish} onNext={setStep} />
    </WizardWrapper>
  );
};
