import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import App from 'App';
import { changePositionOfJivoButton } from 'utils/dom';

if (process.env.NODE_ENV === 'production') {
  const node = document.createElement('script');
  node.setAttribute('src', 'https://code-eu1.jivosite.com/widget/C2O5AcKJDU');
  node.setAttribute('async', 'async');
  document.getElementsByTagName('body')[0].appendChild(node);

  if (window.screen.width < 769) {
    setTimeout(() => {
      changePositionOfJivoButton();
    }, 1000);
  }
} else {
  // const node = document.createElement('script');
  // node.setAttribute('src', 'https://code-eu1.jivosite.com/widget/C2O5AcKJDU');
  // node.setAttribute('async', 'async');
  // document.getElementsByTagName('body')[0].appendChild(node);
  // if (window.screen.width < 769) {
  //   setTimeout(() => {
  //     changePositionOfJivoButton();
  //   }, 1000);
  // }
}

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);
