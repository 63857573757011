import { darkBlue, paleBlue } from 'constants/styles/colors';
import { flexCenter } from 'constants/styles/mixins';
import styled from 'styled-components';
import { Active } from 'types/global';

export const Wrapper = styled.nav`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: white;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NavItem = styled.div<Active>`
  ${flexCenter}
  flex-direction: column;

  svg {
    margin-bottom: 6px;
  }

  svg path {
    stroke: ${({ active }) => (active ? darkBlue : paleBlue)};
  }

  span {
    font-size: 12px;
    color: ${({ active }) => (active ? darkBlue : paleBlue)};
  }
`;
