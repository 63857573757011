import { Menu } from 'components/grid/Menu';
import React from 'react';
import { HeaderContent, HeaderWrapper } from './styles';

export const AuthorizedHeader = () => (
  <HeaderWrapper>
    <HeaderContent>
      <Menu />
    </HeaderContent>
  </HeaderWrapper>
);
