import { loginLink } from 'constants/routes';
import React, { useEffect } from 'react';
import { Redirect, RouteProps } from 'react-router';
import { RemoveTrailingSlashRoute } from 'routes/RemoveTrailingSlashRoute';
import { useStore } from 'effector-react';
import { userStores } from 'stores/user';
import { confirmEmailModal } from 'stores/initialize/initialize.modal.store';

interface TokenProtectedRouteProps extends RouteProps {
  notProtect?: boolean;
}

export const TokenProtectedRoute = ({
  notProtect = false,
  ...props
}: TokenProtectedRouteProps) => {
  const { accessToken } = useStore(userStores.user);
  const { isVerified } = useStore(userStores.userProfile);

  useEffect(() => {
    !notProtect && accessToken && !isVerified && confirmEmailModal.openModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  if (accessToken) return <RemoveTrailingSlashRoute {...props} />;
  else return <Redirect to={loginLink} />;
};
