import axios from 'api/axios';
import { CancelToken } from 'axios';

export const getCountriesList = (
  { namePrefix }: API.CountriesListByNameDto,
  cancelToken?: CancelToken,
) =>
  axios<API.CountriesListResponseDto>(
    {
      url: `/addresses/countries?offset=0&limit=10&namePrefix=${namePrefix}`,
      method: 'GET',
      cancelToken,
    },
    false,
  );

export const getRegionsListByCountry = (
  { namePrefix, ...data }: API.RegionsListByCountryDto,
  cancelToken?: CancelToken,
) =>
  axios<API.RegionsListResponseDto>(
    {
      url: `/addresses/states?limit=10&namePrefix=${namePrefix}`,
      data,
      cancelToken,
    },
    false,
  );

export const getCitiesListByCountry = (
  { namePrefix, ...data }: API.CitiesListDto,
  cancelToken?: CancelToken,
) =>
  axios<API.CitiesListResponseDto>(
    {
      url: `/addresses/cities?limit=10&namePrefix=${namePrefix}`,
      data,
      cancelToken,
    },
    false,
  );

export const createAddress = (data: API.CreateAddressDto) =>
  axios<API.AddressItemResponseDto>({
    url: '/addresses',
    data,
  });

export const updateAddressById = ({ id, ...data }: API.UpdateAddressDto) =>
  axios<API.AddressItemResponseDto>({
    url: `/addresses/${id}`,
    method: 'PUT',
    data,
  });

export const getMyAddresses = (
  { limit, page }: API.GetMyAddressDto,
  cancelToken?: CancelToken,
) =>
  axios<API.MyAddressesResponseDto>({
    url: `/addresses/my-addresses?limit=${limit}&page=${page}`,
    cancelToken,
    method: 'GET',
  });

export const getAllMyAddresses = (cancelToken?: CancelToken) =>
  axios<API.MyAddressesResponseDto>({
    url: `/addresses/my-addresses`,
    cancelToken,
    method: 'GET',
  });

export const getAddressById = (
  { id }: API.GetAddressDto,
  cancelToken?: CancelToken,
) =>
  axios<API.AddressItemResponseDto>({
    url: `/addresses/${id}`,
    cancelToken,
    method: 'GET',
  });

export const deleteAddressById = ({ id }: API.DeleteAddressDto) =>
  axios<API.BaseResponse>({
    url: `/addresses/${id}`,
    method: 'DELETE',
  });
