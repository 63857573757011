import { SocialIconsType } from 'components/common/buttons/SocialButton/types';
import vkIcon from 'assets/icons/vk-icon.svg';
import disabledVkIcon from 'assets/icons/disabled-vk-icon.svg';
import fbIcon from 'assets/icons/fb-icon.svg';
import disabledFbIcon from 'assets/icons/disabled-fb-icon.svg';
import telegramIcon from 'assets/icons/telegram-icon.svg';
import disabledTelegramIcon from 'assets/icons/disable-telegram-icon.svg';
import wechatIcon from 'assets/icons/wechat-icon.svg';
import disabledWechatIcon from 'assets/icons/disable-wechat-icon.svg';
import { languages } from 'constants/languages';

const { socialButton } = languages.buttons;

export const socialIcons: SocialIconsType = {
  vk: {
    src: vkIcon,
    disabledSrc: disabledVkIcon,
    alt: 'vkontakte icon',
    nameIcon: socialButton['vk'].nameIcon,
  },
  fb: {
    src: fbIcon,
    disabledSrc: disabledFbIcon,
    alt: 'facebook icon',
    nameIcon: socialButton['fb'].nameIcon,
  },
  telegram: {
    src: telegramIcon,
    disabledSrc: disabledTelegramIcon,
    alt: 'telegram icon',
    nameIcon: socialButton['telegram'].nameIcon,
  },
  wechat: {
    src: wechatIcon,
    disabledSrc: disabledWechatIcon,
    alt: 'wechat icon',
    nameIcon: socialButton['wechat'].nameIcon,
  },
};
