import React from 'react';
import { Input, InputProps } from 'components/common/inputs/Input';
import { Title } from 'types/data';
import {
  ErrorText,
  InputLabel,
  InputWrapper,
} from 'components/forms/inputs/FormTextInput/styles';
import { Control, FieldValues, useController } from 'react-hook-form';

interface TextInputProps extends InputProps, Pick<Title, 'title'> {
  errorText?: string;
  isSecondaryTitle?: boolean;
}

export const TextInput = ({
  title,
  id,
  isSecondaryTitle,
  errorText,
  ...inputProps
}: TextInputProps) => (
  <InputWrapper>
    <InputLabel htmlFor={id} isSecondary={isSecondaryTitle}>
      {title}
    </InputLabel>
    <Input id={id} {...inputProps} />
    {!!errorText && <ErrorText>{errorText}</ErrorText>}
  </InputWrapper>
);

interface FormTextInputProps extends TextInputProps {
  control: Control<FieldValues>;
}

export const FormTextInput = ({
  name = '',
  control,
  defaultValue = '',
  ...textInputPros
}: FormTextInputProps) => {
  const {
    /* exclude Ref from variable */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    field: { ref, ...field },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue,
  });

  // useEffect(() => {
  //   defaultValue && field.onChange(defaultValue);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [defaultValue]);

  const errorText = error?.message;

  return (
    <TextInput
      {...field}
      errorText={errorText}
      hasError={!!errorText}
      {...textInputPros}
    />
  );
};
