import { steps } from 'components/grid/Wizards/ApproximateCostCalculationWizard/constants';
import { Steps } from 'components/grid/Wizards/ApproximateCostCalculationWizard/types';
import React, { useState } from 'react';
import { WizardWrapper } from '../OrderWizard/styles';

export const ApproximateCostCalculationWizard = () => {
  const [{ Component }, setActive] = useState(steps[Steps.initialStep]);

  const setStep = (step: Steps) => setActive(steps[step]);

  return (
    <WizardWrapper>
      <Component onNext={setStep} />
    </WizardWrapper>
  );
};
