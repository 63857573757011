export interface StepInterface {
  onNext: (step: Steps) => void;
  onFinish?: () => void;
}

export enum Steps {
  initialStep = 'initialStep',
  dimensionsStep = 'dimensionsStep',
  insuranceStep = 'insuranceStep',
  packageStep = 'packageStep',
  confirmationStep = 'confirmationStep',
  newAddressStep = 'newAddressStep',
}

export interface ItemList {
  activeStep: number;
  index?: number;
  Component: (props: StepInterface) => JSX.Element;
}

export type StepsList = {
  [key in Steps]: ItemList;
};
