import { Button } from 'components/common/buttons/Button';
import { fieldsMarginBottom } from 'components/forms/AddressForm/constants';
import { TextInput } from 'components/forms/inputs/FormTextInput';
import {
  contentPadding,
  contentWrapperProps,
} from 'components/modals/ConfirmEmailModal/constants';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import { Column, Section } from 'components/wrappers/grid/FlexWrapper';
import { languages } from 'constants/languages';
import { toPersonalLinkClick } from 'constants/routes';
import { useStore } from 'effector-react';
import React from 'react';
import { confirmEmailModal } from 'stores/initialize/initialize.modal.store';
import { userEffects, userStores } from 'stores/user';

const { formSendVerifyEmail } = userEffects;

const { submitButton, title } = languages.modals.confirmEmailModal;

export const ConfirmEmailModal = () => {
  const { email } = useStore(userStores.userProfile);
  const { visible } = useStore(confirmEmailModal.modal);
  const loading = useStore(formSendVerifyEmail.pending);

  const onCloseClick = () => {
    confirmEmailModal.closeModal();
    toPersonalLinkClick();
  };

  const onSubmitClick = async () => {
    await formSendVerifyEmail({ email: email || '' });
    confirmEmailModal.closeModal();
  };

  return (
    <ModalWrapper
      contentPadding={contentPadding}
      visible={visible}
      onCloseClick={onCloseClick}
      {...contentWrapperProps}
    >
      <Column alignCenter width="100%">
        <Section marginBottom={fieldsMarginBottom}>
          <TextInput disabled title={title} type="text" value={email} />
        </Section>
        <Section alignCenter justifyCenter>
          <Button isLoading={loading} onClick={onSubmitClick}>
            {submitButton}
          </Button>
        </Section>
      </Column>
    </ModalWrapper>
  );
};
