import { white } from 'constants/styles/colors';
import styled from 'styled-components';
import { TextProperties } from 'types/styles';

interface TitleTextProps extends Pick<TextProperties, 'alignCenter'> {
  isSecondary?: boolean;
}

export const TitleText = styled.h2<TitleTextProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  ${({ alignCenter }) => alignCenter && 'text-align: center'};
  color: ${({ isSecondary }) =>
    isSecondary ? 'rgba(255, 255, 255, 0.35)' : white};
  margin: 0;
`;
