import * as Yup from 'yup';
import {
  yupDefault,
  yupDefaultBoolean,
  yupOptionalPhoneNumber,
} from 'constants/yupFields';
import {
  endTimeValues,
  startTimeValues,
  tomorrowDate,
} from 'components/common/inputs/DateInput/constants';

export const contentMarginBottom = '10px';

export const yupSchema = Yup.object().shape({
  address: yupDefault,
  comment: yupDefault,
  confirmation: yupDefaultBoolean,
  currency: yupDefault,
  cargoPickUpPhone: yupOptionalPhoneNumber,
  cargoPickUpAddress: Yup.string(),
});

interface GetTotalPriceInterface {
  packagePrice?: number;
  servicesPrice?: number[];
  tariffCost?: number;
  insurance?: number;
}

export const getTotalPrice = ({
  tariffCost,
  insurance,
  packagePrice,
  servicesPrice,
}: GetTotalPriceInterface) =>
  (tariffCost || 0) +
  (insurance || 0) +
  (packagePrice || 0) +
  (servicesPrice
    ? servicesPrice.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0,
      )
    : 0);

export const defaultPickUpDateAndTimeRangeValues = {
  date: tomorrowDate,
  time: {
    from: startTimeValues[0],
    to: endTimeValues[endTimeValues.length - 1],
  },
};
