import { createEvent, createStore, forward } from 'effector';
import { API } from 'api';
import { defaultTariffCostResponse } from 'constants/defaults/store';
import { ordersEvents } from './orders';
import { createNotifyingErrorEffect } from 'utils/store';

const { setOrderFormValue, clearOrderFormData } = ordersEvents;

const getTariff = createNotifyingErrorEffect({
  handler: async (data: API.GetTariffRequestDto) => {
    const response = await API.tariffs.getTariff(data);
    setOrderFormValue({ tariff: response._id });

    return response;
  },
});

const tariff = createStore<API.GetTariffResponseDto | undefined>({}).on(
  getTariff.doneData,
  (_, newState) => newState,
);

const setDefaultTariffCost = createEvent();

const getTariffCost = createNotifyingErrorEffect({
  handler: async (data: API.GetTariffCostRequestDto) =>
    await API.tariffs.getTariffCost(data),
});

const tariffCost = createStore<API.GetTariffCostResponseDto>(
  defaultTariffCostResponse,
)
  .on(getTariffCost.doneData, (_, newState) => newState)
  .on(setDefaultTariffCost, () => defaultTariffCostResponse);

forward({
  from: [clearOrderFormData],
  to: [setDefaultTariffCost],
});

export const tariffsEvents = { setDefaultTariffCost };
export const tariffsEffects = { getTariff, getTariffCost };
export const tariffsStores = { tariff, tariffCost };
