import { TextButton } from 'components/common/buttons/TextButton';
import { ExternalLink } from 'components/common/links/ExternalLink';
import { AdditionalInputText } from 'components/common/typography/AdditionalInputText';
import { CheckboxText } from 'components/common/typography/CheckboxText';
import { TitleInput } from 'components/common/typography/TitleInput';
import React from 'react';
import {
  insuranceModal,
  termsOfShipmentModal,
} from 'stores/initialize/initialize.modal.store';
import { OrderCurrency, OrderStatuses } from 'types/types';
import { privacyPolicyLink, supportLink } from './routes';

export type LanguagesType = 'ru';

export type Languages = {
  [key in LanguagesType]: any;
};

const languagesData: Languages = {
  ru: {
    pages: {
      register: {
        registerForm: {
          lastName: {
            placeholder: 'Введите вашу фамилию',
            title: 'Фамилия',
          },
          firstName: {
            placeholder: 'Введите ваше имя',
            title: 'Имя',
          },
          phoneNumber: {
            placeholder: 'Введите ваш номер телефона',
            title: 'Номер телефона',
          },
          email: {
            placeholder: 'Введите ваш email',
            title: 'Email',
          },
          password: {
            placeholder: 'Введите ваш пароль',
            title: 'Пароль',
          },
          political: {
            title: (
              <CheckboxText>
                Соглашаюсь с{' '}
                <ExternalLink href={privacyPolicyLink}>
                  политикой конфидециальности
                </ExternalLink>
              </CheckboxText>
            ),
          },
        },
        text: {
          or: 'или',
          submitButton: 'Регистрация',
        },
      },
      login: {
        loginForm: {
          email: {
            placeholder: 'Введите ваш email',
            title: 'Email',
          },
          password: {
            placeholder: 'Введите ваш пароль',
            title: 'Пароль',
          },
        },
        text: {
          or: 'или',
          submitButton: 'Войти',
          forgotPassword: 'Забыли пароль?',
        },
      },
      forgotPassword: {
        forgotPasswordForm: {
          email: {
            placeholder: 'Введите ваш email',
            title: 'Email',
          },
        },
        text: {
          title: 'Забыли пароль?',
          submitButton: 'Войти',
          goBack: 'Вернуться назад',
        },
      },
      resetPassword: {
        resetPasswordForm: {
          password: {
            placeholder: 'Введите новый пароль',
            title: 'Пароль',
          },
          repeatPassword: {
            placeholder: 'Повторите новый пароль',
          },
        },
        text: {
          title: 'Изменение пароля',
          submitButton: 'Сменить пароль',
        },
      },
      confirmation: {
        text: {
          title: 'Подтверждение Email',
        },
      },
      personal: {
        personalForm: {
          firstName: {
            placeholder: 'Введите ваше имя',
            title: 'Имя',
          },
          lastName: {
            placeholder: 'Введите вашу фамилию',
            title: 'Фамилия',
          },
          email: {
            placeholder: 'Введите ваш email',
            title: 'Email',
          },
          dateOfBirth: {
            title: 'Дата рождения',
          },
          gender: {
            title: 'Пол',
            values: ['Женский', 'Мужской'],
          },
          telegram: {
            placeholder: '+7',
            title: 'Telegram',
          },
          weChat: {
            placeholder: 'ID',
            title: 'WeChat',
          },
          phoneNumber: {
            placeholder: '+7',
            title: 'Номер телефона',
          },
        },
        changePasswordForm: {
          password: {
            placeholder: 'Введите ваш текущий пароль',
            title: 'Пароль',
          },
          newPassword: {
            placeholder: 'Введите ваш новый пароль',
            title: 'Новый пароль',
          },
        },
        text: {
          personalData: 'Персональные данные',
          changePassword: 'Изменить пароль',
          feedback: 'Способы связи со мной',
          submitButton: 'Сохранить',
          confirmButton: 'Подтвердить Email',
        },
      },
      addresses: {},
      address: {
        addressForm: {
          addressName: {
            placeholder: 'Введите название адреса',
            title: 'Назовите адрес (например: Дом, Офис, Склад)',
          },
          firstName: {
            placeholder: 'Введите ваше имя',
            title: 'Имя',
          },
          lastName: {
            placeholder: 'Введите вашу фамилию',
            title: 'Фамилия',
          },
          secondName: {
            placeholder: 'Введите ваше отчество',
            title: 'Отчество',
          },
          country: {
            title: 'Страна',
          },
          area: {
            title: 'Область',
          },
          city: {
            title: 'Город',
          },
          street: { title: 'Улица', placeholder: 'Введите улицу' },
          house: { title: 'Дом', placeholder: 'Введите дом' },
          buildingHouse: { title: 'Корпус', placeholder: 'Введите корпус' },
          apartmentNumber: {
            title: 'Квартира',
            placeholder: 'Введите квартиру',
          },
          postCode: { title: 'Индекс', placeholder: 'Введите ваш индекс' },
          phoneNumber: {
            title: 'Номер телефона',
            placeholder: 'Введите ваш номер телефона',
          },
          delivery: {
            values: [
              'Требуется доставка до двери',
              'Самовывоз с почтового отделения в вашем городе',
              'Самовывоз с нашего основного склада',
            ],
          },
        },
        text: {
          recipient: 'Персональные данные',
          deliveryOfCargo: 'Доставка груза',
          continueButton: 'Продолжить',
          submitButton: 'Сохранить',
        },
      },
      shipments: {
        initialStepForm: {
          country: {
            title: 'Выберите страну',
          },
          subcategory: {
            title: 'Выберите категорию товара',
          },
          method: {
            title: 'Выберите метод отправки',
          },
        },
        dimensionsStepForm: {
          weight: {
            placeholder: 'Введите вес товара',
            title: 'Вес, кг',
          },
          height: {
            title: 'Высота, cм',
          },
          width: {
            title: 'Ширина, cм',
          },
          length: {
            title: 'Длинна, cм',
          },
          volume: {
            title: 'Объем, м³',
          },
        },
        packageStepForm: {
          packageType: {
            title: 'Выберите упаковку',
          },
          services: {
            title: 'Дополнительные услуги',
          },
        },
        insuranceStepForm: {
          productPrice: {
            postfix: '$',
            title: 'Введите общую стоимость груза',
          },
          confirmation: {
            title: (
              <CheckboxText>
                Соглашаюсь c{' '}
                <TextButton
                  type="button"
                  onClick={() => insuranceModal.openModal()}
                >
                  условиями оформления страховки
                </TextButton>
              </CheckboxText>
            ),
          },
        },
        confirmationStepForm: {
          country: {
            title: 'Страна',
          },
          category: {
            title: 'Категория товара',
          },
          method: {
            title: 'Метод отправки',
          },
          dimensions: {
            title: 'Габариты, см',
          },
          weight: {
            title: 'Вес, кг',
          },
          volume: {
            title: 'Объем, м³',
          },
          insurance: {
            title: 'Страховка',
          },
          package: {
            title: 'Упаковка',
          },
          services: {
            title: 'Дополнительные услуги',
          },
          currency: {
            title: 'Валюта заказа',
            values: [OrderCurrency.Yuan, OrderCurrency.Other],
          },
          address: {
            title: 'Добавить адрес из списка',
            placeholder: 'Выберите адрес',
          },
          comment: {
            title: 'Комментарий к заказу',
            placeholder:
              'Укажите перевозимые товары, их количество и комментарий для нашего менеджера, который поможет доставить Ваш груз в целости и сохранности',
          },
          confirmation: {
            title: (
              <CheckboxText>
                Согласен c{' '}
                <TextButton
                  type="button"
                  onClick={() => termsOfShipmentModal.openModal()}
                >
                  условиями отправки
                </TextButton>
              </CheckboxText>
            ),
          },
          cargoPickUpCheckbox: {
            title: (
              <CheckboxText>
                Оформить забор груза от двери.{' '}
                <ExternalLink href={supportLink}>Подробнее</ExternalLink>
              </CheckboxText>
            ),
          },
          cargoPickUpAddress: {
            title: 'Адрес, откуда мы заберем заказ, на китайском языке',
          },
          cargoPickUpDateAndTimeRange: {
            title:
              'Дата и время забора заказа (GMT+8). Заявки принимаются за 1 день',
          },
          cargoPickUpPhone: {
            title: 'Телефон контактного лица',
          },
        },
        text: {
          requiredField: '* Обязательное поле',
          submitButton: 'Далее',
          approximateCost: 'Примерная цена',
          calculateCostButton: 'Рассчитать стоимость',
          dimensionsStepFormTitle: 'Введите габариты товара',
          insuranceStepFormTitle: 'Оформление страховки',
          confirmationStepFormTitle: 'Подтверждение заказа',
          newAddressButton: 'Новый адрес',
          orderButton: 'Оформить заказ',
          timeRange: ['От', 'До'],
          insurancePrice: 'Стоимость страховки',
          orderPrice: 'Стоимость заказа',
          orderPriceAdditionalInfo:
            '* Цена доставки до нашего склада в стране получения',
          itemStatus: ['Отсутствует', 'Присутствует'],
          insuranceOrderingButton: 'Оформить страховку',
          withoutInsuranceButton: 'Продолжить без страховки',
          insurancePerWeightError:
            'Невозможно оформить страховку на введенную сумму заказа',
          insurancePerPriceError:
            'Введенная сумма заказа не может быть застрахована',
          volumeError: 'Высота, ширина и длина не соответствует объему',
          weightTitle: 'Вес товара',
          weightMeasurement: 'кг',
          price: 'Стоимость',
          priceComment: '*Цена доставки до нашего склада в стране получения',
          PackageDescriptionJSX: () => (
            <AdditionalInputText>
              Цена указана за <b>1 место груза</b> Если Ваш заказ займет больше
              места, стоимость заказа изменится и наш менеджер сообщит Вам об
              этом.
            </AdditionalInputText>
          ),
          InsuranceDescriptionJSX: () => (
            <TitleInput>
              Если Вы хотите продолжить <b>без оформления страховки</b>, мы{' '}
              <b>не сможем вернуть</b> Вам стоимость заказа.
            </TitleInput>
          ),
        },
      },
    },
    layouts: {
      headers: {
        mainHeader: {
          logoutButton: 'Выйти',
          contactNumber: '+86 134 071 095 01',
          goMain: 'На главную',
        },
        authorizedHeader: {
          menu: {
            account: 'Личный кабинет',
            shipments: 'Отправки из Китая',
            support: 'Справочная',
          },
        },
      },
      mobileNavBar: {
        main: 'Главная',
        support: 'Справочная',
        shipments: 'Отправки',
        account: 'Профиль',
      },
      tabs: {
        authTab: {
          registration: 'Регистрация',
          login: 'Войти',
        },
        accountTab: {
          personal: 'Личная информация',
          addresses: 'Адреса',
          orders: 'Мои заказы',
        },
      },
      addressesLayout: {
        addButton: 'Добавить новый адрес',
      },
      ordersLayout: {
        inputs: {
          orderNumber: {
            placeholder: 'Введите номер заказа',
            // prefix: '№',
          },
          orderStatus: {
            placeholder: 'Статус отправки',
          },
          orderDateRange: {
            placeholder: 'Выберите дату',
          },
        },
        text: {
          resetFilter: 'Сбросить фильтры',
        },
        addButton: 'Добавить новый заказ',
        fileButton: 'Вложения',
      },
      cards: {
        addressCard: {
          cityTableColumns: ['Страна', 'Область', 'Город', 'Улица'],
          houseTableColumns: ['Индекс', 'Дом', 'Корпус', 'Квартира'],
          phoneTableColumns: ['Номер телефона'],
        },
        orderCard: {
          historyTableColumns: ['История заказа'],
          amountTableColumns: ['Сумма заказа'],
          cityTableColumns: ['Страна', 'Область', 'Город', 'Улица'],
          houseTableColumns: ['Индекс', 'Дом', 'Корпус', 'Квартира'],
          termsTableColumns: [
            'Метод отправки',
            'Категория товара',
            'Страховка',
          ],
          packagingTableColumns: [
            'Упаковка',
            'Габариты, см',
            'Вес,кг',
            'Объем, м3',
          ],
          extraServiceTableColumns: ['Доп. Услуги', 'Валюта заказа'],
          cargoPickUpAddressColumns: ['Адрес забора груза'],
          cargoPickUpDateAndPhoneColumns: [
            'Дата и время',
            'Телефон контактного лица',
          ],
          insuranceStatus: ['Отсутствует', 'Присутствует'],
        },
      },
    },
    buttons: {
      socialButton: {
        vk: {
          nameIcon: 'Вконтакте',
        },
        fb: {
          nameIcon: 'Facebook',
        },
        telegram: {
          nameIcon: 'Telegram',
        },
        wechat: {
          nameIcon: 'WeChat',
        },
      },
    },
    inputs: {
      dateInput: {
        months: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
      },
      orderSelector: {
        orderStatus: [
          { value: OrderStatuses.Issued, label: 'Оформлен' },
          { value: OrderStatuses.InProcessing, label: 'В обработке' },
          { value: OrderStatuses.ShippedWarehouse, label: 'В пути на склад' },
          { value: OrderStatuses.AtWarehouse, label: 'На складе' },
          {
            value: OrderStatuses.ShippedCountry,
            label: 'Отправлен со склада в страну назначения',
          },
          {
            value: OrderStatuses.ArrivedCountry,
            label: 'Прибыл в страну назначения',
          },
          { value: OrderStatuses.AwaitingPickup, label: 'Готов к выдаче' },
          { value: OrderStatuses.SentClient, label: 'Отправлен клиенту' },
          { value: OrderStatuses.Canceled, label: 'Отменен' },
          { value: OrderStatuses.Completed, label: 'Завершен' },
          { value: OrderStatuses.Deleted, label: 'Удален' },
        ],
      },
    },
    validation: {
      messages: {
        requiredField: 'Это поле обязательно к заполнению',
        requiredSet: 'Этот набор обязателен',
        firstNameLength: (min: number) =>
          `Имя должно содержать как минимум ${min} символа`,
        lastNameLength: (min: number) =>
          `Фамилия должна содержать как минимум ${min} символа`,
        invalidEmail: 'Некорректный email',
        exactLimit: (limit: number) =>
          `В этом поле содержится ровно ${limit} символов`,
        matchPassword: 'Пароли не совпадают',
        confirmCondition: 'Вы должны подтвердить условия',
        urlMatch: 'Пожалуйста, введите URL',
        maximumValue: (max: number) =>
          `Максимальное значение для поля - ${max}`,
        wholeNumberValue: `Допустимы только целые значения`,
        passwordLength: (min: number) =>
          `Пароль должен содержать как минимум ${min} символов`,
        oneCapitalCharRequired:
          'Требуется использовать как минимум одну заглавную букву',
        atLeastOneNumberRequired:
          'Требуется использовать как минимум один цифровой символ',
        onlySimpleCharactersAllowed: 'Разрешены только 0-9 a-z A-Z символы',
        incorrectId:
          'Разрешены только 0-9 a-z A-Z символы длинной минимум 4 символа',
        incorrectPhoneNumber: 'Некорректный номер телефона',
        incorrectPostCode: 'Некорректный индекс',
        shouldBeMoreThanZero: 'Значение должно быть больше или равно 0.01',
        shouldBePositive: 'Значение должно быть положительное',
      },
    },
    modals: {
      informationModal: {
        successRegistration: 'Регистрация прошла успешно',
        sendingEmail: 'На Вашу почту отправлено подтверждение',
        successPasswordChange: 'Пароль успешно изменен',
        successVerify: 'Аккаунт успешно подтвержден',
        somethingError: 'Непредвиденная ошибка',
        successAddressSave: 'Ваш адрес успешно сохранен',
        successOrderDelete: 'Ваш заказ успешно удален',
        successDeleteAddress: 'Ваш адрес удален',
      },
      confirmationModal: {
        titleAddress: 'Вы уверены, что хотите удалить выбранный адрес?',
        titleOrder: 'Вы уверены, что хотите удалить выбранный заказ?',
        okText: 'Да',
        closeText: 'Нет',
      },
      inputEmailModal: {
        submitButton: 'Зарегистрироваться',
        cancelLink: 'Вернуться назад',
        title: 'Введите свой email, чтобы продолжить регистрацию',
      },
      confirmEmailModal: {
        submitButton: 'Отправить письмо',
        title: 'Подтвердите свой email, чтобы продолжить',
      },
      errorModal: {
        title: 'Ошибка',
        defaultSubtitle: 'К сожалению, произошла ошибка',
        callToActionText: `Сообщите нам об этой ошибке по email: <b>info@malean.online</b>,
            скопировав код ошибки.`,
        supplementaryText: 'Посмотреть код ошибки',
        userIdTitle: 'user id: ',
        entityIdTitle: 'entity Id: ',
        pathTitle: 'path: ',
        statusTitle: 'status: ',
        errorTitle: 'error: ',
        messageTitle: 'message: ',
        copyButtonText: 'Copy error text',
        successButtonText: 'Ok',
      },
      insuranceModal: {
        content: `<h2>Зачем нужна страховка и как она работает?</h2>
            <ol>
              <li>
                Страховка распространяется на утерю и конфискацию груза
                таможней.
              </li>

              <li>
                Если вы не получили посылку в течении 60 дней со дня выхода
                накладной, <b>MALEAN</b> обязуется выплатить полную
                стоимость груза, при условии оформленной страховки.
              </li>

              <li>
                В случае, если ваша посылка в процессе перевозки была
                конфискована таможней, <b>MALEAN</b> обязуется
                выплатить полную стоимость груза, при условии оформленной
                страховки.
              </li>
              <li>
                В случае, если ваша посылка в процессе перевозки была
                конфискована таможней и в ней были обнаружены запрещенные или
                ранее не указанные вами товары, <b>MALEAN</b> оставляет
                за собой право не выплачивать страховку
              </li>

              <li>
                При оформлении страховки правдиво указывайте содержимое посылки
                и ее реальную стоимость!
              </li>
            </ol>
        `,
      },
      termsOfShipmentModal: {
        content: `
            <h2>Условия отправки</h2>
            <ol>
              <li>
                Правдиво и максимально подробно заполняйте информацию о
                содержимом вашей посылки.
              </li>

              <li>
                Убедитесь, что ваш личный код указан на посылке или же в имени
                получателя на нашем складе, в противном случае могут возникнуть
                трудности в идентификации посылки.
              </li>

              <li>
                Все посылки при поступлении на склад проверяются на наличие
                запрещенных к перевозке товаров. При обнаружении запрещенных
                товаров они изымаются до дальнейших выяснений обстоятельств.
              </li>

              <li>ОБЯЗАТЕЛЬНО указывайте наличие ценных вещей и документов!</li>

              <li>
                Убедитесь что данные конечного получателя (адрес, номер телефона
                whats app) верны и актуальны.
              </li>

              <li>
                По прошествии 2ух дней после выхода накладной все данные
                считаются верными и актуальными. Изменения вносятся не позднее 5
                дней после выхода накладной.
              </li>

              <li>
                При возникновении влияния третьей стороны/форс-мажоров могут
                быть незначительные изменения в сроках доставки.
              </li>

              <li>
                Оплата доставки по накладной должна быть произведена не позднее
                чем в день получения извещения о прибытии груза. В ином случае
                будет взыматься плата за хранение.
              </li>

              <li>
                В случае самовывоза груза с пункта прибытия, не позднее чем в
                день получения извещения о прибытии груза. В ином случае будет
                взыматься плата за хранение.
              </li>

              <li>
                Конвертация суммы оплаты по накладной из долларов в валюту
                оплаты происходит по внутреннему курсу компании в момент оплаты.
              </li>

              <li>
                Посылки не вскрываются на протяжении всего пути. При обнаружении
                следов вскрытия обратитесь в пункт выдачи по месту.
              </li>

              <li>
                Клиент имеет право оспорить данные о весе и кубатуре посылки
                ТОЛЬКО до вскрытия целостности упаковки груза.
              </li>
            </ol>
          `,
      },
      invoiceModal: {
        submitButton: 'Подтвердить',
        title: 'Чек заказа',
        titleCargoPickUp: 'Адрес забора груза',
        cargoPickUpAddress: 'Адрес',
        cargoPickUpDateAndTime: 'Дата и время',
        cargoPickUpPhone: 'Телефон контактного лица',
        titleAddress: 'Адрес доставки',
        recipient: 'Получатель',
        phoneNumber: 'Номер телефона',
        country: 'Страна',
        region: 'Область',
        city: 'Город',
        street: 'Улица',
        building: 'Дом,корпус',
        frame: 'корпус',
        flat: 'Квартира',
        postcode: 'Индекс',
        titleCost: 'Стоимость заказа',
        tariff: 'Тариф',
        insurance: 'Страховка',
        extraServices: 'Доп. услуги:',
        titleAmount: 'Сумма',
        orderCurrency: 'Валюта заказа',
        successInvoiceConfirmed: 'Ваш заказ успешно оформлен',
      },
      approximateCostCalculator: {
        message:
          'Вам необходимо зарегистрироваться, чтобы продолжить оформление заказa.',
        buttonText: 'Зарегистрироваться',
      },
      adaptiveNotificationModal: {
        NotificationJSX: () => (
          <span>
            К сожалению, данное разрешение экрана <b>не поддерживается.</b>
            &nbsp;Вы можете открыть сайт с устройств с разрешением экрана более
            1024px.
          </span>
        ),
      },
    },
  },
};

export const languages = languagesData['ru'];
