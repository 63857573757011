import {
  Column,
  FlexGrow,
  Section,
} from 'components/wrappers/grid/FlexWrapper';
import styled from 'styled-components';
import { ColumnAlignment } from 'types/data';
import { BackgroundColor } from 'types/styles';

export const TableHeaderColumnSpan = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.35);
`;

export const Cell = styled(FlexGrow)<ColumnAlignment>`
  flex-direction: row;
  ${({ columnAlignment }) =>
    columnAlignment === 'start'
      ? 'justify-content: flex-start;'
      : columnAlignment === 'end'
      ? 'justify-content: flex-end;'
      : 'justify-content: center;'}

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  padding-left: 15px;
  padding-right: 15px;
`;

interface TableHeaderProps extends BackgroundColor {}

export const TableHeader = styled(Section)<TableHeaderProps>`
  box-sizing: border-box;
  ${({ height }) => height && `height: ${height}`};
  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};
`;

export const TableBody = styled(Column)`
  width: 100%;
`;

export const RowWrapper = styled(Section)`
  min-height: 48px;
  border-top: 0.5px solid rgba(255, 255, 255, 0.35);
  box-sizing: border-box;
  padding-top: 10px;
  padding-bottom: 10px;
`;
