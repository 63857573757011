import { ConfirmationButton } from 'components/common/buttons/ConfirmationButton';
import { contentWrapperProps } from 'components/modals/ConfirmationModal/constants';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import { Column, Row, Section } from 'components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { modalEvents, modalStores } from 'stores/modals/asyncModal';
import { TitleSpan } from './styles';

export const ConfirmationModal = () => {
  const [loading, { visible, title, onOk, closeText, okText }] = useStore(
    modalStores.asyncModalStore,
  );
  const isMobile = useMediaQuery(MediaSizes.Mobile);
  const isSmallMobile = useMediaQuery(MediaSizes.MobileSmall);
  const mobileMaxWidth = isSmallMobile
    ? '210px'
    : isMobile
    ? '295px'
    : undefined;

  const okHandler = () => onOk && onOk();
  const onClose = () => modalEvents.closeAsyncModal();

  return (
    <ModalWrapper
      visible={visible}
      onCloseClick={onClose}
      {...contentWrapperProps}
    >
      <Column alignCenter width="100%">
        <Row
          alignCenter
          marginBottom="35px"
          maxWidth={mobileMaxWidth}
          width={isMobile ? '100%' : '370px'}
        >
          <TitleSpan>{title}</TitleSpan>
        </Row>

        <Section justifyCenter noWrap marginBottom="0">
          <MarginWrapper marginRight="20px">
            <ConfirmationButton onClick={onClose}>
              {closeText}
            </ConfirmationButton>
          </MarginWrapper>

          <ConfirmationButton
            buttonType="secondary"
            isLoading={loading}
            onClick={okHandler}
          >
            {okText}
          </ConfirmationButton>
        </Section>
      </Column>
    </ModalWrapper>
  );
};
