import { Span } from 'components/common/typography/Span';
import { Tab } from 'components/grid/Bars/TabBar/types';
import React from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { MobileTab, Wrapper } from './styles';

interface Props {
  tabs: Tab[];
}

export const MobileTabBar = ({ tabs }: Props) => {
  const { pathname } = useLocation();

  return (
    <Wrapper>
      {tabs.map(({ path, description }) => {
        const isActive = !!matchPath(pathname, {
          path,
        });

        return (
          <MobileTab key={path} active={isActive}>
            <Link to={path}>
              <Span>{description}</Span>
            </Link>
          </MobileTab>
        );
      })}
    </Wrapper>
  );
};
