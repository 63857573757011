import { white } from 'constants/styles/colors';
import styled, { css } from 'styled-components';

interface TitleTextProps {
  isSecondary?: boolean;
}

export const TitleInputMixin = css`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const TitleInput = styled.h3<TitleTextProps>`
  ${TitleInputMixin};
  color: ${({ isSecondary }) =>
    isSecondary ? 'rgba(255, 255, 255, 0.35)' : white};
  margin: 0;
`;
