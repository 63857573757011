import React from 'react';
import { ApproximateCostCalculationWizard } from 'components/grid/Wizards/ApproximateCostCalculationWizard';
import { toShipmentsLinkClick } from 'constants/routes';
import { userStores } from 'stores/user';
import { useStore } from 'effector-react';

export const ApproximateCostCalculator = () => {
  const { accessToken } = useStore(userStores.user);

  if (accessToken) {
    toShipmentsLinkClick();
    return null;
  }

  return <ApproximateCostCalculationWizard />;
};
