import { languages } from 'constants/languages';
import { inputEmailModal } from 'stores/initialize/initialize.modal.store';
import { SelectorType } from 'types/types';

const { values: genderValues } = languages.pages.personal.personalForm.gender;
const { values: deliveryValues } = languages.pages.address.addressForm.delivery;
const orderStatus: SelectorType[] = languages.inputs.orderSelector.orderStatus;

//values: ['Женский', 'Мужской']
const genderMale = genderValues[1];
const genderFemale = genderValues[0];

const genderBackendValues: { [key: string]: API.GenderType } = {
  [genderMale]: 'male', //Мужской: 'male',
  [genderFemale]: 'female', //Женский: 'female',
};

const homeDelivery = deliveryValues[0];
const postDelivery = deliveryValues[1];
const cargoDelivery = deliveryValues[2];

const deliveryBackendValues: { [key: string]: string } = {
  [homeDelivery]: 'home',
  [postDelivery]: 'post',
  [cargoDelivery]: 'cargo',
};

export const getDateFromString = (dateISOString?: string) => {
  if (!dateISOString) return '';

  // * 2021-01-09T13:57:15.832Z -> 2021-01-09
  return dateISOString.split('T')[0];
};

export const getValueByKey = (object: { [key: string]: any }, value: any) =>
  Object.keys(object).find((key) => object[key] === value);

//when submit to BE: 'Мужской' / 'Женский' => male/ female
export const getGender = (gender: string | undefined) =>
  gender === undefined ? undefined : genderBackendValues[gender];

// for radio btn label: male/ female => 'Мужской' / 'Женский'
export const getGenderValue = (gender: API.GenderType | undefined) =>
  getValueByKey(genderBackendValues, gender);

export const getDeliveryPost = (delivery: string | undefined) =>
  delivery === undefined ? undefined : deliveryBackendValues[delivery];

export const getDeliveryPostValue = (
  delivery: string | undefined,
): string | undefined => getValueByKey(deliveryBackendValues, delivery);

export const getOrderStatus = (value: string | number | undefined) =>
  orderStatus.find(({ value: orderValue }) => orderValue === value)?.label;

// TRIGGER COPY TO CLIPBOARD
export const triggerCopy: (text: string) => void = (text) =>
  navigator.clipboard.writeText(text);

export const getRegistrationData = () => {
  //  'auth/login?noEmail=true&socialId=665124576&firstName=Malean&lastName=Malean&provider=vkontakte';
  const userDataResponse = window.location.search;

  if (userDataResponse.includes('noEmail=true')) {
    const registrationData: API.SignUpWithSocialMediaDto & {
      [key: string]: string | number;
    } = {
      email: '',
      socialId: NaN,
      firstName: '',
      lastName: '',
      provider: '',
    };

    const startIndex = userDataResponse.indexOf('&') + 1;

    userDataResponse
      .slice(startIndex)
      .split('&')
      .forEach((item) => {
        const [key, value] = item.split('=');

        if (Object.keys(registrationData).includes(key)) {
          registrationData[key] = value;
        }
      });

    inputEmailModal.openModal(registrationData);
  }
};
