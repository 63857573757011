import React from 'react';
import {
  Badge,
  BadgeContentWrapper,
  TitleSpan,
  TotalTitleSpan,
  ValueWrapper,
} from './styles';
import {
  InformationBadgeColorTypeInterface,
  InformationBadgeTypeInterface,
  InformationValue,
} from 'components/common/dividers/InformationBadge/types';
import { Disabled } from 'types/form';
import { Column } from 'components/wrappers/grid/FlexWrapper';
import { Loading } from 'types/data';
import { Loader } from 'components/dynamic/Loader';
import { MinSizes } from 'types/styles';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';

export interface InformationBadgeProps
  extends InformationBadgeTypeInterface,
    InformationBadgeColorTypeInterface,
    Disabled,
    Pick<MinSizes, 'minWidth'>,
    Loading {
  content: InformationValue | InformationValue[];
}

export const InformationBadge = ({
  minWidth,
  content,
  type = 'information',
  colorType = 'default',
  disabled = false,
  isLoading = false,
}: InformationBadgeProps) => {
  const isInformationType = type === 'information';

  function isMultiple(
    content: InformationValue | InformationValue[],
  ): content is InformationValue[] {
    return (content as InformationValue).value === undefined;
  }

  return (
    <Badge
      colorType={colorType}
      disabled={disabled}
      minWidth={minWidth}
      type={type}
    >
      {isMultiple(content) ? (
        <Column width="100%">
          {content.map((item) => {
            const { title, value } = item;
            return (
              <BadgeContentWrapper key={title}>
                <TitleSpan>{title}</TitleSpan>
                <ValueWrapper>{value}</ValueWrapper>
              </BadgeContentWrapper>
            );
          })}
        </Column>
      ) : isInformationType ? (
        <>
          <MarginWrapper marginRight="10px">
            <TitleSpan>{content.title}</TitleSpan>
          </MarginWrapper>
          <ValueWrapper>{content.value}</ValueWrapper>
        </>
      ) : isLoading ? (
        <Loader />
      ) : (
        <TotalTitleSpan>{content.value}</TotalTitleSpan>
      )}
    </Badge>
  );
};
