import React from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import {
  Select,
  SelectorInterface,
  SelectProps,
} from 'components/common/inputs/Select';
import { Column, Row } from 'components/wrappers/grid/FlexWrapper';
import { Title } from 'types/data';
import { InputLabel } from '../FormTextInput/styles';

interface SelectInputProps extends SelectProps, Pick<Title, 'title'> {}

export const SelectInput = ({ title, ...selectProps }: SelectInputProps) => (
  <Column width="100%">
    <Row marginBottom="5px">
      <InputLabel>{title}</InputLabel>
    </Row>
    <Select width="100%" {...selectProps} />
  </Column>
);

interface FormSelectProps extends SelectInputProps {
  control: Control<FieldValues>;
  name: string;
}

export const FormSelect = ({
  name,
  control,
  defaultValue = { value: '', label: '' },
  selector,
  ...selectProps
}: FormSelectProps) => {
  const {
    field: { onChange, value },
    // fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue?.value,
  });

  const onSelectChange = ({ value }: SelectorInterface) => {
    onChange(value);
  };

  return (
    <SelectInput
      defaultValue={selector.find(
        ({ value: selectorValue }) => selectorValue === value,
      )}
      selector={selector}
      onChange={onSelectChange}
      {...selectProps}
    />
  );
};
