import { MobileAccountIcon } from 'components/common/icons/SVGIcons/MobileAccountIcon';
import { MobileMainIcon } from 'components/common/icons/SVGIcons/MobileMainIcon';
import { MobileShipmentsIcon } from 'components/common/icons/SVGIcons/MobileShipmentsIcon';
import { MobileSupportIcon } from 'components/common/icons/SVGIcons/MobileSupportIcon';
import { languages } from 'constants/languages';
import {
  accountLink,
  landingLink,
  shipmentsLink,
  supportLink,
} from 'constants/routes';

const { main, account, shipments, support } = languages.layouts.mobileNavBar;

export const mobileNavItems = [
  {
    title: main,
    path: landingLink,
    Icon: MobileMainIcon,
  },
  {
    title: support,
    path: supportLink,
    Icon: MobileSupportIcon,
  },

  {
    title: shipments,
    path: shipmentsLink,
    Icon: MobileShipmentsIcon,
  },

  {
    title: account,
    path: accountLink,
    Icon: MobileAccountIcon,
  },
];
