import React, { useEffect, useState } from 'react';
import checkmarkIcon from 'assets/icons/checked-icon.svg';
import largeCheckmarkIcon from 'assets/icons/large-checed-icon.svg';
import { CustomImg } from 'components/common/imgComponents/CustomImg';
import {
  checkMarkIconHeight,
  checkMarkIconWidth,
  checkPosition,
  largeCheckMarkIconHeight,
  largeCheckMarkIconWidth,
} from 'components/common/inputs/Checkbox/constants';
import {
  HiddenCheckbox,
  Label,
  LabelText,
  VisibleCheckbox,
  Wrapper,
} from 'components/common/inputs/Checkbox/styles';
import {
  CheckboxProps,
  CheckboxTypeInterface,
} from 'components/common/inputs/Checkbox/types';
import { AbsoluteWrapper } from 'components/wrappers/grid/AbsoluteWrapper';
import { noop } from 'constants/functions';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { MarginWrapper } from 'components/wrappers/MarginWrapper';

interface BooleanCheckboxProps extends CheckboxProps, CheckboxTypeInterface {
  onChange?: (checked: boolean) => void;
  name?: string;
}

export const BooleanCheckbox = ({
  defaultChecked = false,
  disabled = false,
  onChange = noop,
  name = 'name',
  type = 'default',
  id,
}: BooleanCheckboxProps) => {
  const [checked, setChecked] = useState(defaultChecked);
  const isLargeType = type === 'large';

  const onCheckboxChange = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  useEffect(() => setChecked(defaultChecked), [defaultChecked]);

  return (
    <Wrapper>
      <Label>
        <VisibleCheckbox checked={checked} disabled={disabled} type={type}>
          <AbsoluteWrapper {...checkPosition(isLargeType)}>
            {isLargeType ? (
              <CustomImg
                alt="Checkbox"
                height={largeCheckMarkIconHeight}
                src={largeCheckmarkIcon}
                width={largeCheckMarkIconWidth}
              />
            ) : (
              <CustomImg
                alt="Checkbox"
                height={checkMarkIconHeight}
                src={checkmarkIcon}
                width={checkMarkIconWidth}
              />
            )}
          </AbsoluteWrapper>
        </VisibleCheckbox>
        <HiddenCheckbox
          checked={checked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={onCheckboxChange}
        />
      </Label>
    </Wrapper>
  );
};

export interface TextCheckboxProps extends BooleanCheckboxProps {
  title?: Element | string;
}

export const TextCheckbox = ({
  type = 'default',
  id,
  title,
  textType = 'default',
  ...checkboxProps
}: TextCheckboxProps) => (
  <Section alignCenter noWrap>
    <LabelText htmlFor={id} textType={textType}>
      <MarginWrapper marginRight="11px">
        <BooleanCheckbox id={id} type={type} {...checkboxProps} />
      </MarginWrapper>
      {title}
    </LabelText>
  </Section>
);
