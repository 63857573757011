import { homeLink } from 'constants/routes';
import React from 'react';
import { Redirect, RouteProps } from 'react-router';
import { RemoveTrailingSlashRoute } from 'routes/RemoveTrailingSlashRoute';
import { userStores } from 'stores/user';
import { useStore } from 'effector-react';

export const AuthRoute = (props: RouteProps) => {
  const user = useStore(userStores.user);

  if (!user?.accessToken) return <RemoveTrailingSlashRoute {...props} />;
  else return <Redirect to={homeLink} />;
};
