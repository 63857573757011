import React, { FC } from 'react';

import {
  StyledLink,
  StyledLinkProps,
} from 'components/common/links/ExternalLink/styles';
import { Color, TextProperties } from 'types/styles';

interface Props extends StyledLinkProps, TextProperties, Color {
  href: string;
  hoverColor?: string;
  activeColor?: string;
}

export const ExternalLink: FC<Props> = ({
  href,
  children = href,
  ...styleProps
}) => (
  <StyledLink href={href} target="_blank" {...styleProps}>
    {children}
  </StyledLink>
);
