import {
  yupDefault,
  yupPassword,
  yupRepeatPassword,
} from 'constants/yupFields';
import * as Yup from 'yup';
import { ResetPasswordData } from 'pages/Auth/ResetPassword/types';
import { userEffects } from 'stores/user';

export const yupSchema = Yup.object().shape({
  confirmationToken: yupDefault,
  password: yupPassword,
  repeatPassword: yupRepeatPassword(),
});

export const onSubmit = async ({
  confirmationToken,
  password,
}: ResetPasswordData) => {
  await userEffects.formRestorePassword({
    password,
    token: confirmationToken,
  });
};
