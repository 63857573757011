import styled from 'styled-components';

export const ForgotPassword = styled.div`
  a {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #ffffff;
  }
`;
