import { add, getDaysInMonth } from 'date-fns';
import { languages } from 'constants/languages';

export const currentYear = new Date().getFullYear();

export const getRangeValue = (minValue: number, maxValue: number) =>
  maxValue - minValue > 0
    ? Array.from({ length: maxValue - minValue + 1 }, (_, i) =>
        (minValue + i).toString(),
      )
    : [maxValue.toString()];

export const daysInMonth = (month: number) =>
  getDaysInMonth(new Date(currentYear, month));

export const defaultDays = 30;
export const defaultDaysRange = getRangeValue(1, defaultDays);
export const tomorrowDate = add(new Date(), { days: 1 });
export const selectFontWeight = '500';

export const getDayRange = (
  numberOfSelectedMonth: number | undefined,
  selectedYear: number | undefined,
  maxDate: Date,
) => {
  const isSelectedDateIsMax =
    selectedYear === maxDate?.getFullYear() &&
    numberOfSelectedMonth === maxDate?.getMonth();

  const returnDays = isSelectedDateIsMax
    ? maxDate?.getDate()
    : numberOfSelectedMonth !== undefined
    ? daysInMonth(numberOfSelectedMonth)
    : defaultDays;

  return getRangeValue(1, returnDays);
};

export const months: string[] = languages.inputs.dateInput.months;

export const getMonthRange = (
  selectedYear: number | undefined,
  maxDate: Date,
) =>
  selectedYear === maxDate.getFullYear()
    ? months.filter((_, i) => i <= maxDate.getMonth())
    : months;

export const dataPickerIconDiameter = '46px';
export const dataPickerWidth = '260px';
export const inputHeight = '48px';

export interface DateAndTimeRange {
  date: Date;
  time: API.TimeRange;
}

export const timeRangeValues = [
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
];
export const startTimeValues = timeRangeValues.slice(0, -1);

export const endTimeValues = timeRangeValues.slice(1);
