import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  width: 49px;

  .circleG {
    background-color: rgb(255, 255, 255);
    float: left;
    height: 10px;
    margin-left: 6px;
    width: 10px;
    animation-name: bounce_circleG;
    -o-animation-name: bounce_circleG;
    -ms-animation-name: bounce_circleG;
    -webkit-animation-name: bounce_circleG;
    -moz-animation-name: bounce_circleG;
    animation-duration: 0.9775s;
    -o-animation-duration: 0.9775s;
    -ms-animation-duration: 0.9775s;
    -webkit-animation-duration: 0.9775s;
    -moz-animation-duration: 0.9775s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    border-radius: 7px;
    -o-border-radius: 7px;
    -ms-border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
  }

  #circleG_1 {
    animation-delay: 0.1995s;
    -o-animation-delay: 0.1995s;
    -ms-animation-delay: 0.1995s;
    -webkit-animation-delay: 0.1995s;
    -moz-animation-delay: 0.1995s;
  }

  #circleG_2 {
    animation-delay: 0.4555s;
    -o-animation-delay: 0.4555s;
    -ms-animation-delay: 0.4555s;
    -webkit-animation-delay: 0.4555s;
    -moz-animation-delay: 0.4555s;
  }

  #circleG_3 {
    animation-delay: 0.5885s;
    -o-animation-delay: 0.5885s;
    -ms-animation-delay: 0.5885s;
    -webkit-animation-delay: 0.5885s;
    -moz-animation-delay: 0.5885s;
  }

  @keyframes bounce_circleG {
    0% {
    }

    50% {
      background-color: #e30b5e;
    }

    100% {
    }
  }

  @-o-keyframes bounce_circleG {
    0% {
    }

    50% {
      background-color: #e30b5e;
    }

    100% {
    }
  }

  @-ms-keyframes bounce_circleG {
    0% {
    }

    50% {
      background-color: #e30b5e;
    }

    100% {
    }
  }

  @-webkit-keyframes bounce_circleG {
    0% {
    }

    50% {
      background-color: #e30b5e;
    }

    100% {
    }
  }

  @-moz-keyframes bounce_circleG {
    0% {
    }

    50% {
      background-color: #e30b5e;
    }

    100% {
    }
  }
`;
