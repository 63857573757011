import {
  lg,
  lg_1,
  md,
  md_1,
  sm,
  sm_1,
  xl,
  xl_1,
  xs,
  xs_1,
  xxl,
  xxl_1,
} from 'constants/styles/sizes';

export type Noop = () => void;

export type AlertStatusType = 'info' | 'success' | 'error' | undefined;
export type WomValidationStageType = 0 | 1 | 2 | 3 | undefined;

export type SubjectType = string | boolean | string[];

export type OkHandlerType = (subject: SubjectType) => Promise<void>;

export type AuthValuesType = {
  password: string;
  usernameOrEmail: string;
};

export type RegisterValuesType = {
  username: string;
  email: string;
  password: string;
};

// TODO: reduce expression
export type Size =
  | typeof xs
  | typeof sm
  | typeof md
  | typeof lg
  | typeof xl
  | typeof xxl
  | typeof xs_1
  | typeof sm_1
  | typeof md_1
  | typeof lg_1
  | typeof xl_1
  | typeof xxl_1;
export type PropertyBlockType = 'id' | 'date';
export type OverflowType = 'scroll';
export type SortType = '+asc' | '+desc';
export type AlignmentType = 'start' | 'center' | 'end';

export type SelectorType = { label: string; value: string | number };

export enum OrderStatuses {
  Issued = 'issued',
  InProcessing = 'inProcessing',
  ShippedWarehouse = 'shippedWarehouse',
  AtWarehouse = 'atWarehouse',
  ShippedCountry = 'shippedCountry',
  ArrivedCountry = 'arrivedCountry',
  AwaitingPickup = 'awaitingPickup',
  SentClient = 'sentClient',
  Canceled = 'canceled',
  Completed = 'completed',
  Deleted = 'deleted',
}

export enum OrderCurrency {
  Yuan = 'Юань',
  Other = 'Другая',
}

export enum SocialMediaEnum {
  Google = 'google',
  Facebook = 'facebook',
  Vkontakte = 'vkontakte',
  LinkedIn = 'linkedIn',
}
