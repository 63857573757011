import {
  darkBlue,
  grey3,
  paleBlue,
  raspberry,
  white,
} from 'constants/styles/colors';
import styled, { css } from 'styled-components';
import { stepBackButtonDiameter } from 'components/common/buttons/ImageButtons/constants';
import { BackgroundColor } from 'types/styles';
import { Span } from 'components/common/typography/Span';

export const StyledButton = styled.button``;

export const IconButton = styled.button`
  height: 40px;
  width: 40px;
  color: ${paleBlue};
  transition: color 0.3s;

  &:hover {
    color: ${white};
  }
`;

interface ButtonProps extends BackgroundColor {}

export const ModalButton = styled.button<ButtonProps>`
  position: fixed;
  height: 40px;
  width: 40px;
  color: ${({ backgroundColor }) =>
    backgroundColor === 'white' ? grey3 : paleBlue};

  ${({ backgroundColor }) =>
    backgroundColor && `background-color: ${backgroundColor}`};
  border-radius: 6px;

  &:hover {
    color: ${({ backgroundColor }) =>
      backgroundColor === 'white' ? raspberry : white};
  }
`;

export const BackButton = styled.button`
  color: ${darkBlue};
  height: ${stepBackButtonDiameter};
  border-radius: 4px;
  background-color: transparent;
  transition: color 0.3s, background-color 0.3s;

  svg {
    height: ${stepBackButtonDiameter};
    width: ${stepBackButtonDiameter};
  }

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background-color: ${darkBlue};
        color: ${white};
      `}
  }

  :disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`;

export const FileButton = styled.button`
  display: flex;
  align-items: center;
  padding: 4px 9px;
  color: ${white};
  border-radius: 5px;
  border: 1px solid ${white};
  background-color: ${darkBlue};
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    color: ${raspberry};
    background-color: ${white};

    ${Span} {
      color: ${raspberry};
      transition: color 0.3s;
    }
  }
`;
