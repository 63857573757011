import { white } from 'constants/styles/colors';
import styled from 'styled-components';

export const TitleSpan = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${white};
  text-align: center;
`;
