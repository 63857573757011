import { Column, Section } from 'components/wrappers/grid/FlexWrapper';
import { black, gray, grey4, grey5 } from 'constants/styles/colors';
import { xs } from 'constants/styles/sizes';
import styled, { css } from 'styled-components';
import { Color } from 'types/styles';

const commonStyleMixin = css`
  align-items: center;
  font-size: 12px;
  line-height: 24px;
`;

export const TitleText = styled.span`
  font-size: 16px;
  line-height: 20px;
  color: ${black};
`;

export const StyledSection = styled(Section)`
  min-height: 30px;
  padding-top: 5px;
  border-bottom: 2px solid ${grey5};
`;

export const InvoiceHeader = styled(Section)`
  height: 30px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 15px 0px 10px;
  align-items: center;
  font-size: 12px;
  line-height: 15px;
  color: ${black};
  border-radius: 6px;
  background-color: ${grey4};
`;

export const InvoiceSubHeader = styled(Section)`
  ${commonStyleMixin};
  margin-top: 10px;
  color: ${black};
  border-bottom: 2px solid ${grey5};
`;

export const InvoiceTitle = styled.span<Color>`
  ${commonStyleMixin};
  color: ${gray};
  margin-right: 10px;
`;

export const ValueSpan = styled.span`
  ${commonStyleMixin};
  color: ${black};
`;
export const InvoiceContentWrapper = styled(Column)`
  height: 100%;
  width: 410px;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: ${xs}) {
    width: 100%;
  }
`;
