import { createEffect, createStore } from 'effector';
import { API } from 'api';
import { defaultGetAllResponse } from 'constants/defaults/store';
import { getDataAndOpenErrorModal } from 'utils/store';

const getAllPackages = createEffect({
  handler: async () => {
    try {
      return await API.packages.getAllPackages();
    } catch (error) {
      getDataAndOpenErrorModal(error);
    }
  },
});

const packages = createStore<API.GetAllPackagesResponseDto>(
  defaultGetAllResponse,
).on(getAllPackages.doneData, (_, newState) => newState);

export const packagesEffects = { getAllPackages };
export const packagesEvents = {};
export const packagesStores = { packages };
