import { combine, createEvent, createStore } from 'effector';
import { Sizes, StrictVisibility } from 'types/styles';
import { Noop } from 'types/types';
import { Title } from 'types/data';
import { API } from 'api';

interface Modal extends StrictVisibility {}

const initializeStoreModal = () => {
  const openModal = createEvent();
  const closeModal = createEvent();

  const modal = createStore<Modal>({
    visible: false,
  })
    .on(openModal, () => ({
      visible: true,
    }))
    .on(closeModal, () => ({
      visible: false,
    }));

  return { modal, openModal, closeModal };
};

export const inputModal = initializeStoreModal();
export const confirmEmailModal = initializeStoreModal();
export const insuranceModal = initializeStoreModal();
export const termsOfShipmentModal = initializeStoreModal();
export const adaptiveNotificationModal = initializeStoreModal();
// export const updateSubscriptionModal = initializeStoreModal();

const initializeGenericStore = <T>(initialState: T) => {
  const openModal = createEvent<T>();
  const closeModal = createEvent();

  const visible = createStore(false)
    .on(openModal, () => true)
    .on(closeModal, () => false);
  const state = createStore<T>(initialState).on(
    openModal,
    (_, newState) => newState,
  );
  const modal = combine(visible, state);

  return { modal, openModal, closeModal };
};

export interface InformationModalPayload extends Pick<Sizes, 'width'> {
  message: string;
  buttonText?: string;
  actionAfterCloseClick?: Noop;
}

export const informationModalStore =
  initializeGenericStore<InformationModalPayload>({
    message: '',
    buttonText: '',
    width: '',
  });

export interface InvoiceModalPayload extends Partial<API.CreateOrderDto> {}

export const invoiceModalStore = initializeGenericStore<InvoiceModalPayload>(
  {},
);

interface TitleModalPayload extends Required<Pick<Title, 'title'>> {}

export const titleModal = initializeGenericStore<TitleModalPayload>({
  title: '',
});

export const inputEmailModal =
  initializeGenericStore<API.SignUpWithSocialMediaDto>({
    email: '',
    socialId: NaN,
    firstName: '',
    lastName: '',
    provider: '',
  });
