import { lightBlue2, whiteHover2 } from 'constants/styles/colors';
import { flexStart } from 'constants/styles/mixins';
import styled from 'styled-components';

export const TableRow = styled.div`
  width: 100%;
  ${flexStart}
  align-items: center;
  padding-bottom: 12px;
  padding-top: 25px;
  border-bottom: 1px solid ${lightBlue2};

  &:last-child {
    border-bottom: none;
  }
`;

export const Description = styled.span`
  font-size: 14px;
  line-height: 17px;
  word-break: break-word;

  color: #ffffff;
`;

export const Title = styled.span`
  font-size: 14px;
  line-height: 17px;
  font-weight: 600px;
  color: ${whiteHover2};
`;
