import React, { useEffect } from 'react';
import {
  StepInterface,
  Steps,
} from 'components/grid/Wizards/ApproximateCostCalculationWizard/types';
import { useFormSchema } from 'hooks/form';
import {
  contentMarginBottom,
  yupSchema,
} from 'components/forms/OrdersFroms/InitialStepFrom/constants';
import { Form } from 'components/forms/styles';

import { InitialStepData } from 'components/forms/OrdersFroms/InitialStepFrom/types';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import { FormSelect } from 'components/forms/inputs/FormSelect';
import { languages } from 'constants/languages';
import { useStore } from 'effector-react';
import { countriesEffects, countriesStores } from 'stores/countries';
import { Button } from 'components/common/buttons/Button';
import { categoriesEffects, categoriesStores } from 'stores/categories';
import { methodsEffects, methodsStores } from 'stores/methods';
import { tariffsEffects } from 'stores/tariffs';
import { calculatorEvents } from 'stores/calculator';

const { getAllCountries } = countriesEffects;
const { getSubcategoriesByCountry } = categoriesEffects;
const { getMethods } = methodsEffects;
const { getTariff } = tariffsEffects;
const { setCalculatorStoreValue } = calculatorEvents;

const {
  initialStepForm,
  text: { submitButton },
} = languages.pages.shipments;

interface InitialStepFromProps
  extends Pick<StepInterface, 'onNext'>,
    Partial<InitialStepData> {}

export const InitialStepFrom = ({
  onNext,
  country,
  subcategory,
  method,
}: InitialStepFromProps) => {
  const {
    handleSubmit,
    formState: { isValid },
    control,
    watch,
  } = useFormSchema(yupSchema);

  const countryFieldValue: string = watch().country;
  const subcategoryFieldValue: string = watch().subcategory;
  const methodFieldValue: string = watch().method;

  const countries = useStore(countriesStores.countries).result.map(
    ({ title, _id }) => ({ value: _id, label: title }),
  );

  const subcategories = useStore(categoriesStores.subcategories).result.map(
    ({ title, _id }) => ({ value: _id, label: title }),
  );

  const methods = useStore(methodsStores.methods).result.map(
    ({ title, _id }) => ({ value: _id, label: title }),
  );

  const loadingCountries = useStore(getAllCountries.pending);
  const loadingSubcategories = useStore(getSubcategoriesByCountry.pending);
  const loadingMethods = useStore(getMethods.pending);

  const onSubmit = async ({
    country,
    subcategory,
    method,
  }: InitialStepData) => {
    setCalculatorStoreValue({ country, subcategory, method });
    onNext(Steps.dimensionsStep);
  };

  useEffect(() => {
    countries.length === 0 && getAllCountries();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    countryFieldValue &&
      getSubcategoriesByCountry({ country: countryFieldValue });
  }, [countryFieldValue]);

  useEffect(() => {
    countryFieldValue &&
      subcategoryFieldValue &&
      getMethods({
        country: countryFieldValue,
        subcategory: subcategoryFieldValue,
      });
  }, [countryFieldValue, subcategoryFieldValue]);

  useEffect(() => {
    methodFieldValue &&
      getTariff({
        country: countryFieldValue,
        subcategory: subcategoryFieldValue,
        method: methodFieldValue,
      });
  }, [countryFieldValue, subcategoryFieldValue, methodFieldValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Section marginBottom={contentMarginBottom}>
        <FormSelect
          control={control}
          defaultValue={countries.find(({ value }) => value === country)}
          id="country"
          isLoading={loadingCountries}
          name="country"
          selector={countries}
          {...initialStepForm['country']}
        />
      </Section>
      <Section marginBottom={contentMarginBottom}>
        <FormSelect
          control={control}
          defaultValue={subcategories.find(
            ({ value }) => value === subcategory,
          )}
          id="subcategory"
          isLoading={loadingSubcategories}
          name="subcategory"
          selector={subcategories}
          {...initialStepForm['subcategory']}
        />
      </Section>
      <Section marginBottom="40px">
        <FormSelect
          control={control}
          defaultValue={methods.find(({ value }) => value === method)}
          id="method"
          isLoading={loadingMethods}
          name="method"
          selector={methods}
          {...initialStepForm['method']}
        />
      </Section>
      <Section alignCenter justifyCenter>
        <Button disabled={!isValid} type="submit">
          {submitButton}
        </Button>
      </Section>
    </Form>
  );
};
