import { DataPickerStyles } from 'components/common/inputs/DateInput/styles';
import { createGlobalStyle } from 'styled-components';
import { hColor, linkColor, white } from './colors';

export const GlobalStyle = createGlobalStyle`
  body, html, #root {
    height: 100%;

    /* Hide scrollbar for Chrome, Safari and Opera */
    ::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

}

  body, textarea, input {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  //code {
  //  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  //}

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    //color: ${linkColor};
  }

  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${hColor}
  }

  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${hColor}
  }

  span, h1, h2, h3, div {
    color: ${white};
  }

  /* Reset Style */

  button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
  }

  input:focus {
    border: 0;
    outline: none;
  }

  ${DataPickerStyles}

`;
