import React from 'react';
import {
  StepInterface,
  Steps,
} from 'components/grid/Wizards/OrderWizard/types';
import { AddressForm } from 'components/forms/AddressForm';
import { AddressFormInterface } from 'components/forms/AddressForm/types';
import { getDeliveryPost } from 'utils/data';
import { addressesEffects } from 'stores/addresses';

const { formCreateAddress, getAllMyAddresses } = addressesEffects;

interface NewAddressStepProps extends StepInterface {}

export const NewAddressStep = ({ onNext }: NewAddressStepProps) => {
  const onNextStep = () => onNext(Steps.confirmationStep);

  const onSubmit = async ({
    firstName,
    lastName,
    country,
    city,
    postCode,
    addressName,
    secondName,
    delivery,
    apartmentNumber,
    area,
    buildingHouse,
    house,
    phoneNumber,
    street,
  }: AddressFormInterface) => {
    const data = {
      firstName,
      lastName,
      country,
      city,
      postCode: postCode === null ? undefined : postCode,
      apartmentNumber,
      region: area,
      frameNumber: buildingHouse,
      buildingNumber: house,
      phone: phoneNumber,
      street,
      title: addressName,
      deliveryPoint: getDeliveryPost(delivery),
      middleName: secondName,
    };

    const actionAfterCloseClick = async () => {
      await getAllMyAddresses();
      onNextStep();
    };
    await formCreateAddress({
      ...data,
      actionAfterCloseClick,
    });
  };

  return (
    <>
      <AddressForm onClose={onNextStep} onSubmit={onSubmit} />
    </>
  );
};
