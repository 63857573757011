import { Loader } from 'components/dynamic/Loader';
import { Section } from 'components/wrappers/grid/FlexWrapper';
import React, { FC } from 'react';
import { Loading } from 'types/data';
import { Disabled } from 'types/form';
import { ReactClick } from 'types/react';
import { MinSizes } from 'types/styles';

import { StyledButton } from './styles';

interface ConfirmationButtonProps
  extends Loading,
    Disabled,
    ReactClick<HTMLButtonElement>,
    Pick<MinSizes, 'minWidth'> {
  buttonType?: 'primary' | 'secondary' | 'tertiary';
}

export const ConfirmationButton: FC<ConfirmationButtonProps> = ({
  isLoading,
  minWidth,
  disabled,
  buttonType = 'primary',
  onClick,
  children,
}) => (
  <StyledButton
    buttonType={buttonType}
    disabled={disabled || isLoading}
    minWidth={minWidth}
    onClick={onClick}
  >
    {isLoading ? (
      <Section alignCenter justifyCenter>
        <Loader />
      </Section>
    ) : (
      children
    )}
  </StyledButton>
);
