import React from 'react';

export const MobileAccountIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3858 4.74725C14.3858 7.07517 12.4199 8.99451 9.95434 8.99451C7.48877 8.99451 5.52283 7.07517 5.52283 4.74725C5.52283 2.41933 7.48877 0.5 9.95434 0.5C12.4199 0.5 14.3858 2.41933 14.3858 4.74725ZM0.517348 19.5C0.814243 15.231 4.88661 11.7527 10 11.7527C15.1134 11.7527 19.1858 15.231 19.4827 19.5H0.517348Z"
      stroke="#90A8E4"
    />
  </svg>
);
