import { ConfirmationStepForm } from 'components/forms/OrdersFroms/ConfirmationStepForm';
import { StepInterface } from 'components/grid/Wizards/OrderWizard/types';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { MediaSizes } from 'constants/styles/sizes';
import { useStore } from 'effector-react';
import {
  mobileStepContentWrapperPadding,
  stepContentWrapperPadding,
} from 'pages/Shipments/constants';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { ordersStores } from 'stores/orders';

interface ConfirmationStepProps extends StepInterface {}

export const ConfirmationStep = (props: ConfirmationStepProps) => {
  const order = useStore(ordersStores.orderFormData);
  const isMobileLarge = useMediaQuery(MediaSizes.MobileLarge);

  return (
    <ContentWrapper
      padding={
        isMobileLarge
          ? mobileStepContentWrapperPadding
          : stepContentWrapperPadding
      }
      width="100%"
    >
      <ConfirmationStepForm {...order} {...props} />
    </ContentWrapper>
  );
};
