import axios from 'api/axios';

export const getTariff = (data: API.GetTariffRequestDto) =>
  axios<API.GetTariffResponseDto>({
    url: `/tariffs/search-tariff`,
    method: 'POST',
    data,
  });

export const getTariffCost = (data: API.GetTariffCostRequestDto) =>
  axios<API.GetTariffCostResponseDto>({
    url: `/tariffs/cost-calc`,
    method: 'POST',
    data,
  });
