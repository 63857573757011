import * as Yup from 'yup';
import {
  yupPositiveNumber,
  yupProductHeight,
  yupProductLength,
  yupProductWidth,
  yupProductVolume,
} from 'constants/yupFields';
import {
  DimensionsProps,
  DimensionsStepData,
} from 'components/forms/OrdersFroms/DimensionsStepForm/types';

export const contentMarginBottom = '10px';

export const getDimensionValue = (value: number) => (isNaN(value) ? 0 : value);

export const getVolumeValueInMeters = ({
  height,
  width,
  length,
}: DimensionsProps) => {
  const conversionCoefficient = 100;
  const volume =
    (getDimensionValue(height) / conversionCoefficient) *
    (getDimensionValue(width) / conversionCoefficient) *
    (getDimensionValue(length) / conversionCoefficient);

  return Number(volume.toFixed(2));
};

export const getDimensionsValue = ({
  height,
  width,
  length,
}: Pick<DimensionsStepData, 'width' | 'length' | 'height'>) =>
  height && width && length ? `${height}x${width}x${length}` : undefined;

export const yupSchema = Yup.object().shape({
  weight: yupPositiveNumber,
  height: yupProductHeight,
  width: yupProductWidth,
  length: yupProductLength,
  volume: yupProductVolume,
});
