import { accountLink, shipmentsLink, supportLink } from 'constants/routes';
import {
  AccountIcon,
  ShipmentsIcon,
  SupportIcon,
} from 'components/common/icons/SVGIcons';
import { languages } from 'constants/languages';

const { account, shipments, support } =
  languages.layouts.headers.authorizedHeader.menu;

export const menuItems = [
  {
    title: account,
    path: accountLink,
    Icon: AccountIcon,
  },
  {
    title: shipments,
    path: shipmentsLink,
    Icon: ShipmentsIcon,
  },
  {
    title: support,
    path: supportLink,
    Icon: SupportIcon,
  },
];
