import { ContentWrapperInterface } from '../ModalWrapper';

export const contentWrapperProps: ContentWrapperInterface = {
  height: 'fit-content',
  width: '460px',
  backgroundColor: 'white',
  borderRadius: '6px',
};

export const contentPadding = '0';

export const iconDiameter = '32px';
export const smallIconDiameter = '20px';

export const rowMaxWidth = '335px';
