import styled from 'styled-components';
import { ContentWrapper } from 'components/wrappers/ContentWrapper';
import { contentMarginBottom } from 'components/forms/AddressForm/constants';

export const FormWrapper = styled(ContentWrapper)`
  position: relative;
  width: 100%;
  margin-bottom: ${contentMarginBottom};
  padding: 30px;
`;
